import { useCallback, useMemo } from 'react';

import { useLdFlags } from '@selfridges-pkg/feature-flag/LDContext';

import {
  transformToBundleProductMap,
  transformToBundleProductMediaMap,
  transformToBundleProductSkusMap,
} from '../transformers/transformBundlesResponse';

import { getStockAndPriceFromMap } from './useSku';

import type { BundlesResponse } from '@selfridges-pkg/product-aggregation-service-api/contracts/BundlesResponse';
import type {
  ColourInfos,
  GetBundleProducts,
  SelectedColour,
  Sizes,
  BundleProduct,
  CountryLanguage,
  BundleProductBase,
  GetSku,
} from '../types';

function getColourType(colours?: ColourInfos) {
  if (!colours) return 'NO_COLOUR';
  if (colours.length === 1) return 'SINGLE_COLOUR';
  return 'MULTI_COLOUR';
}

function getSizeType(sizes?: Sizes) {
  if (!sizes) return 'NO_SIZE';
  if (sizes.length === 1) return 'SINGLE_SIZE';
  return 'MULTI_SIZE';
}

function getAction(bundleProduct: BundleProductBase) {
  const colourType = getColourType(bundleProduct.colours);
  const sizeType = getSizeType(bundleProduct.sizes);

  if (colourType !== 'MULTI_COLOUR' && sizeType !== 'MULTI_SIZE') {
    return 'ADD_TO_BAG';
  }

  if (colourType !== 'MULTI_COLOUR' && sizeType === 'MULTI_SIZE') {
    return 'SELECT_SIZE';
  }

  if (colourType === 'MULTI_COLOUR' && sizeType !== 'MULTI_SIZE') {
    return 'SELECT_COLOUR';
  }

  return 'SELECT_SIZE';
}

function getColourAndSizeToUse({
  bundleProduct,
  parentSelectedColour,
  getSku,
}: {
  bundleProduct: BundleProductBase;
  parentSelectedColour: SelectedColour;
  parentProductColours?: ColourInfos;
  getSku: GetSku;
}): {
  colour: string;
  size: string;
} {
  const colourType = getColourType(bundleProduct.colours);
  const sizeType = getSizeType(bundleProduct.sizes);
  let colourToUse = '';
  let sizeToUse = '';

  if (sizeType === 'NO_SIZE') {
    sizeToUse = 'NOT_APPLICABLE';
  } else if (sizeType === 'SINGLE_SIZE') {
    sizeToUse = bundleProduct.sizes?.[0] || '';
  }

  if (colourType === 'NO_COLOUR') {
    return {
      colour: 'NOT_APPLICABLE',
      size: sizeToUse,
    };
  } else if (colourType === 'SINGLE_COLOUR') {
    return {
      colour: bundleProduct.colours?.[0].name || '',
      size: sizeToUse,
    };
  }

  const hasMatchingColour = bundleProduct.colours?.some(colour => colour.name === parentSelectedColour.value);

  if (!hasMatchingColour) {
    if (sizeType !== 'MULTI_SIZE') {
      colourToUse = 'FIRST_AVAILABLE';
    }
  } else {
    colourToUse = parentSelectedColour.value;

    if (getSku({ colour: parentSelectedColour.value, size: sizeToUse }).outOfStock) {
      colourToUse = 'FIRST_AVAILABLE';
    }
  }

  if (colourToUse === 'FIRST_AVAILABLE') {
    colourToUse =
      bundleProduct.colours?.find(colour => !getSku({ colour: colour.name, size: sizeToUse }).outOfStock)?.name || '';
  }

  return {
    colour: colourToUse,
    size: sizeToUse,
  };
}

export function useBundles({
  bundlesResponse,
  countryLanguage,
  parentColourInfos,
}: {
  countryLanguage: CountryLanguage;
  bundlesResponse?: BundlesResponse;
  parentColourInfos?: ColourInfos;
}) {
  const bundleProductSkusMap = useMemo(
    () => transformToBundleProductSkusMap(bundlesResponse, countryLanguage.countryCode),
    [bundlesResponse, countryLanguage.countryCode],
  );
  const ldFlags = useLdFlags();
  const enableNewProductRoute = ldFlags['enable-new-product-route'];
  const bundleProductMap = useMemo(
    () => transformToBundleProductMap(bundlesResponse, countryLanguage, enableNewProductRoute),
    [bundlesResponse, countryLanguage, enableNewProductRoute],
  );
  const bundleProductMediaMap = useMemo(() => transformToBundleProductMediaMap(bundlesResponse), [bundlesResponse]);
  const bundleIds = useMemo(() => Object.keys(bundlesResponse || {}), [bundlesResponse]);

  const getBundleProducts = useCallback<GetBundleProducts>(
    ({ bundleId, selectedColour: parentSelectedColour }) => {
      const products = Object.values(bundleProductMap[bundleId] || {});

      if (!products.length) return [];

      return products.reduce((bundleProducts: Array<BundleProduct>, product) => {
        const getSku: GetSku = ({ colour, size }) =>
          getStockAndPriceFromMap({
            stockAndPriceMap: bundleProductSkusMap[bundleId][product.partNumber],
            colour,
            size,
          });

        const { colour, size } = getColourAndSizeToUse({
          bundleProduct: product,
          parentProductColours: parentColourInfos,
          parentSelectedColour,
          getSku,
        });

        if (!colour) return bundleProducts;

        const { outOfStock, price, isFinalPrice } = getSku({ colour, size });

        if (outOfStock) return bundleProducts;

        const getMedia = (colour: string) => bundleProductMediaMap[bundleId][product.partNumber][colour];

        const getRawData = () => {
          if (!bundlesResponse) return;

          return bundlesResponse[bundleId].find(bundleData => bundleData.partNumber === product.partNumber);
        };

        bundleProducts.push({
          ...product,
          selectedColour: {
            value: colour,
            display: colour === 'NOT_APPLICABLE' ? '' : colour,
            source: 'NOT_SELECTED',
          },
          selectedSize: {
            value: size,
            display: size === 'NOT_APPLICABLE' ? '' : size,
          },
          price,
          isFinalPrice: Boolean(isFinalPrice),
          action: getAction(product),
          getSku,
          getRawData,
          getMedia,
        });

        return bundleProducts;
      }, []);
    },
    [bundleProductMap, parentColourInfos, bundleProductSkusMap, bundleProductMediaMap, bundlesResponse],
  );

  return { bundleIds, getBundleProducts };
}
