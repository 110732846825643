import styled from 'styled-components';

import { getStaticResourceUrl } from '@selfridges-pkg/utils';

import KeyIconPath from './icons/KeyIcon.svg';
import RosetteIconPath from './icons/RosetteIcon.svg';
import GbFlagIconPath from './icons/GbFlagIcon.svg';
import ClickAndCollectIconPath from './icons/ClickAndCollectIcon.svg';
import CountdownTimerPath from './icons/CountdownTimerCalendar.svg';

const BaseIcon = styled.div({
  width: '4.8rem',
  height: '4.8rem',

  '::after': {
    content: '" "',
    display: 'block',
    width: '100%',
    height: '100%',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
  },
});

export const KeyIcon = styled(BaseIcon)({
  '::after': {
    background: `url(${getStaticResourceUrl(KeyIconPath)})`,
  },
});

export const RosetteIcon = styled(BaseIcon)({
  '::after': {
    background: `url(${getStaticResourceUrl(RosetteIconPath)})`,
  },
});

export const GbFlagIcon = styled(BaseIcon)({
  '::after': {
    background: `url(${getStaticResourceUrl(GbFlagIconPath)})`,
  },
});

export const ClickAndCollectIcon = styled(BaseIcon)({
  '::after': {
    background: `url(${getStaticResourceUrl(ClickAndCollectIconPath)})`,
  },
});

export const CountdownTimerIcon = styled(BaseIcon)({
  '::after': {
    background: `url(${getStaticResourceUrl(CountdownTimerPath)})`,
  },
});
