import React from 'react';
import styled from 'styled-components';

import { typography } from '@selfridges-co/frontend-sdk-react-theme';

type KlarnaMessageProps = {
  price: number;
};

const Container = styled.div({
  '#klarna-message': {
    '&::part(osm-container)': {
      ...typography.typeface.text.md.regular,
      border: 'none',
      fontSize: 'initial',
    },

    '&::part(osm-cta)': {
      ...typography.typeface.text.md.bold,
    },

    '&::part(osm-legal)': {
      ...typography.typeface.text.sm.regular,
    },
  },
});

function KlarnaMessage({ price }: KlarnaMessageProps) {
  return (
    <Container
      dangerouslySetInnerHTML={{
        __html: `<klarna-placement id="klarna-message" data-key="credit-promotion-auto-size" data-locale="en-GB" data-purchase-amount="${Math.trunc(
          price * 100,
        )}"></klarna-placement>`,
      }}
    ></Container>
  );
}

export default KlarnaMessage;
