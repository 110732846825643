import { env, getCurrentUKTime, parseUKTime } from '@selfridges-pkg/utils';

export function getLegacyServiceBannerHeight(): number {
  if (typeof window === 'undefined') return 0;

  const serviceBanner = document.querySelector('.box-yellow.container-fluid');

  return serviceBanner ? (serviceBanner as HTMLElement).offsetHeight : 0;
}

export function convertToHoverImage(defaultImage?: string, withFallback?: boolean) {
  if (!defaultImage) return '';

  const defaultImageName = defaultImage.split('/').pop() as string;
  const hoverImageName = defaultImageName.replace(/_M($|.jpeg$)/, '_ALT01$1');

  const fallback = withFallback ? `?defaultimage=${defaultImageName}` : '';

  return `${defaultImage.replace(defaultImageName, hoverImageName)}${fallback}`;
}

export function removeHtmlTags(text: string): string {
  return text.replace(/(<([^>]+)>)/gi, '');
}

function isLaunchDateInTheFuture(launchDate: string) {
  const ukCurrentDate = new Date(parseUKTime(getCurrentUKTime()));
  const ukLaunchDate = new Date(
    parseUKTime(new Date(launchDate).toLocaleString('en-GB', { timeZone: 'Europe/London' })),
  );

  return ukCurrentDate < ukLaunchDate;
}

export function isNoIndexProduct(launchDate?: string): boolean {
  if (launchDate) {
    return isLaunchDateInTheFuture(launchDate);
  }

  return false;
}

export function cleanHtmlString(text: string) {
  const tags = ['a', 'b', 'ul', 'strong', 'p', 'li'];

  const escapeTags = (match: string, pattern1: string | undefined) => {
    return pattern1 ? match : match.replace(/</g, '&lt;').replace(/>/g, '&gt;');
  };

  let cleanedText = text
    .replace(/^\s*"?([^"]*?)"?\s*$/gi, '$1') //removing leading and trailing quotations and spacing if there's any
    .replace(/^\s*<br[ ]*\/?>/i, '') // removing leading <br> if there is any
    .replace(/<\s*\/\s*(\w+)\s*>/gi, '</$1>'); //replacing any broken closing tags that have extra spacing such as </ a> or </ p>

  tags.forEach(tag => {
    const regex = new RegExp(`<(${tag})\\b[^>]*>(?:(?!<\\1\\b).)*<\/\\1>|<${tag}\\b[^>]*>`, 'gi'); //tag pairs or floating opening tags
    cleanedText = cleanedText.replace(regex, escapeTags);
  });

  return cleanedText;
}

export function generateErrorImageUrl() {
  const MEDIA_ROOT_URL = env('REACT_APP_MEDIA_ROOT_URL') || '';

  return `${MEDIA_ROOT_URL}/image/selfridges/image-not-found-selfridges`;
}
