import { MouseEvent } from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

import { Link } from '@selfridges-co/frontend-sdk-react-link';
import { spacing } from '@selfridges-co/frontend-sdk-react-theme';

type MoreInformationLinkProps = {
  onClick: (event?: MouseEvent<HTMLElement>) => void;
};

const MoreInfoWrapper = styled.div({
  margin: spacing(2, 0),
});

export default function MoreInformationLink({ onClick }: MoreInformationLinkProps) {
  return (
    <MoreInfoWrapper>
      <Link as="button" linkType="primary" variant="black" onClick={onClick}>
        <Trans i18nKey="app.more-information-link.text">More information</Trans>
      </Link>
    </MoreInfoWrapper>
  );
}
