import styled from 'styled-components';

import { breakpoints, spacing, color, typography } from '@selfridges-co/frontend-sdk-react-theme';

import { StockMessage } from '../stock-message/StockMessage';

import Swatch from './Swatch';

import type { ColourInfos, GetSku, SelectedColour, SelectedSize, SetSelectedColour } from '../types';

export interface ColourListProps {
  colours: ColourInfos;
  getSku: GetSku;
  selectedColour: SelectedColour;
  selectedSize: SelectedSize;
  onSelect: SetSelectedColour;
  onClick?: (event?: React.MouseEvent<HTMLInputElement>) => void;
}

const ColourListWrapper = styled.div({
  width: '100%',
  padding: spacing(0, 3),
  boxSizing: 'border-box',

  [breakpoints.md.mediaQuery]: {
    padding: spacing(0, 6),
  },
});

const ListContainer = styled.div({
  paddingBottom: spacing(0, 3, 3, 3),

  [breakpoints.md.mediaQuery]: {
    paddingBottom: spacing(0, 6, 6, 6),
  },
});

const ColourName = styled.div({
  display: 'inline-flex',
  alignItems: 'baseline',
  flexGrow: 1,
  padding: spacing(3),
  whiteSpace: 'nowrap',
  minWidth: 0,
});

const ColourContainer = styled.label<{ selected: boolean; outOfStock: boolean }>(({ selected, outOfStock }) => ({
  ...typography.typeface.text.md.regular,
  position: 'relative',
  color: color.palette.mainBlack,
  padding: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  margin: spacing(1, 0),
  transition: 'all 0.1s ease-out',

  ...(selected && {
    ...typography.typeface.text.md.bold,
    boxShadow: `inset 0px 0px 0px 2px ${color.palette.mainBlack}`,
  }),

  '@media (hover: hover)': {
    ':hover': {
      boxShadow: `inset 0px 0px 0px 1px ${color.palette.midGrey2}`,
    },
  },

  ...(outOfStock && {
    color: color.palette.midGrey2,
  }),
}));

const RadioInput = styled.input({
  cursor: 'pointer',
  width: '100%',
  height: '100%',
  appearance: 'none',
  opacity: 1,
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 1,
});

export function ColourList({ colours, selectedColour, selectedSize, onSelect, onClick, getSku }: ColourListProps) {
  function handleClick(event: React.MouseEvent<HTMLInputElement>) {
    event.stopPropagation();

    // event.detail === 0 when interact via keyboard
    if (event.detail && onClick) {
      onClick(event);
    }
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    onSelect(event.currentTarget.value, 'USER_CLICK_FROM_COLOUR_LIST');
  }

  return (
    <ColourListWrapper>
      <ListContainer>
        {colours.map(({ name, swatchUrl }) => {
          const selected = name === selectedColour.value;
          const { availableStock, outOfStock } = getSku({ colour: name, size: selectedSize.value });

          return (
            <ColourContainer key={name} selected={selected} outOfStock={outOfStock}>
              <RadioInput
                type="radio"
                name="colour-list-radio"
                value={name}
                onClick={handleClick}
                onChange={handleChange}
                checked={selected}
                autoFocus={selected}
              />
              <Swatch src={swatchUrl} outOfStock={outOfStock} />
              <ColourName>
                {name} <StockMessage stockAmount={availableStock} messageColour={outOfStock ? 'grey' : 'red'} />
              </ColourName>
            </ColourContainer>
          );
        })}
      </ListContainer>
    </ColourListWrapper>
  );
}
