import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { fetchStockAndPrice } from '@selfridges-pkg/product-aggregation-service-api/fetchStockAndPrice';
import { StockAndPriceResponse } from '@selfridges-pkg/product-aggregation-service-api/contracts/StockAndPriceResponse';
import { env } from '@selfridges-pkg/utils';

const PRODUCT_API_HOST = () => env('REACT_APP_PRODUCT_API_HOST') || '';
const PRODUCT_API_KEY = () => env('REACT_APP_PRODUCT_API_KEY') || '';

export function useFetchStockAndPrice({
  countryLanguage,
  partNumber,
}: {
  countryLanguage: string;
  partNumber: string;
}): StockAndPriceResponse | undefined {
  const [dataKey, setDataKey] = useState<string | null>(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search);
    setDataKey(searchParams.get('stockAndPriceDataKey') || '');
  }, []);

  const { data } = useQuery({
    enabled: dataKey !== null,
    queryKey: [countryLanguage, 'product', partNumber, 'stock-and-price'],
    queryFn: () =>
      fetchStockAndPrice({
        hostRootUrl: PRODUCT_API_HOST(),
        countryLanguage,
        partNumber,
        additionalHeaders: {
          ...(PRODUCT_API_KEY() && { 'x-api-key': PRODUCT_API_KEY() }),
          ...(dataKey && { 'X-Stock-And-Price-Data-Key': dataKey }),
        },
      }),
    refetchOnWindowFocus: true,
    staleTime: 1800000, // 30 minutes
  });

  return data;
}
