import styled from 'styled-components';

import { spacing, breakpoints } from '@selfridges-co/frontend-sdk-react-theme';

import DeliveryChannel from './DeliveryChannel';
import ClickAndCollect, { ClickAndCollectOption } from './ClickAndCollect';
import Returns, { ReturnsProps } from './Returns';

import type { DeliveryOption, SelfridgesPlus } from '../types';

type Delivery = {
  title: string;
  unavailableMessage: string;
  selfridgesPlus: SelfridgesPlus;
  options: Array<DeliveryOption>;
};

export type DeliveryAndReturnsProps = {
  localDelivery: Delivery;
  internationalDelivery: Delivery;
  clickAndCollect: ClickAndCollectOption;
  returns: ReturnsProps;
};

const Container = styled.div({
  padding: spacing(0, 4, 6, 4),

  [breakpoints.md.mediaQuery]: {
    padding: spacing(0, 6, 6, 6),
  },
});

function DeliveryAndReturns({
  localDelivery,
  internationalDelivery,
  clickAndCollect,
  returns,
}: DeliveryAndReturnsProps) {
  return (
    <Container>
      <DeliveryChannel
        options={localDelivery.options}
        title={localDelivery.title}
        unavailableMessage={localDelivery.unavailableMessage}
        selfridgesPlus={localDelivery.selfridgesPlus}
      />
      <DeliveryChannel
        options={internationalDelivery.options}
        title={internationalDelivery.title}
        unavailableMessage={internationalDelivery.unavailableMessage}
        selfridgesPlus={internationalDelivery.selfridgesPlus}
      />
      <ClickAndCollect isAvailable={clickAndCollect.isAvailable} />
      <Returns {...returns} />
    </Container>
  );
}

export default DeliveryAndReturns;
