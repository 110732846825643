import React, { useRef, useState } from 'react';
import styled, { DefaultTheme, StyledComponent } from 'styled-components';

import { color, spacing, typography, breakpoints } from '@selfridges-co/frontend-sdk-react-theme';

interface WithTooltipProps {
  tooltip: string;
  tooltipMaxWidth?: number;
}

const MIN_MARGIN = 12;

const Tooltip = styled.div<{ visible: boolean }>(({ visible }) => ({
  display: 'none',

  [breakpoints.md.mediaQuery]: {
    display: 'block',
    visibility: visible ? 'visible' : 'hidden',
    opacity: visible ? 1 : 0,
    transition: 'all ease-out 0.2s',

    ...(!visible && {
      position: 'absolute',
      top: '-9999px',
      left: '-9999px',
    }),
  },
}));

const Content = styled.div<{ maxWidth?: number }>(({ maxWidth }) => ({
  ...typography.typeface.text.md.regular,
  position: 'absolute',
  background: color.palette.mainBlack,
  color: color.palette.mainWhite,
  padding: spacing(2, 3),
  zIndex: 2,

  p: {
    width: 'max-content',
    ...(maxWidth && { maxWidth: `${maxWidth}px` }),
  },
}));

const DownArrow = styled.div({
  height: '12px',
  width: '12px',
  background: color.palette.mainBlack,
  transform: 'rotate(45deg)',
  position: 'absolute',
  zIndex: 1,
});

export function withTooltip<T extends React.HTMLAttributes<HTMLElement>>(
  Component: StyledComponent<React.ComponentType<any>, DefaultTheme>,
): React.ForwardRefExoticComponent<React.PropsWithoutRef<WithTooltipProps & T> & React.RefAttributes<HTMLElement>> {
  return React.forwardRef(
    ({ tooltip, tooltipMaxWidth, ...props }: WithTooltipProps & T, ref: React.Ref<HTMLElement>) => {
      const [isTooltipVisible, setIsTooltipVisible] = useState(false);

      const tooltipRef = useRef<HTMLDivElement>(null);

      function handleMouseOver(event) {
        if (!tooltipRef.current) return;

        setIsTooltipVisible(true);

        const { offsetWidth: tooltipWidth, offsetHeight: tooltipHeight } = tooltipRef.current;

        const {
          offsetLeft: targetRelativeLeft,
          offsetTop: targetRelativeTop,
          offsetWidth: targetWidth,
          offsetParent: targetParent,
        } = event.currentTarget;

        const targetAbsoluteMidPoint = event.currentTarget.getBoundingClientRect().left + targetWidth / 2;
        const targetRelativeMidPoint = targetRelativeLeft + targetWidth / 2;

        // set tooltip arrow position
        const tooltipArrow = tooltipRef.current.nextElementSibling as HTMLElement;
        tooltipArrow.style.top = `${targetRelativeTop - tooltipArrow.offsetHeight * 1.2}px`;
        tooltipArrow.style.left = `${targetRelativeMidPoint - tooltipArrow.offsetWidth / 2}px`;

        // set tooltip position
        tooltipRef.current.style.top = `${targetRelativeTop - tooltipHeight - tooltipArrow.offsetHeight / 2}px`;

        const tooltipRelativeLeft = targetRelativeMidPoint - tooltipWidth / 2;
        const tooltipSpaceRequired = tooltipWidth / 2 + MIN_MARGIN;
        const offScreenAmountRight =
          targetAbsoluteMidPoint + tooltipSpaceRequired - document.documentElement.clientWidth;

        if (targetAbsoluteMidPoint - tooltipSpaceRequired <= 0) {
          // if tooltip goes off left edge of the screen
          tooltipRef.current.style.left = `${MIN_MARGIN - targetParent.offsetLeft}px`;
        } else if (offScreenAmountRight >= 0) {
          // if tooltip goes off right edge of the screen
          tooltipRef.current.style.left = `${tooltipRelativeLeft - offScreenAmountRight}px`;
        } else {
          tooltipRef.current.style.left = `${tooltipRelativeLeft}px`;
        }
      }

      function handleMouseLeave() {
        setIsTooltipVisible(false);
      }

      return (
        <>
          <Component {...props} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave} ref={ref} />
          <Tooltip role="tooltip" visible={isTooltipVisible} aria-hidden={!isTooltipVisible}>
            <Content ref={tooltipRef} maxWidth={tooltipMaxWidth}>
              <p>{tooltip}</p>
            </Content>
            <DownArrow />
          </Tooltip>
        </>
      );
    },
  );
}
