import { MouseEvent } from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

import { spacing } from '@selfridges-co/frontend-sdk-react-theme';
import { ButtonLink, ButtonLinkOwnProps } from '@selfridges-co/frontend-sdk-react-button-link';

type AddGiftMessageButtonProps = {
  onClick: (event?: MouseEvent<HTMLElement>) => void;
};

const GIFT_ICON_SVG =
  "data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M18.5 10.5H19.5V7.50003H14M18.5 10.5V18.5C18.5 19.0523 18.0523 19.5 17.5 19.5H14M18.5 10.5H14M5.5 10.5H4.5V7.50003H10M5.5 10.5V18.5C5.5 19.0523 5.94772 19.5 6.5 19.5H10M5.5 10.5H10M10 7.50003V19.5M10 7.50003H14M10 19.5H14M14 7.50003V19.5M12.2246 6.67951C12.9643 5.60856 14.1053 4.50003 15.4838 4.50003C17.9838 4.50003 17.9838 7.50003 16.4838 7.50003H13C11.5 7.50003 11.8166 7.27022 12.2246 6.67951ZM11.7592 6.67948C11.0195 5.60852 9.87852 4.5 8.5 4.5C6 4.5 6 7.50003 7.5 7.50003H10.9838C12.5 7.4879 12.1672 7.27018 11.7592 6.67948Z' stroke='%23212121' stroke-linejoin='round'/%3e%3c/svg%3e";

const Button = styled(ButtonLink).attrs({ as: 'button' })<ButtonLinkOwnProps>({
  border: 'none',
  cursor: 'pointer',
});

const GiftIcon = styled.span({
  marginRight: spacing(2),
  width: '24px',
  height: '24px',
  display: 'inline-block',
  verticalAlign: 'middle',

  ':before': {
    content: "''",
    display: 'block',
    backgroundImage: `url("${GIFT_ICON_SVG}")`,
    width: '100%',
    height: '100%',
  },
});

function AddGiftMessageButton({ onClick }: AddGiftMessageButtonProps) {
  return (
    <Button buttonType="secondary" onClick={onClick} fullWidth>
      <GiftIcon />
      <Trans i18nKey="app.gifting.add-gift-message-button.text">Add gift message</Trans>
    </Button>
  );
}

export default AddGiftMessageButton;
