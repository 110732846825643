import React, { useEffect, useRef, useState, MouseEvent, ReactEventHandler } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { spacing, breakpoints } from '@selfridges-co/frontend-sdk-react-theme';

import { useIsServerSide } from '../hooks/useIsServerSide';

const PAUSE_SVG = encodeURI(
  `<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24" cy="24" r="15.5" stroke="#212121"/>
    <path d="M27.5 29.5V18.5" stroke="#212121" stroke-linecap="round"/>
    <path d="M20.5 29.4999V18.4999" stroke="#212121" stroke-linecap="round"/>
    </svg>`,
).replace(/#/g, '%23');

export const PLAY_SVG = encodeURI(
  `<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="24" cy="24" r="15.5" stroke="#212121"/>
  <path d="M20.5 30.5V17.5L30.5 24L20.5 30.5Z" fill="#212121" stroke="#212121" stroke-linejoin="round"/>
  </svg>`,
).replace(/#/g, '%23');

const PlayButton = styled.div<{ playing: boolean; centerPlayButton: boolean }>(({ playing, centerPlayButton }) => ({
  width: spacing(13),
  height: spacing(13),
  borderRadius: spacing(13),
  background: 'white',
  position: 'absolute',
  border: 'none',
  backgroundImage: `url("data:image/svg+xml,${playing ? PAUSE_SVG : PLAY_SVG}")`,
  backgroundPosition: 'center',
  cursor: 'pointer',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  ...(playing && {
    display: 'none',
  }),

  [breakpoints.md.mediaQuery]: {
    display: 'block',

    ...(centerPlayButton
      ? {
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          ...(playing && { display: 'none' }),
        }
      : {
          top: spacing(2),
          right: spacing(2),
          transform: 'translate(0)',
          left: 'auto',
        }),
  },
}));

const Container = styled.div({
  position: 'relative',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
});

type Scene7VideoProps = {
  src: string;
  fmt: string;
  alt: string;
  centerPlayButton?: boolean;
  onClick?: (event: MouseEvent<HTMLVideoElement>) => void;
  muted?: boolean;
  showControls?: boolean;
  autoPlay?: boolean;
  playButtonEnabled?: boolean;
  showPlayButton?: boolean;
  onError?: ReactEventHandler<HTMLVideoElement>;
};

export function Scene7Video({
  src,
  fmt,
  alt,
  centerPlayButton = true,
  muted = false,
  showControls = false,
  autoPlay = true,
  showPlayButton = false,
  onClick,
  onError,
}: Scene7VideoProps): React.JSX.Element {
  const { t } = useTranslation();
  const videoElementRef = useRef<Nullable<HTMLVideoElement>>(null);
  const [isPlaying, setIsPlaying] = useState(autoPlay);
  const isSSR = useIsServerSide();

  function toggleVideoPlaying() {
    if (!videoElementRef.current) return;

    const playing = !videoElementRef.current.paused && !videoElementRef.current.ended;

    if (playing) {
      videoElementRef.current.pause();
    } else {
      videoElementRef.current.play();
    }

    setIsPlaying(!playing);
  }

  function handlePlayButtonClick(event: MouseEvent<HTMLInputElement>) {
    event.stopPropagation();
    toggleVideoPlaying();
  }

  useEffect(() => {
    if (videoElementRef.current) {
      if (autoPlay) {
        videoElementRef.current.play();
        setIsPlaying(true);
      } else {
        videoElementRef.current.pause();
        setIsPlaying(false);
      }
    }
  }, [autoPlay, setIsPlaying]);

  return (
    <Container>
      <video
        onClick={onClick}
        height="100%"
        width="100%"
        playsInline={true}
        ref={videoElementRef}
        muted={muted}
        controls={showControls}
        autoPlay={autoPlay}
        loop
        onError={onError}
      >
        <source src={src} type={`video/${fmt}`} aria-label={alt}></source>
      </video>
      {!isSSR && showPlayButton ? (
        <PlayButton
          aria-label={
            isPlaying
              ? t('image-gallery.scene-7-video.pause', { defaultValue: 'Pause video' }).toString()
              : t('image-gallery.scene-7-video.play', { defaultValue: 'Play video' }).toString()
          }
          onClick={handlePlayButtonClick}
          playing={isPlaying}
          centerPlayButton={centerPlayButton}
        />
      ) : null}
    </Container>
  );
}
