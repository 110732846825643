import { env } from '@selfridges-pkg/utils';

import { ColourInfos, BrandInfo, Breadcrumbs } from './types';
import { removeHtmlTags } from './utils';

const MEDIA_ROOT_URL = env('REACT_APP_MEDIA_ROOT_URL') || '';

type AvailabilitySchema = 'https://schema.org/InStock' | 'https://schema.org/OutOfStock';

type Offer = {
  '@type': 'Offer';
  availability?: AvailabilitySchema;
  itemCondition?: string;
  price: string;
  priceCurrency: string;
};

type AggregateOffer = {
  '@type': 'AggregateOffer';
  priceCurrency: string;
  highPrice: string;
  lowPrice: string;
  offerCount: number;
};

type ProductModel = {
  '@type': 'ProductModel';
  name: string;
  offers: Offer;
  color: string;
};

type ProductNonMultiColour = {
  '@context': 'https://schema.org';
  '@type': 'Product';
  name: string;
  image: string;
  brand: string;
  description: string;
  color: string;
  category: string;
  offers: Offer;
  url: string;
  model?: never;
};

type ProductMultiColour = {
  '@context': 'https://schema.org';
  '@type': 'Product';
  name: string;
  image: string;
  brand: string;
  description: string;
  color?: never;
  category: string;
  offers: AggregateOffer;
  url: string;
  model: Array<ProductModel>;
};

type Product = ProductNonMultiColour | ProductMultiColour;

export function createStructuredDataScript({
  brandInfo,
  productDescription,
  details,
  breadcrumbs,
  productOutOfStock,
  colourInfos,
  image,
  price,
  pageUrl,
}: {
  brandInfo: BrandInfo;
  productDescription: string;
  details: string;
  breadcrumbs: Breadcrumbs;
  productOutOfStock: boolean;
  colourInfos: ColourInfos;
  image: string;
  price: Nullable<{
    currency: string;
    current: number;
    was?: Nullable<number>;
    wasWas?: Nullable<number>;
  }>;
  pageUrl: string;
}) {
  if (!price) return '';

  const product: Pick<Product, '@context' | '@type' | 'name' | 'brand' | 'image' | 'category' | 'url' | 'description'> =
    {
      '@context': 'https://schema.org',
      '@type': 'Product',
      name: productDescription,
      brand: brandInfo.name,
      description: removeHtmlTags(details),
      image: `${MEDIA_ROOT_URL}/image/${image}`,
      category: breadcrumbs.map(breadcrumb => breadcrumb.title).join(' | '),
      url: pageUrl,
    };

  if (colourInfos.length > 1) {
    const multiColourProduct: ProductMultiColour = {
      ...product,
      offers: {
        '@type': 'AggregateOffer',
        priceCurrency: price.currency,
        highPrice: price.current.toFixed(2),
        lowPrice: price.current.toFixed(2),
        offerCount: colourInfos.length,
      },
      model: colourInfos.map(colourInfo => ({
        '@type': 'ProductModel',
        name: productDescription,
        offers: {
          '@type': 'Offer',
          price: price.current.toFixed(2),
          priceCurrency: price.currency,
        },
        color: colourInfo.name,
      })),
    };

    return JSON.stringify(multiColourProduct);
  } else {
    const nonMultiColourProduct: ProductNonMultiColour = {
      ...product,
      color: colourInfos[0]?.name,
      offers: {
        '@type': 'Offer',
        availability: productOutOfStock ? 'https://schema.org/OutOfStock' : 'https://schema.org/InStock',
        itemCondition: 'NewCondition',
        price: price.current.toFixed(2),
        priceCurrency: price.currency,
      },
    };

    return JSON.stringify(nonMultiColourProduct);
  }
}
