import styled from 'styled-components';
import { Trans } from 'react-i18next';

import { color, spacing, typography } from '@selfridges-co/frontend-sdk-react-theme';
import { Link } from '@selfridges-co/frontend-sdk-react-link';

import { MAX_CHARACTERS_IN_SIZE_GRID, MAX_NUM_OF_SIZES_IN_SIZE_GRID } from '../constants';
import { StockMessage } from '../stock-message/StockMessage';
import { useModal } from '../modal/useModal';

import SizeDropdown from './SizeDropdown';
import SizeGrid from './SizeGrid';
import SizeList from './SizeList';

import type { SelectedSize, SelectedColour, GetSku } from '../types';

type SizeSelectorProps = {
  sizes: Array<string>;
  selectedSize: SelectedSize;
  selectedColour: SelectedColour;
  onSelect: (size: string) => void;
  showSelectPromptMessage: boolean;
  getSku: GetSku;
  showSizeGuide: boolean;
  openSizeGuideModal: () => void;
};

const Title = styled.div({
  display: 'inline-flex',
  alignItems: 'baseline',
  marginBottom: spacing(3),
});

const Wrapper = styled.div({
  margin: spacing(8, 0),
});

const Label = styled.h4({
  ...typography.typeface.display.xs,
  display: 'inline-block',
});

const SelectedSizeValue = styled.span({
  ...typography.typeface.text.md.regular,
  marginLeft: spacing(2),
});

const PromptMessage = styled.span({
  ...typography.typeface.text.md.regular,
  color: color.palette.warningRed,
  textDecoration: 'underline',
});

const SizeHelper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',

  // Fit Analytics hover styles override
  '.fitanalytics__button:hover': {
    '> img': {
      opacity: '0.7!important',
    },

    '> span': {
      textDecoration: 'underline!important',
      color: `${color.palette.midGrey1}!important`,
    },
  },
});

const SizeGuideButton = styled(Link).attrs({ forwardedAs: 'button' })({
  marginTop: spacing(3),

  ':hover': {
    '::before': {
      opacity: 0.7,
    },
  },
  '::before': {
    content: '" "',
    backgroundImage: `url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.5 16H18.5M5.5 16L5.5 14.5M5.5 16L5.5 17.5M18.5 16V14.5M18.5 16V17.5' stroke='%23212121' stroke-linecap='round'/%3e%3crect x='19.5' y='11.5' width='15' height='5' rx='0.5' transform='rotate(-180 19.5 11.5)' stroke='%23212121' stroke-linejoin='round'/%3e%3cpath d='M7.5 10L7.5 9.5L6.5 9.5L6.5 10L7.5 10ZM7.5 11L7.5 10L6.5 10L6.5 11L7.5 11Z' fill='%23212121'/%3e%3cpath d='M15 9L15 8.5L14 8.5L14 9L15 9ZM15 11L15 9L14 9L14 11L15 11Z' fill='%23212121'/%3e%3cpath d='M10 9L10 8.5L9 8.5L9 9L10 9ZM10 11L10 9L9 9L9 11L10 11Z' fill='%23212121'/%3e%3cpath d='M17.5 10L17.5 9.5L16.5 9.5L16.5 10L17.5 10ZM17.5 11L17.5 10L16.5 10L16.5 11L17.5 11Z' fill='%23212121'/%3e%3cpath d='M12.5 10L12.5 9.5L11.5 9.5L11.5 10L12.5 10ZM12.5 11L12.5 10L11.5 10L11.5 11L12.5 11Z' fill='%23212121'/%3e%3c/svg%3e")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    marginRight: spacing(1),
    height: '24px',
    width: '24px',
  },
});

export default function SizeSelector({
  sizes,
  selectedSize,
  selectedColour,
  onSelect,
  getSku,
  showSelectPromptMessage,
  showSizeGuide,
  openSizeGuideModal,
}: SizeSelectorProps) {
  const longestSizeLength = Math.max(...sizes.map(size => size.length));
  const numOfSizes = sizes.length;

  const hideSizeGridOnMobile =
    longestSizeLength > MAX_CHARACTERS_IN_SIZE_GRID.MOBILE || numOfSizes > MAX_NUM_OF_SIZES_IN_SIZE_GRID.MOBILE;

  const hideSizeGridOnDesktop =
    longestSizeLength > MAX_CHARACTERS_IN_SIZE_GRID.DESKTOP || numOfSizes > MAX_NUM_OF_SIZES_IN_SIZE_GRID.DESKTOP;

  const { Modal: SizeListModal, open: openSizeListModal, close: closeSizeListModal } = useModal();
  const { availableStock } = getSku({ colour: selectedColour.value, size: selectedSize.value });

  return (
    <>
      <Wrapper>
        <Title>
          <Label tabIndex={0}>
            <Trans i18nKey="app.size-selector.label">
              Size:<SelectedSizeValue>{{ selectedSize: selectedSize.display }}</SelectedSizeValue>
            </Trans>
          </Label>
          {showSelectPromptMessage && (
            <PromptMessage>
              <Trans i18nKey="app.size-selector.select-size-prompt">Please select a size</Trans>
            </PromptMessage>
          )}
          {availableStock === 'ALL_OUT_OF_STOCK' ? (
            <StockMessage messageColour="black">
              <Trans i18nKey="app.size-selector.all-out-of-stock">All sizes out of stock</Trans>
            </StockMessage>
          ) : (
            <StockMessage stockAmount={availableStock} />
          )}
        </Title>
        {(hideSizeGridOnMobile || hideSizeGridOnDesktop) && numOfSizes > 1 && (
          <SizeDropdown
            hideOnMobile={!hideSizeGridOnMobile}
            hideOnDesktop={!hideSizeGridOnDesktop}
            sizes={sizes}
            selectedSize={selectedSize}
            onClick={openSizeListModal}
          />
        )}
        {!(hideSizeGridOnMobile && hideSizeGridOnDesktop) && numOfSizes > 1 && (
          <SizeGrid
            sizes={sizes}
            hideOnMobile={hideSizeGridOnMobile}
            hideOnDesktop={hideSizeGridOnDesktop}
            getSku={getSku}
            selectedSize={selectedSize}
            selectedColour={selectedColour}
            onSelect={onSelect}
          />
        )}
        <SizeHelper id="size-helper-container">
          {showSizeGuide && (
            <SizeGuideButton
              id="size-guide-button"
              linkType="secondary"
              onClick={openSizeGuideModal}
              data-analytics-component="size_guide_button"
            >
              <Trans i18nKey="app.size-selector.size-guide-button">Size guide</Trans>
            </SizeGuideButton>
          )}
        </SizeHelper>
      </Wrapper>
      <SizeListModal
        Title={<Trans i18nKey="app.size-selector.size-list-modal.title">Size:</Trans>}
        CloseButtonLabel={
          <Trans i18nKey="app.size-selector.size-list-modal.close-button-label">Close size list modal</Trans>
        }
      >
        <SizeList
          sizes={sizes}
          selectedSize={selectedSize}
          selectedColour={selectedColour}
          onSelect={onSelect}
          onClick={closeSizeListModal}
          getSku={getSku}
        />
      </SizeListModal>
    </>
  );
}
