import styled from 'styled-components';
import { unescape } from 'html-escaper';
import { useTranslation } from 'react-i18next';

import { breakpoints, spacing, typography, color } from '@selfridges-co/frontend-sdk-react-theme';

import { useFetchSizeGuide } from '../hooks/useFetchSizeGuide';
import Skeleton from '../loading-skeleton/LoadingSkeleton';

type SizeGuideProps = {
  countryLanguage: string;
  categoryName: string;
  brandName: string;
};

const Container = styled.div({
  padding: spacing(0, 4, 6, 4),

  [breakpoints.md.mediaQuery]: {
    padding: spacing(0, 6, 6, 6),
  },
});

const Content = styled.div({
  ...typography.typeface.text.md.regular,
  marginBottom: spacing(4),
  position: 'relative',
});

const Heading = styled.h4({
  ...typography.typeface.text.md.bold,
  color: color.palette.mainBlack,
  marginBottom: spacing(4),
  position: 'relative',
});

const TableContainer = styled.div<{ isLoading?: boolean }>(({ isLoading }) => ({
  ...typography.typeface.text.sm.regular,
  color: color.palette.midGrey1,
  boxSizing: 'border-box',
  overflowX: 'auto',

  table: {
    display: 'inline-table',
  },

  '@media (hover: hover)': {
    'tr:nth-child(n + 2):hover': {
      background: isLoading ? 'inherit' : color.palette.lightGrey2,
    },

    'tr:nth-child(n + 2) td:hover': {
      background: isLoading ? 'inherit' : color.palette.lightGrey1,
    },
  },

  'tr:first-child': {
    ...typography.typeface.text.sm.bold,
    color: color.palette.mainBlack,
    background: color.palette.lightGrey2,
  },

  'tr:nth-child(n + 3)': {
    borderTop: `1px solid ${color.palette.lightGrey1}`,
  },

  td: {
    padding: spacing(4, 0),
    textAlign: 'center',
  },
}));

const TableColumn = styled.td({
  position: 'relative',
});

const TableContent = styled.div({
  position: 'relative',
  marginLeft: spacing(2),
});

function LoadingSkeleton() {
  const { t } = useTranslation();
  const LINES = 8;
  const COLUMNS = 5;

  return (
    <Content>
      <Heading aria-label={t('app.size-guide.loading', { defaultValue: 'Loading size guide' }).toString()}>
        <Skeleton height="80%" width="20%" />
      </Heading>
      <TableContainer isLoading>
        <table>
          <tbody>
            {Array(LINES)
              .fill('')
              .map((_, lineIndex) => (
                <tr key={`size-guide-tr-${lineIndex}`} {...(lineIndex !== 0 && { height: '21' })}>
                  {Array(COLUMNS)
                    .fill('')
                    .map((_, columnIndex) => (
                      <TableColumn key={`size-guide-td-${columnIndex}`} height="21" width="87">
                        <TableContent>
                          <Skeleton height="90%" width="90%" />
                        </TableContent>
                      </TableColumn>
                    ))}
                </tr>
              ))}
          </tbody>
        </table>
      </TableContainer>
    </Content>
  );
}

function SizeGuide({ brandName, categoryName, countryLanguage }: SizeGuideProps) {
  const { data, isLoading } = useFetchSizeGuide({ brandName, categoryName, countryLanguage });

  return (
    <Container>
      {isLoading ? (
        <LoadingSkeleton />
      ) : (
        data?.map(({ title, html }) => (
          <Content key={title}>
            <Heading>{unescape(title)}</Heading>
            <TableContainer dangerouslySetInnerHTML={{ __html: html }} />
          </Content>
        ))
      )}
    </Container>
  );
}

export default SizeGuide;
