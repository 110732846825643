import { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { fetchRatingsAndReviews } from '@selfridges-pkg/yotpo-api';
import { RatingsAndReviewsResponse } from '@selfridges-pkg/yotpo-api/contracts/RatingsAndReviewsResponse';
import { env } from '@selfridges-pkg/utils';

import { REVIEWS_PER_PAGE } from '../constants';

const YOTPO_API_HOST = () => env('REACT_APP_YOTPO_API_HOST') || '';
const YOTPO_API_KEY = () => env('REACT_APP_YOTPO_API_KEY') || '';

export function useFetchRatingAndReviews({
  partNumber,
  skuId,
  enabled,
}: {
  partNumber: string;
  skuId?: string;
  enabled: boolean;
}): {
  data?: RatingsAndReviewsResponse;
  nextPage: () => void;
  updateStarFilter: (filter: Nullable<number>) => void;
  starFilter: Nullable<number>;
  isLoading: boolean;
} {
  const [dataKey, setDataKey] = useState<string | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [starFilter, setStarFilter] = useState<Nullable<number>>(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search);
    setDataKey(searchParams.get('ratingsAndReviewsDataKey') || '');
  }, []);

  const queryParams = { page: pageNumber, ...(starFilter && { starFilter }) };

  const { data, isPreviousData: isLoading } = useQuery({
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    queryKey: ['fetch-rating-reviews', skuId, queryParams],
    queryFn: () =>
      fetchRatingsAndReviews({
        hostRootUrl: YOTPO_API_HOST(),
        apiKey: YOTPO_API_KEY(),
        skuId,
        perPage: REVIEWS_PER_PAGE,
        headers: {
          'X-Part-Number': partNumber,
          ...(dataKey && { 'X-Ratings-And-Reviews-Data-Key': dataKey }),
        },
        ...queryParams,
      }),
    keepPreviousData: true,
    enabled: Boolean(skuId) && enabled,
  });

  const nextPage = useCallback(() => {
    setPageNumber(prev => prev + 1);
  }, [setPageNumber]);

  const updateStarFilter = useCallback(
    (filter: Nullable<number>) => {
      setPageNumber(1);
      setStarFilter(filter);
    },
    [setPageNumber, setStarFilter],
  );

  return {
    data,
    nextPage,
    updateStarFilter,
    starFilter,
    isLoading,
  };
}
