import { Trans } from 'react-i18next';
import styled from 'styled-components';

import { typography, spacing, breakpoints } from '@selfridges-co/frontend-sdk-react-theme';
import { ButtonLink } from '@selfridges-co/frontend-sdk-react-button-link';
import Tags from '@selfridges-co/frontend-sdk-react-tags';

import { PRODUCT_PANEL_GUTTER } from '../constants';

export type OutOfStockTemplateProps = {
  tagLinks: Array<{ text: string; url: string }>;
  backToLink: { text: string; url: string };
};

const Container = styled.div({
  margin: spacing(10, 0),

  [breakpoints.md.mediaQuery]: {
    margin: spacing(16, 0),
  },
});

const Title = styled.h3({
  ...typography.typeface.display.xs,
  marginBottom: spacing(3),
});

const Description = styled.p({
  ...typography.typeface.text.md.regular,
  marginBottom: spacing(3),
});

const BackToCategoryButton = styled(ButtonLink)({
  userSelect: 'none',

  ':active': {
    border: 'none',
  },

  marginLeft: `-${PRODUCT_PANEL_GUTTER.MOBILE}`,
  width: '100vw',

  [breakpoints.md.mediaQuery]: {
    width: '100%',
    margin: 0,
  },
});

export default function OutOfStockTemplate({ tagLinks, backToLink }: OutOfStockTemplateProps) {
  return (
    <>
      <Container>
        <Title>
          <Trans i18nKey="app.out-of-stock-template.title">So sorry, this product is currently unavailable</Trans>
        </Title>
        <Description>
          <Trans i18nKey="app.out-of-stock-template.description">
            This product is now out of stock, but don't worry you can shop similar products from
          </Trans>
        </Description>
        <Tags links={tagLinks} />
      </Container>
      <BackToCategoryButton href={backToLink.url} fullWidth variant="black">
        <Trans i18nKey="app.out-of-stock-template.back-to-category">Back to {{ categoryName: backToLink.text }}</Trans>
      </BackToCategoryButton>
    </>
  );
}
