import { useCallback, useEffect, useRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import styled from 'styled-components';

import env from '@selfridges-pkg/utils/env';
import { breakpoints, color, spacing } from '@selfridges-co/frontend-sdk-react-theme';
import { ButtonLink, ButtonLinkOwnProps } from '@selfridges-co/frontend-sdk-react-button-link';
import { addToCart } from '@selfridges-pkg/wcs-api/addToCart';

import { EXTERNAL_EVENT } from '../constants';
import { PersonalisationUserInput } from '../types';

import type { ErrorKey } from '@selfridges-pkg/wcs-api/contracts/AddToCartResponse';

type Status = 'idle' | 'loading' | 'success';

export interface AddToCartButtonProps
  extends Pick<React.PropsWithChildren<Parameters<typeof ButtonLink>[0]>, 'disabled' | 'variant'> {
  id?: string;
  statusText: {
    [key in Status]: JSX.Element | string;
  };
  disabledBgColour?: string;
}

export interface AddToCartError {
  noSkuProvided: boolean;
}

const errorDefaultState: AddToCartError = {
  noSkuProvided: false,
};

const WCS_API_HOST = () => env('REACT_APP_WCS_API_HOST') || '';
const WCS_API_KEY = () => env('REACT_APP_WCS_API_KEY') || '';

const AddToBagButton = styled(ButtonLink)
  .withConfig<ButtonLinkOwnProps & { disabledBgColour?: string }>({
    shouldForwardProp: props => !['disabledBgColour'].includes(String(props)),
  })
  .attrs({ as: 'button' })(({ disabledBgColour }) => ({
  cursor: 'pointer',
  userSelect: 'none',
  position: 'sticky',
  top: 0,
  left: 0,
  pointerEvents: 'all',
  border: 'none',

  ':active': {
    border: 'none',
  },

  ':disabled': {
    background: disabledBgColour || color.palette.lightYellow,
    opacity: 1,
    boxShadow: 'none',
    cursor: 'default',
  },

  [breakpoints.md.mediaQuery]: {
    position: 'relative',
  },
}));

const StatusIcon = styled.span({
  position: 'absolute',
  display: 'inline-block',
  top: '50%',
  transform: `translateY(calc(-50% + 1px)) translateX(${spacing(3)})`,

  '::after': {
    content: '" "',
    position: 'absolute',
    left: 0,
    width: '100%',
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  '@keyframes spin-animation': {
    '0%': { transform: 'rotateZ(0turn)' },
    '100%': { transform: 'rotateZ(1turn)' },
  },
});

const LoadingIcon = styled(StatusIcon)<{ strokeColour: string }>(({ strokeColour }) => ({
  width: '2.4rem',
  height: '2.4rem',

  '::after': {
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='${strokeColour.replace(
      /#/,
      '%23',
    )}'
      stroke-linecap='round' stroke-linejoin='round' xmlns='http://www.w3.org/2000/svg'
      %3E%3Cpath d='M19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19M19 12L21.5 8.5M19 12L15.5 10'/%3E%3C/svg%3E")
    `,
    animationName: 'spin-animation',
    animationDuration: '1s',
    animationIterationCount: 'infinite',
  },
}));

const SuccessIcon = styled(StatusIcon)({
  boxShadow: '0px 0px 0px 1px inset',
  borderRadius: '50%',
  width: '1.6rem',
  height: '1.6rem',

  '::after': {
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' stroke='%23ffffff'
      stroke-linecap='round' stroke-linejoin='round' xmlns='http://www.w3.org/2000/svg'
      %3E%3Cpath d='M7.75 0.25L2.5 5.5L0.25 3.25'/%3E%3C/svg%3E")
    `,
  },
});

export const ADD_TO_CART_FAKE_DELAY_IN_MS = 500;
export const ADD_TO_CART_SUCCESS_DELAY_IN_MS = 3000;

export function useAddToCart({
  countryLanguage,
  skuId,
  partNumber,
  quantity,
  giftMessage,
  personalisationUserInput,
  onSuccess,
  onError,
}: {
  countryLanguage: string;
  skuId?: string;
  partNumber: string;
  quantity: number;
  giftMessage?: string;
  personalisationUserInput?: PersonalisationUserInput;
  onSuccess: () => void;
  onError: ({ errorKey, errorMessage }: { errorKey: ErrorKey; errorMessage: string }) => void;
}) {
  const { mutateAsync, status, data } = useMutation(() =>
    addToCart({
      hostRootUrl: WCS_API_HOST(),
      apiKey: WCS_API_KEY(),
      countryLanguage,
      skuId,
      quantity,
      giftMessage,
      personalisationUserInput,
      headers: {
        'X-Part-Number': partNumber,
      },
    }),
  );
  const [error, setError] = useState<AddToCartError>({ ...errorDefaultState });
  const [buttonStatus, setButtonStatus] = useState<Status>('idle');
  const currentStatus = useRef(status);

  const fetchAddToCartHandler = useCallback(async () => {
    const error = { ...errorDefaultState };

    if (!skuId) {
      error.noSkuProvided = true;
      setError(error);
      return;
    }

    await mutateAsync();

    // fire event to refresh the cart button count
    window.dispatchEvent(new CustomEvent(EXTERNAL_EVENT.USER_MENU_REFRESH));
  }, [mutateAsync, skuId]);

  const cycleButtonState = useCallback(async () => {
    await new Promise(resolve => setTimeout(resolve, ADD_TO_CART_FAKE_DELAY_IN_MS));

    if (data && 'errors' in data) {
      onError({
        errorKey: data.errors.errorKey,
        errorMessage: Array.isArray(data.errors.errorMessage)
          ? data.errors.errorMessage[0].message
          : data.errors.errorMessage,
      });
    } else if (data && 'errorMessage' in data) {
      onError({
        errorKey: data.errorMessage.errors.errorKey,
        errorMessage: Array.isArray(data.errorMessage.errors.errorMessage)
          ? data.errorMessage.errors.errorMessage[0].message
          : data.errorMessage.errors.errorMessage,
      });
    } else {
      setButtonStatus('success');
      onSuccess();

      await new Promise(resolve => setTimeout(resolve, ADD_TO_CART_SUCCESS_DELAY_IN_MS));
    }

    setButtonStatus('idle');
  }, [onSuccess, onError, data]);

  useEffect(() => {
    if (currentStatus.current === status) return;
    currentStatus.current = status;

    if (status === 'success') {
      cycleButtonState();
    } else if (status === 'loading') {
      setButtonStatus('loading');
    }
  }, [status, cycleButtonState]);

  const AddToCartButton = useCallback(
    ({ disabled = false, statusText, disabledBgColour, ...rest }: AddToCartButtonProps) => {
      const loadingIconStrokeColour = rest.variant === 'black' ? color.palette.mainWhite : color.palette.mainBlack;
      const statusIcons: { [key in Status]?: JSX.Element } = {
        loading: <LoadingIcon strokeColour={loadingIconStrokeColour} />,
        success: <SuccessIcon />,
      };

      return (
        <AddToBagButton
          buttonType="primary"
          onClick={buttonStatus === 'idle' ? fetchAddToCartHandler : undefined} // temp since styles are messed up when disabled
          variant={buttonStatus === 'success' ? 'black' : 'yellow'}
          disabled={disabled}
          disabledBgColour={disabledBgColour}
          fullWidth
          data-enabled={Boolean(!disabled && skuId)} // this attribute is used in functional UI tests
          {...rest}
        >
          {statusText[buttonStatus]} {statusIcons[buttonStatus]}
        </AddToBagButton>
      );
    },
    [buttonStatus, fetchAddToCartHandler, skuId],
  );

  return { AddToCartButton, error };
}
