import { MouseEvent, ChangeEvent } from 'react';
import styled from 'styled-components';

import { breakpoints, color, spacing, typography } from '@selfridges-co/frontend-sdk-react-theme';

import { StockMessage } from '../stock-message/StockMessage';

import type { GetSku, SelectedColour, SelectedSize, Size } from '../types';

export interface SizeListProps {
  sizes: Array<Size>;
  selectedSize: SelectedSize;
  selectedColour: SelectedColour;
  getSku: GetSku;
  onSelect: (colour: string) => void;
  onClick?: (event?: MouseEvent<HTMLInputElement>) => void;
}

const SizeListWrapper = styled.div({
  width: '100%',
  padding: spacing(0, 3),
  boxSizing: 'border-box',

  [breakpoints.md.mediaQuery]: {
    padding: spacing(0, 6),
  },
});

const ListContainer = styled.div({
  paddingBottom: spacing(0, 3, 3, 3),

  [breakpoints.md.mediaQuery]: {
    paddingBottom: spacing(0, 6, 6, 6),
  },
});

const SizeContainer = styled.label<{ selected: boolean; outOfStock: boolean }>(({ selected, outOfStock }) => ({
  ...typography.typeface.text.md.regular,
  position: 'relative',
  color: color.palette.mainBlack,
  padding: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  margin: spacing(1, 0),
  transition: 'all 0.1s ease-out',

  ...(outOfStock && {
    color: color.palette.midGrey2,
  }),

  ...(selected && {
    ...typography.typeface.text.md.bold,
    boxShadow: `inset 0px 0px 0px 2px ${color.palette.mainBlack}`,
  }),

  '@media (hover: hover)': {
    ':hover': {
      boxShadow: `inset 0px 0px 0px 1px ${color.palette.midGrey2}`,
    },
  },
}));

const SizeName = styled.div({
  display: 'inline-flex',
  alignItems: 'center',
  flexGrow: 1,
  padding: spacing(3),
});

const RadioInput = styled.input({
  cursor: 'pointer',
  width: '100%',
  height: '100%',
  appearance: 'none',
  opacity: 1,
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 1,
});

function SizeList({ sizes, selectedColour, onSelect, selectedSize, onClick, getSku }: SizeListProps) {
  function handleClick(event: MouseEvent<HTMLInputElement>) {
    event.stopPropagation();

    // event.detail === 0 when interact via keyboard
    if (event.detail && onClick) {
      onClick(event);
    }
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    onSelect(event.currentTarget.value);
  }

  return (
    <SizeListWrapper>
      <ListContainer>
        {sizes.map(size => {
          const selected = size === selectedSize.value;
          const { availableStock, outOfStock } = getSku({ colour: selectedColour.value, size });

          return (
            <SizeContainer key={size} selected={selected} outOfStock={outOfStock}>
              <RadioInput
                type="radio"
                name="size-list-radio"
                value={size}
                onClick={handleClick}
                onChange={handleChange}
                checked={selected}
                autoFocus={selected}
              />
              <SizeName>
                {size} <StockMessage stockAmount={availableStock} messageColour={outOfStock ? 'grey' : 'red'} />
              </SizeName>
            </SizeContainer>
          );
        })}
      </ListContainer>
    </SizeListWrapper>
  );
}

export default SizeList;
