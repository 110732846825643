import { updateSession } from './updateSession';

import type { AddToCartResponse } from './contracts/AddToCartResponse';

type PersonalisationUserInput = {
  message: string;
  colour?: string;
  font?: string;
};

export async function addToCart({
  hostRootUrl,
  apiKey,
  countryLanguage,
  skuId,
  quantity,
  giftMessage,
  personalisationUserInput,
  headers,
}: {
  hostRootUrl: string;
  apiKey: string;
  countryLanguage: string;
  skuId?: string;
  quantity: number;
  giftMessage?: string;
  personalisationUserInput?: PersonalisationUserInput;
  headers: Record<string, string>;
}): Promise<AddToCartResponse> {
  await updateSession({ hostRootUrl, headers: { ...headers, 'api-key': apiKey }, countryLanguage });

  const response = await fetch(`${hostRootUrl}/api/cms/ecom/v1/${countryLanguage}/cart`, {
    method: 'post',
    headers: {
      'api-key': apiKey,
      'Content-Type': 'application/json',
      ...headers,
    },
    body: JSON.stringify({
      itemPartNumber: skuId,
      quantity: quantity,
      ...(giftMessage && { itemGiftPackaging: { gftMessage: giftMessage } }),
      ...(personalisationUserInput?.message && {
        itemPersonalisation: {
          pznMessage: personalisationUserInput.message,
          ...(personalisationUserInput.colour && { pznColour: personalisationUserInput.colour }),
          ...(personalisationUserInput.font && { pznFont: personalisationUserInput.font }),
        },
      }),
    }),
  });

  if (!response.ok) {
    const error = await response.json();

    if (error.errors) {
      return error;
    }

    if (error.errorMessage.errors) {
      return error.errorMessage;
    }

    return {
      errors: {
        errorKey: 'CART.UNKNOWN_ERROR',
        errorMessage: [{ message: 'Unknown error adding to cart', locale: 'en_US' }],
      },
    };
  }

  return response.json();
}
