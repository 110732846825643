import React, { useRef, useState, MouseEvent } from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

import { breakpoints, color, spacing, typography } from '@selfridges-co/frontend-sdk-react-theme';
import { ButtonLink, ButtonLinkOwnProps } from '@selfridges-co/frontend-sdk-react-button-link';
import Textarea from '@selfridges-co/frontend-sdk-react-textarea';

import { saveGiftMessageTracking } from '../analytics';

export const TEXT_BOX_CHARACTER_LIMIT = 240;

type GiftingProps = {
  savedMessage: string;
  onSave: (message: string) => void;
  onRemove: (event?: MouseEvent<HTMLElement>) => void;
  partNumber: string;
};

const REMOVE_MESSAGE_ICON_SVG =
  "data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='12' cy='12' r='7.5' stroke='%23212121'/%3e%3cpath d='M9 9L12 12M15 15L12 12M12 12L15 9M12 12L9 15' stroke='%23212121' stroke-linecap='round'/%3e%3c/svg%3e";
const Container = styled.div({
  ...typography.typeface.text.md.regular,
  padding: spacing(0, 4, 6, 4),

  [breakpoints.md.mediaQuery]: {
    padding: spacing(0, 6, 6, 6),
  },
});

const TextAreaWithFixedHeight = styled(Textarea)({
  height: '96px',
});

const Information = styled.ul({
  listStyle: 'disc',
  paddingLeft: spacing(4),
  marginBottom: spacing(10),

  [breakpoints.md.mediaQuery]: {
    paddingLeft: spacing(6),
  },
});

const CharacterCount = styled.p<{ isError: boolean }>(({ isError }) => ({
  color: isError ? color.palette.warningRed : color.palette.midGrey1,
}));

const RemoveIcon = styled.span({
  width: '24px',
  height: '24px',
  display: 'inline-block',
  verticalAlign: 'middle',
  marginRight: spacing(2),

  ':before': {
    content: "''",
    display: 'block',
    backgroundImage: `url("${REMOVE_MESSAGE_ICON_SVG}")`,
    backgroundRepeat: 'no-repeat',
    height: '100%',
    width: '100%',
  },
});

const RemoveButton = styled(ButtonLink).attrs({ as: 'button' })<ButtonLinkOwnProps>({
  border: 'none',
  cursor: 'pointer',
  marginTop: spacing(3),
});

const SaveButton = styled(ButtonLink).attrs({ as: 'button' })<ButtonLinkOwnProps>({
  border: 'none',
  cursor: 'pointer',
  marginTop: spacing(10),
});

function hasInvalidCharacters(message: string) {
  const allASCIICharacters = '\x00-\x7F';
  const extraCharacters = '\xA3';
  const apostrophe = '\u2018\u2019';

  const regex = new RegExp(`[^${allASCIICharacters}${extraCharacters}${apostrophe}]`);

  return regex.test(message.replace(/\n/g, ''));
}

function Gifting({ savedMessage, onSave, partNumber, onRemove }: GiftingProps) {
  const { t } = useTranslation();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const hasSavedMessageRef = useRef(savedMessage.length > 0);

  const [characterCount, setCharacterCount] = useState(savedMessage.length);
  const [errorMessages, setErrorMessages] = useState<Array<string>>([]);

  const reachedCharacterLimitErrorMessage = t('app.gifting.error.reached-character-limit', {
    defaultValue: "You've reached the limit, use {{characterLimit}} characters or less",
    characterLimit: TEXT_BOX_CHARACTER_LIMIT,
  }).toString();

  const emptyMessageErrorMessage = t('app.gifting.error.empty-message', {
    defaultValue: 'Please enter a message above if you wish to save a gift message',
  }).toString();

  const invalidCharacterErrorMessage = t('app.gifting.error.invalid-character', {
    defaultValue: 'Please ensure you are only using English characters',
  }).toString();

  function validateMessage(message: string) {
    const errorMessages: Array<string> = [];

    setCharacterCount(message.length);

    if (hasInvalidCharacters(message)) {
      errorMessages.push(invalidCharacterErrorMessage);
    }

    if (message.length > TEXT_BOX_CHARACTER_LIMIT) {
      errorMessages.push(reachedCharacterLimitErrorMessage);
    }

    setErrorMessages(errorMessages);

    return errorMessages.length === 0;
  }

  function handleChangeTextArea(event: React.ChangeEvent<HTMLTextAreaElement>) {
    if (!textAreaRef.current) return;

    const message = event.target.value;
    textAreaRef.current.value = message;

    validateMessage(message);
  }

  function handleSaveMessage(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!textAreaRef.current) return;

    // Replacing apostrophes to normal single quote since they are not supported by the /cart API
    // We don't block them as they are commonly used on mobile esp. iphone
    const message = textAreaRef.current.value.trim().replace(/[‘’]/gi, "'");
    let saveStatus: 'success' | 'fail' = 'success';

    if (!message) {
      setErrorMessages(prev => [...prev, emptyMessageErrorMessage]);
      saveStatus = 'fail';
    } else {
      const isMessageValid = validateMessage(message);
      if (isMessageValid) {
        onSave(message);
      } else {
        saveStatus = 'fail';
      }
    }

    saveGiftMessageTracking({
      saveStatus,
      partNumber,
    });
  }

  function handleResetInput() {
    onRemove();

    if (!textAreaRef.current) return;
    textAreaRef.current.value = '';
  }

  return (
    <Container>
      <Information>
        <li>
          <Trans i18nKey="app.gifting.information">
            A small 65mm x 55mm card will be added to your order with your personalised message.
          </Trans>
        </li>
        <li>
          <Trans i18nKey="app.gifting.instruction">
            Check your spelling and use of capital letters, maximum 240 characters.
          </Trans>
        </li>
      </Information>
      <form onSubmit={handleSaveMessage} onReset={handleResetInput}>
        <TextAreaWithFixedHeight
          name="complimentary-message"
          ref={textAreaRef}
          defaultValue={savedMessage}
          label={t('app.gifting.textarea.label', {
            defaultValue: 'Add your complimentary message',
          }).toString()}
          placeholder={t('app.gifting.textarea.placeholder', {
            defaultValue: 'Type your message here',
          }).toString()}
          onChange={handleChangeTextArea}
          errorMessages={errorMessages}
          helperText={
            <CharacterCount isError={characterCount > TEXT_BOX_CHARACTER_LIMIT}>
              <Trans i18nKey="app.gifting.textarea.character-count">
                {{ characterCount }} / {{ characterLimit: TEXT_BOX_CHARACTER_LIMIT }} Characters
              </Trans>
            </CharacterCount>
          }
        />
        <SaveButton type="submit" buttonType="primary" variant="black" disabled={errorMessages.length > 0} fullWidth>
          <Trans i18nKey="app.gifting.save-button-text">Save</Trans>
        </SaveButton>
        {hasSavedMessageRef.current && (
          <RemoveButton buttonType="secondary" fullWidth type="reset">
            <RemoveIcon />
            <Trans i18nKey="app.gifting.remove-button-text">Remove gift message</Trans>
          </RemoveButton>
        )}
      </form>
    </Container>
  );
}

export default Gifting;
