import {
  analyticsMiddleware,
  getSiteInformation,
  getWcsUtagData,
} from '@selfridges-co/frontend-sdk-react-analytics-middleware';
import env from '@selfridges-pkg/utils/env';

import { BundleProduct } from './types';

import type {
  AnalyticsEvent,
  AnalyticsEventObject,
  AnalyticsEventReturn,
  WcsData,
} from '@selfridges-co/frontend-sdk-react-analytics-middleware';
import type { ProductResponse } from '@selfridges-pkg/product-aggregation-service-api/contracts/ProductResponse';
import type { StockAndPriceResponse } from '@selfridges-pkg/product-aggregation-service-api/contracts/StockAndPriceResponse';
import type { Price, Sizes } from './types';

type UnmappedField = { [key: string]: string | Array<string> | boolean | number };

type Product = {
  product_price_type: ['fullprice' | 'markdown'];
  product_stock: ['instock' | 'outstock'];
  product_subscription_eligibility: ['eligible' | 'not eligible'];
} & UnmappedField;

type AnalyticsView = {
  page_breadcrumb: string;
  page_category_name: [string];
  page_name: string;
  page_category_id: string;
  page_type: string;
  pageload_event: string;
  event_name_cm: string;
  event_name: string;
  page_load_time: number;
  platform: string;
  site_view: string;

  //membership related variables - type of string
  key_customer_status: boolean; // new request - non existing in PDPs
  membership_tier: string; // new request - non existing in PDPs
} & Product &
  WcsData &
  UnmappedField;

export type AnalyticsProductArray = [
  product_name: string,
  product_type: string,
  product_id: string,
  product_price: string,
  product_brand: string,
  product_wcid: string,
  product_was_price: string,
  product_was_was_price: string,
  product_badge: string,
  product_division_name: string,
  product_department_name: string,
];

interface BundleAnalyticsBaseProps {
  parentPartNumber: string;
  productSize?: string;
  productColour?: string;
  skuId?: string;
  skuPrice?: Price;
  gbpPrice?: Price;
  bundleProduct: BundleProduct;
}

interface BundleAddToBagTrackingProps extends BundleAnalyticsBaseProps {
  productQuantity: number;
}

let prevProductAvailabilityColour: Nullable<string> | undefined = undefined;

export function productAvailabilityTracking({
  stockAndPriceData,
  sizes,
  productColour,
  partNumber,
}: {
  stockAndPriceData: StockAndPriceResponse;
  sizes?: Sizes;
  productColour?: string;
  partNumber: string;
}) {
  const { helpers } = analyticsMiddleware;

  const selectedColour = productColour || stockAndPriceData[0].colour;

  if (selectedColour === prevProductAvailabilityColour) return;

  prevProductAvailabilityColour = selectedColour;

  const colourStock = stockAndPriceData.filter(sku => sku.colour === selectedColour);
  const availableStock = colourStock.filter(sku => sku.price && sku.availableStock && sku.availableStock > 0);

  analyticsMiddleware.triggerLink({
    ...helpers.sanitiseDataLayer(window.dataLayer || {}),
    event_name_cm: 'Product_Availability',
    event_name: 'product_availability',
    event_details_1: `${partNumber}:${selectedColour || ''}:${availableStock.length}:${sizes ? sizes.length : 1}`,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    product_instock: stockAndPriceData.map(({ price, ...rest }) => rest),
  });
}

export function addToBagTracking({
  productSize,
  productColour,
  productQuantity,
  skuId,
  skuPrice,
  gbpPrice,
  giftMessageAdded,
  personalisationAdded,
}: {
  productSize?: string;
  productColour?: string;
  productQuantity: number;
  skuId?: string;
  skuPrice?: Price;
  gbpPrice?: Price;
  giftMessageAdded?: boolean;
  personalisationAdded?: boolean;
}) {
  const { helpers } = analyticsMiddleware;

  analyticsMiddleware.triggerLink({
    ...helpers.sanitiseDataLayer(window.dataLayer || {}),
    ga_event_name: 'add to cart',
    ga_eventCategory: 'Ecommerce',
    ga_eventAction: 'Add to cart',
    event_name: 'cart_add',
    product_qty: productQuantity.toFixed(1),
    product_colour: productColour,
    product_size: productSize,
    product_sku: skuId,
    product_action_list: [''],
    recommended_product_size: [],
    fa_action: null,
    ratings_reviews_exits: 'no',
    ratings_reviews_user_engagement: 'no',
    product_price: [`${skuPrice?.raw.current || ''}`],
    was_price: [`${skuPrice?.raw.was || ''}`],
    was_was_price: [`${skuPrice?.raw.wasWas || ''}`],
    product_price_gbp: [`${gbpPrice?.raw.current || ''}`],
    ...(typeof giftMessageAdded !== 'undefined' && {
      giftmessage_added: [giftMessageAdded ? 'gift_message_added' : 'gift_message_not_added'],
    }),
    ...(typeof personalisationAdded !== 'undefined' && {
      personalmessage_added: [personalisationAdded ? 'personal_message_added' : 'personal_message_not_added'],
    }),
  });
}

// eslint-disable-next-line sonarjs/cognitive-complexity
function getBundleAnalyticsBaseData({
  parentPartNumber,
  productColour,
  skuId,
  skuPrice,
  gbpPrice,
  bundleProduct,
}: BundleAnalyticsBaseProps) {
  const breadcrumbs = bundleProduct.breadcrumbs || [];

  return {
    product_sku: skuId,
    product_action_list: [''],
    product_price_gbp: [`${gbpPrice?.raw.current || ''}`],
    product_list_name: [`bundles|${bundleProduct.brand?.name || ''}|${parentPartNumber}`],
    product_badge: [bundleProduct.displayFlag || ''],
    product_brand: [bundleProduct.brand?.name || ''],
    product_category: [bundleProduct.parentCategory?.id || ''],
    product_id: [bundleProduct.partNumber],
    product_image: [`${bundleProduct.getMedia(productColour ?? '').imageUrl || ''}?$PDP_M_ZOOM$`],
    product_name: [bundleProduct.productName],
    product_sizes: bundleProduct.sizes || [''],
    product_stock: ['instock'],
    product_season: [bundleProduct.season?.name || ''],
    product_subscription_eligibility: [''],
    product_wcid: [bundleProduct.partNumber],
    product_price_type: [Boolean(skuPrice && !skuPrice.was && !skuPrice.wasWas) ? 'fullprice' : 'markdown'],
    product_department_id: [bundleProduct.department?.id || ''],
    product_department_name: [bundleProduct.department?.name || ''],
    product_division_id: [bundleProduct.division?.id || ''],
    product_division_name: [bundleProduct.division?.name || ''],
    product_group_id: [bundleProduct.group?.id || ''],
    product_group_name: [bundleProduct.group?.name || ''],
    product_price: [`${skuPrice?.raw.current || ''}`],
    product_type: [''],
    product_subtype: [''],
    was_price: [`${skuPrice?.raw.was || ''}`],
    was_was_price: [`${skuPrice?.raw.wasWas || ''}`],
    page_breadcrumb: `home|${[...breadcrumbs].pop()?.key.toLowerCase()}`,
    page_category_name: [bundleProduct.parentCategory?.name || ''],
    page_category_id: [bundleProduct.parentCategory?.id || ''],
  };
}

export function bundlePanelAvailabilityTracking(bundleAnalyticsBaseProps: BundleAnalyticsBaseProps) {
  const { helpers } = analyticsMiddleware;

  const bundleAnalyticsBaseData = getBundleAnalyticsBaseData(bundleAnalyticsBaseProps);

  const { bundleProduct, productColour } = bundleAnalyticsBaseProps;

  const skus = bundleProduct.getRawData()?.skus;

  if (!skus) return;

  const selectedColour = productColour || skus[0].colour?.name || null;

  const colourStock = skus.filter(sku => sku.colour?.name === selectedColour);
  const availableStock = colourStock.filter(sku => sku.prices && sku.availableQuantity && sku.availableQuantity > 0);

  analyticsMiddleware.triggerLink({
    ...helpers.sanitiseDataLayer(window.dataLayer || {}),
    ...bundleAnalyticsBaseData,
    event_name_cm: 'Product_Availability',
    event_name: 'product_availability',
    event_details_1: `${bundleProduct.partNumber}:${productColour || ''}:${availableStock.length}:${
      bundleProduct.sizes ? bundleProduct.sizes.length : 1
    }`,
    product_instock:
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      skus.map(({ media, colour, availableQuantity, ...rest }) => ({
        colour: colour?.name || null,
        availableStock: availableQuantity,
        ...rest,
      })),
  });
}

export function bundlePanelAddToBagTracking({
  productQuantity,
  ...bundleAnalyticsBaseProps
}: BundleAddToBagTrackingProps) {
  const { helpers } = analyticsMiddleware;

  const bundleAnalyticsBaseData = getBundleAnalyticsBaseData(bundleAnalyticsBaseProps);

  const { productColour, productSize } = bundleAnalyticsBaseProps;

  analyticsMiddleware.triggerLink({
    ...helpers.sanitiseDataLayer(window.dataLayer || {}),
    ...bundleAnalyticsBaseData,
    ga_event_name: 'add to cart',
    ga_eventCategory: 'Ecommerce',
    ga_eventAction: 'Add to cart',
    event_name: 'cart_add',
    recommended_product_size: [],
    product_qty: productQuantity.toFixed(1),
    product_colour: productColour,
    product_size: productSize,
    ratings_reviews_exits: 'no',
    ratings_reviews_user_engagement: 'no',
  });
}

export async function bundlePanelViewTracking(bundleAnalyticsBaseProps: BundleAnalyticsBaseProps) {
  await analyticsMiddleware.isReady();

  const { helpers } = analyticsMiddleware;

  const bundleAnalyticsBaseData = getBundleAnalyticsBaseData(bundleAnalyticsBaseProps);

  const { bundleProduct } = bundleAnalyticsBaseProps;

  analyticsMiddleware.triggerPageView({
    ...helpers.sanitiseDataLayer(window.dataLayer || {}),
    ...bundleAnalyticsBaseData,
    page_name: `Product_details_${bundleProduct.partNumber}_bundledrawer`,
    page_type: 'product|bundledrawer',
  });
}

const youMayAlsoLikeCarouselProductEvent: AnalyticsEvent = {
  app: 'pdp',
  component: 'ymal_product_carousel',
  onTrigger: ({ linkElement }: AnalyticsEventObject): AnalyticsEventReturn => {
    const { helpers } = analyticsMiddleware;

    const position = helpers.getAnalyticsAttribute(linkElement, 'Position') || '';
    const product = helpers.getAnalyticsAttribute(linkElement, 'Details');

    const productArray = product?.split('|') as AnalyticsProductArray;

    const {
      product_division_name = '',
      product_department_name = '',
      product_brand = '',
      product_id = '',
    } = (window.dataLayer || {}) as Record<string, string>;

    return {
      ...helpers.sanitiseDataLayer(window.dataLayer || {}),
      product_list_name: ['pdp_ymal'],
      product_list_pos: [position],
      product_name: [productArray[0]],
      product_type: [productArray[1]],
      product_id: [productArray[2]],
      product_price: [productArray[3]],
      product_brand: [productArray[4]],
      product_wcid: [productArray[5]],
      product_was_price: [productArray[6]],
      product_was_was_price: [productArray[7]],
      product_badge: [productArray[8]],
      ga_eventlabel: `${
        product_id && product_id[0]
      }|${product_brand}|${product_division_name}|${product_department_name}`,
      ga_eventcategory: 'enhanced_ecommerce',
      ga_eventaction: product_id && `Product_detail_${product_id[0]}`,
    };
  },
};

const sizeGuideTracking: AnalyticsEvent = {
  app: 'pdp',
  component: 'size_guide_button',
  onTrigger: (): AnalyticsEventReturn => {
    const {
      page_name,
      page_type,
      page_breadcrumb,
      site_language,
      site_currency,
      site_version,
      site_region,
      site_view,
      platform,
      product_id,
    } = window.dataLayer as Record<string, string>;

    return {
      page_name,
      page_type,
      page_breadcrumb,
      site_language,
      site_currency,
      site_version,
      site_region,
      site_view,
      platform,
      event_name: 'sizefit_guide_click',
      ga_event_name: 'sizefit_guide_click',
      ga_eventcategory: 'sizefit_guide_pdp_click',
      ga_event_action: page_breadcrumb,
      ga_eventlabel: product_id[0],
    };
  },
};

const wishListTracking: AnalyticsEvent = {
  app: 'pdp',
  component: 'wish_list_button',
  onTrigger: ({ linkElement }: AnalyticsEventObject): AnalyticsEventReturn => {
    const { helpers } = analyticsMiddleware;

    const isAddedToWishList = helpers.getAnalyticsAttribute(linkElement, 'Details') === 'remove';

    const { product_id = '' } = (window.dataLayer || {}) as Record<string, string>;

    return {
      ...helpers.sanitiseDataLayer(window.dataLayer || {}),
      event_name: isAddedToWishList ? 'remove_from_wishlist' : 'add_to_wishlist',
      ga_eventName: isAddedToWishList ? 'remove_from_wishlist' : 'add_to_wishlist',
      ga_eventCategory: 'Ecommerce',
      ga_eventAction: isAddedToWishList ? 'PDP Wish Icon Removal Click' : 'PDP Wish Icon Add Click',
      link_name: isAddedToWishList ? 'remove from wishlist' : 'Add to wishlist',
      link_component: isAddedToWishList ? 'pdp remove from wishlist CTA' : "PDP's Add to wishlist CTA",
      link_position: '1',
      ga_eventLabel: product_id[0],
    };
  },
};

const recommendedForYouCarouselProductEvent: AnalyticsEvent = {
  app: 'pdp',
  component: 'rfy_product_carousel',
  onTrigger: ({ linkElement }: AnalyticsEventObject): AnalyticsEventReturn => {
    const { helpers } = analyticsMiddleware;

    const {
      product_division_name = '',
      product_department_name = '',
      product_brand = '',
      product_id = '',
    } = (window.dataLayer || {}) as Record<string, string>;

    const product = helpers.getAnalyticsAttribute(linkElement, 'Details');
    const position = helpers.getAnalyticsAttribute(linkElement, 'Position') || '';

    const productArray = product?.split('|') as AnalyticsProductArray;

    return {
      ...helpers.sanitiseDataLayer(window.dataLayer || {}),
      product_list_name: ['pdp_rfy'],
      product_list_pos: [position],
      ga_eventlabel: `${
        product_id && product_id[0]
      }|${product_brand}|${product_division_name}|${product_department_name}`,
      ga_eventaction: product_id && `Product_detail_${product_id[0]}`,
      ga_eventcategory: 'enhanced_ecommerce',
      product_name: [productArray[0]],
      product_type: [productArray[1]],
      product_id: [productArray[2]],
      product_price: [productArray[3]],
      product_brand: [productArray[4]],
      product_wcid: [productArray[5]],
      product_was_price: [productArray[6]],
      product_was_was_price: [productArray[7]],
      product_badge: [productArray[8]],
    };
  },
};

const recentlyViewedProductEvent: AnalyticsEvent = {
  app: 'pdp',
  component: 'recently_viewed_carousel',
  onTrigger: ({ linkElement }: AnalyticsEventObject): AnalyticsEventReturn => {
    const { helpers } = analyticsMiddleware;

    const {
      product_division_name = '',
      product_department_name = '',
      product_brand = '',
      product_id = '',
    } = (window.dataLayer || {}) as Record<string, string>;

    const position = helpers.getAnalyticsAttribute(linkElement, 'Position') || '';
    const product = helpers.getAnalyticsAttribute(linkElement, 'Details');

    const productArray = product?.split('|') as AnalyticsProductArray;

    return {
      ...helpers.sanitiseDataLayer(window.dataLayer || {}),
      ga_eventlabel: `${
        product_id && product_id[0]
      }|${product_brand}|${product_division_name}|${product_department_name}`,
      ga_eventaction: product_id && `Product_detail_${product_id[0]}`,
      product_list_name: ['pdp_recentlyviewed'],
      product_list_pos: [position],
      product_name: [productArray[0]],
      product_type: [productArray[1]],
      product_id: [productArray[2]],
      product_price: [productArray[3]],
      product_brand: [productArray[4]],
      product_wcid: [productArray[5]],
      product_was_price: [productArray[6]],
      product_was_was_price: [productArray[7]],
      product_badge: [productArray[8]],
      ga_eventcategory: 'enhanced_ecommerce',
    };
  },
};

const bundleCarouselTracking: AnalyticsEvent = {
  app: 'pdp',
  component: 'bundle_carousel',
  onTrigger: ({ linkElement }: AnalyticsEventObject): AnalyticsEventReturn => {
    const { helpers } = analyticsMiddleware;

    const position = helpers.getAnalyticsAttribute(linkElement, 'Position') || '';
    const product = helpers.getAnalyticsAttribute(linkElement, 'Details');

    const { product_brand = '', product_id = '' } = (window.dataLayer || {}) as Record<string, string>;

    const productArray = product?.split('|') as AnalyticsProductArray;

    return {
      ...helpers.sanitiseDataLayer(window.dataLayer || {}),
      product_name: [productArray[0]],
      product_price: [productArray[3]],
      product_brand: [productArray[4]],
      product_wcid: [productArray[2]],
      product_id: [productArray[2]],
      product_list_name: [`bundle|${product_brand}|${product_id}`],
      product_list_pos: [position],
      ga_eventcategory: 'enhanced_ecommerce',
      product_price_gbp: '',
    };
  },
};

const averageRatingEventTracking: AnalyticsEvent = {
  app: 'pdp',
  component: 'rating-anchor',
  onTrigger: (): AnalyticsEventReturn => {
    const { helpers } = analyticsMiddleware;

    const { product_id = '' } = (window.dataLayer || {}) as Record<string, string>;

    return {
      ...helpers.sanitiseDataLayer(window.dataLayer || {}),
      ga_eventaction: 'Anchor Link',
      ga_eventlabel: product_id[0],
    };
  },
};

const ratingsReviewsEventTracking: AnalyticsEvent = {
  app: 'pdp',
  component: 'ratings-and-reviews',
  onTrigger: ({ linkElement }: AnalyticsEventObject): AnalyticsEventReturn => {
    const { helpers } = analyticsMiddleware;

    const actionName = helpers.getAnalyticsAttribute(linkElement, 'Differentiator') || '';

    const { product_id = '' } = (window.dataLayer || {}) as Record<string, string>;

    return {
      ...helpers.sanitiseDataLayer(window.dataLayer || {}),
      ga_eventaction: actionName,
      ga_eventlabel: product_id[0],
    };
  },
};

const expandReviewEventTracking: AnalyticsEvent = {
  app: 'pdp',
  component: 'show-more-button',
  onTrigger: (): AnalyticsEventReturn => {
    const { helpers } = analyticsMiddleware;

    const { product_id = '' } = (window.dataLayer || {}) as Record<string, string>;

    return {
      ...helpers.sanitiseDataLayer(window.dataLayer || {}),
      ga_eventaction: 'Expand Review',
      ga_eventlabel: product_id[0],
    };
  },
};

export function saveGiftMessageTracking({
  saveStatus,
  partNumber,
}: {
  saveStatus: 'success' | 'fail';
  partNumber: string;
}) {
  const { helpers } = analyticsMiddleware;

  analyticsMiddleware.triggerLink({
    ...helpers.sanitiseDataLayer(window.dataLayer || {}),
    event_name: `giftmessage|${partNumber}`,
    ga_eventname: `giftmessage|${partNumber}`,
    ga_eventaction: 'giftmessage_saved',
    ga_eventcategory: 'gifting',
    ga_eventlabel: saveStatus,
  });
}

export function savePersonalisationTracking({
  saveStatus,
  partNumber,
}: {
  saveStatus: 'success' | 'fail';
  partNumber: string;
}) {
  const { helpers } = analyticsMiddleware;

  analyticsMiddleware.triggerLink({
    ...helpers.sanitiseDataLayer(window.dataLayer || {}),
    event_name: `personalise|${partNumber}`,
    ga_eventname: `personalise|${partNumber}`,
    ga_eventaction: 'personaliseitem_saved',
    ga_eventcategory: 'personalisation',
    ga_eventlabel: saveStatus,
  });
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export async function productDetailsPageAnalytics(
  partNumber: string,
  productResponse: ProductResponse,
  gbpPrice?: Price,
) {
  if (typeof window === 'undefined') return;

  const performanceEntry = performance.getEntriesByType('navigation')[0];

  const { siteView, siteVersion } = getSiteInformation();
  const MEDIA_ROOT_URL = () => env('REACT_APP_MEDIA_ROOT_URL') || '';

  const breadcrumbs = productResponse.breadcrumbs || [];
  const { price, giftMessageAvailable: isGifting, personalisation, displayFlag } = productResponse;
  const isPersonalised = Boolean(personalisation);
  const isBundled = productResponse.isBundled;

  let pageName: string;
  let pageType: string;
  switch (true) {
    case isGifting:
      pageName = `Product_detail_${partNumber}_gift_messaging`;
      pageType = 'product|giftmessage';
      break;
    case isBundled:
      pageName = `Product_detail_${partNumber}_bundles`;
      pageType = 'product|bundles';
      break;
    case isPersonalised:
      pageName = `Product_detail_${partNumber}_personalise`;
      pageType = 'product|personalise';
      break;
    default:
      pageName = `Product_detail_${partNumber}`;
      pageType = 'product';
      break;
  }

  analyticsMiddleware.addVisibilityEvents([
    ...analyticsMiddleware.attachCoreEventProps(
      {
        event_name: 'recentlyviewed_product_impression',
        ga_event_name: 'recentlyviewed_product_impression',
      },
      [recentlyViewedProductEvent],
    ),
    ...analyticsMiddleware.attachCoreEventProps(
      {
        event_name: 'rfy_product_impression',
        ga_event_name: 'rfy_product_impression',
      },
      [recommendedForYouCarouselProductEvent],
    ),
    ...analyticsMiddleware.attachCoreEventProps(
      {
        event_name: 'ymal_product_impression',
        ga_event_name: 'ymal_product_impression',
      },
      [youMayAlsoLikeCarouselProductEvent],
    ),
    ...analyticsMiddleware.attachCoreEventProps(
      {
        event_name: 'pdpbundles_carousel_impression',
        ga_eventaction: 'pdpbundles_carousel_impression',
      },
      [bundleCarouselTracking],
    ),
  ]);

  analyticsMiddleware.addClickEvents([
    wishListTracking,
    sizeGuideTracking,
    ...analyticsMiddleware.attachCoreEventProps(
      {
        event_name: 'recentlyviewed_product_click',
        ga_event_name: 'recentlyviewed_product_click',
      },
      [recentlyViewedProductEvent],
    ),
    ...analyticsMiddleware.attachCoreEventProps(
      {
        event_name: 'rfy_product_click',
        ga_event_name: 'rfy_product_click',
      },
      [recommendedForYouCarouselProductEvent],
    ),
    ...analyticsMiddleware.attachCoreEventProps(
      {
        event_name: 'ymal_product_click',
        ga_event_name: 'ymal_product_click',
      },
      [youMayAlsoLikeCarouselProductEvent],
    ),
    ...analyticsMiddleware.attachCoreEventProps(
      {
        event_name: 'pdpbundles_carousel_impression_click',
        ga_eventaction: 'pdpbundles_carousel_impression_click',
      },
      [bundleCarouselTracking],
    ),
    ...analyticsMiddleware.attachCoreEventProps(
      {
        event_name: 'rating_review_engagement',
        ga_eventname: 'rating_review_engagement',
        ga_eventcategory: 'review_engagement',
      },
      [averageRatingEventTracking, ratingsReviewsEventTracking, expandReviewEventTracking],
    ),
  ]);

  await analyticsMiddleware.isReady();

  const WCSUtagData = await getWcsUtagData();

  const analyticsView: AnalyticsView = {
    site_view: siteView,
    site_version: siteVersion,
    platform: window && window['FEATURE_SWITCHES'] && !window['FEATURE_SWITCHES'].WCSv9Migration ? 'v6' : 'v9',
    event_name_cm: 'pdp_pageload',
    event_name: 'pdp_pageload',
    pageload_event: 'pdp_pageload',
    page_name: pageName,
    page_breadcrumb: `home|${[...breadcrumbs].pop()?.key.toLowerCase()}`,
    page_category_name: [breadcrumbs[breadcrumbs.length - 1]?.name],
    page_load_time: performanceEntry.duration,
    page_type: pageType,
    page_category_id: productResponse.catalogueParentId || '',
    product_badge: [productResponse.displayFlag || ''],
    product_brand: [productResponse.brand?.name || ''],
    product_category: [productResponse.catalogueParentId || ''],
    product_id: [partNumber],
    product_image: [`${MEDIA_ROOT_URL()}/image/${productResponse.media?.defaultImage || ''}`],
    product_instock: [''],
    product_name: [productResponse.productName],
    product_obcn: [''],
    product_personalised: [isPersonalised ? 'y' : 'n'],
    product_rating_reviews: false,
    product_sizes: productResponse.sizes || [''],
    product_stock: [productResponse.outOfStock ? 'outstock' : 'instock'],
    product_supplier: [''],
    product_sustainability: ['false'],
    product_season: [productResponse.season.name || ''],
    product_subtype: [breadcrumbs[2]?.seo || ''],
    product_subscription_eligibility: [productResponse.eligibleForSubscription ? 'eligible' : 'not eligible'],
    product_type: [breadcrumbs[1]?.seo || breadcrumbs[0]?.seo || ''],
    product_wcid: [partNumber],
    product_price: [`${price?.current || ''}`],
    product_price_gbp: [`${gbpPrice?.raw.current || price?.current}`],
    was_price: [`${(price && price.was) || ''}`],
    was_was_price: [`${(price && price.wasWas) || ''}`],
    product_price_type: [Boolean(price && !price.was && !price.wasWas) ? 'fullprice' : 'markdown'],
    product_department_id: [productResponse.department.id || ''],
    product_department_name: [productResponse.department.name || ''],
    product_division_id: [productResponse.division?.id || ''],
    product_division_name: [productResponse.division?.name || ''],
    product_group_id: [''], //temporarily blank until fix from BE is in
    product_group_name: [productResponse.group.name || ''],
    instore_product:
      displayFlag?.toLowerCase() === 'in store only' || displayFlag === '门店特供' ? 'instore_only' : 'online',

    //membership related variables
    key_customer_status: false,
    membership_tier: 'none',

    ...WCSUtagData,
  };

  if (!window.dataLayer) {
    window.dataLayer = {};
  }

  Object.assign(window.dataLayer, analyticsView);

  analyticsMiddleware.triggerPageView(analyticsView);
}

export function clearAnalytics() {
  // run funcs to clear any dangling analytics events related to the app(ie pdp or megamenu)
  analyticsMiddleware.clearEvents('pdp');
}
