import { MediaResponse } from './contracts/MediaResponse';

export async function fetchProductMedia({
  colour,
  countryLanguage,
  hostRootUrl,
  partNumber,
  additionalHeaders = {},
}: {
  colour: string;
  countryLanguage: string;
  hostRootUrl: string;
  partNumber: string;
  additionalHeaders?: Record<string, string>;
}): Promise<MediaResponse> {
  const response = await fetch(
    `${hostRootUrl}/${countryLanguage}/product/${partNumber}/media?colour=${encodeURIComponent(colour)}`,
    {
      headers: { ...additionalHeaders },
    },
  );

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message);
  }

  return await response.json();
}
