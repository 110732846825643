import AE from './country-flags/ae.svg';
import AU from './country-flags/au.svg';
import CA from './country-flags/ca.svg';
import CN from './country-flags/cn.svg';
import DE from './country-flags/de.svg';
import ES from './country-flags/es.svg';
import FR from './country-flags/fr.svg';
import HK from './country-flags/hk.svg';
import IE from './country-flags/ie.svg';
import IT from './country-flags/it.svg';
import JP from './country-flags/jp.svg';
import KR from './country-flags/kr.svg';
import KW from './country-flags/kw.svg';
import MO from './country-flags/mo.svg';
import NL from './country-flags/nl.svg';
import QA from './country-flags/qa.svg';
import SA from './country-flags/sa.svg';
import SG from './country-flags/sg.svg';
import TW from './country-flags/tw.svg';
import US from './country-flags/us.svg';
import GB from './country-flags/gb.svg';

import type { AvailableCountryCode } from './types';

export const countryFlag: Record<AvailableCountryCode, string> = {
  AU,
  CA,
  CN,
  FR,
  DE,
  HK,
  IE,
  IT,
  JP,
  KW,
  MO,
  NL,
  QA,
  SA,
  SG,
  KR,
  ES,
  TW,
  AE,
  GB,
  US,
};
