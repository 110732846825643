import { EXTERNAL_EVENT } from './constants';

export function createContextDataListener() {
  return `
      function addContextDetails(event) {
        if (!window['__INITIAL_STATE__']) return;
        window['__INITIAL_STATE__'].context = event.detail;
      }
      
      window.removeEventListener("${EXTERNAL_EVENT.CONTEXT_API_DATA_EVENT}", addContextDetails);
      window.addEventListener("${EXTERNAL_EVENT.CONTEXT_API_DATA_EVENT}", addContextDetails);
    `;
}
