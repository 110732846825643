// timeString format has to be DD/MM/YYYY
export function parseUKTime(timeString: string) {
  const [inputDate, inputTime] = timeString.replace(/\s+/g, '').split(',');
  const [day, month, year] = inputDate.split('/').map(Number);
  const [hour, minute, second] = inputTime.split(':').map(Number);

  return new Date(year, month - 1, day, hour, minute, second).getTime();
}

export function getCurrentUKTime() {
  return new Date().toLocaleString('en-GB', { timeZone: 'Europe/London' });
}
