import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { typography, spacing, breakpoints, color } from '@selfridges-co/frontend-sdk-react-theme';

import { PRODUCT_PANEL_GUTTER, WISHLIST_ICON_SIZE } from '../constants';
import { useBrandConfig } from '../brand-configs/Context';

import type { AddToCartError } from '../hooks/useAddToCart';
import type { BrandInfo } from '../types';

export interface ProductPanelProps {
  brandInfo: BrandInfo;
  productDescription: string;
  MoreInformationLink?: React.JSX.Element;
  Rating?: React.JSX.Element;
  DisplayFlag?: React.JSX.Element;
  WishListButton: React.JSX.Element;
  AddGiftMessage?: React.JSX.Element;
  AddPersonalisedMessage?: React.JSX.Element;
  AddToCartButton?: React.JSX.Element;
  addToCartError: AddToCartError;
  Price: React.JSX.Element;
  SizeSelector?: React.JSX.Element;
  ColourSelector?: React.JSX.Element;
  Quantity?: React.JSX.Element;
  OutOfStockTemplate?: React.JSX.Element;
  InStoreOnlyTemplate?: React.JSX.Element;
}

const ProductPanelWrapper = styled.div<{ removeTopPadding?: boolean }>(({ removeTopPadding }) => ({
  width: '100%',
  padding: PRODUCT_PANEL_GUTTER.MOBILE,
  boxSizing: 'border-box',

  [breakpoints.md.mediaQuery]: {
    padding: PRODUCT_PANEL_GUTTER.DESKTOP,
    ...(removeTopPadding && { paddingTop: 0 }),
  },
}));

const WishlistButtonContainer = styled.div<{ removeTopPadding?: boolean }>(({ removeTopPadding }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  padding: PRODUCT_PANEL_GUTTER.MOBILE,

  [breakpoints.md.mediaQuery]: {
    padding: PRODUCT_PANEL_GUTTER.DESKTOP,
    ...(removeTopPadding && { paddingTop: 0 }),
  },
}));

const BrandName = styled.a({
  ...typography.typeface.display.sm,
  color: color.palette.mainBlack,
  width: `calc(100% - ${WISHLIST_ICON_SIZE})`,
  display: 'block',
  textDecoration: 'none',
  padding: 0,
  margin: 0,

  [breakpoints.md.mediaQuery]: {
    ...typography.typeface.display.md,
  },
});

const ProductDescription = styled.p({
  ...typography.typeface.text.md.regular,
  color: color.palette.mainBlack,
  width: '100%',
  paddingTop: spacing(2),
});

const ButtonsContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginTop: spacing(8),
  gap: spacing(4),
});

const DisplayFlagContainer = styled.div({
  marginBottom: spacing(2),
});

export default function ProductPanel({
  brandInfo,
  productDescription,
  MoreInformationLink,
  Rating,
  DisplayFlag,
  WishListButton,
  AddGiftMessage,
  AddPersonalisedMessage,
  AddToCartButton,
  addToCartError,
  Price,
  SizeSelector,
  ColourSelector,
  Quantity,
  OutOfStockTemplate,
  InStoreOnlyTemplate,
}: ProductPanelProps): React.ReactElement {
  const productPanelRef = useRef<HTMLDivElement>(null);

  const { removeProductPanelTopPadding } = useBrandConfig();

  useEffect(() => {
    if (!productPanelRef.current || !addToCartError.noSkuProvided) return;
    productPanelRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }, [addToCartError]);

  return (
    <ProductPanelWrapper ref={productPanelRef} removeTopPadding={removeProductPanelTopPadding}>
      <WishlistButtonContainer removeTopPadding={removeProductPanelTopPadding}>
        {WishListButton}
      </WishlistButtonContainer>
      {DisplayFlag && <DisplayFlagContainer>{DisplayFlag}</DisplayFlagContainer>}
      <h1>
        <BrandName href={brandInfo.url}>
          {brandInfo.name}
          {brandInfo.subName && (
            <>
              <br />
              {brandInfo.subName}
            </>
          )}
        </BrandName>
        {productDescription && <ProductDescription>{productDescription}</ProductDescription>}
      </h1>
      {MoreInformationLink}
      {Price}
      {Rating}
      {ColourSelector}
      {SizeSelector}
      {Quantity}
      {!InStoreOnlyTemplate && !OutOfStockTemplate && (
        <ButtonsContainer>
          {!Boolean(AddPersonalisedMessage) && AddGiftMessage}
          {AddPersonalisedMessage}
          {AddToCartButton}
        </ButtonsContainer>
      )}
      {InStoreOnlyTemplate}
      {OutOfStockTemplate}
    </ProductPanelWrapper>
  );
}
