import React from 'react';
import styled from 'styled-components';
import { t } from 'i18next';

import { breakpoints, color, spacing, typography } from '@selfridges-co/frontend-sdk-react-theme';

import { InfoToast } from '../hooks/useToastMessage';

import type { ErrorKey } from '@selfridges-pkg/wcs-api/contracts/AddToCartResponse';

type ErrorMessageProps = {
  errorKey: ErrorKey;
  item: string;
};

const Container = styled(InfoToast)({
  boxSizing: 'border-box',
  padding: spacing(4),
  background: color.palette.mainBlack,
  pointerEvent: 'auto',

  [breakpoints.md.mediaQuery]: {
    width: '100%',
  },
});

const Text = styled.div({
  ...typography.typeface.display.xs,
  color: color.palette.mainWhite,
});

function ErrorMessage({ errorKey, item }: ErrorMessageProps) {
  let errorMessage: string;

  switch (errorKey) {
    case 'CART.CHECK_SUBSCRIPTION_RULES.ALREADY_IN_BASKET':
      errorMessage = t('app.add-to-cart.error.already-in-basket', {
        defaultValue: 'Selfridges Plus - {{item}} is already in your basket',
        item,
      });
      break;
    case 'CART.CHECK_SUBSCRIPTION_RULES.ALREADY_SUBSCRIBED':
      errorMessage = t('app.add-to-cart.error.already-subscribed', {
        defaultValue: 'Your subscription to Selfridges Plus - {{item}} is already active',
        item,
      });
      break;
    default:
      errorMessage = t('app.add-to-cart.error.unknown', {
        defaultValue: 'We are unable to add Selfridges Plus to your bag right now. Please try again later.',
        item,
      });
      break;
  }

  return (
    <Container>
      <Text>{errorMessage}</Text>
    </Container>
  );
}

export default ErrorMessage;
