import { MouseEvent } from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

import { Link as LinkComponent } from '@selfridges-co/frontend-sdk-react-link';

type LinkProps = {
  onClick: (event?: MouseEvent<HTMLElement>) => void;
};

const LinkEl = styled(LinkComponent).attrs({ forwardedAs: 'button' })({});

export function Link({ onClick }: LinkProps) {
  return (
    <LinkEl linkType="primary" variant="black" onClick={onClick}>
      <Trans i18nKey="app.wish-list.button.unchecked">Add to wish list</Trans>
    </LinkEl>
  );
}
