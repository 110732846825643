import { JSX } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { spacing, typography } from '@selfridges-co/frontend-sdk-react-theme';
import { Link } from '@selfridges-co/frontend-sdk-react-link';

import { useAddToCart } from '../hooks/useAddToCart';

import SuccessMessage from './SuccessMessage';
import ErrorMessage from './ErrorMessage';

import type { SelfridgesPlusItem } from '../types';

type SelfridgesPlusItemProps = {
  item: SelfridgesPlusItem;
  countryLanguage: string;
  onAddToBag: (Message: JSX.Element) => void;
};

const Container = styled.div({
  '&:not(:last-child)': {
    marginBottom: spacing(10),
  },
});

const TitleAndPriceContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

const Title = styled.h3({
  ...typography.typeface.text.lg.bold,
});

const Price = styled.div({
  ...typography.typeface.text.lg.bold,
});

const List = styled.ul({
  ...typography.typeface.text.md.regular,
  listStyle: 'disc',
  paddingLeft: spacing(6),
  marginTop: spacing(3),
});

const AddToBagContainer = styled.div({
  marginTop: spacing(3),
  marginBottom: spacing(4),
});

const TermsAndConditions = styled.div({
  marginTop: spacing(4),
  textAlign: 'center',
});

const TermsAndConditionsLink = styled(Link)({
  textDecoration: 'underline',
});

export function Item({ item, countryLanguage, onAddToBag }: SelfridgesPlusItemProps) {
  const { t } = useTranslation();
  const { AddToCartButton } = useAddToCart({
    skuId: item.skuId,
    partNumber: item.partNumber,
    quantity: 1,
    countryLanguage,
    onSuccess: () => {
      /* istanbul ignore next */ // This scenario is handled within a functional UI test
      onAddToBag(<SuccessMessage title={item.title} countryLanguage={countryLanguage} />);
    },
    onError: ({ errorKey }) => {
      /* istanbul ignore next */ // This scenario is handled within a functional UI test
      onAddToBag(<ErrorMessage errorKey={errorKey} item={item.title} />);
    },
  });

  return (
    <Container>
      <TitleAndPriceContainer>
        <Title>{item.title}</Title>
        <Price>{item.price}</Price>
      </TitleAndPriceContainer>
      <List>
        {item.copyList.map(item => (
          <li key={item}>{item}</li>
        ))}
      </List>
      <AddToBagContainer>
        <AddToCartButton
          statusText={{
            idle: <Trans i18nKey="app.selfridges-plus.add-to-bag.idle">Add to bag - {{ price: item.price }}</Trans>,
            loading: (
              <Trans i18nKey="app.selfridges-plus.add-to-bag.loading">Add to bag - {{ price: item.price }}</Trans>
            ),
            success: <Trans i18nKey="app.selfridges-plus.add-to-bag.success">Added to bag</Trans>,
          }}
          aria-label={t('app.selfridges-plus.add-to-bag.label', {
            defaultValue: 'Add to bag - {{title}}',
            title: item.title,
          })}
        />
      </AddToBagContainer>
      <TermsAndConditions>
        <TermsAndConditionsLink linkType="secondary" variant="black" href={item.termsConditions.url} target="_blank">
          {item.termsConditions.text}
        </TermsAndConditionsLink>
      </TermsAndConditions>
    </Container>
  );
}
