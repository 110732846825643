import { useMemo } from 'react';

import type { Rating as RatingResponse } from '@selfridges-pkg/yotpo-api/contracts/RatingsAndReviewsResponse';
import type { RatingSummary } from '../types';

export function useTransformRatingData(data?: RatingResponse): RatingSummary | undefined {
  return useMemo(() => {
    if (!data) return;

    return {
      score: Math.round(data.average_score * 10) / 10,
      totalReviews: data.total_review,
      starDistribution: data.star_distribution || { '1': 0, '2': 0, '3': 0, '4': 0, '5': 0 },
    };

    // We are purposely ignoring `data.star_distribution` from the dep array because it is an object that is compared by reference so will cause this code to run on every render.
    // We are confident that `data.star_distribution` will never change on re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.total_review, data?.average_score]);
}
