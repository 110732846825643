import { useTranslation } from 'react-i18next';
import { MouseEvent } from 'react';

import AddOnMessageNotification from '../add-on-message-notification/AddOnMessageNotification';

import AddGiftMessageButton from './AddGiftMessageButton';

interface AddGiftMessageProps {
  giftMessage: string;
  openGiftingModal: (event?: MouseEvent<HTMLElement>) => void;
  onRemove: (event?: MouseEvent<HTMLElement>) => void;
}

export default function AddGiftMessage({ giftMessage, openGiftingModal, onRemove }: AddGiftMessageProps) {
  const { t } = useTranslation();

  return giftMessage ? (
    <AddOnMessageNotification
      message={giftMessage}
      title={t('app.gifting.add-gift-message.notification.title', {
        defaultValue: 'Gift message:',
      }).toString()}
      editButtonAriaLabel={t('app.gifting.add-gift-message.notification.edit-button-aria-label', {
        defaultValue: 'Edit gift message',
      }).toString()}
      removeButtonAriaLabel={t('app.gifting.add-gift-message.notification.remove-button-aria-label', {
        defaultValue: 'Remove gift message',
      }).toString()}
      onEdit={openGiftingModal}
      onRemove={onRemove}
    />
  ) : (
    <AddGiftMessageButton onClick={openGiftingModal} />
  );
}
