import { useCallback, useEffect, useRef } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { DialogContent, DialogOverlay } from '@reach/dialog';

import { breakpoints, color, spacing } from '@selfridges-co/frontend-sdk-react-theme';
import { Picture } from '@selfridges-co/frontend-sdk-react-scene7';
import { getStaticResourceUrl } from '@selfridges-pkg/utils';

import { MAX_SCREEN_WIDTH } from '../constants';

import { Scene7Video, PLAY_SVG } from './Scene7Video';
import ZoomOutCursor from './ZoomOutCursor.png';

const CROSS_SVG_MOBILE =
  "data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5 5L12 12M19 19L12 12M12 12L19 5M12 12L5 19' stroke='%23212121' stroke-linecap='round'/%3e%3c/svg%3e";
const CROSS_SVG_DESKTOP =
  "data:image/svg+xml,%3csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M10 38L24 24M38 10L24 24M24 24L10 10M24 24L38 38' stroke='%23212121' stroke-linecap='round'/%3e%3c/svg%3e";
const ARROW_SVG_MOBILE =
  "data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M19.5 12H4.5L10 17.5' stroke='%23212121' stroke-linecap='round' stroke-linejoin='round'/%3e%3cpath d='M19.5 12H4.5L10 6.5' stroke='%23212121' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e";
const ARROW_SVG_DESKTOP =
  "data:image/svg+xml,%3csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M39.5 23.5H8.5C8.5 23.5 14.2516 29.2516 20.5 35.5' stroke='%23212121' stroke-linecap='round' stroke-linejoin='round'/%3e%3cpath d='M39.5 23.5H8.5L19.5 12.5' stroke='%23212121' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e";

export type MediaInfo = { type: 'video' | 'image'; url: string };

type ZoomImageModalProps = {
  mediaInfoList: Array<MediaInfo>;
  altText: string;
  zoomedImageIndex: number;
  onClickThumbnail: (index: number) => void;
  onNextImage: () => void;
  onPreviousImage: () => void;
  onClose: () => void;
  imageBackgroundColour: string;
};

const Overlay = styled(DialogOverlay)({
  ':root': {
    '--reach-dialog': 1,
  },

  background: `${color.palette.mainBlack}33`,
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 101, // needs to appear on top of the mega menu on mobile
});

const Dialog = styled(DialogContent)({
  background: color.palette.lightGrey2,
  position: 'relative',
  maxHeight: '1280px',
  height: '100%',
  maxWidth: MAX_SCREEN_WIDTH.DESKTOP,
  margin: 'auto',
  top: '50%',
  transform: 'translateY(-50%)',
});

const Content = styled.div({
  display: 'flex',
  height: '100%',
  flexDirection: 'column',

  [breakpoints.md.mediaQuery]: {
    flexDirection: 'row',
  },
});

const PlayButton = styled.div({
  width: spacing(13),
  height: spacing(13),
  borderRadius: spacing(13),
  background: 'white',
  border: 'none',
  backgroundImage: `url("data:image/svg+xml,${PLAY_SVG}")`,
  backgroundPosition: 'center',
  cursor: 'pointer',
});

const VideoThumbnail = styled.div({
  width: '90px',
  height: 'calc(90px / 3 * 4)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [breakpoints.md.mediaQuery]: {
    width: '120px',
    height: 'calc(120px / 3 * 4)',
  },
});

const VideoCenter = styled.div({
  display: 'flex',
  justifyContent: 'center',
  height: '100%',
  cursor: 'default',
});

const BaseIcon = styled.div({
  background: color.palette.mainWhite,
  borderRadius: '100%',
  transition: 'background 0.2s',
  cursor: 'pointer',

  '@media (hover: hover)': {
    ':hover': {
      background: color.palette.midGrey2,
    },
  },

  ':active': {
    background: color.palette.lightGrey1,
    boxShadow: `inset 0px 0px 0px 0.1rem ${color.palette.midGrey2}`,
  },
});

const CloseIcon = styled(BaseIcon)({
  width: '3.2rem',
  height: '3.2rem',

  '::after': {
    content: '" "',
    display: 'block',
    width: '100%',
    height: '100%',
    background: `url("${CROSS_SVG_MOBILE}") center center no-repeat`,
  },

  [breakpoints.md.mediaQuery]: {
    width: '5.2rem',
    height: '5.2rem',

    '::after': {
      background: `url("${CROSS_SVG_DESKTOP}") center center no-repeat`,
    },
  },
});

const ZoomedImageWrapper = styled.div({
  // uses 2x to make the cursor twice in pixel density to product better cursor image
  // IMPORTANT: PNG is required here to enable FireFox to work correctly
  cursor: `-webkit-image-set( url(${getStaticResourceUrl(ZoomOutCursor)}) 2x ) 20 20, zoom-out`,
  overflow: 'auto',
  overscrollBehavior: 'none',
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  ['@media (prefers-reduced-motion)']: {
    scrollBehavior: 'auto',
  },

  ['::-webkit-scrollbar']: {
    display: 'none',
  },

  [breakpoints.md.mediaQuery]: {
    display: 'block',
    position: 'relative',
    order: 1,
  },
});

const NavigationButton = styled.button({
  position: 'absolute',
  cursor: 'pointer',
  fontSize: 0,
  zIndex: 2,
  padding: spacing(4),
  border: 'none',
  background: 'transparent',

  [breakpoints.md.mediaQuery]: {
    position: 'fixed',
    padding: spacing(8),
    top: '50%',
    transform: 'translateY(-50%)',
  },
});

const PreviousButton = styled(NavigationButton)({
  left: 0,
  [breakpoints.md.mediaQuery]: {
    left: 'unset',
  },
});

const NextButton = styled(NavigationButton)({
  right: 0,
});

const CloseButton = styled(NavigationButton)({
  right: 0,
  top: 0,
  transform: 'unset',
});

const PreviousIcon = styled(BaseIcon)({
  width: '3.2rem',
  height: '3.2rem',

  '::after': {
    content: '" "',
    display: 'block',
    width: '100%',
    height: '100%',
    background: `url("${ARROW_SVG_MOBILE}") center center no-repeat`,
  },

  [breakpoints.md.mediaQuery]: {
    width: '5.2rem',
    height: '5.2rem',

    '::after': {
      background: `url("${ARROW_SVG_DESKTOP}") center center no-repeat`,
    },
  },
});

const NextIcon = styled(PreviousIcon)({
  transform: 'rotate(180deg)',
});

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const ZoomImageContainer = styled.div({
  width: '100%',
  height: '100%',
});

const ZoomedImage = styled(Picture)(
  {
    transform: 'scale(2)',
    transformOrigin: 'top left',
    width: '100%',

    [breakpoints.sm.mediaQuery]: {
      transform: 'scale(1.5)',
    },

    [breakpoints.md.mediaQuery]: {
      height: 'auto',
      transform: 'scale(1)',
    },
  },
  css`
    animation: ${fadeInAnimation} 0.2s ease-out;
  `,
);

const Thumbnails = styled.section({
  display: 'flex',
  height: '120px',
  overflowX: 'auto',
  overflowY: 'hidden',
  scrollbarWidth: 'none',

  ['::-webkit-scrollbar']: {
    display: 'none',
  },

  [breakpoints.md.mediaQuery]: {
    overflowX: 'hidden',
    overflowY: 'auto',
    height: 'auto',
    flexDirection: 'column',
    flex: '0 0 120px',
    order: 0,
  },
});

const ThumbnailButton = styled.button<{ selected: boolean }>(({ selected }) => ({
  position: 'relative',
  background: 'none',
  padding: 0,
  boxSizing: 'border-box',
  border: 'none',
  cursor: 'pointer',

  '::after': {
    content: "' '",
    position: 'absolute',
    inset: 0,
    border: `1px solid ${selected ? color.palette.mainBlack : 'transparent'}`,
  },
}));

const ThumbnailImage = styled(Picture)({
  cursor: 'pointer',
  width: '90px',

  [breakpoints.md.mediaQuery]: {
    width: '100%',
  },
});

export default function ZoomImageModal({
  mediaInfoList,
  altText,
  zoomedImageIndex,
  onClickThumbnail,
  onNextImage,
  onPreviousImage,
  onClose,
  imageBackgroundColour,
}: ZoomImageModalProps) {
  const { t } = useTranslation();
  const thumbnailsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!thumbnailsRef.current) return;

    thumbnailsRef.current.children[zoomedImageIndex].scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }, [zoomedImageIndex]);

  const imageContainerRef = useCallback(
    node => {
      if (!node) return;

      const scrollAdjustment = 18;

      // Center the image
      node.scrollLeft = (node.scrollWidth - node.clientWidth - scrollAdjustment) / 2;
      node.scrollTop = (node.scrollHeight - node.clientHeight - scrollAdjustment) / 2;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [zoomedImageIndex],
  );

  function handleClickNext(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();
    onNextImage();
  }

  function handleClickPrevious(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();
    onPreviousImage();
  }

  return (
    <Overlay isOpen={zoomedImageIndex > -1} onDismiss={onClose} allowPinchZoom>
      <Dialog>
        <Content>
          <ZoomedImageWrapper onClick={onClose} ref={imageContainerRef}>
            {zoomedImageIndex > 0 && (
              <PreviousButton onClick={handleClickPrevious} role="button">
                <PreviousIcon>
                  <Trans i18nKey="image-gallery.zoom-modal.previous-button">Previous image</Trans>
                </PreviousIcon>
              </PreviousButton>
            )}
            {mediaInfoList[zoomedImageIndex]?.type === 'image' ? (
              <ZoomImageContainer>
                <ZoomedImage
                  key={zoomedImageIndex}
                  src={mediaInfoList[zoomedImageIndex].url}
                  loading="lazy"
                  draggable="false"
                  alt={`${t('image-gallery.zoom-modal.zoomed-image.alt-text', {
                    defaultValue: '{{altText}} - Zoomed image',
                    altText: altText,
                  }).toString()}`}
                  intrinsicMinWidth={1500}
                  aspectRatio={[3, 4]}
                  bgColour={imageBackgroundColour}
                  extendedCrop={[-18, 0, -18, 0]}
                  devicePixelRatios={[1]}
                />
              </ZoomImageContainer>
            ) : (
              <VideoCenter>
                <Scene7Video
                  key={zoomedImageIndex}
                  src={mediaInfoList[zoomedImageIndex]?.url}
                  fmt="mp4"
                  centerPlayButton={true}
                  onClick={event => event.stopPropagation()}
                  alt={`${t('image-gallery.zoom-modal.zoomed-video.alt-text', {
                    defaultValue: '{{altText}} - Zoomed video',
                    altText: altText,
                  }).toString()}`}
                  muted={false}
                  autoPlay={true}
                  showControls={true}
                  showPlayButton={false}
                />
              </VideoCenter>
            )}
            {mediaInfoList.length - 1 - zoomedImageIndex > 0 && (
              <NextButton onClick={handleClickNext} role="button">
                <NextIcon>
                  <Trans i18nKey="image-gallery.zoom-modal.next-button">Next image</Trans>
                </NextIcon>
              </NextButton>
            )}
          </ZoomedImageWrapper>
          <Thumbnails ref={thumbnailsRef}>
            {mediaInfoList.map((mediaInfo: MediaInfo, index: number) => (
              <ThumbnailButton
                key={mediaInfo.url}
                onClick={() => onClickThumbnail(index)}
                selected={index === zoomedImageIndex}
                autoFocus={index === zoomedImageIndex}
              >
                {mediaInfo.type === 'image' ? (
                  <ThumbnailImage
                    src={mediaInfo.url}
                    loading="lazy"
                    draggable="false"
                    role="presentation"
                    alt={`${t('image-gallery.zoom-modal.thumbnail.alt-text', {
                      defaultValue: '{{altText}} - Thumbnail {{index}}',
                      altText: altText,
                      index: index + 1,
                    }).toString()}`}
                    intrinsicMinWidth={{ xxs: 90, xs: 90, sm: 90, md: 120, lg: 120 }}
                    intrinsicMinHeight={{ xxs: 120, xs: 120, sm: 120, md: 160, lg: 160 }}
                    aspectRatio={[3, 4]}
                    bgColour={imageBackgroundColour}
                    extendedCrop={[-18, 0, -18, 0]}
                  />
                ) : (
                  <VideoThumbnail>
                    <PlayButton />
                  </VideoThumbnail>
                )}
              </ThumbnailButton>
            ))}
          </Thumbnails>
        </Content>
        <CloseButton onClick={onClose}>
          <CloseIcon>
            <Trans i18nKey="image-gallery.zoom-modal.close-button">Close image zoom modal</Trans>
          </CloseIcon>
        </CloseButton>
      </Dialog>
    </Overlay>
  );
}
