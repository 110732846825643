import { useCallback, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';

import { env } from '@selfridges-pkg/utils';
import { addWishListItem, deleteWishListItem, fetchWishListItems } from '@selfridges-pkg/wcs-api/wish-list';
import { WishListItem } from '@selfridges-pkg/wcs-api/contracts/WishListItem';

import { EXTERNAL_EVENT } from '../constants';

type ContextApiDataEvent = {
  wishlistCount?: number;
};

declare global {
  interface WindowEventMap {
    CONTEXT_API_DATA_EVENT: CustomEvent<ContextApiDataEvent>;
  }
}

const WCS_API_KEY = () => env('REACT_APP_WCS_API_KEY') || '';
const WCS_API_HOST = () => env('REACT_APP_WCS_API_HOST') || '';

const headers = {
  'api-key': WCS_API_KEY(),
  'Content-Type': 'application/json',
};

function isInWishList(
  items: Array<WishListItem>,
  { skuId, partNumber }: { skuId?: string; partNumber: string },
): boolean {
  if (!items.length) return false;

  if (skuId) {
    return items.some(({ partNumber: itemPartNumber }) => itemPartNumber === skuId);
  }

  return items.some(
    ({ productPartNumber, partNumber: itemPartNumber }) => productPartNumber === partNumber && !itemPartNumber,
  );
}

export function useWishList({
  countryLanguage,
  partNumber,
  onAdded,
  onRemoved,
}: {
  countryLanguage: string;
  onAdded: () => void;
  onRemoved: () => void;
  partNumber: string;
}): {
  addItemToWishList: ({ skuId, partNumber }: { skuId?: string; partNumber: string }) => Promise<void>;
  deleteItemFromWishList: ({ skuId, partNumber }: { skuId?: string; partNumber: string }) => Promise<void>;
  isAddedToWishList: ({ skuId, partNumber }: { skuId?: string; partNumber: string }) => boolean;
} {
  const isIdle = useRef(true);

  const [wishListCount, setWishListCount] = useState<number | undefined>(undefined);
  const [wishListItems, setWishListItems] = useState<Array<WishListItem>>([]);

  const isWishListCreated = wishListCount !== undefined;

  const { data: wishListData } = useQuery({
    queryKey: ['fetch-wish-list-items'],
    refetchOnWindowFocus: false,
    queryFn: () => fetchWishListItems({ hostRootUrl: WCS_API_HOST(), countryLanguage, headers, partNumber }),
    enabled: isWishListCreated && wishListCount > 0,
  });

  const { mutateAsync: deleteItem } = useMutation((variables: { id: string }) =>
    deleteWishListItem({ hostRootUrl: WCS_API_HOST(), countryLanguage, partNumber: variables.id, headers }),
  );

  const { mutateAsync: addItem } = useMutation((variables: { id: string }) =>
    addWishListItem({
      hostRootUrl: WCS_API_HOST(),
      countryLanguage,
      partNumber: variables.id,
      headers,
      // use POST to create wish list and add item if there is no wish list
      method: isWishListCreated ? 'PUT' : 'POST',
    }),
  );

  useEffect(() => {
    function handleContextApiDataEvent(event: CustomEvent<ContextApiDataEvent>) {
      setWishListCount(event.detail.wishlistCount);
      window.removeEventListener(EXTERNAL_EVENT.CONTEXT_API_DATA_EVENT, handleContextApiDataEvent);
    }

    if (!window['__INITIAL_STATE__']?.context) {
      window.addEventListener(EXTERNAL_EVENT.CONTEXT_API_DATA_EVENT, handleContextApiDataEvent);
    } else {
      setWishListCount(window['__INITIAL_STATE__'].context.wishlistCount);
    }

    return () => {
      window.removeEventListener(EXTERNAL_EVENT.CONTEXT_API_DATA_EVENT, handleContextApiDataEvent);
    };
  }, []);

  useEffect(() => {
    const wishListDataItems = wishListData?.wishlist.item;

    if (wishListDataItems) {
      setWishListItems(wishListDataItems);
    }
  }, [wishListData?.wishlist.item]);

  const isAddedToWishList = useCallback(
    ({ skuId, partNumber }: { skuId?: string; partNumber: string }) =>
      isInWishList(wishListItems, { skuId, partNumber }),
    [wishListItems],
  );

  const addItemToWishList = useCallback(
    async ({ skuId, partNumber }: { skuId?: string; partNumber: string }) => {
      if (!isIdle.current) return;
      isIdle.current = false;

      const addItemResponse = await addItem({ id: skuId || partNumber });

      if (isWishListCreated) {
        setWishListItems(addItemResponse.item);
      } else {
        setWishListItems([{ partNumber: skuId, productPartNumber: partNumber }]);
        setWishListCount(1);
      }

      onAdded();

      window.dispatchEvent(new CustomEvent(EXTERNAL_EVENT.USER_MENU_REFRESH));

      isIdle.current = true;
    },
    [addItem, isWishListCreated, onAdded],
  );

  const deleteItemFromWishList = useCallback(
    async ({ skuId, partNumber }: { skuId?: string; partNumber: string }) => {
      if (!isIdle.current) return;
      isIdle.current = false;

      const deleteItemResponse = await deleteItem({ id: skuId || partNumber });

      setWishListItems(deleteItemResponse.wishlist.item);

      onRemoved();

      window.dispatchEvent(new CustomEvent(EXTERNAL_EVENT.USER_MENU_REFRESH));

      isIdle.current = true;
    },
    [deleteItem, onRemoved],
  );

  return { addItemToWishList, deleteItemFromWishList, isAddedToWishList };
}
