import { getCookie } from '../utils/cookies';

interface CommonProps {
  hostRootUrl: string;
  countryLanguage: string;
  headers: Record<string, string>;
}

export async function updateSession({ hostRootUrl, headers, countryLanguage }: CommonProps): Promise<void> {
  const sessionEstablished = getCookie('WC_SESSION_ESTABLISHED');

  if (sessionEstablished) return;

  const [country, language] = countryLanguage.split('/');

  const response = await fetch(
    `${hostRootUrl}/api/ecom/v1/${country}/guestidentity/?updateCookies=true&language=${language}`,
    {
      method: 'POST',
      cache: 'no-cache',
      headers,
    },
  );

  if (!response.ok) {
    const error = await response.json();
    // eslint-disable-next-line
    console.error(error);
  }
}
