export const MAX_SCREEN_WIDTH = { DESKTOP: '1920px' };
export const PRODUCT_PANEL_WIDTH = { DESKTOP: '48.8rem' };
export const PRODUCT_PANEL_GUTTER = { MOBILE: '1.6rem', DESKTOP: '2.4rem' };
export const WISHLIST_ICON_SIZE = '3.4rem';
export const MEGAMENU_HEIGHT = { DESKTOP: '117px', MOBILE: '109px' };
export const DELIVERY_RESTRICTION_BANNER_HEIGHT = '40px';
export const DEFAULT_MAX_PURCHASE = 10;
export const MAX_IMAGE_HEIGHT = { DESKTOP: '634px', TABLET: '560px' };
export const MAX_CHARACTERS_IN_SIZE_GRID = { DESKTOP: 4, MOBILE: 8 };
export const MAX_NUM_OF_SIZES_IN_SIZE_GRID = { DESKTOP: 16, MOBILE: 12 };
export const DISPLAY_FLAG_COLOUR_FOR_OUT_OF_STOCK_TEMPLATE = '#C90000';
export const STOCK_LEFT_MESSAGE_THRESHOLD = 5;
export const LOW_STOCK_MESSAGE_THRESHOLD = 10;
export const TOAST_MESSAGE_ANIMATION_DURATION_IN_MS = 200; // milliseconds
export const SALES_PROP_DELAY_IN_MS = 1000; // milliseconds
export const MODAL_ANIMATION_DURATION_IN_MS = 500; // milliseconds
export const VIEW_BAG_URL = '/app/checkout/bag';
export const VIEW_WISH_LIST_URL = '/app/wishlist';
export const REVIEWS_PER_PAGE = 6;
export const INITIAL_NUM_OF_REVIEWS = 2;
export const MIN_NUM_OF_REVIEWS = 3;

// gets the value from reach modal dialog
export const REMOVED_BODY_SCROLL_BAR_SIZE_ON_MODAL_OPEN = 'var(--removed-body-scroll-bar-size, 0px)';

export enum EXTERNAL_EVENT {
  OVERLAY_SHOW = 'OVERLAY_SHOW',
  USER_MENU_REFRESH = 'USER_MENU_REFRESH',
  CONTEXT_API_DATA_EVENT = 'CONTEXT_API_DATA_EVENT',
}
