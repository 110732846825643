import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

import { spacing, color, typography } from '@selfridges-co/frontend-sdk-react-theme';

import { LOW_STOCK_MESSAGE_THRESHOLD, STOCK_LEFT_MESSAGE_THRESHOLD } from '../constants';

import type { AvailableStock } from '../types';

type MessageColour = 'grey' | 'red' | 'black';

type StockMessageProps = {
  stockAmount?: AvailableStock;
  messageColour?: MessageColour;
  children?: React.ReactNode;
};

const colourMap: Record<MessageColour, string> = {
  red: color.palette.warningRed,
  grey: color.palette.midGrey2,
  black: color.palette.mainBlack,
};

const StockWarning = styled.span<{ messageColour: MessageColour }>(({ messageColour }) => ({
  ...typography.typeface.text.xs.regular,
  color: colourMap[messageColour],
  paddingLeft: spacing(2),
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

function renderMessage(stockAmount: AvailableStock) {
  if (stockAmount === 'NOT_APPLICABLE') return null;

  if (stockAmount === 0 || stockAmount === 'ALL_OUT_OF_STOCK') {
    return <Trans i18nKey="app.product-panel.stock-message.not-available">Out of stock</Trans>;
  } else if (stockAmount < STOCK_LEFT_MESSAGE_THRESHOLD) {
    return (
      <Trans i18nKey="app.product-panel.stock-message.stock-left" count={Number(stockAmount)}>
        Only {{ count: stockAmount }} left
      </Trans>
    );
  } else if (stockAmount < LOW_STOCK_MESSAGE_THRESHOLD) {
    return <Trans i18nKey="app.product-panel.stock-message.stock-low">Low in stock</Trans>;
  }

  return null;
}

export function StockMessage({ stockAmount = 'NOT_APPLICABLE', messageColour = 'red', children }: StockMessageProps) {
  const message = renderMessage(stockAmount);

  if (!children && !message) return null;

  return <StockWarning messageColour={messageColour}>{children || message}</StockWarning>;
}
