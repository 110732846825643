import { createContext, useContext, ReactNode } from 'react';

import { BrandConfig } from '../types';

type ProviderProps = {
  children: ReactNode;
  config: BrandConfig;
};

const BrandConfigContext = createContext<BrandConfig>({} as BrandConfig);

export function BrandConfigProvider({ children, config }: ProviderProps) {
  return <BrandConfigContext.Provider value={config}>{children}</BrandConfigContext.Provider>;
}

export function useBrandConfig(): BrandConfig {
  const context = useContext(BrandConfigContext);

  if (context === undefined) {
    throw new Error('useBrandConfig must be used within a Provider');
  }

  return context;
}
