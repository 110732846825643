import React, { useState, LabelHTMLAttributes, useRef, useEffect } from 'react';
import styled, { CSSObject } from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

import { breakpoints, color, spacing, typography } from '@selfridges-co/frontend-sdk-react-theme';
import { Link } from '@selfridges-co/frontend-sdk-react-link';

import { withTooltip } from '../tooltip/withTooltip';
import { VisuallyHidden } from '../visually-hidden/VisuallyHidden';
import { PRODUCT_PANEL_GUTTER } from '../constants';
import { StockMessage } from '../stock-message/StockMessage';

import type { ColourInfo, SelectedColour, GetSku, SelectedSize, SetSelectedColour } from '../types';

interface ColourPaletteProps {
  colours: Array<ColourInfo>;
  getSku: GetSku;
  onSelect: SetSelectedColour;
  selectedSize: SelectedSize;
  selectedColour: SelectedColour;
  onShowAll: () => void;
  showSelectPromptMessage: boolean;
}

interface SwatchContainerProps extends LabelHTMLAttributes<HTMLLabelElement> {
  selected: boolean;
  swatchUrl: string;
  outOfStock: boolean;
}

const SWATCH_SIZE = 48;
const COLOUR_PALETTE_GAP = { VERTICAL: 12, HORIZONTAL: 8 };
const BROKEN_SWATCH_SVG = encodeURI(
  '<svg height="36" viewBox="0 0 36 36" width="36" xmlns="http://www.w3.org/2000/svg"><g fill="#EAEAEA" fill-rule="evenodd" stroke="#979797"><path d="m.5.5h35v35h-35z"/><path d="m1.5 1.5 33 33" stroke-linecap="square"/></g></svg>',
).replace(/#/g, '%23');

const SELECTED_STYLE: CSSObject = {
  boxShadow: `inset 0px 0px 0px 2px ${color.palette.mainBlack}, inset 0px 0px 0px 4px ${color.palette.mainWhite}`,
};

const HOVER_STYLE: CSSObject = {
  transition: 'all ease-out 0.2s',
  boxShadow: `inset 0px 0px 0px 2px ${color.palette.midGrey1}, inset 0px 0px 0px 4px ${color.palette.mainWhite}`,
};

const Wrapper = styled.div({
  margin: spacing(8, 0),
});

const ColourHeadingAndShowAllContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: spacing(3),
});

const Title = styled.div({
  display: 'inline-flex',
  alignItems: 'baseline',
});

const ColourHeadingWithSelectedColour = styled.h4({
  ...typography.typeface.display.xs,
});

const SelectedColourValue = styled.span({
  ...typography.typeface.text.md.regular,
  marginLeft: spacing(2),
});

const ShowAllButton = styled(Link).attrs({ forwardedAs: 'button' })({
  textDecoration: 'underline',
  cursor: 'pointer',
});

const ColourSwatchesContainer = styled.div({
  width: `calc(100% + ${PRODUCT_PANEL_GUTTER.MOBILE} * 2)`,
  marginLeft: `-${PRODUCT_PANEL_GUTTER.MOBILE}`,

  [breakpoints.md.mediaQuery]: {
    width: 'auto',
    margin: 0,
  },
});

const ColourSwatches = styled.div({
  border: 'none',
  boxSizing: 'border-box',
  display: 'grid',
  gridAutoFlow: 'column',
  gridAutoColumns: `${SWATCH_SIZE}px`,
  gridAutoRows: `${SWATCH_SIZE}px`,
  gridGap: `${COLOUR_PALETTE_GAP.HORIZONTAL}px`,
  listStyle: 'none',
  margin: 0,
  overflowX: 'scroll',
  overflowY: 'hidden',
  padding: `0 ${PRODUCT_PANEL_GUTTER.MOBILE}`,
  scrollbarWidth: 'none',

  ['@media (prefers-reduced-motion)']: {
    scrollBehavior: 'auto',
  },

  ['::-webkit-scrollbar']: {
    display: 'none',
  },

  [breakpoints.md.mediaQuery]: {
    overflowX: 'initial',
    overflowY: 'initial',
    gridAutoFlow: 'row',
    gridTemplateColumns: 'repeat(8, 1fr)',
    gridGap: `${COLOUR_PALETTE_GAP.VERTICAL}px ${COLOUR_PALETTE_GAP.HORIZONTAL}px`,
    padding: 0,
  },
});

const RadioInput = styled.input({
  cursor: 'pointer',
  width: '100%',
  height: '100%',
  appearance: 'none',
  opacity: 1,
  position: 'absolute',
  top: 0,
  left: 0,
});

const SwatchContainer = withTooltip<SwatchContainerProps>(
  styled.label<SwatchContainerProps>(({ selected, swatchUrl, outOfStock }) => ({
    alignItems: 'center',
    background: `url("${swatchUrl}&wid=96&hei=96&fmt=jpg&fit=crop,1&qlt=90,1"), url(data:image/svg+xml,${BROKEN_SWATCH_SVG})`,
    backgroundSize: 'cover',
    [`@supports (background-image: url("${swatchUrl}&wid=96&hei=96&fmt=webp&fit=crop,1&qlt=90,1"))`]: {
      background: `url("${swatchUrl}&wid=96&hei=96&fmt=webp&fit=crop,1&qlt=90,1"), url(data:image/svg+xml,${BROKEN_SWATCH_SVG})`,
      backgroundSize: 'cover',
    },
    border: 'none',
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'flex',
    width: '100%',
    height: '100%',
    position: 'relative',
    justifyContent: 'center',

    ...(outOfStock && {
      ':after': {
        pointerEvents: 'none',
        content: '""',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        background: `linear-gradient(to bottom right,
          ${color.palette.mainWhite}88 calc(50% - 1px),
          ${color.palette.mainWhite} calc(50% - 1px),
          ${color.palette.mainWhite} calc(50% + 1px),
          ${color.palette.mainWhite}88 calc(50% + 1px))`,
        ...(selected && SELECTED_STYLE),
      },
      ':hover': {
        ':after': HOVER_STYLE,
      },
    }),

    ...(selected
      ? SELECTED_STYLE
      : {
          '@media (hover: hover)': {
            ':hover': HOVER_STYLE,
          },
        }),
  })),
);

const PromptMessage = styled.span({
  ...typography.typeface.text.md.regular,
  color: color.palette.warningRed,
  textDecoration: 'underline',
});

function ColourPalette({
  colours,
  getSku,
  selectedSize,
  selectedColour,
  onSelect,
  onShowAll,
  showSelectPromptMessage,
}: ColourPaletteProps) {
  const { t } = useTranslation();
  const [hasScroll, setHasScrollEnabled] = useState(false);
  const swatchContainerRef = useRef<HTMLDivElement>(null);
  const { availableStock } = getSku({ size: selectedSize.value, colour: selectedColour.value });
  const colourIndex = colours.findIndex(({ name }) => name === selectedColour.value);

  useEffect(() => {
    const node = swatchContainerRef.current;
    if (!node) return;

    const updateHasScroll = () => {
      setHasScrollEnabled(node.scrollWidth > node.clientWidth);
    };

    const scrollToSelectedSwatch = () => {
      const swatches = Array.from(node.children) as Array<HTMLDivElement>;
      const offsetLeftValue = swatches[colourIndex]?.offsetLeft - COLOUR_PALETTE_GAP.HORIZONTAL - SWATCH_SIZE / 2;
      node.scrollTo({ left: offsetLeftValue, behavior: 'smooth' });
    };

    function handleChange() {
      updateHasScroll();
      scrollToSelectedSwatch();
    }

    const matchMedia = window.matchMedia('(orientation:portrait)');
    matchMedia.addEventListener('change', handleChange);

    updateHasScroll();

    if (selectedColour.source === 'BROWSER_HASH' || selectedColour.source === 'USER_CLICK_FROM_COLOUR_LIST') {
      scrollToSelectedSwatch();
    }

    return () => {
      matchMedia.removeEventListener('change', handleChange);
    };
  }, [colourIndex, selectedColour.source]);

  function handleClick(event: React.MouseEvent<HTMLInputElement>) {
    event.stopPropagation();

    // event.detail === 0 when interact via keyboard
    if (event.detail) {
      onSelect(event.currentTarget.value, 'USER_CLICK_FROM_PALETTE');
    }
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    onSelect(event.currentTarget.value, 'USER_CLICK_FROM_PALETTE');
  }

  return (
    <Wrapper>
      <ColourHeadingAndShowAllContainer>
        <Title>
          <ColourHeadingWithSelectedColour tabIndex={0}>
            <Trans i18nKey="app.colour-selector.colour-palette.selected-colour-heading">
              Colour:<SelectedColourValue>{{ selectedColour: selectedColour.display }}</SelectedColourValue>
            </Trans>
          </ColourHeadingWithSelectedColour>
          {showSelectPromptMessage && (
            <PromptMessage>
              <Trans i18nKey="app.colour-selector.colour-palette.select-colour-prompt">Please select a colour</Trans>
            </PromptMessage>
          )}
          <StockMessage stockAmount={availableStock} />
        </Title>
        {hasScroll && (
          <ShowAllButton linkType="secondary" variant="black" onClick={onShowAll}>
            <Trans i18nKey="app.colour-selector.colour-palette.show-all">
              Show all ({{ coloursAmount: colours.length }})
            </Trans>
          </ShowAllButton>
        )}
      </ColourHeadingAndShowAllContainer>
      <ColourSwatchesContainer>
        <ColourSwatches ref={swatchContainerRef}>
          {colours.map(({ name, swatchUrl }) => {
            const selected = selectedColour.display === name;
            const { outOfStock } = getSku({ colour: name, size: selectedSize.value });

            return (
              <div key={name}>
                <SwatchContainer selected={selected} swatchUrl={swatchUrl} tooltip={name} outOfStock={outOfStock}>
                  <RadioInput
                    type="radio"
                    name="colour-palette-radio"
                    onChange={handleChange}
                    onClick={handleClick}
                    value={name}
                    checked={selected}
                    aria-label={t('app.colour-selector.colour-palette.swatch-label', {
                      defaultValue: 'Colour {{colour}}',
                      colour: name,
                    }).toString()}
                  />
                  <VisuallyHidden as="span">{name}</VisuallyHidden>
                </SwatchContainer>
              </div>
            );
          })}
        </ColourSwatches>
      </ColourSwatchesContainer>
    </Wrapper>
  );
}

export default ColourPalette;
