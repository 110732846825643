const MIN_PRICE_IN_GBP = 30;
const MAX_PRICE_IN_GBP = 4000;

export function isWithinKlarnaPriceRange(price?: number): boolean {
  if (price) {
    return price >= MIN_PRICE_IN_GBP && price <= MAX_PRICE_IN_GBP;
  }

  return false;
}
