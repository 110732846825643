import type { RatingsAndReviewsResponse } from './contracts/RatingsAndReviewsResponse';

export async function fetchRatingsAndReviews({
  hostRootUrl,
  apiKey,
  skuId,
  headers,
  perPage,
  page,
  starFilter,
}: {
  hostRootUrl: string;
  apiKey: string;
  skuId?: string;
  headers: Record<string, string>;
  perPage: number;
  page: number;
  starFilter?: number;
}): Promise<RatingsAndReviewsResponse | undefined> {
  const starFilterParam = starFilter ? `&star=${starFilter}` : '';

  const response = await fetch(
    `${hostRootUrl}/v1/widget/${apiKey}/products/${skuId}/reviews.json?per_page=${perPage}&page=${page}&sort=rating${starFilterParam}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
    },
  );

  if (!response.ok) {
    const error = await response.json();

    // eslint-disable-next-line no-console
    console.error(error);

    return undefined;
  }

  return response.json();
}
