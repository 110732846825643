import React from 'react';
import styled from 'styled-components';

import { breakpoints } from '@selfridges-co/frontend-sdk-react-theme';

import type { BrandBanner as BrandBannerType } from '../types';

type BrandBannerProps = {
  banner: BrandBannerType;
};

const Banner = styled.div<{ bannerHeight: BrandBannerType['height']; svgLogoHeight: BrandBannerType['svgLogoHeight'] }>(
  ({ bannerHeight, svgLogoHeight }) => ({
    '> *': {
      height: bannerHeight.mobile,
      svg: {
        height: svgLogoHeight.mobile,
      },

      [breakpoints.md.mediaQuery]: {
        height: bannerHeight.desktop,
        svg: {
          height: svgLogoHeight.desktop,
        },
      },
    },
  }),
);

function BrandBanner({ banner }: BrandBannerProps) {
  return (
    <Banner
      bannerHeight={banner.height}
      svgLogoHeight={banner.svgLogoHeight}
      dangerouslySetInnerHTML={{ __html: banner.html }}
    />
  );
}

export default BrandBanner;
