import env from './env';

export default function getStaticResourceUrl(fileName = ''): string {
  const staticResourceRootUrl: string | undefined = env('REACT_APP_STATIC_RESOURCE_ROOT_URL');
  // eslint-disable-next-line no-console
  if (staticResourceRootUrl === undefined) console.error("REACT_APP_STATIC_RESOURCE_ROOT_URL can't be undefined");

  const appHash: string | undefined = env('REACT_APP_APP_HASH');
  // eslint-disable-next-line no-console
  if (appHash === undefined) console.error("REACT_APP_APP_HASH can't be undefined");

  // Small HACK to avoid duplicated resource url root appended
  const staticResourceUrl = `${staticResourceRootUrl}/${appHash}`;

  if (!fileName.startsWith(staticResourceUrl)) {
    return fileName ? `${staticResourceUrl}/${fileName}` : staticResourceUrl;
  }

  return fileName;
}
