import { ProductRecommendationsResponse } from './contracts/ProductRecommendationsResponse';

export async function fetchRecommendations(countryLanguage: string): Promise<ProductRecommendationsResponse> {
  const response = await fetch(`/${countryLanguage}/product/api/user-recommendations/`, {
    headers: { pragma: 'no-cache', 'Cache-Control': 'no-cache' },
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message);
  }

  return response.json();
}
