import styled from 'styled-components';

import { spacing, typography } from '@selfridges-co/frontend-sdk-react-theme';

import { DeliveryOption, SelfridgesPlus } from '../types';

type DeliveryChannelProps = {
  options: Array<DeliveryOption>;
  title: React.ReactNode;
  unavailableMessage: React.ReactNode;
  selfridgesPlus: SelfridgesPlus;
};

const Title = styled.h3({
  ...typography.typeface.text.lg.bold,
  marginBottom: spacing(3),
});

const Container = styled.div({
  marginBottom: spacing(10),
});

const List = styled.ul({
  listStyle: 'disc',
  paddingLeft: spacing(6),
  fontSize: '1.5rem',
});

const ListItemContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

const Price = styled.span({
  ...typography.typeface.text.md.bold,
});

const DeliveryLabel = styled.span({
  ...typography.typeface.text.md.regular,
  marginRight: spacing(4),
});

const Message = styled.p({
  ...typography.typeface.text.md.regular,
  margin: 0,
});

const SelfridgesPlusListItem = styled.li({
  marginBottom: spacing(3),
});

function DeliveryChannel({ options, title, unavailableMessage, selfridgesPlus }: DeliveryChannelProps) {
  const hasDeliveryOptions = options.length > 0;
  return (
    <Container>
      <Title>{title}</Title>
      {!hasDeliveryOptions && <Message>{unavailableMessage}</Message>}
      {hasDeliveryOptions && (
        <List data-testid={title}>
          <SelfridgesPlusListItem>
            <ListItemContainer>
              <DeliveryLabel dangerouslySetInnerHTML={{ __html: selfridgesPlus.label }} />
              <Price>{selfridgesPlus.cost}</Price>
            </ListItemContainer>
          </SelfridgesPlusListItem>
          {options.map(({ methods }) =>
            methods.map(({ label, cost, cutoffTime }) => (
              <li key={label}>
                <ListItemContainer>
                  <DeliveryLabel>
                    {label}
                    {cutoffTime}
                  </DeliveryLabel>
                  <Price dangerouslySetInnerHTML={{ __html: cost }} />
                </ListItemContainer>
              </li>
            )),
          )}
        </List>
      )}
    </Container>
  );
}

export default DeliveryChannel;
