import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { fetchRecommendations } from '@selfridges-pkg/product-aggregation-service-api/fetchRecommendations';
import { ProductRecommendationsResponse } from '@selfridges-pkg/product-aggregation-service-api/contracts/ProductRecommendationsResponse';
import { useLdFlags } from '@selfridges-pkg/feature-flag/LDContext';

export function useFetchRecommendations(countryLanguage: string): ProductRecommendationsResponse | undefined {
  const [dataKey, setDataKey] = useState<string | null>(null);
  const flags = useLdFlags();

  useEffect(() => {
    setDataKey('');
  }, []);

  const { data } = useQuery({
    enabled: Boolean(dataKey !== null && flags['enable-pdp-perso-rec']),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    queryKey: [countryLanguage, 'product', 'api', 'recommendations', dataKey],
    queryFn: () => fetchRecommendations(countryLanguage),
  });

  return data;
}
