import { Breadcrumbs } from '../types';

import { blacklistCategories } from './blacklistCategories';

const convertToCommonFormat = value => {
  return value.replace(/\s/g, '').toLowerCase();
};

const convertArrayToCommonFormat = array => {
  return array.map(el => convertToCommonFormat(el));
};
export function isFitFinderProduct(breadcrumbs: Breadcrumbs): boolean {
  const breadcrumbKey = [...breadcrumbs].pop()?.key;
  const breadcrumbKeyArray = breadcrumbKey?.split('|') || [];

  const breadcrumbsWithCommonFormat = convertArrayToCommonFormat(breadcrumbKeyArray);
  const blacklistCategoriesWithCommonFormat = convertArrayToCommonFormat(blacklistCategories);

  return !breadcrumbsWithCommonFormat.some(item => blacklistCategoriesWithCommonFormat.includes(item));
}
