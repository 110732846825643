import React, { useEffect, useCallback, useState, useRef, RefObject } from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { DialogOverlay, DialogContent } from '@reach/dialog';

import { breakpoints, color, spacing, typography } from '@selfridges-co/frontend-sdk-react-theme';

import { VisuallyHidden } from '../visually-hidden/VisuallyHidden';
import { useIsServerSide } from '../hooks/useIsServerSide';
import {
  PRODUCT_PANEL_WIDTH,
  MEGAMENU_HEIGHT,
  MODAL_ANIMATION_DURATION_IN_MS,
  REMOVED_BODY_SCROLL_BAR_SIZE_ON_MODAL_OPEN,
} from '../constants';

const CROSS_SVG = encodeURI(
  "<svg width='14' height='14' viewBox='0 0 14 14' fill='none' stroke='#212121' stroke-linecap='round' stroke-linejoin='round' xmlns='http://www.w3.org/2000/svg'><path d='M0.5 0.5 L13.5 13.5'/><path d='M0.5 13.5 L13.5 0.5'/></svg>",
).replace(/#/g, '%23');

export const MIN_SWIPE_DISTANCE = 50;
export const SWIPE_SPEED_THRESHOLD = 250;

export interface ModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  Title?: JSX.Element;
  CloseButtonLabel?: JSX.Element;
  children: React.ReactNode;
  ssrEnabled?: boolean;
  dismissOnOverlay?: boolean;
  isMounted: boolean;
  onMounted: () => void;
}

type DialogProps = Omit<ModalProps, 'isOpen' | 'ssrEnabled'>;

const Overlay = styled(DialogOverlay).withConfig<{ mounted: boolean }>({
  shouldForwardProp: props => !['mounted'].includes(String(props)),
})(({ mounted }) => ({
  ':root': {
    '--reach-dialog': 1,
  },

  transition: `background ${MODAL_ANIMATION_DURATION_IN_MS}ms ease`,
  background: mounted ? `${color.palette.mainBlack}33` : 'none',
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 101, // needs to appear on top of the mega menu on mobile

  [breakpoints.md.mediaQuery]: {
    top: MEGAMENU_HEIGHT.DESKTOP,
    zIndex: 99, // needs to appear below the mega menu on desktop
  },
}));

const DialogContainer = styled(DialogContent).withConfig<{
  mounted: boolean;
}>({
  shouldForwardProp: props => !['mounted'].includes(String(props)),
})(({ mounted }) => {
  return {
    width: '100%',
    right: 0,
    bottom: 0,
    position: 'absolute',
    boxSizing: 'border-box',
    maxHeight: `calc(100% - (160px))`,
    minHeight: '240px',
    boxShadow: `${color.palette.mainBlack}1f 0px -6px 20px 10px;`,
    background: color.palette.mainWhite,
    outline: 'none',
    transition: `transform ${MODAL_ANIMATION_DURATION_IN_MS}ms ease`,
    transform: mounted ? 'translateY(0%)' : 'translateY(100%)',
    borderRadius: spacing(4, 4, 0, 0),
    display: 'flex',
    flexDirection: 'column',

    [breakpoints.md.mediaQuery]: {
      transform: mounted ? 'translateX(0%)' : 'translateX(100%)',
      width: `calc(${PRODUCT_PANEL_WIDTH.DESKTOP} + ${REMOVED_BODY_SCROLL_BAR_SIZE_ON_MODAL_OPEN})`,
      boxShadow: 'none',
      height: '100%',
      maxHeight: 'unset',
      borderRadius: 0,
    },
  };
});

const Content = styled.div({
  display: 'flex',
  flexDirection: 'column',
  minHeight: 0,
});

const Container = styled.div({
  overflowY: 'scroll',
  display: 'flex',
  flexDirection: 'column',
  minHeight: 0,
  borderRadius: spacing(4, 4, 0, 0),
});

const CloseButton = styled.button({
  position: 'absolute',
  border: 'none',
  top: 0,
  right: 0,
  zIndex: 2,
  background: 'transparent',
  padding: spacing(4),

  [breakpoints.md.mediaQuery]: {
    padding: spacing(6),
  },
});

const CloseButtonIcon = styled.div({
  width: '3.2rem',
  height: '3.2rem',
  borderRadius: '100%',
  background: color.palette.lightGrey1,
  fontSize: 0,
  transition: 'background 0.2s',
  cursor: 'pointer',

  ':hover': {
    background: color.palette.midGrey2,
  },

  ':active': {
    background: color.palette.lightGrey1,
    boxShadow: `inset 0px 0px 0px 0.1rem ${color.palette.midGrey2}`,
  },

  '::after': {
    content: '" "',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundImage: `url("data:image/svg+xml,${CROSS_SVG}")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
});

const StickyTitle = styled.h2<{ showDivider: boolean }>(({ showDivider }) => ({
  ...typography.typeface.display.md,
  color: color.palette.mainBlack,
  top: 0,
  position: 'sticky',
  width: '100%',
  boxSizing: 'border-box',
  background: color.palette.mainWhite,
  zIndex: 1,
  padding: spacing(4, 6, 4, 4),
  paddingRight: `calc(${spacing(4)} + 3.2rem)`,
  border: `0px solid ${color.palette.lightGrey1}`,
  transition: 'border-bottom-width .1s linear',
  borderRadius: spacing(4, 4, 0, 0),

  ...(showDivider && {
    borderBottomWidth: `1px`,
  }),

  [breakpoints.md.mediaQuery]: {
    padding: spacing(6),
    paddingRight: `calc(${spacing(6)} + 3.2rem)`,
    borderRadius: 0,
  },
}));

export default function Modal({
  isOpen,
  onDismiss,
  Title,
  CloseButtonLabel,
  children,
  ssrEnabled = true,
  dismissOnOverlay = true,
  isMounted,
  onMounted,
}: ModalProps): React.ReactElement {
  const isServerSide = useIsServerSide();

  return isServerSide && ssrEnabled ? (
    <VisuallyHidden>{React.cloneElement(<></>, {}, children)}</VisuallyHidden>
  ) : (
    <Overlay isOpen={isOpen} mounted={isMounted} {...(dismissOnOverlay && { onDismiss: onDismiss })}>
      <Dialog
        Title={Title}
        CloseButtonLabel={CloseButtonLabel}
        children={children}
        onDismiss={onDismiss}
        isMounted={isMounted}
        onMounted={onMounted}
      />
    </Overlay>
  );
}

function getScrollableElement<T extends RefObject<HTMLElement>>(node: HTMLElement, parentRef: T): HTMLElement | null {
  if (!node || !node.parentElement || node === parentRef.current) return null;

  if (node.scrollHeight > node.clientHeight) {
    return node;
  }

  return getScrollableElement(node.parentElement, parentRef);
}

function useSwipeDown<T extends RefObject<HTMLElement>>(ref: T, onSwipeDown: () => void) {
  const dragStartPosition = useRef(0);
  const dragEndPosition = useRef(0);
  const scrollableElement = useRef<Nullable<HTMLElement>>(null);
  const lastScrollableElementPosition = useRef(0);
  const swipeThresholdTimerId = useRef<NodeJS.Timeout | null>(null);

  const [isScrolling, setIsScrolling] = useState(false);
  const [isSwipeable, setIsSwipeable] = useState(true);

  function onTouchStart(event: React.TouchEvent<HTMLElement>) {
    scrollableElement.current = getScrollableElement(event.target as HTMLElement, ref);
    lastScrollableElementPosition.current = scrollableElement.current?.scrollTop || 0;

    if (lastScrollableElementPosition.current > 0) setIsScrolling(true);

    dragStartPosition.current = event.targetTouches[0].clientY;
  }

  function onTouchMove(event: React.TouchEvent<HTMLElement>) {
    if (!ref.current) return;

    if (lastScrollableElementPosition.current < 0) {
      setIsScrolling(false);
    } else if (
      scrollableElement.current &&
      lastScrollableElementPosition.current !== scrollableElement.current?.scrollTop
    ) {
      setIsScrolling(true);
    }

    if (isScrolling) return;

    dragEndPosition.current = event.targetTouches[0].clientY;
    const dragDistance = dragEndPosition.current - dragStartPosition.current;

    if (!scrollableElement.current && dragDistance <= 0) {
      dragStartPosition.current = event.targetTouches[0].clientY;
    } else {
      if (!swipeThresholdTimerId.current) {
        swipeThresholdTimerId.current = setTimeout(() => {
          setIsSwipeable(false);
        }, SWIPE_SPEED_THRESHOLD);
      }
    }

    if (dragDistance >= 0) {
      ref.current.style.transition = 'unset';
      ref.current.style.transform = `translateY(${dragDistance}px)`;
    }
  }

  function onTouchEnd() {
    if (!ref.current) return;

    ref.current.style.transition = '';
    ref.current.style.transform = '';

    const dragDistance = dragEndPosition.current - dragStartPosition.current;

    if (dragDistance > MIN_SWIPE_DISTANCE && isSwipeable) {
      onSwipeDown();
    }

    if (swipeThresholdTimerId.current) {
      clearTimeout(swipeThresholdTimerId.current);
      swipeThresholdTimerId.current = null;
    }

    setIsScrolling(false);
    setIsSwipeable(true);
    scrollableElement.current = null;
    lastScrollableElementPosition.current = 0;
    dragStartPosition.current = 0;
    dragEndPosition.current = 0;
  }

  return {
    handlers: {
      onTouchStart,
      onTouchMove,
      onTouchEnd,
    },
  };
}

function Dialog({ Title, CloseButtonLabel, children, onDismiss, isMounted, onMounted }: DialogProps) {
  const [hasScrolled, setHasScrolled] = useState(false);

  const dialogContainerRef = useRef<HTMLDivElement>(null);

  const { handlers: swipeHandlers } = useSwipeDown(dialogContainerRef, onDismiss);

  const contentContainerRef = useCallback((contentEl: Nullable<HTMLDivElement>) => {
    if (!contentEl) return;

    setHasScrolled(contentEl.scrollTop > 0);

    contentEl.addEventListener('scroll', (event: Event) => setHasScrolled((event.target as HTMLElement).scrollTop > 0));
  }, []);

  useEffect(() => {
    onMounted();
  }, [onMounted]);

  return (
    <DialogContainer mounted={isMounted} ref={dialogContainerRef}>
      <Content tabIndex={0}>
        {Title && (
          <StickyTitle showDivider={hasScrolled} {...swipeHandlers}>
            {Title}
          </StickyTitle>
        )}
        <Container ref={contentContainerRef} {...swipeHandlers}>
          {children}
        </Container>
      </Content>
      <CloseButton onClick={onDismiss}>
        <CloseButtonIcon>
          <span>{CloseButtonLabel || <Trans i18nKey="modal.close-button">Close</Trans>}</span>
        </CloseButtonIcon>
      </CloseButton>
    </DialogContainer>
  );
}
