import { useMemo } from 'react';

import env from '@selfridges-pkg/utils/env';
import { useLdFlags } from '@selfridges-pkg/feature-flag/LDContext';

import { AnalyticsProductArray } from '../analytics';
import { convertToHoverImage } from '../utils';

import { transformPriceWithCurrency } from './transformPriceWithCurrency';
import { extractTextFromProductName } from './helpers';

import type { ProductCarouselProps } from '@selfridges-co/frontend-sdk-react-native-carousel';
import type { ProductResponse } from '@selfridges-pkg/product-aggregation-service-api/contracts/ProductResponse';

type CarouselPropsToTransform = (Partial<ProductCarouselProps> & Pick<ProductCarouselProps, 'slides'>) | null;

const MEDIA_ROOT_URL = () => env('REACT_APP_MEDIA_ROOT_URL') || '';

export function useTransformYMALDataToProps(
  productResponse: ProductResponse,
  countryLanguage: string,
): CarouselPropsToTransform {
  const ldFlags = useLdFlags();
  const enableNewProductRoute = ldFlags['enable-new-product-route'];

  return useMemo(() => {
    const { recommendations, breadcrumbs, displayFlag } = productResponse;
    if (!recommendations || !recommendations.length) return null;

    return {
      slides: recommendations.map(({ productName, brand, price, media, partNumber }) => {
        const defaultImageName = media.defaultImage.replace('selfridges/', '');

        const { subBrand, productDescription } = extractTextFromProductName(productName);

        const { current, was, wasWas } = transformPriceWithCurrency({
          currency: price.currency,
          current: price.current,
          was: null,
          wasWas: null,
        });

        return {
          targetUrl: `/${countryLanguage}/${
            enableNewProductRoute ? 'product' : 'cat'
          }/${brand.seo.toLowerCase()}-${productName
            .replace(/[^a-z0-9 -]/gi, '')
            .replace(/ /g, '-')
            .toLowerCase()}_${partNumber}/`,
          brandName: brand.name,
          description: `${subBrand} ${productDescription}`.trim(),
          lowestPrice: current,
          imageUrl: `${MEDIA_ROOT_URL()}/image/selfridges/${defaultImageName}`,
          hoverImageUrl: `${MEDIA_ROOT_URL()}/image/selfridges/${convertToHoverImage(defaultImageName, true)}`,
          analyticsDetails: (<AnalyticsProductArray>[
            productName,
            breadcrumbs?.[1]?.seo || breadcrumbs?.[0]?.seo || '',
            partNumber,
            current,
            brand?.name,
            partNumber,
            was,
            wasWas,
            displayFlag,
            productResponse.division ? productResponse.division.name : '',
            productResponse.department ? productResponse.department.name : '',
          ]).join('|'),
        };
      }),
    };
  }, [countryLanguage, productResponse, enableNewProductRoute]);
}
