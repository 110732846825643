/* eslint-disable sonarjs/cognitive-complexity */
import { useEffect, useRef, useState, ReactElement, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';

import { HeadTag, AppFonts } from '@selfridges-pkg/ui-components';
import { breakpoints, spacing } from '@selfridges-co/frontend-sdk-react-theme';
import MoreFrom from '@selfridges-co/frontend-sdk-react-quick-links';
import SelfridgesSays from '@selfridges-co/frontend-sdk-react-selfridges-says';
import { bareboneGlobalStyle } from '@selfridges-co/frontend-sdk-react-global-style';
import { GridConfigsProvider, GridConfigs, Grid, Row, Col } from '@selfridges-co/frontend-sdk-react-grid-system';
import Breadcrumb from '@selfridges-co/frontend-sdk-react-breadcrumb';
import { createTealiumLoaderScript } from '@selfridges-co/frontend-sdk-react-analytics-middleware';
import { logger } from '@selfridges-pkg/logger';
import NativeCarousel from '@selfridges-co/frontend-sdk-react-native-carousel';
import RecentlyViewed from '@selfridges-co/frontend-sdk-react-recently-viewed';
import { env, getStaticResourceUrl, isMobile } from '@selfridges-pkg/utils';
import { countryInfo, formatPrice } from '@selfridges-pkg/intl';

import ProjectEarthIcon from './project-earth/ProjectEarthIcon.svg';
import {
  useTransformProductData,
  transformToMoreFromProps,
  useTransformMediaDataToImageGalleryProps,
  transformProductDataToOutOfStockTemplateProps,
  useTransformYMALDataToProps,
  useTransformProductDataToRecommendedForYouProps,
  useTransformProductDataToDeliveryAndReturnsProps,
  transformToMainImageUrl,
  useTransformDataToCountdown,
} from './transformers';
import ProductPanel from './product-panel/ProductPanel';
import ProductDetails from './product-panel/ProductDetails';
import ImageGallery from './image-gallery/ImageGallery';
import CompleteTheSetButton from './bundles/CompleteTheSetButton';
import BundleCarousel from './bundles/Carousel';
import BundlePanel from './bundles/Panel';
import {
  PRODUCT_PANEL_WIDTH,
  MEGAMENU_HEIGHT,
  DELIVERY_RESTRICTION_BANNER_HEIGHT,
  PRODUCT_PANEL_GUTTER,
  MIN_NUM_OF_REVIEWS,
  MAX_SCREEN_WIDTH,
  REMOVED_BODY_SCROLL_BAR_SIZE_ON_MODAL_OPEN,
} from './constants';
import { useProductMedia } from './hooks/useProductMedia';
import { useModal } from './modal/useModal';
import { useWishList } from './wish-list/useWishList';
import { useAddToCart } from './hooks/useAddToCart';
import { useQuantity } from './hooks/useQuantity';
import { useIntersectionArea } from './hooks/useIntersectionArea';
import { useToastMessage } from './hooks/useToastMessage';
import { useSelectedColour } from './hooks/useSelectedColour';
import { useFetchStockAndPrice } from './hooks/useFetchStockAndPrice';
import { useSelectedSize } from './hooks/useSelectedSize';
import { useSku } from './hooks/useSku';
import { useAdjustProductPanelHeight } from './hooks/useAdjustProductPanelHeight';
import { useIsServerSide } from './hooks/useIsServerSide';
import { useFetchRatingAndReviews } from './hooks/useFetchRatingAndReviews';
import Quantity from './quantity/Quantity';
import OverlayLinks from './overlay-links/OverlayLinks';
import Specifications from './product-panel/Specifications';
import DeliveryAndReturns from './delivery-and-returns/DeliveryAndReturns';
import DeliveryRestrictionsBanner from './delivery-restriction-banner/DeliveryRestrictionBanner';
import AddToBag from './product-add-to-bag/AddToBag';
import { SuccessMessage } from './product-add-to-bag/SuccessMessage';
import { ErrorMessage } from './product-add-to-bag/ErrorMessage';
import { ToastMessage } from './wish-list/ToastMessage';
import Price from './price/Price';
import SizeSelector from './size-selector/SizeSelector';
import ColourSelector from './colour-selector/ColourSelector';
import SizeGuide from './size-guide/SizeGuide';
import OutOfStockTemplate from './out-of-stock-template/OutOfStockTemplate';
import DisplayFlag from './display-flag/DisplayFlag';
import MoreInformationLink from './more-information-link/MoreInformationLink';
import { SalesPropDesktop, SalesPropMobile } from './sales-prop/SalesProp';
import SelfridgesPlus from './selfridges-plus/SelfridgesPlus';
import { createStructuredDataScript } from './structuredData';
import { createContextDataListener } from './contextData';
import { productDetailsPageAnalytics, productAvailabilityTracking, addToBagTracking } from './analytics';
import { getLegacyServiceBannerHeight, isNoIndexProduct } from './utils';
import ProjectEarth from './project-earth/ProjectEarth';
import ProductPanelBottomToast from './product-panel/BottomToast';
import AverageRating from './average-rating/AverageRating';
import RatingReviews from './rating-reviews/RatingReviews';
import Gifting from './gifting/Gifting';
import AddGiftMessage from './gifting/AddGiftMessage';
import AddPersonalisedMessage from './personalised/AddPersonalisedMessage';
import WishListIconButton from './wish-list/IconButton';
import Personalised from './personalised/Personalised';
import { useTransformReviewsData } from './transformers/transformReviewsData';
import { useTransformRatingData } from './transformers/transformRatingData';
import { useFitAnalyticsDataLayer } from './fit-finder/fitAnalyticsData';
import { useFetchBundles } from './hooks/useFetchBundles';
import { useBundles } from './hooks/useBundles';
import { useFetchRecommendations } from './hooks/useFetchRecommendations';
import { useBrandConfig } from './brand-configs/Context';
import BrandBanner from './brand-banner/BrandBanner';
import InStoreOnlyTemplate from './in-store-only-template/InStoreOnlyTemplate';
import KlarnaScript from './klarna-message/KlarnaScript';
import KlarnaMessage from './klarna-message/KlarnaMessage';
import { isWithinKlarnaPriceRange } from './klarna-message/helpers';

import type { TealiumConfig } from '@selfridges-pkg/app-config';
import type { AvailableCountryCode, AvailableLanguage } from '@selfridges-pkg/intl/types';
import type { ProductResponse } from '@selfridges-pkg/product-aggregation-service-api/contracts/ProductResponse';
import type { CountryLanguage, PersonalisationUserInput } from './types';

export interface AppData {
  pdpData: {
    productKey?: string;
    partNumber: string;
    productResponse: ProductResponse;
  };
}

interface AppProps {
  data: AppData;
  pageUrl: string;
  countryLanguage: string;
  tealiumConfig: TealiumConfig;
  isMfeTemplate: boolean;
}

const ENABLE_DYNATRACE = () => env('REACT_APP_ENABLE_DYNATRACE') === 'true';

const Container = styled.div({
  maxWidth: MAX_SCREEN_WIDTH.DESKTOP,
  display: 'grid',
  gridTemplateRows: 'repeat(auto-fill, 1fr)',
  margin: '0 auto',
  contain: 'layout paint',
});

const LayoutContainer = styled.div({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr 100px',
  gridTemplateRows: 'auto auto auto',
  [breakpoints.md.mediaQuery]: {
    gridTemplateColumns: `1fr ${PRODUCT_PANEL_WIDTH.DESKTOP}`,
    gridTemplateRows: 'auto auto',
  },
});

const ImageGalleryContainer = styled.div<{ projectEarth: boolean }>(({ projectEarth }) => ({
  gridColumnStart: 1,
  gridColumnEnd: 3,

  ...(projectEarth && {
    position: 'relative',

    '::after': {
      content: '" "',
      width: '54px',
      height: '54px',
      position: 'absolute',
      margin: spacing(3),
      pointerEvents: 'none',
      top: 0,
      right: 0,
      backgroundImage: `url(${getStaticResourceUrl(ProjectEarthIcon)})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  }),

  [breakpoints.md.mediaQuery]: {
    gridColumnStart: 1,
    gridColumnEnd: 1,

    ...(projectEarth && {
      '::after': {
        margin: spacing(6),
      },
    }),
  },
}));

const ProductContainer = styled.div({
  width: '100%',
  position: 'relative',
  gridColumnStart: 1,
  gridColumnEnd: 3,
  gridRowStart: 2,
  gridRowEnd: 'auto',
  [breakpoints.md.mediaQuery]: {
    gridColumnStart: 2,
    gridColumnEnd: 2,
    gridRowStart: 1,
    gridRowEnd: 4,
  },
});

const ContentContainer = styled.div({
  width: '100%',
  overflow: 'hidden',
  gridColumnStart: 1,
  gridColumnEnd: 3,
  gridRowStart: 3,
  marginTop: `calc(6.4rem - ${PRODUCT_PANEL_GUTTER.MOBILE})`,

  [breakpoints.md.mediaQuery]: {
    marginTop: `calc(6.4rem - ${PRODUCT_PANEL_GUTTER.DESKTOP})`,
    gridColumnStart: 1,
    gridColumnEnd: 1,
  },
});

const StickyContainer = styled.div({
  [breakpoints.md.mediaQuery]: {
    position: 'absolute',
    top: '0px',
    height: '100%',
    width: '100%',
  },
});

const ScrollToTargetContainer = styled.div({
  scrollMarginTop: MEGAMENU_HEIGHT.MOBILE,

  [breakpoints.md.mediaQuery]: {
    scrollMarginTop: MEGAMENU_HEIGHT.DESKTOP,
  },
});

const YouMayAlsoLikeCarousel = styled(NativeCarousel)({
  paddingBottom: 0,
});

const RecommendedForYouCarousel = styled(NativeCarousel)({
  paddingTop: 0,
  paddingBottom: 0,
});

const StickyElement = styled.div<{ hasDeliveryRestrictionBanner: boolean; hasTopPaddingRemoved: boolean }>(
  ({ hasDeliveryRestrictionBanner, hasTopPaddingRemoved }) => {
    const topValue = [MEGAMENU_HEIGHT.DESKTOP];

    if (hasDeliveryRestrictionBanner) {
      topValue.push(DELIVERY_RESTRICTION_BANNER_HEIGHT);
    }

    if (hasTopPaddingRemoved) {
      topValue.push(PRODUCT_PANEL_GUTTER.DESKTOP);
    }

    return {
      position: 'sticky',
      top: `calc(${topValue.join(' + ')})`,
      width: '100%',
      zIndex: 2,
    };
  },
);

const StickyShortScreenOffset = styled.div({
  transition: 'all 0.2s ease-in-out',
});

const PageBottomRightToast = styled.div({
  width: `calc(100% - (${PRODUCT_PANEL_GUTTER.MOBILE} * 2))`,
  bottom: 0,
  right: spacing(4),
  position: 'fixed',
  zIndex: 102,
  paddingBottom: spacing(4),

  [breakpoints.md.mediaQuery]: {
    width: `calc(${PRODUCT_PANEL_WIDTH.DESKTOP} - (${PRODUCT_PANEL_GUTTER.DESKTOP} * 2) + ${REMOVED_BODY_SCROLL_BAR_SIZE_ON_MODAL_OPEN})`,
    paddingBottom: spacing(6),
    right: PRODUCT_PANEL_GUTTER.DESKTOP,
  },
});

const tealiumLogger = logger.get('pdp', 'tealium');

const gridConfigs: GridConfigs = {
  fluid: true,
  margin: { xxs: 4, xs: 4, sm: 6, md: 15, lg: 30 },
};

const initialPersonalisationUserInput: PersonalisationUserInput = {
  message: '',
  font: undefined,
  colour: undefined,
};

export default function App({ data, pageUrl, countryLanguage, tealiumConfig, isMfeTemplate }: AppProps) {
  const { t } = useTranslation();
  const { pdpData } = data;
  const [countryCode, language] = countryLanguage.split('/') as [AvailableCountryCode, AvailableLanguage];
  const countryLang: CountryLanguage = { countryCode, language, toString: () => countryLanguage };
  const stickyPanelRef = useRef<HTMLDivElement>(null);
  const ratingsReviewsRef = useRef<HTMLDivElement>(null);
  const bundleCarouselRef = useRef<HTMLDivElement>(null);
  const brandBannerRef = useRef<HTMLDivElement>(null);
  const isServerSide = useIsServerSide();
  const [selectedBundleProductPartNumber, setSelectedBundleProductPartNumber] = useState('');

  const {
    sizes,
    showQuantity,
    colourInfos,
    isBeauty,
    hasRatingReviews,
    hasFitFinder,
    pageTitle,
    pageDescription,
    brandInfo,
    showSizeGuide,
    details,
    specifications,
    taxAndDuties,
    price,
    selfridgesSays,
    isGifting,
    isBundled,
    personalisation,
    breadcrumbs,
    productOutOfStock,
    displayFlag,
    productName,
    productDescription,
    projectEarth,
    selfridgesPlusItems,
    productUrl,
    imageUrl,
    hoverImageUrl,
    promotionalMessages,
    productLaunchDate,
    inStoreOnlyMessageHtml,
    isInStoreOnly,
  } = useTransformProductData(pdpData.productKey || '', pdpData.partNumber, pdpData.productResponse, countryLanguage);
  const stockAndPriceData = useFetchStockAndPrice({ countryLanguage, partNumber: pdpData.partNumber });

  const { getSku, stockAndPriceMap, allOutOfStock } = useSku(
    countryCode,
    stockAndPriceData,
    pdpData.productResponse.price || undefined,
    colourInfos,
    sizes,
  );
  const { selectedSize, setSelectedSize } = useSelectedSize(sizes);
  const { selectedColour, setSelectedColour, isColourUrlHashChecked } = useSelectedColour(colourInfos);
  const [pageViewFired, setPageViewFired] = useState(false);
  const [giftMessage, setGiftMessage] = useState('');
  const [personalisationUserInput, setPersonalisationUserInput] = useState<PersonalisationUserInput>(
    initialPersonalisationUserInput,
  );
  const userRecommendations = useFetchRecommendations(countryLanguage);

  const {
    skuId,
    availableStock,
    price: skuPrice,
    isFinalPrice,
    outOfStock,
    gbpPrice,
  } = getSku({
    colour: selectedColour.value,
    size: selectedSize.value,
  });

  const { quantity, updateQuantity, purchaseLimit } = useQuantity({
    colour: selectedColour,
    size: selectedSize,
    stockLevel: availableStock,
  });

  const productAddToBagToast = useToastMessage({
    messageLifeTime: 3000,
    messageAnimationOverflow: 'contain',
    direction: 'normal',
  });
  const productPanelBottomToast = useToastMessage({ messageLifeTime: 3000, direction: 'reverse' });
  const pageBottomRightToast = useToastMessage({ messageLifeTime: 3000, direction: 'reverse' });

  const { addItemToWishList, deleteItemFromWishList, isAddedToWishList } = useWishList({
    countryLanguage,
    partNumber: pdpData.partNumber,
    onAdded: () => {
      productPanelBottomToast.queueMessage(<ToastMessage addedToWishList={true} countryLanguage={countryLanguage} />);
    },
    onRemoved: () => {
      productPanelBottomToast.queueMessage(<ToastMessage addedToWishList={false} countryLanguage={countryLanguage} />);
    },
  });
  const isProductInWishList = isAddedToWishList({ skuId, partNumber: pdpData.partNumber });

  const { Modal: ProductDetailsModal, open: openProductDetailsModal } = useModal();
  const { Modal: SpecificationsModal, open: openSpecificationsModal } = useModal();
  const { Modal: DeliveryReturnsModal, open: openDeliveryReturnsModal } = useModal();
  const { Modal: SelfridgesPlusModal, open: openSelfridgesPlusModal } = useModal();
  const { Modal: SizeGuideModal, open: openSizeGuideModal } = useModal();
  const { Modal: GiftingModal, open: openGiftingModal, close: closeGiftingModal } = useModal();
  const { Modal: PersonalisedModal, open: openPersonalisedModal, close: closePersonalisedModal } = useModal();
  const { Modal: BundlesModal, open: openBundlesModal } = useModal();

  const brandConfig = useBrandConfig();

  const {
    data: ratingAndReviewsData,
    nextPage,
    updateStarFilter,
    starFilter,
    isLoading: isLoadingReviews,
  } = useFetchRatingAndReviews({
    partNumber: pdpData.partNumber,
    skuId: stockAndPriceData?.[0].skuId, // hardcode to use the first skuId
    enabled: hasRatingReviews && !brandConfig.blockRatingReviews,
  });
  const ratingSummary = useTransformRatingData(ratingAndReviewsData?.response.bottomline);
  const reviews = useTransformReviewsData(ratingAndReviewsData);

  const youMayAlsoLikeProps = useTransformYMALDataToProps(pdpData.productResponse, countryLanguage);

  const hideRecommendedForYou = brandConfig?.hideRecommendedForYou;
  const recommendedForYouProps = useTransformProductDataToRecommendedForYouProps(
    countryLanguage,
    userRecommendations,
    hideRecommendedForYou,
  );

  const deliveryAndReturnsProps = useTransformProductDataToDeliveryAndReturnsProps(
    pdpData.productResponse,
    countryLanguage,
  );

  const deliveryCutoffs = useTransformDataToCountdown({
    localDelivery: deliveryAndReturnsProps.localDelivery,
    clickAndCollect: deliveryAndReturnsProps.clickAndCollect,
    showCountdownTimer: pdpData.productResponse.showCountdownTimer && !isInStoreOnly,
  });

  const noLocalDeliveryOption = !deliveryAndReturnsProps.localDelivery.options.length;
  const clickAndCollectOnly =
    noLocalDeliveryOption &&
    deliveryAndReturnsProps.clickAndCollect &&
    countryCode === 'GB' &&
    !productOutOfStock &&
    !isInStoreOnly;
  const showDeliveryRestrictionBanner = noLocalDeliveryOption && !productOutOfStock && !isInStoreOnly;
  const ukInternationalDelivery =
    deliveryAndReturnsProps.internationalDelivery.options.some(
      ({ region }) => region === countryInfo.GB.alt || region === countryInfo.GB.zh,
    ) && !isInStoreOnly;

  const bundlesResponse = useFetchBundles({
    countryLanguage,
    partNumber: pdpData.partNumber,
    enabled: isBundled,
  });

  const { bundleIds, getBundleProducts } = useBundles({
    bundlesResponse,
    countryLanguage: countryLang,
    parentColourInfos: colourInfos,
  });

  const bundleProducts = useMemo(
    () => getBundleProducts({ selectedColour, bundleId: bundleIds[0] }),
    [selectedColour, bundleIds, getBundleProducts],
  );

  const bundleProduct = bundleProducts.find(product => product.partNumber === selectedBundleProductPartNumber);

  const CompleteTheSet = useMemo(() => {
    return bundleProducts.length ? <CompleteTheSetButton scrollToTarget={bundleCarouselRef.current} /> : undefined;
  }, [bundleProducts.length]);

  const megamenuOffset =
    parseFloat(MEGAMENU_HEIGHT.DESKTOP) +
    (showDeliveryRestrictionBanner ? parseFloat(DELIVERY_RESTRICTION_BANNER_HEIGHT) : 0) +
    (brandConfig.banner && brandBannerRef.current ? brandBannerRef.current.clientHeight : 0);

  useAdjustProductPanelHeight({
    offsetTop: megamenuOffset,
    stickyPanelRef,
    numOfToastMessages: productAddToBagToast.messages.length,
    reviewsLength: reviews.length,
    personalisationMessageLength: personalisationUserInput.message.length,
    giftMessageLength: giftMessage.length,
    numOfBundleProducts: bundleProducts.length,
  });

  const {
    UpperBoundary: StickyAddToBagUpperBoundary,
    LowerBoundary: StickyAddToBagLowerBoundary,
    isIntersecting: isIntersectingStickyAddToCart,
  } = useIntersectionArea({ useOnMobile: true });

  const {
    UpperBoundary: SalesPropUpperBoundary,
    LowerBoundary: SalesPropLowerBoundary,
    isIntersecting: salesPropisIntersecting,
    updateIntersection: salesPropUpdateIntersection,
  } = useIntersectionArea({ useOnDesktop: true, intersectTop: false });

  useEffect(() => {
    if (gbpPrice && !pageViewFired) {
      productDetailsPageAnalytics(pdpData.partNumber, pdpData.productResponse, gbpPrice);
      setPageViewFired(true);
    }
  }, [pdpData.partNumber, pdpData.productResponse, gbpPrice, setPageViewFired, pageViewFired]);

  useEffect(() => {
    if (stockAndPriceData && pageViewFired) {
      productAvailabilityTracking({
        stockAndPriceData,
        sizes,
        productColour: colourInfos && selectedColour.display,
        partNumber: pdpData.partNumber,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stockAndPriceData, selectedColour, pageViewFired]);

  const { AddToCartButton, error: addToCartError } = useAddToCart({
    skuId,
    quantity,
    countryLanguage,
    partNumber: pdpData.partNumber,
    giftMessage,
    personalisationUserInput,
    onSuccess: () => {
      addToBagTracking({
        productSize: sizes && selectedSize.display,
        productColour: colourInfos && selectedColour.display,
        productQuantity: quantity,
        skuId: skuId,
        skuPrice,
        gbpPrice,
        giftMessageAdded: isGifting ? Boolean(giftMessage) : undefined,
        personalisationAdded: personalisation ? Boolean(personalisationUserInput.message) : undefined,
      });

      productAddToBagToast.queueMessage(
        <SuccessMessage
          selectedColour={selectedColour.display}
          selectedSize={selectedSize.display}
          quantity={quantity}
          brandName={brandInfo.name}
          productDescription={productDescription}
          countryLanguage={countryLanguage}
        />,
      );
      if (!isMobile()) productAddToBagToast.scrollToMessage();
    },
    onError: ({ errorKey, errorMessage }) => {
      productAddToBagToast.queueMessage(
        <ErrorMessage
          errorKey={errorKey}
          errorMessage={errorMessage}
          addToWishList={() => addItemToWishList({ skuId, partNumber: pdpData.partNumber })}
        />,
      );

      if (!isMobile()) productAddToBagToast.scrollToMessage();
    },
  });

  const isFitFinderEnabled = Boolean(
    hasFitFinder && typeof window !== 'undefined' && !navigator.userAgent.includes('Googlebot'),
  );

  const mediaData = useProductMedia({
    colour: selectedColour.display || colourInfos?.[0].name || '',
    countryLanguage,
    partNumber: pdpData.partNumber,
    enabled: isColourUrlHashChecked && (colourInfos || []).length > 1,
  });

  const imageGalleryProps = useTransformMediaDataToImageGalleryProps(mediaData, pdpData.productResponse);

  const currentMainImageUrl = transformToMainImageUrl(pdpData.productResponse, mediaData);

  useFitAnalyticsDataLayer({
    enabled: isFitFinderEnabled,
    countryCode,
    language,
    mainImageUrl: currentMainImageUrl,
    partNumber: pdpData.partNumber,
    selectedColour,
    colourInfos,
    stockAndPriceMap,
    selectedSize,
    selectSize: setSelectedSize,
  });

  const showKlarnaPaymentMessage = Boolean(isWithinKlarnaPriceRange(skuPrice?.raw.current) && isFinalPrice);

  const isNoIndex = isNoIndexProduct(productLaunchDate);

  return (
    <>
      <HeadTag.PageEssentials
        title={pageTitle}
        description={pageDescription}
        countryLanguage={countryLanguage}
        isMfeTemplate={isMfeTemplate}
      />
      <Helmet>
        {ENABLE_DYNATRACE() && (
          <script
            type="text/javascript"
            src="https://js-cdn.dynatrace.com/jstag/164ae1b51de/bf67380nlf/584a6eb1119e7df9_complete.js"
            crossOrigin="anonymous"
          ></script>
        )}
        <link rel="preconnect" href="https://tags.tiqcdn.com" />
        <link rel="canonical" href={pageUrl} />
        {isNoIndex && <meta name="robots" content="noindex" />}
        <style>{bareboneGlobalStyle({ includeNormalizedCSS: true, includeResetCSS: true })}</style>
        <script>{createContextDataListener()}</script>
        <script>
          {createTealiumLoaderScript({
            pageType: 'PDP',
            loadEventName: 'UTAG_LOADED',
            tealiumConfig,
            logger: tealiumLogger,
          })}
        </script>
        <script type="application/ld+json">
          {createStructuredDataScript({
            brandInfo,
            productDescription,
            details: selfridgesSays || '',
            breadcrumbs: breadcrumbs || [],
            productOutOfStock,
            colourInfos: colourInfos || [],
            image: pdpData.productResponse.media?.defaultImage || '',
            price: pdpData.productResponse.price,
            pageUrl,
          })}
        </script>
        {isFitFinderEnabled && (
          <script type="text/javascript" defer={true} src={getStaticResourceUrl('fitAnalytics.js')} />
        )}
      </Helmet>
      {!isMfeTemplate && (
        <>
          <AppFonts />
          <HeadTag.FontPreload includes={['avalonDemi', 'dinRegular', 'dinMedium']} />
        </>
      )}
      <HeadTag.Favicon />
      <HeadTag.SingleSpaEssentials appName="@selfridges/product-details-page" isMfeTemplate={isMfeTemplate} />
      <HeadTag.HrefLangs url={pageUrl} />
      <HeadTag.OpenGraphMetaTag
        type="product"
        title={`${brandInfo.name} ${productDescription}`}
        url={pageUrl}
        image={imageUrl}
      />
      <HeadTag.TwitterMetaTag
        card="summary_large_image"
        title={`${brandInfo.name} ${productDescription}`}
        image={imageUrl}
      />
      {countryCode === 'GB' && <KlarnaScript />}
      {SalesPropUpperBoundary}
      <Container data-analytics-app="pdp">
        {showDeliveryRestrictionBanner && <DeliveryRestrictionsBanner countryCode={countryCode} language={language} />}
        {pdpData.productKey ? (
          <GridConfigsProvider configs={gridConfigs}>
            {brandConfig.banner && (
              <div ref={brandBannerRef}>
                <BrandBanner banner={brandConfig.banner} />
              </div>
            )}
            <LayoutContainer>
              <ImageGalleryContainer projectEarth={Boolean(projectEarth)}>
                <ImageGallery
                  {...imageGalleryProps}
                  {...brandConfig.imageGallery}
                  onUpdate={salesPropUpdateIntersection}
                />
                {SalesPropLowerBoundary}
              </ImageGalleryContainer>
              <ProductContainer>
                <ProductPanelBottomToast offsetTop={megamenuOffset + getLegacyServiceBannerHeight()}>
                  {productPanelBottomToast.Message}
                </ProductPanelBottomToast>
                <StickyContainer>
                  <StickyElement
                    hasDeliveryRestrictionBanner={showDeliveryRestrictionBanner}
                    hasTopPaddingRemoved={Boolean(brandConfig.removeProductPanelTopPadding)}
                    ref={stickyPanelRef}
                  >
                    <StickyShortScreenOffset>
                      <ProductPanel
                        brandInfo={brandInfo}
                        productDescription={productDescription}
                        DisplayFlag={
                          (productOutOfStock && (
                            <DisplayFlag variant="large" type="OUT_OF_STOCK">
                              <Trans i18nKey="app.display-flag.out-of-stock">Out of stock</Trans>
                            </DisplayFlag>
                          )) ||
                          (displayFlag && (
                            <DisplayFlag variant="large" type={projectEarth ? 'PROJECT_EARTH' : 'NORMAL'}>
                              {displayFlag}
                            </DisplayFlag>
                          )) ||
                          undefined
                        }
                        MoreInformationLink={
                          details ? <MoreInformationLink onClick={openProductDetailsModal} /> : undefined
                        }
                        Rating={
                          ratingSummary && ratingSummary.totalReviews >= MIN_NUM_OF_REVIEWS ? (
                            <AverageRating
                              score={ratingSummary.score}
                              totalReviews={ratingSummary.totalReviews}
                              scrollToTarget={ratingsReviewsRef.current}
                            />
                          ) : undefined
                        }
                        Quantity={
                          showQuantity && !productOutOfStock && !isInStoreOnly ? (
                            <Quantity
                              quantity={quantity}
                              updateQuantity={updateQuantity}
                              purchaseLimit={purchaseLimit}
                              availableStock={availableStock}
                            />
                          ) : undefined
                        }
                        WishListButton={
                          <WishListIconButton
                            onClick={() =>
                              !isProductInWishList
                                ? addItemToWishList({ skuId, partNumber: pdpData.partNumber })
                                : deleteItemFromWishList({ skuId, partNumber: pdpData.partNumber })
                            }
                            active={isProductInWishList}
                          />
                        }
                        AddGiftMessage={
                          isGifting && !productOutOfStock ? (
                            <AddGiftMessage
                              giftMessage={giftMessage}
                              openGiftingModal={openGiftingModal}
                              onRemove={() => setGiftMessage('')}
                            />
                          ) : undefined
                        }
                        AddPersonalisedMessage={
                          personalisation && !productOutOfStock ? (
                            <AddPersonalisedMessage
                              personalisedMessage={personalisationUserInput.message}
                              isMandatory={personalisation.mandatory}
                              price={personalisation?.price?.formatted}
                              onEdit={openPersonalisedModal}
                              onRemove={() => setPersonalisationUserInput(initialPersonalisationUserInput)}
                            />
                          ) : undefined
                        }
                        AddToCartButton={
                          (!personalisation?.mandatory || personalisationUserInput.message) &&
                          !productOutOfStock &&
                          !isInStoreOnly ? (
                            <AddToBag
                              id="primary-add-to-bag-btn"
                              show={!isIntersectingStickyAddToCart}
                              displayPrice={
                                personalisationUserInput.message && personalisation?.price && skuPrice
                                  ? formatPrice(
                                      personalisation.price.raw + skuPrice?.raw.current,
                                      personalisation.price.currency,
                                    )
                                  : undefined
                              }
                              AddToCartButton={AddToCartButton}
                              disabled={outOfStock}
                              ToastMessage={productAddToBagToast.Message}
                              ExtraContentBelow={
                                <>
                                  {CompleteTheSet}
                                  {showKlarnaPaymentMessage && skuPrice ? (
                                    <KlarnaMessage price={skuPrice?.raw.current} />
                                  ) : undefined}
                                </>
                              }
                            />
                          ) : undefined
                        }
                        addToCartError={addToCartError}
                        Price={
                          <Price
                            isFinal={isFinalPrice}
                            hiddenPrice={price}
                            displayPrice={skuPrice}
                            taxAndDuties={taxAndDuties}
                          />
                        }
                        ColourSelector={
                          colourInfos && !productOutOfStock && !isInStoreOnly ? (
                            <ColourSelector
                              colours={colourInfos}
                              selectedColour={selectedColour}
                              selectedSize={selectedSize}
                              onSelect={setSelectedColour}
                              getSku={getSku}
                              isBeauty={isBeauty}
                              showSelectPromptMessage={
                                addToCartError.noSkuProvided && selectedColour.value === 'NOT_SELECTED'
                              }
                            />
                          ) : undefined
                        }
                        SizeSelector={
                          sizes && !productOutOfStock && !isInStoreOnly ? (
                            <SizeSelector
                              sizes={sizes}
                              selectedSize={selectedSize}
                              selectedColour={selectedColour}
                              onSelect={setSelectedSize}
                              getSku={getSku}
                              showSelectPromptMessage={
                                addToCartError.noSkuProvided && selectedSize.value === 'NOT_SELECTED'
                              }
                              showSizeGuide={Boolean(showSizeGuide && breadcrumbs)}
                              openSizeGuideModal={openSizeGuideModal}
                            />
                          ) : undefined
                        }
                        OutOfStockTemplate={
                          productOutOfStock ? (
                            <OutOfStockTemplate
                              {...transformProductDataToOutOfStockTemplateProps(
                                pdpData.productResponse,
                                countryLanguage,
                              )}
                            />
                          ) : undefined
                        }
                        InStoreOnlyTemplate={
                          isInStoreOnly ? <InStoreOnlyTemplate htmlMessage={inStoreOnlyMessageHtml} /> : undefined
                        }
                      />
                    </StickyShortScreenOffset>
                  </StickyElement>
                </StickyContainer>
                {StickyAddToBagUpperBoundary}
              </ProductContainer>
              <ContentContainer>
                <Grid {...gridConfigs}>
                  {
                    (
                      <SalesPropMobile
                        promotionalMessages={promotionalMessages}
                        ukDeliveryOnly={noLocalDeliveryOption && ukInternationalDelivery}
                        clickAndCollectOnly={clickAndCollectOnly}
                        countryCode={countryCode}
                        deliveryCutoffs={deliveryCutoffs}
                        outOfStock={productOutOfStock || allOutOfStock}
                      />
                    ) as ReactElement<typeof Row>
                  }
                  <Row>
                    <Col xxs={4} sm={6} md={12}>
                      {breadcrumbs && <Breadcrumb path={breadcrumbs} />}
                    </Col>
                  </Row>
                  <Row>
                    <Col xxs={4} sm={6} md={12}>
                      <OverlayLinks>
                        <OverlayLinks.Item onClick={openProductDetailsModal} hide={!details}>
                          <Trans i18nKey="app.overlay-links.product-details">Product details</Trans>
                        </OverlayLinks.Item>
                        <OverlayLinks.Item onClick={openSpecificationsModal} hide={!specifications}>
                          <Trans i18nKey="app.overlay-links.specifications">Specifications</Trans>
                        </OverlayLinks.Item>
                        <OverlayLinks.Item onClick={openDeliveryReturnsModal} hide={isInStoreOnly}>
                          <Trans i18nKey="app.overlay-links.delivery-and-returns">Delivery & returns</Trans>
                        </OverlayLinks.Item>
                        <OverlayLinks.Item onClick={openSelfridgesPlusModal} hide={!selfridgesPlusItems}>
                          <Trans i18nKey="app.overlay-links.selfridges-plus">Selfridges Plus</Trans>
                        </OverlayLinks.Item>
                      </OverlayLinks>
                    </Col>
                  </Row>
                  {(projectEarth && <ProjectEarth projectEarth={projectEarth} />) as ReactElement<typeof Row>}
                </Grid>
                {selfridgesSays && (
                  <SelfridgesSays
                    gridConfigs={gridConfigs}
                    html={selfridgesSays}
                    quoteAuthor={brandConfig.selfridgesSays?.quoteAuthor}
                    speechMarkColour={brandConfig.selfridgesSays?.speechMarkColour}
                  />
                )}
                {StickyAddToBagLowerBoundary}
                <ScrollToTargetContainer ref={bundleCarouselRef}>
                  {Boolean(bundleProducts.length) && (
                    <BundleCarousel
                      bundles={bundleProducts}
                      title={t('app.bundles.carousel.title', { defaultValue: 'Complete the set' }).toString()}
                      onCardSelect={partNumber => {
                        setSelectedBundleProductPartNumber(partNumber);
                        openBundlesModal();
                      }}
                    />
                  )}
                </ScrollToTargetContainer>
              </ContentContainer>
            </LayoutContainer>
            {ratingAndReviewsData && (
              <ScrollToTargetContainer ref={ratingsReviewsRef}>
                {ratingSummary && ratingSummary.totalReviews >= MIN_NUM_OF_REVIEWS && (
                  <RatingReviews
                    gridConfigs={gridConfigs}
                    reviews={reviews}
                    ratingSummary={ratingSummary}
                    nextPage={nextPage}
                    updateStarFilter={updateStarFilter}
                    starFilter={starFilter}
                    isLoadingReviews={isLoadingReviews}
                  />
                )}
              </ScrollToTargetContainer>
            )}
            {youMayAlsoLikeProps && (
              <YouMayAlsoLikeCarousel
                isProductCarousel
                gridConfigs={gridConfigs}
                aria-label={t('app.you-may-also-like.aria-label', {
                  defaultValue: 'You May Also Like Carousel',
                }).toString()}
                title={t('app.you-may-also-like.title', {
                  defaultValue: 'You may also like',
                }).toString()}
                data-analytics-key="ymal_product_carousel"
                data-analytics-component="ymal_product_carousel"
                {...youMayAlsoLikeProps}
              />
            )}
            {breadcrumbs && (
              <MoreFrom
                data-testid="more-from"
                gridConfigs={gridConfigs}
                title={t('app.more-from-title', { defaultValue: 'More from' }).toString()}
                {...transformToMoreFromProps({ breadcrumbs, brandInfo })}
              />
            )}
            {recommendedForYouProps && (
              <RecommendedForYouCarousel
                isProductCarousel
                gridConfigs={gridConfigs}
                aria-label={t('app.recommended-for-you.aria-label', {
                  defaultValue: 'Recommended for you Carousel',
                }).toString()}
                title={t('app.recommended-for-you.title', {
                  defaultValue: 'Recommended for you',
                }).toString()}
                data-analytics-key="rfy_product_carousel"
                data-analytics-component="rfy_product_carousel"
                {...recommendedForYouProps}
              />
            )}
            <RecentlyViewed
              data-analytics-key="recently_viewed_carousel"
              data-analytics-component="recently_viewed_carousel"
              currentProduct={{
                partNumber: pdpData.partNumber,
                brandName: brandInfo.name,
                description: productName,
                targetUrl: productUrl,
                imageUrl,
                hoverImageUrl,
                analyticsDetails: [
                  pdpData.productResponse.productName,
                  pdpData.productResponse.breadcrumbs?.[1]?.seo || pdpData.productResponse.breadcrumbs?.[0]?.seo || '',
                  pdpData.partNumber,
                  stockAndPriceData && stockAndPriceData[0].price ? stockAndPriceData[0].price.current : '',
                  brandInfo.name,
                  pdpData.partNumber,
                  stockAndPriceData && stockAndPriceData[0].price ? stockAndPriceData[0].price.was : '',
                  stockAndPriceData && stockAndPriceData[0].price ? stockAndPriceData[0].price.wasWas : '',
                  pdpData.productResponse.displayFlag,
                  pdpData.productResponse.division.name || '',
                  pdpData.productResponse.department.name || '',
                ].join('|'),
              }}
              title={t('app.recently-viewed.title', { defaultValue: 'Recently viewed' })}
              aria-label={t('app.recently-viewed.title', { defaultValue: 'Recently viewed' })}
              removeCardButtonAriaLabel={t('app.recently-viewed.remove-card-label', {
                defaultValue: 'Remove recently viewed card',
              }).toString()}
              gridConfigs={gridConfigs}
            />
            {details && (
              <ProductDetailsModal
                Title={<Trans i18nKey="app.product-details-modal.title">Product details</Trans>}
                CloseButtonLabel={
                  <Trans i18nKey="app.product-details-modal.close-button-label">Close product details modal</Trans>
                }
              >
                <ProductDetails content={details} skuId={skuId} productId={pdpData.partNumber} />
              </ProductDetailsModal>
            )}
            {specifications && (
              <SpecificationsModal
                Title={<Trans i18nKey="app.specifications-modal.title">Specifications</Trans>}
                CloseButtonLabel={
                  <Trans i18nKey="app.specifications-modal.close-button-label">Close specifications modal</Trans>
                }
              >
                <Specifications content={specifications} />
              </SpecificationsModal>
            )}
            <DeliveryReturnsModal
              Title={<Trans i18nKey="app.delivery-and-returns-modal.title">Delivery & returns</Trans>}
              CloseButtonLabel={
                <Trans i18nKey="app.delivery-and-returns-modal.close-button-label">
                  Close Delivery & returns modal
                </Trans>
              }
            >
              <DeliveryAndReturns {...deliveryAndReturnsProps} />
            </DeliveryReturnsModal>
            {selfridgesPlusItems && (
              <SelfridgesPlusModal
                Title={<Trans i18nKey="app.selfridges-plus-modal.title">Selfridges Plus</Trans>}
                CloseButtonLabel={
                  <Trans i18nKey="app.selfridges-plus-modal.close-button-label">Close Selfridges plus modal</Trans>
                }
              >
                <SelfridgesPlus
                  items={selfridgesPlusItems}
                  countryLanguage={countryLanguage}
                  onAddToBag={Message => pageBottomRightToast.queueMessage(Message)}
                />
              </SelfridgesPlusModal>
            )}
            {showSizeGuide && breadcrumbs?.length && (
              <SizeGuideModal
                Title={<Trans i18nKey="app.size-guide-modal.title">Size guide</Trans>}
                ssrEnabled={false}
                CloseButtonLabel={
                  <Trans i18nKey="app.size-guide-modal.close-button-label">Close size guide modal</Trans>
                }
              >
                <SizeGuide
                  brandName={brandInfo.name}
                  categoryName={breadcrumbs[0].title}
                  countryLanguage={countryLanguage}
                />
              </SizeGuideModal>
            )}
            {isGifting && (
              <GiftingModal
                Title={<Trans i18nKey="app.gifting-modal.title">Add gift message</Trans>}
                ssrEnabled={false}
                dismissOnOverlay={false}
                CloseButtonLabel={<Trans i18nKey="app.gifting-modal.close-button-label">Close gifting modal</Trans>}
              >
                <Gifting
                  savedMessage={giftMessage}
                  partNumber={pdpData.partNumber}
                  onSave={newMessage => {
                    setGiftMessage(newMessage);
                    closeGiftingModal();
                  }}
                  onRemove={() => {
                    setGiftMessage('');
                    closeGiftingModal();
                  }}
                />
              </GiftingModal>
            )}
            {personalisation && (
              <PersonalisedModal
                Title={<Trans i18nKey="app.personalised-modal.title">Personalise this item</Trans>}
                ssrEnabled={false}
                dismissOnOverlay={false}
                CloseButtonLabel={
                  <Trans i18nKey="app.personalised-modal.close-button-label">Close personalised modal</Trans>
                }
              >
                <Personalised
                  savedInput={personalisationUserInput}
                  onSave={userInput => {
                    setPersonalisationUserInput(userInput);
                    closePersonalisedModal();
                  }}
                  onRemove={() => {
                    setPersonalisationUserInput(initialPersonalisationUserInput);
                    closePersonalisedModal();
                  }}
                  personalisation={personalisation}
                  partNumber={pdpData.partNumber}
                />
              </PersonalisedModal>
            )}
            <BundlesModal ssrEnabled={false}>
              {bundleProduct && (
                <BundlePanel
                  parentPartNumber={pdpData.partNumber}
                  bundleProduct={bundleProduct}
                  countryLanguage={countryLang}
                  addItemToWishList={addItemToWishList}
                />
              )}
            </BundlesModal>
          </GridConfigsProvider>
        ) : (
          <div>Error fetching PDP content</div>
        )}
        {(!personalisation?.mandatory || personalisationUserInput.message) &&
          !productOutOfStock &&
          isMobile() &&
          !isInStoreOnly && (
            <AddToBag
              AddToCartButton={AddToCartButton}
              disabled={outOfStock}
              show={isIntersectingStickyAddToCart}
              sticky={true}
              ToastMessage={productAddToBagToast.Message}
            />
          )}
      </Container>
      {!isServerSide && <PageBottomRightToast>{pageBottomRightToast.Message}</PageBottomRightToast>}
      {!isMobile() && (
        <SalesPropDesktop
          salesPropisIntersecting={salesPropisIntersecting}
          promotionalMessages={promotionalMessages}
          ukDeliveryOnly={noLocalDeliveryOption && ukInternationalDelivery}
          clickAndCollectOnly={clickAndCollectOnly}
          countryCode={countryCode}
          deliveryCutoffs={deliveryCutoffs}
          outOfStock={productOutOfStock || allOutOfStock}
        />
      )}
    </>
  );
}
