import { useCallback, useState, useEffect } from 'react';

import { DEFAULT_MAX_PURCHASE } from '../constants';

import type { AvailableStock, SelectedColour, SelectedSize } from '../types';

export function useQuantity({
  colour,
  size,
  stockLevel = 1,
  maxPurchase = DEFAULT_MAX_PURCHASE,
}: {
  colour: SelectedColour;
  size: SelectedSize;
  stockLevel: AvailableStock;
  maxPurchase?: number;
}) {
  const [quantity, setQuantity] = useState(1);
  const [purchaseLimit, setPurchaseLimit] = useState(maxPurchase);

  const updateQuantity = useCallback(
    (amount: number) => {
      const maxPurchaseLimit = Math.min(maxPurchase, Number(stockLevel) || 0);
      const upperBounds = Math.min(maxPurchaseLimit, amount);
      const lowerBounds = Math.max(upperBounds, 1);

      setPurchaseLimit(maxPurchaseLimit);

      setQuantity(lowerBounds);
    },
    [stockLevel, maxPurchase],
  );

  useEffect(() => {
    setQuantity(1);
  }, [colour, size]);

  useEffect(() => {
    const maxPurchaseLimit = Math.min(maxPurchase, Number(stockLevel) || 0);

    setPurchaseLimit(maxPurchaseLimit);
  }, [stockLevel, maxPurchase]);

  return { quantity, updateQuantity, purchaseLimit };
}
