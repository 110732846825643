import { useState } from 'react';
import styled from 'styled-components';

import { Picture } from '@selfridges-co/frontend-sdk-react-scene7';
import { spacing, color } from '@selfridges-co/frontend-sdk-react-theme';

interface SwatchProps {
  src: string;
  alt?: string;
  outOfStock?: boolean;
  pixelSize?: string;
}

const DEFAULT_SIZE_IN_PX = '48px';
const BROKEN_SWATCH_SVG = encodeURI(
  '<svg height="36" viewBox="0 0 36 36" width="36" xmlns="http://www.w3.org/2000/svg"><g fill="#EAEAEA" fill-rule="evenodd" stroke="#979797"><path d="m.5.5h35v35h-35z"/><path d="m1.5 1.5 33 33" stroke-linecap="square"/></g></svg>',
).replace(/#/g, '%23');

const SwatchContainer = styled.div<{ outOfStock?: boolean; pixelSize?: string }>(({ outOfStock, pixelSize }) => ({
  width: pixelSize,
  height: pixelSize,
  margin: spacing(1),
  marginRight: 0,
  position: 'relative',

  ...(outOfStock && {
    ':after': {
      content: '""',
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      background: `linear-gradient(to bottom right,
        ${color.palette.mainWhite}88 calc(50% - 1px),
        ${color.palette.mainWhite} calc(50% - 1px),
        ${color.palette.mainWhite} calc(50% + 1px),
        ${color.palette.mainWhite}88 calc(50% + 1px))`,
    },
  }),
}));

const SwatchImage = styled(Picture)({
  height: '100%',
  width: '100%',
});

const ErrorSwatchImage = styled.img({
  height: '100%',
  width: '100%',
});

export default function Swatch({ src, alt, outOfStock, pixelSize = DEFAULT_SIZE_IN_PX }: SwatchProps) {
  const [isError, setIsError] = useState(false);

  function handleSwatchError() {
    setIsError(true);
  }

  return (
    <SwatchContainer outOfStock={outOfStock} pixelSize={pixelSize}>
      {!isError ? (
        <SwatchImage
          onError={handleSwatchError}
          src={src}
          intrinsicMinWidth={96}
          aspectRatio={[1, 1]}
          devicePixelRatios={[1]}
          alt={alt || ''}
        />
      ) : (
        <ErrorSwatchImage src={`data:image/svg+xml,${BROKEN_SWATCH_SVG}`} alt={alt || ''} />
      )}
    </SwatchContainer>
  );
}
