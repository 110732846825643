import styled from 'styled-components';
import { Trans } from 'react-i18next';

import { color, spacing, typography } from '@selfridges-co/frontend-sdk-react-theme';
import { Link } from '@selfridges-co/frontend-sdk-react-link';

import { InfoToast } from '../hooks/useToastMessage';
import { VIEW_BAG_URL } from '../constants';

type SuccessMessageProps = {
  brandName: string;
  productDescription: string;
  selectedColour: string;
  selectedSize: string;
  quantity: number;
  countryLanguage: string;
};

const TruncateText = styled.div({
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
});

const Parameters = styled(TruncateText)({
  ...typography.typeface.text.sm.bold,
  color: color.palette.mainWhite,
});

const BrandName = styled(TruncateText)({
  ...typography.typeface.display.xs,
  color: color.palette.mainWhite,
});

const ProductDescription = styled(TruncateText)({
  ...typography.typeface.text.sm.regular,
  color: color.palette.midGrey2,
});

const Container = styled(InfoToast)({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridAutoRows: 'auto',
  gridGap: spacing(2),
});

const DotDivider = styled.span({
  ':after': {
    content: '"•"',
    padding: spacing(0, 2),
  },
});

export function SuccessMessage({
  brandName,
  productDescription,
  selectedColour,
  selectedSize,
  quantity,
  countryLanguage,
}: SuccessMessageProps) {
  return (
    <Container>
      <BrandName>{brandName}</BrandName>
      <ProductDescription>{productDescription}</ProductDescription>
      <Parameters>
        {selectedColour}
        {Boolean(selectedSize && selectedColour) && <DotDivider />}
        {selectedSize}
        {!(!selectedSize && !selectedColour) && Boolean(!selectedSize || !selectedColour) && <DotDivider />}
        {Boolean(!selectedSize || !selectedColour) && (
          <Trans i18nKey="app.add-to-bag.quantity">Qty: {{ quantity }}</Trans>
        )}
      </Parameters>
      <Link linkType="primary" variant="yellow" href={`/${countryLanguage}${VIEW_BAG_URL}`}>
        <Trans i18nKey="app.add-to-bag.view-bag">View bag</Trans>
      </Link>
    </Container>
  );
}
