import React from 'react';
import styled from 'styled-components';
import ReactLoadingSkeleton, { SkeletonProps } from 'react-loading-skeleton';

import { color } from '@selfridges-co/frontend-sdk-react-theme';

const DEFAULT_HIGHLIGHT_COLOUR = color.palette.lightGrey2;
const DEFAULT_BASE_COLOUR = color.palette.lightGrey1;

const StyledLoadingSkeleton = styled(ReactLoadingSkeleton)({
  '--baseColor': DEFAULT_BASE_COLOUR,
  '--highlight-color': DEFAULT_HIGHLIGHT_COLOUR,
  '--animation-duration': '1.5s',
  '--animation-direction': 'normal',
  '--pseudo-element-display': 'block',

  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  backgroundColor: 'var(--base-color)',
  width: '100%',
  borderRadius: '0.25rem',
  display: 'inline-flex',
  lineHeight: '1',

  overflow: 'hidden',
  zIndex: '1',

  '::after': {
    content: '" "',
    display: 'var(--pseudo-element-display)',
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundImage: 'linear-gradient(90deg, var(--base-color), var(--highlight-color), var(--base-color) )',
    transform: 'translateX(-100%)',

    animationName: 'skeleton-animation',
    animationDirection: 'var(--animation-direction)',
    animationDuration: 'var(--animation-duration)',
    animationTimingFunction: 'ease-in-out',
    animationIterationCount: 'infinite',
  },

  '@keyframes skeleton-animation': {
    '100%': { transform: 'translateX(100%)' },
  },

  '@media (prefers-reduced-motion)': {
    'skeleton-animation': {
      '--pseudo-element-display': 'none',
    },
  },
});

export default function LoadingSkeleton(props: SkeletonProps) {
  return <StyledLoadingSkeleton {...props} />;
}
