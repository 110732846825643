import { useMemo } from 'react';

import env from '@selfridges-pkg/utils/env';

import type { ProductResponse } from '@selfridges-pkg/product-aggregation-service-api/contracts/ProductResponse';
import type { MediaResponse } from '@selfridges-pkg/product-aggregation-service-api/contracts/MediaResponse';
import type { Video } from '@selfridges-pkg/product-aggregation-service-api/contracts/Media';
import type { ImageGalleryProps } from '../image-gallery/ImageGallery';
import type { ProductVideos } from '../types';

type ImageGalleryPropsToTransform = Partial<ImageGalleryProps> &
  Pick<ImageGalleryProps, 'imageAltText' | 'productImageUrlList' | 'productVideoList' | 'loading'>;

const MEDIA_ROOT_URL = () => env('REACT_APP_MEDIA_ROOT_URL') || '';

function getImageUrlList(mediaData: MediaResponse | ProductResponse['media'] | undefined): Array<string> | null {
  return !mediaData?.images?.filter(imgUrl => Boolean(imgUrl)).length ? null : mediaData.images;
}

function getVideoList(mediaData: MediaResponse | ProductResponse['media'] | undefined): Array<Video> | null {
  return !mediaData?.videos?.length ? null : mediaData.videos;
}

function transformToProductVideos(videos: Array<Video>): ProductVideos {
  const splitVideos: Record<string, Array<Video>> = videos.reduce((result, video) => {
    // Regex looks for 'VID01','VID02', etc. in the name and matches the number. eg. '1','2'
    const videoNumber = video.name.match(/VID\d+/)?.[0];

    if (videoNumber === undefined) return result;

    if (!result[videoNumber]) result[videoNumber] = [];

    result[videoNumber].push(video);

    return result;
  }, {});

  Object.values(splitVideos).forEach(videolist => {
    videolist.sort((a, b) => b.bitRate - a.bitRate);
  });

  const highestBitRateVideos = Object.values(splitVideos).map(video => video[0]);

  return highestBitRateVideos.map(video => {
    const { name, x, y } = video;

    return {
      url: `${MEDIA_ROOT_URL()}/content/${name}`,
      ratio: x / y,
    };
  });
}

export function transformMediaDataToImageGalleryProps(
  mediaResponse: MediaResponse | undefined,
  productResponse: ProductResponse,
): ImageGalleryPropsToTransform {
  const isMultiColour = productResponse.colours && productResponse.colours.length > 1;
  const imageUrlList = isMultiColour ? getImageUrlList(mediaResponse) : getImageUrlList(productResponse.media);
  const videoList = isMultiColour ? getVideoList(mediaResponse) : getVideoList(productResponse.media);

  return {
    imageAltText: `${productResponse.brand?.name || ''} ${productResponse.productName}`,
    productImageUrlList: imageUrlList?.map(imgUrl => `${MEDIA_ROOT_URL()}/image/${imgUrl}`),
    productVideoList: videoList ? transformToProductVideos(videoList) : undefined,
    loading: Boolean(isMultiColour && mediaResponse === undefined),
  };
}

export const useTransformMediaDataToImageGalleryProps = (
  mediaResponse: MediaResponse | undefined,
  productResponse: ProductResponse,
) => {
  return useMemo(
    () => transformMediaDataToImageGalleryProps(mediaResponse, productResponse),
    [mediaResponse, productResponse],
  );
};
