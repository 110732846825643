import styled from 'styled-components';

import { color, typography } from '@selfridges-co/frontend-sdk-react-theme';

import { DISPLAY_FLAG_COLOUR_FOR_OUT_OF_STOCK_TEMPLATE } from '../constants';

import type { DisplayFlagType } from '../types';

export default styled.h4.withConfig<{ variant?: 'large' | 'small'; type: DisplayFlagType }>({
  shouldForwardProp: props => !['type', 'variant'].includes(String(props)),
})(({ variant = 'small', type }) => ({
  ...typography.typeface.display.xxs,
  color: color.palette.midGrey1,

  ...(variant === 'large' && {
    ...typography.typeface.display.xs,
  }),

  ...(type === 'PROJECT_EARTH' && {
    color: color.palette.successGreen,
  }),

  ...(type === 'OUT_OF_STOCK' && {
    color: DISPLAY_FLAG_COLOUR_FOR_OUT_OF_STOCK_TEMPLATE,
  }),
}));
