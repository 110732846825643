import { JSX } from 'react';
import styled from 'styled-components';

import { breakpoints, spacing } from '@selfridges-co/frontend-sdk-react-theme';

import { Item } from './Item';

import type { SelfridgesPlusItems } from '../types';

export type SelfridgesPlusProps = {
  items: SelfridgesPlusItems;
  countryLanguage: string;
  onAddToBag: (Message: JSX.Element) => void;
};

const Container = styled.div({
  padding: spacing(0, 4, 6, 4),

  [breakpoints.md.mediaQuery]: {
    padding: spacing(0, 6, 6, 6),
  },
});

function SelfridgesPlus({ items, countryLanguage, onAddToBag }: SelfridgesPlusProps) {
  const [primary, secondary] = items;

  return (
    <Container>
      <Item item={primary} countryLanguage={countryLanguage} onAddToBag={onAddToBag} />
      {secondary && <Item item={secondary} countryLanguage={countryLanguage} onAddToBag={onAddToBag} />}
    </Container>
  );
}

export default SelfridgesPlus;
