import { useMemo, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

import { getStaticResourceUrl } from '@selfridges-pkg/utils';
import { typography, spacing, color, breakpoints } from '@selfridges-co/frontend-sdk-react-theme';
import { Picture } from '@selfridges-co/frontend-sdk-react-scene7';
import { ButtonLink } from '@selfridges-co/frontend-sdk-react-button-link';
import { withVisibilityTracking } from '@selfridges-co/frontend-sdk-react-analytics-middleware';

import Price from '../price/Price';
import ProjectEarthIcon from '../project-earth/ProjectEarthIcon.svg';
import DisplayFlag from '../display-flag/DisplayFlag';

import type { BundleProduct } from '../types';
import type { PictureProps } from '@selfridges-co/frontend-sdk-react-scene7';

export interface CardProps extends BundleProduct {
  index: number;
  totalCards: number;
  onSelect: (partNumber: string) => void;
}

const CardWrapper = withVisibilityTracking<HTMLAttributes<HTMLDivElement>>(
  styled.div({
    width: '240px',
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    zIndex: 1,

    [breakpoints.md.mediaQuery]: {
      width: '330px',
    },
  }),
);

const ProductInfo = styled.div({
  boxSizing: 'border-box',
  padding: spacing(3),
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(2),
  background: color.palette.mainWhite,
  userSelect: 'none',
  flex: 1,
});

const BrandName = styled.h3({
  ...typography.typeface.display.xs,
  color: color.palette.mainBlack,
  textDecoration: 'none',
});

const ProductName = styled.p({
  ...typography.typeface.text.sm.regular,
  color: color.palette.darkGrey2,
  display: '-webkit-box',
  '-webkit-box-orient': 'vertical',
  '-webkit-line-clamp': '2',
  overflow: 'hidden',
});

const ProjectEarthLogo = styled.div({
  position: 'absolute',
  top: 0,
  left: 0,

  '::after': {
    content: '" "',
    width: '40px',
    height: '40px',
    position: 'absolute',
    margin: spacing(3),
    pointerEvents: 'none',
    top: 0,
    left: 0,
    backgroundImage: `url(${getStaticResourceUrl(ProjectEarthIcon)})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
});

const HoverPictureContainer = styled.div({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  opacity: 0,
  transition: 'opacity 0.3s',
  pointerEvents: 'none',
});

const PictureButton = styled.button({
  position: 'relative',
  aspectRatio: '3/4',
  userSelect: 'none',
  border: 'none',
  padding: 0,
  cursor: 'pointer',

  [breakpoints.md.mediaQuery]: {
    [`:hover > ${HoverPictureContainer}`]: {
      opacity: 1,
    },
  },
});

const CtaButton = styled(ButtonLink).attrs({ as: 'button' })({
  userSelect: 'none',
  cursor: 'pointer',
  border: 'none',
});

export default function Card({
  index,
  totalCards,
  productName,
  brand,
  displayFlag,
  isProjectEarth,
  isFinalPrice,
  price,
  onSelect,
  action,
  partNumber,
  selectedColour,
  getMedia,
}: CardProps) {
  const { t } = useTranslation();
  const productButtonText = useMemo(() => {
    switch (action) {
      case 'ADD_TO_BAG':
        return <Trans i18nKey="app.bundles.card.add-to-bag">Add to bag</Trans>;
      case 'SELECT_SIZE':
        return <Trans i18nKey="app.bundles.card.select-size">Select size</Trans>;
      case 'SELECT_COLOUR':
      default:
        return <Trans i18nKey="app.bundles.card.select-colour">Select colour</Trans>;
    }
  }, [action]);

  const { imageUrl, hoverImageUrl } = getMedia(selectedColour.display);

  const imageSharedProps: Pick<
    PictureProps,
    'devicePixelRatios' | 'quality' | 'aspectRatio' | 'intrinsicMinWidth' | 'intrinsicMinHeight'
  > = {
    aspectRatio: [3, 4],
    intrinsicMinWidth: 330,
    intrinsicMinHeight: 440,
    quality: 80,
    devicePixelRatios: [1.5, 2],
  };

  const pictureProps: PictureProps = {
    ...imageSharedProps,
    src: imageUrl,
    alt: productName,
  };

  const hoverPictureProps: PictureProps = {
    ...imageSharedProps,
    src: hoverImageUrl,
  };

  return (
    <CardWrapper
      aria-label={t('app.bundles.card.label', {
        defaultValue: 'Slide {{index}} of {{totalCards}}',
        index,
        totalCards,
      }).toString()}
      role="group"
      onClick={() => onSelect(partNumber)}
      data-analytics-component="bundle_carousel"
      data-analytics-link-target="bundle_cta"
      data-analytics-key={`bundle_cta_${partNumber}`}
    >
      <PictureButton data-analytics-link-target="bundle_cta">
        <Picture {...pictureProps} />
        <HoverPictureContainer>
          <Picture {...hoverPictureProps} />
        </HoverPictureContainer>
        {isProjectEarth && <ProjectEarthLogo />}
      </PictureButton>
      <ProductInfo>
        {brand && <BrandName>{brand.name}</BrandName>}
        {productName && <ProductName>{productName}</ProductName>}
        {price && <Price displayPrice={price} isFinal={isFinalPrice} variant="small" />}
        {displayFlag && (
          <DisplayFlag variant="small" type={isProjectEarth ? 'PROJECT_EARTH' : 'NORMAL'}>
            {displayFlag}
          </DisplayFlag>
        )}
      </ProductInfo>
      <CtaButton
        buttonType="primary"
        variant="black"
        fullWidth
        onClick={() => onSelect(partNumber)}
        data-analytics-link="bundle_cta"
        data-analytics-position={index}
        data-analytics-details={`${productName}||${partNumber}|${price?.raw.current}|${brand?.name}`}
      >
        {productButtonText}
      </CtaButton>
    </CardWrapper>
  );
}
