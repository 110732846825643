import { useCallback, useMemo } from 'react';

import {
  sanitiseStockAndPriceResponse,
  transformStockAndPriceResponse,
} from '../transformers/transformStockAndPriceResponse';

import type { StockAndPriceResponse } from '@selfridges-pkg/product-aggregation-service-api/contracts/StockAndPriceResponse';
import type { Price } from '@selfridges-pkg/product-aggregation-service-api/contracts/Price';
import type { AvailableCountryCode } from '@selfridges-pkg/intl/types';
import type { ColourInfos, GetSku, SelectedSizeValue, Sizes, StockAndPrice, StockAndPriceMap } from '../types';

function applyDataFix(size?: Nullable<SelectedSizeValue>) {
  if (size?.replace(/ /gi, '').toLowerCase() === '1size') {
    return { fixedSize: '' };
  }

  return { fixedSize: size };
}

export function getStockAndPriceFromMap({
  stockAndPriceMap,
  colour,
  size,
}: {
  stockAndPriceMap?: StockAndPriceMap;
  colour?: Nullable<string>;
  size?: Nullable<string>;
}): StockAndPrice {
  if (!stockAndPriceMap) return { availableStock: 'NOT_APPLICABLE', outOfStock: false };

  if (!Object.keys(stockAndPriceMap).length) return { availableStock: 0, outOfStock: true };

  if (colour === 'NOT_APPLICABLE' || colour === 'NOT_SELECTED') colour = '';
  if (size === 'NOT_APPLICABLE' || size === 'NOT_SELECTED') size = '';

  let dataKey = [colour, size].filter(Boolean).join('-');
  let stockAndPrice = stockAndPriceMap[dataKey];

  // Only apply fix for data if it is first attempt to get stock and price is failed
  if (!stockAndPrice) {
    const { fixedSize } = applyDataFix(size);

    if (fixedSize !== size) {
      dataKey = [colour, fixedSize].filter(Boolean).join('-');
      stockAndPrice = stockAndPriceMap[dataKey];
    }
  }

  // Mark as out of stock if no record is found
  if (!stockAndPrice) return { availableStock: 0, outOfStock: true };

  return stockAndPrice;
}

export function useSku(
  countryCode: AvailableCountryCode,
  stockAndPriceResponse?: StockAndPriceResponse,
  productPrice?: Price,
  colourInfos?: ColourInfos,
  sizes?: Sizes,
) {
  const stockAndPriceMap = useMemo(() => {
    if (!stockAndPriceResponse || !productPrice) return;

    return transformStockAndPriceResponse(stockAndPriceResponse, productPrice, countryCode, colourInfos, sizes);
  }, [countryCode, stockAndPriceResponse, productPrice, colourInfos, sizes]);

  const getSku = useCallback<GetSku>(
    ({ size, colour }) => getStockAndPriceFromMap({ stockAndPriceMap, colour, size }),
    [stockAndPriceMap],
  );

  const allOutOfStock = useMemo(() => {
    if (!stockAndPriceResponse) return true;

    return sanitiseStockAndPriceResponse(stockAndPriceResponse, countryCode, colourInfos, sizes).every(
      ({ availableStock }) => availableStock === 0,
    );
  }, [colourInfos, countryCode, sizes, stockAndPriceResponse]);

  return { getSku, stockAndPriceMap, allOutOfStock };
}
