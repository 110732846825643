import { RefObject, useEffect, useState } from 'react';

type DotStyle = {
  transform: string;
  borderWidth: string;
};

export const DOTS_AMOUNT_TO_SHOW = 7; // must be an odd number as the active dot needs to sit in the middle
export const DOT_WIDTH = 20;
const MIDDLE_RIGHT_DOT_INDEX = Math.ceil(DOTS_AMOUNT_TO_SHOW / 2);
const MIDDLE_LEFT_DOT_INDEX = Math.floor(DOTS_AMOUNT_TO_SHOW / 2);

const FULL_SIZE_DOT = 1;
export const MEDIUM_SIZE_DOT = 0.75;
export const SMALL_SIZE_DOT = 0.5;

const SMALL_DOT_STYLES: DotStyle = {
  transform: `scale(${SMALL_SIZE_DOT})`,
  borderWidth: `${1 / SMALL_SIZE_DOT}px`,
};

const MEDIUM_DOT_STYLES: DotStyle = {
  transform: `scale(${MEDIUM_SIZE_DOT})`,
  borderWidth: `${1 / MEDIUM_SIZE_DOT}px`,
};

const FULL_DOT_STYLES: DotStyle = {
  transform: `scale(${FULL_SIZE_DOT})`,
  borderWidth: '1px',
};

function addInlineCss(element: HTMLLIElement, styleObj: DotStyle) {
  if (!element) return;

  element.style.transform = styleObj.transform;
  (Array.from(element.children) as Array<HTMLLIElement>)[0].style.borderWidth = styleObj.borderWidth;
}

function scaleMiddleDots(dots: Array<HTMLLIElement>, currentSlideIndex: number, isScrollingLeft, sliderLength) {
  addInlineCss(dots[currentSlideIndex - (MIDDLE_LEFT_DOT_INDEX - 2)], FULL_DOT_STYLES);
  addInlineCss(dots[currentSlideIndex - (MIDDLE_LEFT_DOT_INDEX - 1)], MEDIUM_DOT_STYLES);
  addInlineCss(dots[currentSlideIndex - MIDDLE_LEFT_DOT_INDEX], SMALL_DOT_STYLES);
  addInlineCss(dots[currentSlideIndex], FULL_DOT_STYLES);
  addInlineCss(dots[currentSlideIndex + (MIDDLE_LEFT_DOT_INDEX - 2)], FULL_DOT_STYLES);
  addInlineCss(dots[currentSlideIndex + (MIDDLE_LEFT_DOT_INDEX - 1)], MEDIUM_DOT_STYLES);
  addInlineCss(dots[currentSlideIndex + MIDDLE_LEFT_DOT_INDEX], SMALL_DOT_STYLES);
  addInlineCss(dots[currentSlideIndex + MIDDLE_LEFT_DOT_INDEX + 1], SMALL_DOT_STYLES);

  if (isScrollingLeft) {
    // is moving forward
    if (currentSlideIndex === MIDDLE_RIGHT_DOT_INDEX) {
      addInlineCss(dots[currentSlideIndex - MIDDLE_LEFT_DOT_INDEX], MEDIUM_DOT_STYLES);
      addInlineCss(dots[currentSlideIndex - (MIDDLE_LEFT_DOT_INDEX - 1)], FULL_DOT_STYLES);
    }
  } else {
    // is moving backward
    if (sliderLength - 1 - currentSlideIndex === MIDDLE_RIGHT_DOT_INDEX) {
      addInlineCss(dots[currentSlideIndex + MIDDLE_LEFT_DOT_INDEX], MEDIUM_DOT_STYLES);
      addInlineCss(dots[currentSlideIndex + (MIDDLE_LEFT_DOT_INDEX - 1)], FULL_DOT_STYLES);
    }
  }
}

function scaleInitialDots(dots) {
  Array(DOTS_AMOUNT_TO_SHOW)
    .fill(null)
    .forEach((_, index) => {
      const dot = dots[index];
      addInlineCss(dot, FULL_DOT_STYLES);

      if (index === DOTS_AMOUNT_TO_SHOW - 2) addInlineCss(dot, MEDIUM_DOT_STYLES);
      if (index === DOTS_AMOUNT_TO_SHOW - 1) addInlineCss(dot, SMALL_DOT_STYLES);
    });
}

function scaleFinalDots(dots, imagesLength) {
  const dotIndex = imagesLength - 1;
  Array(DOTS_AMOUNT_TO_SHOW)
    .fill(null)
    .forEach((_, index) => {
      addInlineCss(dots[dotIndex - index], FULL_DOT_STYLES);

      if (index === DOTS_AMOUNT_TO_SHOW - 2) addInlineCss(dots[dotIndex - index], MEDIUM_DOT_STYLES);
      if (index === DOTS_AMOUNT_TO_SHOW - 1) addInlineCss(dots[dotIndex - index], SMALL_DOT_STYLES);
    });
}

export function useAnimatedDots({
  dotsRef,
  imagesLength,
  currentSlideIndex,
  isScrollingLeft,
}: {
  dotsRef: RefObject<HTMLUListElement>;
  imagesLength: number;
  currentSlideIndex: number;
  isScrollingLeft: boolean;
}) {
  const [dotsLeftPos, setDotsLeftPos] = useState(0);

  useEffect(() => {
    if (!dotsRef.current || imagesLength <= DOTS_AMOUNT_TO_SHOW) return;

    const dots = Array.from(dotsRef.current.children) as Array<HTMLLIElement>;

    // at the start of the gallery
    if (currentSlideIndex < MIDDLE_RIGHT_DOT_INDEX) {
      scaleInitialDots(dots);
      setDotsLeftPos(0);
      return;
    }

    // only moves dots when current index is not at the end of the gallery
    if (imagesLength - currentSlideIndex >= MIDDLE_RIGHT_DOT_INDEX) {
      setDotsLeftPos((currentSlideIndex * DOT_WIDTH - DOT_WIDTH * MIDDLE_LEFT_DOT_INDEX) * -1);
    }

    // at the end of the gallery
    if (imagesLength - 1 - currentSlideIndex < MIDDLE_RIGHT_DOT_INDEX) {
      scaleFinalDots(dots, imagesLength);
      return;
    }

    // in the middle of the gallery
    scaleMiddleDots(dots, currentSlideIndex, isScrollingLeft, imagesLength);
  }, [currentSlideIndex, dotsRef, imagesLength, isScrollingLeft]);

  return { dotsLeftPos };
}
