import { useEffect } from 'react';

import type { ColourInfos, SelectedColour, SelectedSize, SetSelectedSize, StockAndPriceMap } from '../types';

declare global {
  interface Window {
    fitAnalyticsData: {
      shopLanguage: string;
      shopCountry: string;
      mainImageLink: string;
      userId: string;
      currentItemSubgroupId: string;
      allItemSubgroupIds: Array<string>;
      shopSessionId: string;
      sizes: Array<{ value: string; isAvailable: boolean }>;
      operations: {
        getAddToCartBtn?: () => HTMLElement | null;
        getSizeChartLink?: () => HTMLElement | null;
        getCurrentSize: () => string | null;
        getCartItems: () => string;
        addToCart: (currentItemSubgroupId: any, size: any) => void;
        selectSize: (currentItemSubgroupId: any, size: any) => void;
      };
    } | null;
  }
}

type useFitAnalyticsDataLayerProps = {
  enabled: boolean;
  countryCode: string;
  language: string;
  mainImageUrl: string;
  partNumber: string;
  colourInfos?: ColourInfos;
  selectedSize: SelectedSize;
  selectSize: SetSelectedSize;
  selectedColour: SelectedColour;
  stockAndPriceMap?: StockAndPriceMap;
};

function getUserId(windowUtagData: any): string {
  if (!windowUtagData || windowUtagData.visitor_status !== 'logged in' || windowUtagData.wcs_user_id === '-1002') {
    return '';
  }

  return windowUtagData.wcs_user_id;
}

/* istanbul ignore next */
function getSizes({
  stockAndPriceMap,
  selectedColour,
}: {
  stockAndPriceMap?: StockAndPriceMap;
  selectedColour: SelectedColour;
}) {
  if (!stockAndPriceMap) return [];

  return Object.values(stockAndPriceMap)
    .filter(item => {
      // Remove stock and price items that have no size
      if (!item.size) return false;

      // if product has no colour, get stock and price items with colour undefined
      if (selectedColour.value === 'NOT_APPLICABLE' && !item.colour) return true;

      // Get stock and price items that have matching selected colours
      return item.colour === selectedColour.display;
    })
    .map(item => ({
      value: item.size || '',
      isAvailable: Boolean(item.availableStock),
    }));
}

export function useFitAnalyticsDataLayer({
  enabled,
  countryCode,
  language,
  mainImageUrl,
  partNumber,
  colourInfos,
  selectedColour,
  selectedSize,
  selectSize,
  stockAndPriceMap,
}: useFitAnalyticsDataLayerProps) {
  useEffect(() => {
    const windowUtag = window.utag as any;

    if (!enabled || !windowUtag?.data) {
      window.fitAnalyticsData = null;
      return;
    }

    window.fitAnalyticsData = {
      shopLanguage: language, // ISO 639-1, e.g. 'en'
      shopCountry: countryCode, // ISO 3166-1, e.g. 'GB'
      mainImageLink: mainImageUrl, // URL to thumbnail to be displayed within the widget
      userId: getUserId(windowUtag.data),
      currentItemSubgroupId: `${partNumber}-${selectedColour.display}`, // current color-level ID
      allItemSubgroupIds: colourInfos?.map(colour => `${partNumber}-${colour.name}`) || [], // all color-level IDs on the page
      shopSessionId: windowUtag.data['cp._ga'], // shop session id: a first-party client generated session ID (can be a cookie) [we use it to track the purchase & keep our data persistent]
      sizes: getSizes({ stockAndPriceMap, selectedColour }),
      // helper functions or paths to functions (relative to `window`) to
      // select DOM elements or execute operations.
      operations: {
        getAddToCartBtn: function (): HTMLElement | null {
          /* istanbul ignore next */
          return document.getElementById('primary-add-to-bag-btn');
        },
        getSizeChartLink: function (): HTMLElement | null {
          /* istanbul ignore next */
          return document.getElementById('size-guide-btn');
        },
        /**
         * This function should add the given size to cart for the given product id (“currentItemSubgroupId” - color level)
         */
        addToCart: function (currentItemSubgroupId: string, size: string) {
          /* istanbul ignore next */
          selectSize(size);
          /* istanbul ignore next */
          document.getElementById('primary-add-to-bag-btn')?.click();
        },
        /**
         * Select the given size for the given product id (“currentItemSubgroupId” - color level)
         */
        selectSize: function (currentItemSubgroupId: string, size: string): void {
          /* istanbul ignore next  */
          selectSize(size);
        },
        getCurrentSize: function (): string {
          /* istanbul ignore next */
          return selectedSize.display;
        },
        /**
         * This is required for implementing the Multiple-Sizes-Alert (MSA) feature
         * @returns {Promise|Object|Array} all current cart items
         */
        getCartItems: function () {
          /* istanbul ignore next */
          return windowUtag.data.product_sku;
        },
      },
    };

    // Dispatch event to execute Fit Finder once fit analytics data is ready
    window.dispatchEvent(new CustomEvent('FIT_ANALYTICS_DATA_READY'));
  }, [
    partNumber,
    colourInfos,
    selectedSize,
    selectedColour,
    countryCode,
    enabled,
    language,
    mainImageUrl,
    selectSize,
    stockAndPriceMap,
  ]);
}
