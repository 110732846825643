import { BrandInfo, Breadcrumbs } from '../types';

import type { QuickLinksProps } from '@selfridges-co/frontend-sdk-react-quick-links';

type MoreFromPropsToTransform = Partial<QuickLinksProps> & Pick<QuickLinksProps, 'links'>;

const MAX_NUM_OF_BREADCRUMBS = 6;

export function transformToMoreFromProps({
  brandInfo,
  breadcrumbs,
}: {
  brandInfo: BrandInfo;
  breadcrumbs: Breadcrumbs;
}): MoreFromPropsToTransform {
  const [firstBreadcrumb, ...remainingBreadcrumbs] = breadcrumbs;
  const firstLink = firstBreadcrumb ? [{ text: firstBreadcrumb.title, url: firstBreadcrumb.url }] : [];

  return {
    links: [
      ...firstLink,
      ...(brandInfo.name
        ? [
            {
              text: brandInfo.name,
              url: brandInfo.url,
            },
          ]
        : []),
      ...remainingBreadcrumbs.map(({ title, url }) => ({ text: title, url })),
    ].slice(0, MAX_NUM_OF_BREADCRUMBS),
  };
}
