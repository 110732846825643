import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { countryInfo } from '@selfridges-pkg/intl';
import { formatPrice } from '@selfridges-pkg/intl/formatPrice';

import type { AvailableCurrency, AvailableCountryCode } from '@selfridges-pkg/intl/types';
import type { DeliveryOption as DeliveryOptionFromResponse } from '@selfridges-pkg/product-aggregation-service-api/contracts/Product';
import type { ProductResponse } from '@selfridges-pkg/product-aggregation-service-api/contracts/ProductResponse';
import type { DeliveryAndReturnsProps } from '../delivery-and-returns/DeliveryAndReturns';
import type { DeliveryMethod, DeliveryOption } from '../types';

type DeliveryAndReturnsPropsToTransform = Partial<DeliveryAndReturnsProps> &
  Pick<DeliveryAndReturnsProps, 'localDelivery' | 'internationalDelivery' | 'clickAndCollect' | 'returns'>;

export function useTransformProductDataToDeliveryAndReturnsProps(
  productResponse: ProductResponse,
  countryLanguage: string,
): DeliveryAndReturnsProps {
  const { t } = useTranslation();

  const groupDeliveryMethods = useCallback(
    (deliveryMethods: Array<DeliveryMethod>, region: string) => {
      if (deliveryMethods.length === 1) return deliveryMethods[0];

      return {
        ...deliveryMethods[0],
        label:
          region === 'UK'
            ? t('app.delivery-and-returns.delivery.method.grouped.label.uk', {
                defaultValue: 'Delivery to the UK',
              }).toString()
            : t('app.delivery-and-returns.delivery.method.grouped.label.other', {
                defaultValue: 'Delivery to {{region}}',
                region,
              }).toString(),
        cost: t('app.delivery-and-returns.delivery.method.grouped.cost', {
          defaultValue: 'from {{cost}}',
          cost: deliveryMethods[0].cost,
        }).toString(),
      };
    },
    [t],
  );

  const getSelfridgesPlusMessage = useCallback(
    (deliveryType: 'local' | 'international', deliveryOptions: Array<DeliveryOption>, currentCountry) => {
      const selfridgesPlusPriceForEurope = productResponse.selfridgesPlus?.find(
        item => item.id.toLowerCase() === 'ukeurope',
      )?.price;
      const selfridgesPlusForEurope = {
        label: t('app.delivery-and-returns.delivery.selfridges-plus.europe', {
          defaultValue: 'Selfridges+ UK and Europe:<br />unlimited deliveries for a year',
        }).toString(),
        cost: selfridgesPlusPriceForEurope
          ? formatPrice(
              selfridgesPlusPriceForEurope.current,
              selfridgesPlusPriceForEurope.currency as AvailableCurrency,
            )
          : null,
      };

      const selfridgesPlusPriceForGlobal = productResponse.selfridgesPlus?.find(
        item => item.id.toLowerCase() === 'global',
      )?.price;
      const selfridgesPlusForGlobal = {
        label: t('app.delivery-and-returns.delivery.selfridges-plus.global', {
          defaultValue: 'Selfridges+ Global:<br />unlimited deliveries for a year',
        }).toString(),
        cost: selfridgesPlusPriceForGlobal
          ? formatPrice(
              selfridgesPlusPriceForGlobal.current,
              selfridgesPlusPriceForGlobal.currency as AvailableCurrency,
            )
          : null,
      };

      if (deliveryType === 'local') {
        return currentCountry.continent === 'Europe' ? selfridgesPlusForEurope : selfridgesPlusForGlobal;
      }

      const onlyContainUKDelivery = deliveryOptions.every(({ region }) => region === 'UK');
      if (onlyContainUKDelivery) return selfridgesPlusForEurope;
      if (currentCountry.continent === 'Europe') return selfridgesPlusForGlobal;

      return selfridgesPlusForEurope;
    },
    [productResponse.selfridgesPlus, t],
  );

  const groupDeliveryOptions = useCallback(
    (deliveryOptions: Array<DeliveryOptionFromResponse> | null, currentCountry, lang: string) => {
      const internationalDelivery: Array<DeliveryOption> = [];
      const localDelivery: Array<DeliveryOption> = [];
      const clickAndCollect: { isAvailable: boolean; cutoffTimeRaw: string | null } = {
        isAvailable: false,
        cutoffTimeRaw: null,
      };

      deliveryOptions?.forEach(({ methods, region }) => {
        // remove delivery method if label is empty
        const sanitisedMethods = methods.filter(({ label }) => Boolean(label));
        if (!sanitisedMethods.length) return;

        const isClickAndCollect = sanitisedMethods[0].label.replace(/[^a-z]/gi, '').toUpperCase() === 'NA';

        if (isClickAndCollect) {
          clickAndCollect.isAvailable = true;
          clickAndCollect.cutoffTimeRaw = sanitisedMethods[0].cutoffTime;
          return;
        }

        const deliveryMethods: Array<DeliveryMethod> = sanitisedMethods.map(({ label, cutoffTime, shippingCost }) => {
          return {
            label: t('app.delivery-and-returns.delivery.method.label', {
              defaultValue: '{{region}} {{label}}',
              region: region.toUpperCase() === 'UK' ? '' : region,
              label,
            })
              .toString()
              .trim(),
            cost: shippingCost,
            ...(cutoffTime && {
              cutoffTimeRaw: cutoffTime,
              cutoffTime: t('app.delivery-and-returns.delivery.method.cutoff-time', {
                defaultValue: ' - order by {{cutoffTime}}',
                cutoffTime,
              }).toString(),
            }),
          };
        });

        if (currentCountry[lang] === region || currentCountry.alt === region) {
          localDelivery.push({ region, methods: deliveryMethods });
        } else {
          const groupedDeliveryMethod = groupDeliveryMethods(deliveryMethods, region);
          internationalDelivery.push({ region, methods: [groupedDeliveryMethod] });
        }
      });

      return { localDelivery, internationalDelivery, clickAndCollect };
    },
    [t, groupDeliveryMethods],
  );

  return useMemo((): DeliveryAndReturnsPropsToTransform => {
    const [countryCode, lang] = countryLanguage.split('/');
    const { deliveryOptions, returnsInfo } = productResponse;

    const currentCountry = countryInfo[countryCode as AvailableCountryCode];
    const { localDelivery, internationalDelivery, clickAndCollect } = groupDeliveryOptions(
      deliveryOptions,
      currentCountry,
      lang,
    );

    const {
      title: returnsTitle,
      copy: returnsCopy,
      termsAndConditions: returnsTnC,
      internationalCopy: returnsIntlCopy,
    } = returnsInfo;

    return {
      localDelivery: {
        title:
          currentCountry.alt === 'UK'
            ? t('app.delivery-and-returns.delivery.local.title.uk', { defaultValue: 'UK Delivery' })
            : t('app.delivery-and-returns.delivery.local.title.other', {
                defaultValue: 'Delivery To {{ country }}',
                country: currentCountry[lang],
              }),

        unavailableMessage: t('app.delivery-and-returns.delivery.local.unavailable-message', {
          defaultValue: 'Sorry, this product is unavailable for {{ country }} Delivery',
          country: currentCountry.alt || currentCountry[lang],
        }),
        options: localDelivery,
        selfridgesPlus: getSelfridgesPlusMessage('local', localDelivery, currentCountry),
      },
      internationalDelivery: {
        title: t('app.delivery-and-returns.delivery.international.title', { defaultValue: 'International Delivery' }),
        unavailableMessage: t('app.delivery-and-returns.delivery.international.unavailable-message', {
          defaultValue: 'Sorry, this product is unavailable for International Delivery',
        }),
        options: internationalDelivery,
        selfridgesPlus: getSelfridgesPlusMessage('international', internationalDelivery, currentCountry),
      },
      clickAndCollect,
      returns: {
        ...(returnsTitle && { title: returnsTitle }),
        ...(returnsCopy && { description: returnsCopy }),
        ...(returnsTnC && { termsAndConditions: returnsTnC }),
        ...(returnsIntlCopy && currentCountry.alt !== 'UK' && { internationalDescription: returnsIntlCopy }),
      },
    };
  }, [countryLanguage, productResponse, t, groupDeliveryOptions, getSelfridgesPlusMessage]);
}
