import { transformToStockAndPriceMap } from './transformToStockAndPriceMap';
import { getPriceByCountryCode } from './helpers';

import type { StockAndPriceResponse } from '@selfridges-pkg/product-aggregation-service-api/contracts/StockAndPriceResponse';
import type { StockAndPrice as StockAndPriceFromResponse } from '@selfridges-pkg/product-aggregation-service-api/contracts/StockAndPrice';
import type { Price } from '@selfridges-pkg/product-aggregation-service-api/contracts/Price';
import type { AvailableCountryCode } from '@selfridges-pkg/intl/types';
import type { ColourInfos, Sizes, StockAndPriceMap } from '../types';
import type { SanitisedSku } from './transformToStockAndPriceMap';

function sanitiser(
  result: Array<SanitisedSku>,
  { skuId, prices, availableStock, colour: skuColour, size: skuSize }: StockAndPriceFromResponse,
  countryCode: AvailableCountryCode,
  colourInfos?: ColourInfos,
  sizes?: Sizes,
): Array<SanitisedSku> {
  // Remove item if price is missing
  const localePrice = getPriceByCountryCode(prices, countryCode);
  if (!localePrice || !localePrice.current) return result;

  const gbpPrice = getPriceByCountryCode(prices, 'GB');

  // Remove item if sku colour is not in colour list
  if (colourInfos?.every(colourInfo => colourInfo.name.toLowerCase() !== skuColour?.toLowerCase())) return result;

  // Remove item if sku size is not in size list except for 1size since we have a data fix later on for 1size
  if (
    sizes?.every(
      size => size.toLowerCase() !== skuSize?.toLowerCase() && size.replace(/ /g, '').toLowerCase() !== '1size',
    )
  ) {
    return result;
  }

  // Remove item if sku has size but no size available from product
  if (!sizes?.length && skuSize) return result;

  // Remove item if sku has colour but no colour available from product
  if (!colourInfos?.length && skuColour) return result;

  if (availableStock === null) availableStock = 0;

  result.push({
    skuId,
    price: localePrice,
    gbpPrice,
    availableStock,
    colour: skuColour,
    size: skuSize,
  });

  return result;
}

export function sanitiseStockAndPriceResponse(
  response: StockAndPriceResponse,
  countryCode: AvailableCountryCode,
  colourInfos?: ColourInfos,
  sizes?: Sizes,
): Array<SanitisedSku> {
  return response.reduce((acc: Array<SanitisedSku>, item) => sanitiser(acc, item, countryCode, colourInfos, sizes), []);
}

/**
 * Apply a temporary fix to commerce promotional price.
 * Since there is a limitation with WCS price API that only returns markdown price
 * while promotion price only returns via mashery product api.
 * This function helps combine data from price api and product api to work out the promotional price.
 * Reference: https://selfridges.atlassian.net/browse/AM-1442
 */
function handleCommercePromotion(sanitisedSkus: Array<SanitisedSku>, productPrice: Price): Array<SanitisedSku> {
  if (!sanitisedSkus.length) return [];

  const skuCurrentPrice = sanitisedSkus[0].price.current;

  // requirement 1: use promotion price when it is a single price product
  const isSinglePrice = sanitisedSkus.every(sku => sku.price.current === skuCurrentPrice);
  if (!isSinglePrice) return sanitisedSkus;

  return sanitisedSkus.map(sku => {
    // requirement 2: use promotion price if there is no mark down price returned in any skus
    if (sku.price.was || sku.price.wasWas) return sku;

    // requirement 3: use promotion price if product `was` price equals sku current price
    if (sku.price.current !== productPrice?.was) return sku;

    // replace sku price with product price
    sku.price.current = productPrice.current;
    sku.price.was = productPrice.was;
    sku.price.wasWas = productPrice.wasWas;

    return sku;
  });
}

export function transformStockAndPriceResponse(
  response: StockAndPriceResponse,
  productPrice: Price,
  countryCode: AvailableCountryCode,
  colourInfos?: ColourInfos,
  sizes?: Sizes,
): StockAndPriceMap {
  let sanitisedList = sanitiseStockAndPriceResponse(response, countryCode, colourInfos, sizes);

  sanitisedList = handleCommercePromotion(sanitisedList, productPrice).sort(
    (a, b) => a.price.current - b.price.current,
  );

  return transformToStockAndPriceMap(sanitisedList);
}
