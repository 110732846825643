import { useState, useMemo, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import { typography, spacing, color, breakpoints } from '@selfridges-co/frontend-sdk-react-theme';
import { Picture, PictureProps } from '@selfridges-co/frontend-sdk-react-scene7';

import { SuccessMessage } from '../product-add-to-bag/SuccessMessage';
import { ErrorMessage } from '../product-add-to-bag/ErrorMessage';
import { useToastMessage } from '../hooks/useToastMessage';
import SizeList from '../size-selector/SizeList';
import { ColourList } from '../colour-selector/ColourList';
import { useAddToCart } from '../hooks/useAddToCart';
import Price from '../price/Price';
import { bundlePanelAddToBagTracking, bundlePanelAvailabilityTracking, bundlePanelViewTracking } from '../analytics';

import type { BundleProduct, CountryLanguage } from '../types';

type PanelProps = {
  parentPartNumber: string;
  bundleProduct: BundleProduct;
  countryLanguage: CountryLanguage;
  addItemToWishList: ({ skuId, partNumber }: { skuId?: string; partNumber: string }) => Promise<void>;
};

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  minHeight: 0,
});

const PanelContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  minHeight: 0,
});

const Product = styled.div<{ showDivider: boolean }>(({ showDivider }) => ({
  position: 'relative',
  boxShadow: showDivider
    ? `0px 4px 20px ${color.palette.mainBlack}11, 0px -1px 0px inset ${color.palette.lightGrey1}`
    : '',
  zIndex: 2,
  transition: 'box-shadow 0.1s',
}));

const ProductDetails = styled.div({
  position: 'relative',
  display: 'flex',
  zIndex: 2,
  padding: spacing(0, 4, 4, 4),

  [breakpoints.md.mediaQuery]: {
    padding: spacing(0, 6, 6, 6),
  },
});

const BuyContainer = styled.div({
  paddingLeft: spacing(4),
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'space-between',

  [breakpoints.md.mediaQuery]: {
    paddingLeft: spacing(6),
  },
});

const ToastContainer = styled.div({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  transform: 'translateY(100%)',
  boxSizing: 'border-box',
  padding: spacing(0, 4),

  [breakpoints.md.mediaQuery]: {
    padding: spacing(0, 6),
  },
});

const ErrorToastContainer = styled.div({
  margin: spacing(0, 4),

  [breakpoints.md.mediaQuery]: {
    margin: spacing(0, 6),
  },
});

const ProductInformation = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(2),
});

const BrandName = styled.a({
  ...typography.typeface.text.md.bold,
  color: color.palette.mainBlack,
  textDecoration: 'none',
  contain: 'inline-size',
  display: 'block',
  textWrap: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const ProductImage = styled(Picture)({
  width: '107px',
});

const ProductName = styled.p({
  ...typography.typeface.text.sm.regular,
  color: color.palette.darkGrey2,
  display: '-webkit-box',
  '-webkit-box-orient': 'vertical',
  '-webkit-line-clamp': '1',
  overflow: 'hidden',
});

const Title = styled.h2({
  ...typography.typeface.display.md,
  color: color.palette.mainBlack,
  width: '100%',
  boxSizing: 'border-box',
  background: color.palette.mainWhite,
  zIndex: 2,
  padding: spacing(4, 6, 4, 4),
  paddingRight: `calc(${spacing(4)} + 3.2rem)`,
  border: `0px solid ${color.palette.lightGrey1}`,
  transition: 'border-bottom-width .1s linear',

  [breakpoints.md.mediaQuery]: {
    padding: spacing(6),
    paddingRight: `calc(${spacing(6)} + 3.2rem)`,
  },
});

const Selector = styled.div({
  overflowY: 'auto',
});

export default function Panel({ parentPartNumber, bundleProduct, countryLanguage, addItemToWishList }: PanelProps) {
  const [selectedColour, setSelectedColour] = useState(bundleProduct.selectedColour);
  const [selectedSize, setSelectedSize] = useState(bundleProduct.selectedSize);
  const [pageViewFired, setPageViewFired] = useState(false);

  const [showDivider, setShowDivider] = useState(false);
  const showDividerRef = useRef(false);
  const selectorRef = useRef<HTMLDivElement>(null);

  const { queueMessage: successQueueMessage, Message: SuccessToast } = useToastMessage({
    messageLifeTime: 3000,
    messageAnimationOverflow: 'contain',
  });
  const { queueMessage: errorQueueMessage, Message: ErrorToast } = useToastMessage({
    messageLifeTime: 3000,
    messageAnimationOverflow: 'contain',
  });

  useEffect(() => {
    const contentEl = selectorRef.current;
    if (!contentEl) return;

    function onScroll(event: Event) {
      if ((event.target as HTMLDivElement).scrollTop === 0 && showDividerRef.current) {
        showDividerRef.current = false;
        setShowDivider(false);
      } else if (!showDividerRef.current) {
        showDividerRef.current = true;
        setShowDivider(true);
      }
    }

    contentEl.addEventListener('scroll', onScroll);

    return () => {
      contentEl.removeEventListener('scroll', onScroll);
    };
  }, []);

  const panelTitleText = useMemo(() => {
    switch (bundleProduct.action) {
      case 'ADD_TO_BAG':
        return <Trans i18nKey="app.bundles.panel.title.add-to-bag">Add to bag</Trans>;
      case 'SELECT_SIZE':
        return <Trans i18nKey="app.bundles.panel.title.select-size">Select size</Trans>;
      case 'SELECT_COLOUR':
      default:
        return <Trans i18nKey="app.bundles.panel.title.select-colour">Select colour</Trans>;
    }
  }, [bundleProduct.action]);

  const { skuId, price, isFinalPrice, gbpPrice } = bundleProduct.getSku({
    size: selectedSize.display,
    colour: selectedColour.display,
  });

  useEffect(() => {
    if (pageViewFired) return;

    bundlePanelViewTracking({
      parentPartNumber,
      productSize: selectedSize.display,
      productColour: selectedColour.display,
      skuId,
      skuPrice: price,
      gbpPrice,
      bundleProduct,
    });
    setPageViewFired(true);
  }, [bundleProduct, gbpPrice, pageViewFired, price, selectedColour.display, selectedSize.display, skuId]);

  useEffect(() => {
    if (!pageViewFired) return;

    bundlePanelAvailabilityTracking({
      parentPartNumber,
      skuId,
      skuPrice: price,
      gbpPrice,
      bundleProduct,
      productColour: selectedColour.display,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bundleProduct, pageViewFired, selectedColour]);

  const { AddToCartButton, error } = useAddToCart({
    skuId,
    partNumber: bundleProduct.partNumber,
    quantity: 1,
    countryLanguage: countryLanguage.toString(),
    onSuccess: () => {
      bundlePanelAddToBagTracking({
        parentPartNumber,
        productSize: selectedSize.display,
        productColour: selectedColour.display,
        productQuantity: 1,
        skuId,
        skuPrice: price,
        gbpPrice,
        bundleProduct,
      });

      successQueueMessage(
        <SuccessMessage
          selectedColour={selectedColour.display}
          selectedSize={selectedSize.display}
          quantity={1}
          brandName={bundleProduct.brand?.name || ''}
          productDescription={bundleProduct.productName || ''}
          countryLanguage={countryLanguage.toString()}
        />,
      );
    },
    onError: ({ errorKey, errorMessage }) => {
      errorQueueMessage(
        <ErrorMessage
          errorKey={errorKey}
          errorMessage={errorMessage}
          addToWishList={() => {
            addItemToWishList({ skuId: skuId, partNumber: bundleProduct.partNumber });
          }}
        />,
      );
    },
  });

  useEffect(() => {
    if (error.noSkuProvided) {
      errorQueueMessage(<ErrorMessage errorKey={'SELECT_SIZE'} />);
    }
  }, [error, errorQueueMessage]);

  const { outOfStock } = bundleProduct.getSku({
    colour: selectedColour.value,
    size: selectedSize.value,
  });

  const pictureProps: PictureProps = {
    src: bundleProduct.getMedia(selectedColour.display).imageUrl,
    aspectRatio: [3, 4],
    intrinsicMinWidth: 107,
    intrinsicMinHeight: 132,
    alt: bundleProduct.productName,
  };

  return (
    <Wrapper>
      <Title>{panelTitleText}</Title>
      <PanelContainer>
        <Product showDivider={showDivider}>
          <ProductDetails>
            <a
              target="_blank"
              href={bundleProduct.productUrl}
              aria-label={t('app.bundles.panel.product-image-link.aria-label', {
                defaultValue: 'Navigate to product {{productName}} (open in a new tab)',
                productName: `${bundleProduct.brand?.name} - ${bundleProduct.productName}`,
              }).toString()}
            >
              <ProductImage {...pictureProps} />
            </a>
            <BuyContainer>
              <ProductInformation>
                {bundleProduct.brand && (
                  <BrandName target="_blank" href={bundleProduct.brand.url}>
                    {bundleProduct.brand.name}
                  </BrandName>
                )}
                {bundleProduct.productName && <ProductName>{bundleProduct.productName}</ProductName>}
                <Price displayPrice={price} isFinal={isFinalPrice} />
              </ProductInformation>
              <AddToCartButton
                statusText={{
                  idle: <Trans i18nKey="app.bundles.panel.add-to-bag.idle-text">Add to bag</Trans>,
                  loading: <Trans i18nKey="app.bundles.panel.add-to-bag.loading-text">Add to bag</Trans>,
                  success: <Trans i18nKey="app.bundles.panel.add-to-bag.success-text">Item added to bag</Trans>,
                }}
                aria-label={t('app.bundles.panel.add-to-bag.aria-label', {
                  defaultValue: 'Add to bag',
                }).toString()}
                disabled={outOfStock}
              />
            </BuyContainer>
          </ProductDetails>
          <div>
            <ToastContainer>{SuccessToast}</ToastContainer>
            <ErrorToastContainer>{ErrorToast}</ErrorToastContainer>
          </div>
        </Product>
        <Selector ref={selectorRef}>
          {bundleProduct.action === 'SELECT_SIZE' && bundleProduct.sizes && (
            <SizeList
              sizes={bundleProduct.sizes}
              selectedColour={selectedColour}
              selectedSize={selectedSize}
              getSku={bundleProduct.getSku}
              onSelect={size => setSelectedSize({ value: size, display: size })}
            />
          )}
          {bundleProduct.action === 'SELECT_COLOUR' && bundleProduct.colours && (
            <ColourList
              colours={bundleProduct.colours}
              selectedColour={selectedColour}
              selectedSize={bundleProduct.selectedSize}
              getSku={bundleProduct.getSku}
              onSelect={colour => {
                setSelectedColour({ value: colour, display: colour, source: 'USER_CLICK_FROM_COLOUR_LIST' });
              }}
            />
          )}
        </Selector>
      </PanelContainer>
    </Wrapper>
  );
}
