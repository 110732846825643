import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { color, typography } from '@selfridges-co/frontend-sdk-react-theme';

type CountdownTimerProps = {
  hours: number;
  minutes: number;
  seconds: number;
};

const TimerWrapper = styled.span({
  ...typography.typeface.text.sm.bold,
  color: color.palette.warningRed,
});

export default function CountdownTimer({ hours, minutes, seconds }: CountdownTimerProps) {
  const { t } = useTranslation();

  return (
    <TimerWrapper>
      {Boolean(hours) && <span>{t('app.sales-prop.countdown-timer.time-count.hours', { count: hours })}</span>}
      {Boolean(minutes) && <span>{t('app.sales-prop.countdown-timer.time-count.minutes', { count: minutes })}</span>}
      {!Boolean(hours) && <span>{t('app.sales-prop.countdown-timer.time-count.seconds', { count: seconds })}</span>}
    </TimerWrapper>
  );
}
