import styled from 'styled-components';
import { Trans } from 'react-i18next';

import { ButtonLink, ButtonLinkOwnProps } from '@selfridges-co/frontend-sdk-react-button-link';

type CompleteTheSetButtonProps = {
  scrollToTarget: Nullable<HTMLDivElement>;
};

const Button = styled(ButtonLink).attrs({ as: 'button' })<ButtonLinkOwnProps>({
  border: 'none',
  cursor: 'pointer',
});

export default function CompleteTheSetButton({ scrollToTarget }: CompleteTheSetButtonProps) {
  function handleClick() {
    if (!scrollToTarget) return null;

    scrollToTarget.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }

  return (
    <Button buttonType="secondary" onClick={handleClick} fullWidth>
      <Trans i18nKey="app.bundles.complete-the-set-button.text">Complete the set</Trans>
    </Button>
  );
}
