import { useMemo, useState } from 'react';

import type { Sizes, SelectedSize, SelectedSizeValue } from '../types';

export function useSelectedSize(sizes?: Sizes) {
  function setInitialState() {
    if (!sizes) {
      return 'NOT_APPLICABLE';
    } else if (sizes.length === 1) {
      return sizes[0];
    } else {
      return 'NOT_SELECTED';
    }
  }
  const [size, setSize] = useState<SelectedSizeValue>(setInitialState());

  const sizeInfo: SelectedSize = useMemo(() => {
    return {
      value: size,
      display: ['NOT_APPLICABLE', 'NOT_SELECTED'].includes(size) ? '' : size,
    };
  }, [size]);

  return { selectedSize: sizeInfo, setSelectedSize: setSize };
}
