import React from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

import { typography, spacing, color, breakpoints } from '@selfridges-co/frontend-sdk-react-theme';

import { StockMessage } from '../stock-message/StockMessage';
import { AvailableStock } from '../types';

const Wrapper = styled.div({
  margin: spacing(8, 0),
});

const Box = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  border: `solid 1px ${color.palette.midGrey2}`,
  boxSizing: 'border-box',
  width: '11.3rem',
  height: '4.8rem',
});

const Icon = styled.button({
  background: 'none',
  backgroundPosition: 'center',
  border: '1px solid transparent',
  borderRadius: '50%',
  boxSizing: 'border-box',
  cursor: 'pointer',
  height: '3.2rem',
  width: '3.2rem',
  padding: '0',
  transition: 'opacity 0.2s',

  [breakpoints.md.mediaQuery]: {
    transition: 'all ease-out 0.1s',
  },

  '@media (hover: hover)': {
    ':hover': {
      borderColor: color.palette.midGrey2,
      backgroundColor: color.palette.midGrey2,
    },
  },

  ':active': {
    borderColor: color.palette.midGrey2,
    backgroundColor: 'transparent',
  },

  ':disabled': {
    opacity: '0.25',
    cursor: 'default',
    borderColor: 'transparent',

    ':hover': {
      backgroundColor: 'initial',
    },

    ':active': {
      backgroundColor: 'initial',
    },
  },
});

const MinusIcon = styled(Icon)({
  backgroundImage: `url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23212121' d='M6 12H18' strokeLinecap='round' /%3E%3C/svg%3E")`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  marginLeft: spacing(2),
});

const PlusIcon = styled(Icon)({
  backgroundImage: `url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23212121' d='M6 12H18' strokeLinecap='round' /%3E%3Cpath stroke='%23212121' d='M12 6V18' strokeLinecap='round' /%3E%3C/svg%3E")`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  marginRight: spacing(2),
});

const Title = styled.div({
  display: 'inline-flex',
  alignItems: 'baseline',
});

const QuantityLabel = styled.h4({
  ...typography.typeface.display.xs,
  display: 'inline-flex',
  alignItems: 'baseline',
  margin: spacing(0, 0, 3, 0),
});

const QuantityAmount = styled.span({
  ...typography.typeface.text.md.regular,
  marginLeft: spacing(2),
});

const BoxLabel = styled.p({
  ...typography.typeface.text.md.regular,
});

export type QuantityProps = {
  purchaseLimit: number;
  quantity: number;
  updateQuantity: (amount: number) => void;
  availableStock: AvailableStock;
};

function Quantity({ quantity, updateQuantity, purchaseLimit, availableStock }: QuantityProps): React.ReactElement {
  const { t } = useTranslation();

  function handleIncreaseClick() {
    updateQuantity(quantity + 1);
  }

  function handleDecreaseClick() {
    updateQuantity(quantity - 1);
  }

  return (
    <Wrapper>
      <Title>
        <QuantityLabel>
          <Trans i18nKey="app.product-panel.quantity.label">
            Quantity: <QuantityAmount>{{ quantity }}</QuantityAmount>
          </Trans>
        </QuantityLabel>
        <StockMessage stockAmount={availableStock} />
      </Title>
      <Box>
        <MinusIcon
          onClick={handleDecreaseClick}
          aria-label={t('app.product-panel.quantity.decrease', { defaultValue: 'decrease' }).toString()}
          disabled={quantity <= 1}
        />
        <BoxLabel data-testid="box-label-quantity">{quantity}</BoxLabel>
        <PlusIcon
          onClick={handleIncreaseClick}
          aria-label={t('app.product-panel.quantity.increase', { defaultValue: 'increase' }).toString()}
          disabled={quantity >= purchaseLimit}
        />
      </Box>
    </Wrapper>
  );
}

export default Quantity;
