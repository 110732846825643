import { formatPrice } from '@selfridges-pkg/intl/formatPrice';

import { transformPriceWithCurrency } from './transformPriceWithCurrency';

import type { Price } from '@selfridges-pkg/product-aggregation-service-api/contracts/Price';
import type { AvailableCurrency } from '@selfridges-pkg/intl/types';
import type { AvailableStock, StockAndPrice, StockAndPriceMap } from '../types';

export type SanitisedSku = {
  skuId: string;
  price: Price;
  gbpPrice?: Price;
  availableStock: number;
  colour: Nullable<string>;
  size: Nullable<string>;
};

function transformSkuItem(skus: Array<SanitisedSku>, colour?: string, size?: string): StockAndPrice {
  const lowestPrice = skus[0].price;
  let lowestGbpPrice = skus[0].gbpPrice;
  let availableStock: AvailableStock = 'NOT_APPLICABLE';
  let skuId: string | undefined;

  if (skus.length === 1) {
    availableStock = skus[0].availableStock;
    skuId = skus[0].skuId;
  } else {
    availableStock = skus.some(sku => sku.availableStock !== 0) ? 'NOT_APPLICABLE' : 'ALL_OUT_OF_STOCK';
  }

  if (!lowestGbpPrice && lowestPrice.currency === 'GBP') {
    lowestGbpPrice = lowestPrice;
  }

  return {
    skuId,
    colour,
    size,
    availableStock,
    outOfStock: availableStock !== 'NOT_APPLICABLE' && (availableStock === 0 || availableStock === 'ALL_OUT_OF_STOCK'),
    isFinalPrice: lowestPrice.current === skus[skus.length - 1].price.current,
    price: transformPriceWithCurrency(lowestPrice),
    ...(lowestGbpPrice && {
      gbpPrice: {
        currency: lowestGbpPrice.currency,
        current: formatPrice(lowestGbpPrice.current, lowestGbpPrice.currency as AvailableCurrency),
        raw: {
          current: lowestGbpPrice.current,
        },
      },
    }),
  };
}

export function transformToStockAndPriceMap(skuList: Array<SanitisedSku>) {
  return skuList.reduce((acc: StockAndPriceMap, item: SanitisedSku) => {
    if (item.colour) {
      const filteredList = skuList.filter(sku => sku.colour === item.colour);
      acc[item.colour] = transformSkuItem(filteredList, item.colour);
    }

    if (item.size) {
      const filteredList = skuList.filter(sku => sku.size === item.size);
      acc[item.size] = transformSkuItem(filteredList, undefined, item.size);
    }
    if (item.colour && item.size) {
      const filteredList = skuList.filter(sku => sku.size === item.size && sku.colour === item.colour);
      acc[`${item.colour}-${item.size}`] = transformSkuItem(filteredList, item.colour, item.size);
    }

    // For when product has no colour and no size provided by the API or selected by user
    acc[''] = transformSkuItem(skuList);

    return acc;
  }, {});
}
