import { MouseEvent } from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

import { spacing, typography } from '@selfridges-co/frontend-sdk-react-theme';

import { Link } from '../wish-list/Link';

type OutOfStockErrorMessageProps = {
  onClick: (event?: MouseEvent<HTMLElement>) => void;
};

const ErrorText = styled.p({
  ...typography.typeface.text.md.bold,
});

const CTAContainer = styled.div({
  flexShrink: 0,
  marginLeft: spacing(4),
});

export function OutOfStockErrorMessage({ onClick }: OutOfStockErrorMessageProps) {
  return (
    <>
      <ErrorText>
        <Trans i18nKey="app.add-to-cart.error.out-of-stock">Sorry, this item is now out of stock</Trans>
      </ErrorText>
      <CTAContainer>
        <Link onClick={onClick} />
      </CTAContainer>
    </>
  );
}
