export default function env(varName: string): string | undefined {
  try {
    return typeof window !== 'undefined' && window['__ENV__'] && typeof window['__ENV__'][varName] !== 'undefined'
      ? window['__ENV__'][varName]
      : process?.env[varName];
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(`Error getting environment variable - ${varName}`, err);
    return undefined;
  }
}
