import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { breakpoints, color, spacing, typography } from '@selfridges-co/frontend-sdk-react-theme';

import type { GetSku, SelectedColour, SelectedSize, Size } from '../types';

export type SizeGridProps = {
  sizes: Array<Size>;
  getSku: GetSku;
  selectedSize: SelectedSize;
  selectedColour: SelectedColour;
  onSelect: (size: string) => void;
  hideOnMobile: boolean;
  hideOnDesktop: boolean;
};

const GRID_GAP = { VERTICAL: 3, HORIZONTAL: 2 };

const Wrapper = styled.div<{ hideOnMobile: boolean; hideOnDesktop: boolean }>(({ hideOnMobile, hideOnDesktop }) => ({
  width: 'calc(100% + 2.4rem)',
  marginLeft: spacing(-3),
  display: hideOnMobile ? 'none' : 'block',

  [breakpoints.md.mediaQuery]: {
    display: hideOnDesktop ? 'none' : 'block',
    width: 'auto',
    margin: 0,
  },
}));

const Grid = styled.div({
  display: 'grid',
  gridAutoFlow: 'row',
  gridAutoColumns: '8rem',
  gridAutoRows: '4.8rem',
  gridGap: spacing(GRID_GAP.HORIZONTAL),
  padding: spacing(0, 3),
  gridTemplateColumns: 'repeat(3, 1fr)',

  [breakpoints.sm.mediaQuery]: {
    gridTemplateColumns: 'repeat(6, 1fr)',
  },

  [breakpoints.md.mediaQuery]: {
    gridAutoColumns: '4.8rem',
    gridTemplateColumns: 'repeat(8, 1fr)',
    gridGap: spacing(GRID_GAP.VERTICAL, GRID_GAP.HORIZONTAL),
    padding: 0,
  },
});

const RadioInput = styled.input({
  cursor: 'pointer',
  width: '100%',
  height: '100%',
  appearance: 'none',
  opacity: 1,
  position: 'absolute',
  top: 0,
  left: 0,
});

const SizeContainer = styled.label<{ selected: boolean; outOfStock: boolean }>(({ selected, outOfStock }) => ({
  ...typography.typeface.text.md.regular,
  backgroundColor: color.palette.mainWhite,
  border: `1px solid ${color.palette.midGrey2}`,
  cursor: 'pointer',
  width: '100%',
  height: '100%',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxSizing: 'border-box',

  ...(selected && {
    backgroundColor: color.palette.mainBlack,
    color: color.palette.mainWhite,
    borderColor: color.palette.mainBlack,
  }),

  '@media (hover: hover)': {
    ':hover': {
      transition: 'all ease-out 0.2s',
      backgroundColor: color.palette.mainBlack,
      color: color.palette.mainWhite,
      borderColor: color.palette.mainBlack,
    },
  },

  ...(outOfStock && {
    color: color.palette.midGrey1,
    borderColor: color.palette.lightGrey1,
    background: `linear-gradient(to bottom right,
      ${color.palette.lightGrey2} calc(50% - 0.5px),
      ${color.palette.lightGrey1} calc(50% - 0.5px),
      ${color.palette.lightGrey1} calc(50% + 0.5px),
      ${color.palette.lightGrey2} calc(50% + 0.5px))`,

    '@media (hover: hover)': {
      ':hover': {
        transition: 'all ease-out 0.2s',
        backgroundColor: color.palette.mainBlack,
        color: color.palette.midGrey1,
        borderColor: color.palette.mainBlack,
      },
    },

    ...(selected && {
      color: color.palette.mainWhite,
      borderColor: color.palette.mainBlack,
      background: `linear-gradient(to bottom right,
      ${color.palette.mainBlack} calc(50% - 0.5px),
      ${color.palette.lightGrey1} calc(50% - 0.5px),
      ${color.palette.lightGrey1} calc(50% + 0.5px),
      ${color.palette.mainBlack} calc(50% + 0.5px))`,

      '@media (hover: hover)': {
        ':hover': {
          color: color.palette.mainWhite,
        },
      },
    }),
  }),
}));

function SizeGrid({
  sizes,
  getSku,
  selectedSize,
  selectedColour,
  onSelect,
  hideOnMobile,
  hideOnDesktop,
}: SizeGridProps) {
  const { t } = useTranslation();

  function handleClick(event: React.MouseEvent<HTMLInputElement>) {
    // event.detail === 0 when interact via keyboard
    if (event.detail) {
      onSelect(event.currentTarget.value);
    }
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    onSelect(event.currentTarget.value);
  }

  return (
    <Wrapper hideOnDesktop={hideOnDesktop} hideOnMobile={hideOnMobile}>
      <Grid>
        {sizes.map(size => {
          const selected = size === selectedSize.value;
          const { outOfStock } = getSku({ size, colour: selectedColour.value });

          return (
            <SizeContainer key={size} selected={selected} outOfStock={outOfStock}>
              <RadioInput
                type="radio"
                name="size-grid-radio"
                onChange={handleChange}
                onClick={handleClick}
                value={size}
                checked={selected}
                aria-label={t('app.product-panel.size-grid.size-label', {
                  defaultValue: 'Size {{size}}',
                  size,
                }).toString()}
              />
              {size}
            </SizeContainer>
          );
        })}
      </Grid>
    </Wrapper>
  );
}

export default SizeGrid;
