import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

import { spacing, color, typography, breakpoints } from '@selfridges-co/frontend-sdk-react-theme';

import Card from './Card';

import type { BundleProduct } from '../types';

interface CarouselProps {
  title?: string;
  onCardSelect: (partNumber: string) => void;
  bundles: Array<BundleProduct>;
}

const BUTTON_SIZE = '3.2rem';

const Title = styled.h3({
  ...typography.typeface.display.md,
  marginLeft: spacing(6),
  marginBottom: spacing(4),

  [breakpoints.md.mediaQuery]: {
    marginLeft: spacing(16),
  },

  [breakpoints.lg.mediaQuery]: {
    marginLeft: spacing(32),
  },
});

const ArrowButton = styled.button({
  display: 'none',
  backgroundColor: color.palette.mainBlack,
  zIndex: 2,
  top: '50%',
  pointerEvents: 'none',
  fontSize: 0,

  ':hover': {
    backgroundColor: color.palette.midGrey2,
  },

  ':active': {
    border: `1px solid ${color.palette.midGrey2}`,
    backgroundColor: color.palette.lightGrey1,
  },

  [breakpoints.md.mediaQuery]: {
    border: 'none',
    width: BUTTON_SIZE,
    height: BUTTON_SIZE,
    borderRadius: BUTTON_SIZE,
    display: 'block',
    position: 'absolute',
    opacity: 0.0,
    transition: 'all 0.1s',
    cursor: 'pointer',
    pointerEvents: 'auto',
  },

  '::after': {
    content: '" "',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.5 6H0.5L6 11.5'/%3E%3Cpath d='M15.5 6H0.5L6 0.5'/%3E%3C/svg%3E")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
});

const PreviousButton = styled(ArrowButton)({
  left: spacing(6),

  [breakpoints.md.mediaQuery]: {
    left: `calc(${spacing(16)} - ${BUTTON_SIZE} - ${spacing(3)})`,
  },

  [breakpoints.lg.mediaQuery]: {
    left: `calc(${spacing(32)} - ${BUTTON_SIZE} - ${spacing(3)})`,
  },
});

const NextButton = styled(ArrowButton)({
  left: 'auto',
  right: spacing(6),
  '::after': {
    transform: 'rotate(180deg)',
  },
});

const Container = styled.section<{ displayNextButton: boolean; displayPreviousButton: boolean }>(
  ({ displayNextButton, displayPreviousButton }) => ({
    position: 'relative',
    paddingTop: spacing(6),
    paddingBottom: spacing(6),
    backgroundColor: color.palette.lightGrey2,

    [`:hover > ${NextButton}`]: {
      opacity: displayNextButton ? 1 : 0,
      pointerEvents: displayNextButton ? 'auto' : 'none',
    },
    [`:hover > ${PreviousButton}`]: {
      opacity: displayPreviousButton ? 1 : 0,
      pointerEvents: displayPreviousButton ? 'auto' : 'none',
    },

    [breakpoints.md.mediaQuery]: {
      '::after': {
        content: '""',
        height: `calc(100% - ${spacing(6)} - 8px)`,
        width: '100%',
        background: 'transparent',
        display: 'block',
        position: 'absolute',
        top: 0,
        left: 0,
        cursor: 'default',
      },
    },
  }),
);

const ScrollContainer = styled.div({
  paddingBottom: spacing(5),
  display: 'flex',
  gap: spacing(4),
  overflowX: 'scroll',
  paddingLeft: spacing(6),
  paddingRight: spacing(6),
  cursor: 'pointer',

  '::-webkit-scrollbar': {
    display: 'none',
  },

  [breakpoints.md.mediaQuery]: {
    overflowX: 'scroll',
    gap: spacing(6),
    scrollbarWidth: 'thin',
    scrollbarColor: `${color.palette.mainBlack} ${color.palette.midGrey2}`,
    marginLeft: spacing(16),
    paddingLeft: 0,
    paddingRight: spacing(6),

    '::-webkit-scrollbar': {
      display: 'block',
      height: '10px',
      width: '4px',
      position: 'absolute',
      bottom: '0px',
      left: spacing(3),
      background: color.palette.midGrey2,

      borderTop: `3px solid ${color.palette.lightGrey2}`,
      borderBottom: `3px solid ${color.palette.lightGrey2}`,
    },

    '::-webkit-scrollbar-thumb': {
      background: color.palette.mainBlack,
      borderTop: `3px solid ${color.palette.lightGrey2}`,
      borderBottom: `3px solid ${color.palette.lightGrey2}`,
    },

    '::-webkit-scrollbar-corner': {
      background: '#000',
    },

    '::-webkit-scrollbar-button:end': {
      height: `calc(${spacing(6)} / 2)`,
      width: `calc(${spacing(6)} / 2)`,
      background: color.palette.lightGrey2,
    },
  },

  [breakpoints.lg.mediaQuery]: {
    marginLeft: spacing(32),
  },
});

export default function Carousel({ bundles, title, onCardSelect }: CarouselProps) {
  const carousel = useRef<HTMLDivElement>(null);
  const [displayNextButton, setDisplayNextButton] = useState(false);
  const [displayPreviousButton, setDisplayPreviousButton] = useState(false);

  useEffect(() => {
    if (!carousel.current) return;
    const currentCarouselEl = carousel.current;

    function scrollHandler() {
      const scrollLeft = currentCarouselEl.scrollLeft || 0;
      const width = currentCarouselEl.offsetWidth || 0;
      const scrollWidth = currentCarouselEl.scrollWidth || 0;

      if (scrollLeft <= 0) {
        setDisplayPreviousButton(false);
      } else if (!displayPreviousButton) {
        setDisplayPreviousButton(true);
      }

      if (scrollLeft >= scrollWidth - width) {
        setDisplayNextButton(false);
      } else if (!displayNextButton) {
        setDisplayNextButton(true);
      }
    }

    if (
      currentCarouselEl.scrollWidth >= currentCarouselEl.offsetWidth &&
      currentCarouselEl.scrollLeft < currentCarouselEl.scrollWidth - currentCarouselEl.offsetWidth &&
      !displayNextButton
    ) {
      setDisplayNextButton(true);
    }

    currentCarouselEl.addEventListener('scroll', scrollHandler);

    return () => {
      currentCarouselEl.removeEventListener('scroll', scrollHandler);
    };
  }, [displayNextButton, displayPreviousButton]);

  const handleSelectCard = (partNumber: string) => {
    onCardSelect(partNumber);
  };

  const handleNextButtonClick = () => {
    if (!carousel.current) return;

    const scrollLeft = carousel.current.scrollLeft;

    if (
      scrollLeft + carousel.current.offsetWidth >
      carousel.current.scrollWidth - (carousel.current.children[0] as HTMLElement).offsetWidth / 2
    ) {
      carousel.current.scrollTo({ left: carousel.current.scrollWidth, behavior: 'smooth' });
      return;
    }

    for (const child of Array.from(carousel.current.children)) {
      const childElement = child as HTMLElement;
      const childRightSide = childElement.offsetLeft + childElement.offsetWidth;
      const lastElement = carousel.current.children[carousel.current.children.length - 1] as HTMLElement;
      const marginSizePx = carousel.current.scrollWidth - (lastElement.offsetLeft + lastElement.offsetWidth);

      if (childRightSide - childElement.offsetWidth / 2 > scrollLeft + carousel.current.offsetWidth) {
        carousel.current.scrollTo({
          left: childRightSide - carousel.current.offsetWidth + marginSizePx,
          behavior: 'smooth',
        });

        break;
      }
    }
  };

  const handlePreviousButtonClick = () => {
    if (!carousel.current) return;

    const scrollLeft = carousel.current.scrollLeft;
    const marginSizePx = (carousel.current.children[0] as HTMLElement).offsetLeft;

    if (scrollLeft < (carousel.current.children[0] as HTMLElement).offsetWidth) {
      carousel.current.scrollTo({ left: 0, behavior: 'smooth' });
      return;
    }

    for (const child of Array.from(carousel.current.children).reverse()) {
      const childElement = child as HTMLElement;
      const childLeftSide = childElement.offsetLeft;

      if (childLeftSide - marginSizePx + childElement.offsetWidth / 2 < scrollLeft) {
        carousel.current.scrollTo({ left: childLeftSide - marginSizePx, behavior: 'smooth' });
        break;
      }
    }
  };

  return (
    <Container
      displayNextButton={displayNextButton}
      displayPreviousButton={displayPreviousButton}
      aria-labelledby="bundle-carousel-title"
    >
      {title && <Title id="bundle-carousel-title">{title}</Title>}
      <PreviousButton onClick={handlePreviousButtonClick}>
        <Trans i18nKey="app.bundles.carousel.previous-button-text">Previous</Trans>
      </PreviousButton>
      <ScrollContainer ref={carousel}>
        {bundles.map((productProps, index) => {
          return (
            <Card
              key={productProps.partNumber}
              index={index + 1}
              totalCards={bundles.length}
              {...productProps}
              onSelect={handleSelectCard}
            />
          );
        })}
      </ScrollContainer>
      <NextButton onClick={handleNextButtonClick}>
        <Trans i18nKey="app.bundles.carousel.next-button-text">Next</Trans>
      </NextButton>
    </Container>
  );
}
