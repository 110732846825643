import { useEffect, useState, useRef } from 'react';

type Status = 'INACTIVE' | 'ACTIVE' | 'COMPLETE';
export type EndTime<T, P> = { time: number; label: T; context?: P };

interface useCountdownTimerProps<T, P> {
  startTime: number;
  endTimes: Array<EndTime<T, P>>;
}

export function useCountdownTimer<T, P>({ startTime, endTimes }: useCountdownTimerProps<T, P>) {
  const [duration, setDuration] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [status, setStatus] = useState<Status>('INACTIVE');
  const [label, setLabel] = useState<T | 'ACTIVE'>('ACTIVE');
  const [context, setContext] = useState<P | undefined>();

  const targetTime = useRef(0);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | undefined = undefined;

    if (!startTime || !endTimes.length) return;

    const currentEndTime = endTimes.find(endTime => Date.now() < endTime.time);
    if (currentEndTime) {
      targetTime.current = currentEndTime.time;
      setLabel(currentEndTime.label);
      setContext(currentEndTime.context);
    }

    const updateCountdown = () => {
      clearTimeout(timeoutId);

      const currentDateEpoch = Date.now();
      if (currentDateEpoch >= targetTime.current) {
        const currentEndTime = endTimes.find(endTime => Date.now() < endTime.time);
        if (currentEndTime) {
          targetTime.current = currentEndTime.time;
          setLabel(currentEndTime.label);
          setContext(currentEndTime.context);
        } else {
          setStatus('COMPLETE');
        }
      }

      const timeDifference = Math.floor((targetTime.current - currentDateEpoch) / 1000);

      const newHours = Math.floor(timeDifference / 3600);

      const newMinutes = Math.floor((timeDifference % 3600) / 60);
      const newSeconds = timeDifference % 60;

      timeoutId = setTimeout(updateCountdown, 1000);

      setDuration({
        hours: Math.max(newHours, 0),
        minutes: Math.max(newMinutes, 0),
        seconds: Math.max(newSeconds, 0),
      });
    };

    if (startTime <= Date.now()) {
      updateCountdown();
      setStatus('ACTIVE');
    } else {
      clearTimeout(timeoutId);
      setStatus('COMPLETE');
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [startTime, endTimes, setStatus, setLabel, setContext]);

  return { duration, status, label, context };
}
