import { SizeGuideResponse } from './contracts/SizeGuideResponse';

export async function fetchSizeGuide({
  hostRootUrl,
  countryLanguage,
  category,
  brand,
  additionalHeaders,
}: {
  hostRootUrl: string;
  countryLanguage: string;
  category: string;
  brand: string;
  additionalHeaders?: Record<string, string>;
}): Promise<SizeGuideResponse> {
  const response = await fetch(
    `${hostRootUrl}/${countryLanguage}/product/size-guide/category/${category}/brand/${brand}`,
    {
      headers: { ...additionalHeaders },
    },
  );

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message);
  }

  return response.json();
}
