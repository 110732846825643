import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

import { typography, spacing, color } from '@selfridges-co/frontend-sdk-react-theme';

import { StockMessage } from '../stock-message/StockMessage';

import Swatch from './Swatch';

import type { ColourInfos, GetSku, SelectedColour, SelectedSize } from '../types';

interface ColourDropdownProps {
  colours: ColourInfos;
  selectedSize: SelectedSize;
  selectedColour: SelectedColour;
  getSku: GetSku;
  onClick: () => void;
  showSelectPromptMessage: boolean;
}

const Wrapper = styled.div({
  margin: spacing(8, 0),
});

const Title = styled.div({
  display: 'flex',
  alignItems: 'baseline',
  marginBottom: spacing(3),
});

const ColourAvailabilityText = styled.h4({
  ...typography.typeface.display.xs,
  display: 'inline-block',
  marginRight: spacing(2),
});

const Dropdown = styled.button({
  ...typography.typeface.text.md.regular,
  background: 'none',
  border: `1px solid ${color.palette.midGrey2}`,
  color: color.palette.mainBlack,
  padding: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'left',
  cursor: 'pointer',
});

const DropdownText = styled.div({
  display: 'inline-flex',
  alignItems: 'baseline',
  flexGrow: 1,
  padding: spacing(3),
  whiteSpace: 'nowrap',
  minWidth: 0,
});

const DropDownGroup = styled.div<{ outOfStock?: boolean }>(({ outOfStock }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  flexGrow: 1,
  minWidth: 0,

  ...(outOfStock && {
    opacity: 0.5,
  }),
}));

const ChevronDown = styled.span({
  backgroundImage: `url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M7 10L12 15L17 10' stroke='%23212121' stroke-linecap='round'/%3e%3c/svg%3e")`,
  backgroundRepeat: 'no-repeat',
  width: '24px',
  height: '24px',
  margin: spacing(3),
});

const PromptMessage = styled.span({
  ...typography.typeface.text.md.regular,
  lineHeight: 0,
  color: color.palette.warningRed,
  textDecoration: 'underline',
});

function ColourDropdown({
  colours,
  getSku,
  selectedColour,
  selectedSize,
  onClick,
  showSelectPromptMessage,
}: ColourDropdownProps) {
  const { t } = useTranslation();
  const colour = colours.find(({ name }) => name === selectedColour.value);
  const { availableStock, outOfStock } = getSku({ colour: selectedColour.value, size: selectedSize.value });

  return (
    <Wrapper>
      <Title>
        <ColourAvailabilityText>
          <Trans i18nKey="app.colour-selector.colour-dropdown.colours-available-heading" count={colours.length}>
            {{ count: colours.length }} Colour available:
          </Trans>
        </ColourAvailabilityText>
        {showSelectPromptMessage && (
          <PromptMessage>
            <Trans i18nKey="app.colour-selector.colour-dropdown.select-colour-prompt">Please select a colour</Trans>
          </PromptMessage>
        )}
      </Title>
      <Dropdown
        role="button"
        value={colour?.name}
        aria-label={t('app.colour-selector.colour-dropdown.label', { defaultValue: 'Choose colour' }).toString()}
        {...(colours.length > 1 && { onClick })}
      >
        {colour ? (
          <DropDownGroup outOfStock={outOfStock}>
            <Swatch
              src={colour.swatchUrl}
              key={colour.swatchUrl}
              alt={colour.name}
              outOfStock={outOfStock}
              pixelSize="40px"
            />
            <DropdownText>
              {colour.name} <StockMessage stockAmount={availableStock} />
            </DropdownText>
          </DropDownGroup>
        ) : (
          <DropdownText>
            <Trans i18nKey="app.colour-selector.colour-dropdown.text">Choose colour</Trans>
          </DropdownText>
        )}
        {colours.length > 1 && <ChevronDown />}
      </Dropdown>
    </Wrapper>
  );
}

export default ColourDropdown;
