import { GetWishListResponse } from './contracts/GetWishListResponse';
import { AddItemResponse } from './contracts/AddItemResponse';
import { DeleteItemResponse } from './contracts/DeleteItemResponse';
import { updateSession } from './updateSession';

interface CommonProps {
  hostRootUrl: string;
  countryLanguage: string;
  headers: Record<string, string>;
  partNumber: string;
}

const credentialsOption = 'same-origin';

export async function fetchWishListItems({
  hostRootUrl,
  countryLanguage,
  headers,
  partNumber,
}: CommonProps): Promise<GetWishListResponse | null> {
  await updateSession({ hostRootUrl, headers, countryLanguage });

  const [, language] = countryLanguage.split('/');

  const response = await fetch(
    `${hostRootUrl}/api/cms/ecom/v1/${countryLanguage}/wishlist?language=${language}&partNumber=${partNumber}`,
    {
      method: 'GET',
      cache: 'no-cache',
      credentials: credentialsOption,
      headers,
    },
  );

  if (response.status === 404) return null;

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message);
  }

  return response.json();
}

interface AddWishListItemProps extends CommonProps {
  method: 'PUT' | 'POST';
}

export async function addWishListItem({
  hostRootUrl,
  countryLanguage,
  partNumber,
  headers,
  method,
}: AddWishListItemProps): Promise<AddItemResponse> {
  await updateSession({ hostRootUrl, headers, countryLanguage });

  const response = await fetch(`${hostRootUrl}/api/cms/ecom/v1/${countryLanguage}/wishlist`, {
    method,
    cache: 'no-cache',
    body: JSON.stringify({ partNumber }),
    credentials: credentialsOption,
    headers,
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message);
  }

  return response.json();
}

export async function deleteWishListItem({
  hostRootUrl,
  countryLanguage,
  partNumber,
  headers,
}: CommonProps): Promise<DeleteItemResponse> {
  const response = await fetch(`${hostRootUrl}/api/cms/ecom/v1/${countryLanguage}/wishlist?partNumber=${partNumber}`, {
    method: 'DELETE',
    cache: 'no-cache',
    credentials: credentialsOption,
    headers,
  });

  if (!response.ok) {
    throw new Error('Fail to delete item from wish list');
  }

  return response.json();
}
