import log, {
  LoggingMethod,
  LogLevelNumbers,
  MethodFactory,
  RootLogger,
  Logger,
  LogLevel,
  LogLevelDesc,
  LogLevelNames,
} from 'loglevel'; //loads log into the global namespace

type Groups = { [key: string]: Group };
type LoggersList = { [key: string]: string };
type GroupsList = { [key: string]: string };
type LogTable = { [key: string]: { level: string } };

const originalFactory: MethodFactory = log.methodFactory;
function prefixLoggerName(
  methodName: LogLevelNames,
  logLevel: LogLevelNumbers,
  loggerName: string | symbol,
): LoggingMethod {
  const rawMethod: LoggingMethod = originalFactory(methodName, logLevel, loggerName);

  return function (...args): LoggingMethod {
    const messages: Array<string | symbol> = [`${String(loggerName)}:`, ...args];
    rawMethod(...messages);
    return rawMethod;
  };
}

export class Group {
  children: { [key: string]: Logger };

  constructor() {
    this.children = {};
  }

  addChild(name: string, child: Logger) {
    this.children[name] = child;
  }

  setLevel(level: LogLevelDesc) {
    Object.values(this.children).forEach(loggerInstance => loggerInstance.setLevel(level));
  }
}

export class Loggers {
  children: { [key: string]: Logger };

  constructor() {
    this.children = {};
  }

  addChild(id: string, child: Logger) {
    this.children[id] = child;
  }

  setLevel(level: LogLevelDesc) {
    Object.values(this.children).forEach(loggerInstance => loggerInstance.setLevel(level));
  }
}

export class LoggerApi {
  #coreLogger: CoreLogger;
  #loggers: Loggers;
  #groups: Groups;
  #levels: LogLevel;

  constructor(coreLogger: CoreLogger) {
    this.#coreLogger = coreLogger;
    this.#loggers = this.#coreLogger.loggers;
    this.#groups = this.#coreLogger.groups;
    this.#levels = this.#coreLogger.log.levels;
  }

  private setLevel(
    key: Array<string> | string,
    target: { [key: string]: Logger } | Groups,
    level: LogLevelNumbers,
  ): void {
    if (typeof key === 'string') {
      target[key].setLevel(level);
    }

    if (Array.isArray(key)) {
      key.forEach(childKey => target[childKey].setLevel(level));
    }
  }

  enable(loggerIds: Array<string> | string): void {
    this.setLevel(loggerIds, this.#loggers.children, this.#levels.DEBUG);
  }

  enableGroup(groupNames: Array<string> | string): void {
    this.setLevel(groupNames, this.#groups, this.#levels.DEBUG);
  }

  enableAll(): void {
    // loglevel.enableAll() only works on page refresh
    this.#loggers.setLevel(this.#levels.DEBUG);
  }

  disable(loggerIds: Array<string> | string): void {
    this.setLevel(loggerIds, this.#loggers.children, this.#levels.SILENT);
  }

  disableGroup(groupNames: Array<string> | string): void {
    this.setLevel(groupNames, this.#groups, this.#levels.SILENT);
  }

  disableAll(): void {
    // loglevel.disableAll() only works on page refresh
    this.#loggers.setLevel(this.#levels.SILENT);
  }

  // list all availible logging functions and their current level
  status(groupName?: string): void {
    let loggers: Array<[string, Logger]>;
    if (groupName) {
      loggers = Object.entries(this.#groups[groupName].children);
    } else {
      loggers = Object.entries(this.#loggers.children);
    }

    const logTable: LogTable = loggers.reduce((acc: LogTable, curr: [string, Logger]): LogTable => {
      const [loggerName, loggerInstance] = curr;

      return {
        ...acc,
        [loggerName]: {
          level: Object.keys(this.#levels).find(key => this.#levels[key] === loggerInstance.getLevel()) as string,
        },
      };
    }, {});
    /* eslint-disable */
    console.table(logTable);
  }

  get loggers(): LoggersList {
    return this.#coreLogger.loggersList;
  }

  get groups(): GroupsList {
    return this.#coreLogger.groupsList;
  }

  get levels(): LogLevel {
    return this.#levels;
  }

  get coreLogger(): CoreLogger {
    return this.#coreLogger;
  }
}

export class CoreLogger {
  #log: RootLogger;
  #loggers: Loggers;
  #groups: Groups;
  #loggersList: LoggersList;
  #groupsList: GroupsList;

  constructor() {
    this.#log = log.noConflict();
    this.#loggers = new Loggers();
    this.#groups = {};
    this.#loggersList = {};
    this.#groupsList = {};

    // attach a prefix to the logs
    log.methodFactory = prefixLoggerName;
  }

  get(groupName: string, loggerName: string): Logger {
    const loggerId = `${groupName}-${loggerName}`;
    const loggerInstance: Logger = this.#log.getLogger(loggerId);

    if (typeof this.#groups[groupName] === 'undefined') {
      this.#groups[groupName] = new Group();
    }

    this.#loggers.addChild(loggerId, loggerInstance);
    this.#groups[groupName].addChild(loggerName, loggerInstance);

    this.addLoggerToList(loggerId);
    this.addGroupToList(groupName);

    return loggerInstance;
  }

  private addGroupToList(groupName: string): void {
    this.#groupsList[groupName] = groupName;
  }

  private addLoggerToList(loggerName: string): void {
    this.#loggersList[loggerName] = loggerName;
  }

  get loggersList() {
    return this.#loggersList;
  }

  get groupsList() {
    return this.#groupsList;
  }

  get loggers(): Loggers {
    return this.#loggers;
  }

  get groups(): Groups {
    return this.#groups;
  }

  get log(): RootLogger {
    return this.#log;
  }
}

//check if another loger instance already exists
function createCoreLogger(): CoreLogger {
  if (typeof window !== 'undefined') {
    if (window['logger']) return window['logger'].coreLogger;

    const coreLogger = new CoreLogger();
    const loggerApi = new LoggerApi(coreLogger);

    window['logger'] = loggerApi;

    return coreLogger;
  } else {
    return new CoreLogger();
  }
}

// if there is already a logger instance use that
export const logger: CoreLogger = createCoreLogger();
