import React, { useState, useCallback, useEffect } from 'react';

import { EXTERNAL_EVENT, MODAL_ANIMATION_DURATION_IN_MS } from '../constants';

import Modal from './Modal';

import type { ModalProps } from './Modal';

type Control = { open: () => void; close: () => void };

type ModalWrapperProps = React.PropsWithChildren<
  Pick<Partial<ModalProps>, 'Title' | 'CloseButtonLabel' | 'ssrEnabled' | 'dismissOnOverlay'>
> & {
  setControl: (control: Control) => void;
};

function ModalWrapper({
  Title,
  CloseButtonLabel,
  children,
  setControl,
  ssrEnabled,
  dismissOnOverlay,
}: ModalWrapperProps) {
  const open = useCallback(() => setShowModal(true), []);
  const close = useCallback(() => setShowModal(false), []);
  const [showModal, setShowModal] = useState(false);
  const [isModalMounted, setIsModalMounted] = useState(false);

  const handleClose = useCallback(() => {
    setIsModalMounted(false);
    setTimeout(() => {
      close();
    }, MODAL_ANIMATION_DURATION_IN_MS);
  }, [close]);

  const handleMounted = useCallback(() => {
    setIsModalMounted(true);
  }, []);

  useEffect(() => {
    if (!showModal) return;

    function handleSearchOverlayOpen(e: any) {
      handleClose();

      // Give half a second to allow focusing on the search input after closing the modal
      setTimeout(() => {
        const searchInput = document.querySelector(e.detail?.inputsDetectionQuery);

        if (!searchInput) return;

        searchInput.click();
        searchInput.focus();
      }, 500);
    }

    window.addEventListener(EXTERNAL_EVENT.OVERLAY_SHOW, handleSearchOverlayOpen); // NOSONAR

    return () => {
      window.removeEventListener(EXTERNAL_EVENT.OVERLAY_SHOW, handleSearchOverlayOpen);
    };
  }, [handleClose, showModal]);

  useEffect(() => {
    setControl({ open, close: handleClose });
  }, [setControl, open, handleClose]);

  return (
    <Modal
      isOpen={showModal}
      onDismiss={handleClose}
      Title={Title}
      CloseButtonLabel={CloseButtonLabel}
      ssrEnabled={ssrEnabled}
      dismissOnOverlay={dismissOnOverlay}
      isMounted={isModalMounted}
      onMounted={handleMounted}
    >
      {children}
    </Modal>
  );
}

export function useModal() {
  const [control, setControl] = useState<Control | Record<string, never>>({});

  const Modal = useCallback(
    ({
      Title,
      CloseButtonLabel,
      children,
      ssrEnabled,
      dismissOnOverlay,
    }: React.PropsWithChildren<
      Pick<Partial<ModalProps>, 'Title' | 'CloseButtonLabel' | 'ssrEnabled' | 'dismissOnOverlay'>
    >) => (
      <ModalWrapper
        setControl={setControl}
        Title={Title}
        CloseButtonLabel={CloseButtonLabel}
        ssrEnabled={ssrEnabled}
        dismissOnOverlay={dismissOnOverlay}
      >
        {children}
      </ModalWrapper>
    ),
    [],
  );

  return {
    open: control.open,
    close: control.close,
    Modal,
  };
}
