export const blacklistCategories = [
  'Bags',
  'Beauty',
  'Home & Tech',
  'Food & Hampers',
  'Jewellery',
  'Gifts',
  'Christmas',
  'Kids',
  'Action figures & collectables',
  'Arts & crafts',
  'Baby & toddler toys',
  'Books',
  'Construction Toys',
  'Dolls & doll houses',
  'Dress up & role play',
  'Educational toys',
  'Electronic toys',
  'Games & puzzles',
  'LEGO & construction toys',
  'Outdoor & garden toys',
  'Preschool toys',
  'Ride-on cars bikes & scooters',
  'Role play toys',
  'Teddy bears & soft toys',
  'Toy vehicles',
  'Wooden toys',
  'Belts',
  'Cases & covers',
  'Cufflinks',
  'Face coverings',
  'Glasses',
  'Gloves',
  'Hats',
  'Jewellery',
  'Keyrings',
  'Lifestyle accessories',
  'Lighters',
  'Pocket squares',
  'Scarves',
  'Sunglasses',
  'Ties',
  'Umbrellas',
  'Wallets',
  'Watches',
  'Backpacks',
  'Belt bags',
  'Briefcases',
  'Messenger bags',
  'Pouches',
  'Suit carriers',
  'Tote bags',
  'Travel bags',
  'Wash bags',
  'Accessories',
  'Bags',
  'Beauty',
  'Wedding grooming',
  'Bracelets',
  'Earrings',
  'Engagement rings',
  'Necklaces',
  'Wedding rings',
  'Eyewear',
  'Hair accessories',
  'Purses and Pouches',
  'Tech accessories',
  'Bag accessories',
  'Bucket bags',
  'Clutch bags',
  'Cross body bags',
  'Mini bags',
  'Oversized bags',
  'Shoulder bags',
  'Top handle bags',
  'underwear & socks',
  'briefs',
  'bras',
  'hosiery',
  'suspenders',
  'lingerie sets',
];
