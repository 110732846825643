import styled from 'styled-components';
import { Trans } from 'react-i18next';
import React, { MouseEvent } from 'react';

import { spacing, typography } from '@selfridges-co/frontend-sdk-react-theme';
import { Link } from '@selfridges-co/frontend-sdk-react-link';
import NotificationBox from '@selfridges-co/frontend-sdk-react-notification-box';

interface AddOnMessageNotificationProps {
  message: string;
  title?: string;
  editButtonAriaLabel?: string;
  removeButtonAriaLabel?: string;
  price?: string;
  onEdit: (event?: MouseEvent<HTMLElement>) => void;
  onRemove: (event?: MouseEvent<HTMLElement>) => void;
}

const PENCIL_ICON_SVG =
  "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M5.92535 15.224L4.5 19.5L8.77605 18.0747C8.92332 18.0256 9.05715 17.9429 9.16692 17.8331L19.5 7.5L16.5 4.5L6.16693 14.8331C6.05715 14.9428 5.97444 15.0767 5.92535 15.224Z' stroke='%23212121' stroke-linejoin='round'/%3E%3Cpath d='M14.5 6.5L17.5 9.5' stroke='%23212121'/%3E%3C/svg%3E";
const CLOSE_ICON_SVG =
  "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Ccircle cx='12' cy='12' r='7.5' stroke='%23212121'/%3E%3Cpath d='M9 9L12 12M15 15L12 12M12 12L15 9M12 12L9 15' stroke='%23212121' stroke-linecap='round'/%3E%3C/svg%3E";

const Wrapper = styled.div({
  marginBottom: spacing(4),
  width: '100%',
});

const MessageContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  overflow: 'auto',
});

const Title = styled.h4({
  ...typography.typeface.display.xs,
});

const MessageContent = styled.p({
  ...typography.typeface.text.md.regular,
});

const Price = styled.p({
  ...typography.typeface.text.md.bold,
  marginTop: spacing(1),
});

const ActionButtonsContainer = styled.div({
  display: 'flex',
  alignSelf: 'stretch',
  justifyContent: 'space-between',
  alignItems: 'end',
  flexDirection: 'column',
  flexShrink: 0,
  gap: spacing(4),
});

const IconButton = styled(Link).attrs({ forwardedAs: 'button' })({
  ':hover': {
    '::before': {
      opacity: 0.7,
    },
  },
  '::before': {
    content: '" "',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    marginRight: spacing(1),
    height: '2.4rem',
    width: '2.4rem',
  },
});

const EditButton = styled(IconButton)({
  '::before': {
    backgroundImage: `url("${PENCIL_ICON_SVG}")`,
  },
});

const RemoveButton = styled(IconButton)({
  '::before': {
    backgroundImage: `url("${CLOSE_ICON_SVG}")`,
  },
});

function AddOnMessageNotification({
  message,
  price,
  onEdit,
  onRemove,
  title,
  editButtonAriaLabel,
  removeButtonAriaLabel,
}: AddOnMessageNotificationProps): React.JSX.Element {
  return (
    <Wrapper>
      <NotificationBox variant="success">
        <MessageContainer>
          <Title>{title}</Title>
          <MessageContent>{message}</MessageContent>
          {price && <Price>{price}</Price>}
        </MessageContainer>
        <ActionButtonsContainer>
          <EditButton linkType="secondary" aria-label={editButtonAriaLabel} onClick={onEdit}>
            <Trans i18nKey="app.add-on-message-notification.edit-button-text">Edit</Trans>
          </EditButton>
          <RemoveButton linkType="secondary" aria-label={removeButtonAriaLabel} onClick={onRemove}>
            <Trans i18nKey="app.add-on-message-notification.remove-button-text">Remove</Trans>
          </RemoveButton>
        </ActionButtonsContainer>
      </NotificationBox>
    </Wrapper>
  );
}

export default AddOnMessageNotification;
