import styled from 'styled-components';
import { Trans } from 'react-i18next';

import { breakpoints, spacing, typography } from '@selfridges-co/frontend-sdk-react-theme';

import { cleanHtmlString } from '../utils';

type ProductDetailsProps = {
  content: string;
  productId: string;
  skuId?: string;
};

const Container = styled.div({
  ...typography.typeface.text.md.regular,
  padding: spacing(0, 4, 6, 4),

  [breakpoints.md.mediaQuery]: {
    padding: spacing(0, 6, 6, 6),
  },
});

const Content = styled.div({
  a: {
    overflowWrap: 'anywhere',
  },

  ul: {
    listStyle: 'disc',
    marginLeft: spacing(6),
  },
});

const ProductInformation = styled.p({
  marginTop: spacing(6),
});

export default function ProductDetails({ content, productId, skuId }: ProductDetailsProps) {
  return (
    <Container>
      <Content
        dangerouslySetInnerHTML={{
          __html: cleanHtmlString(content),
        }}
      />
      <ProductInformation>
        {skuId && (
          <>
            <Trans i18nKey="app.product-panel.product-details.product-code">Product Code: {{ skuId: skuId }}</Trans>
            <br />
          </>
        )}
        <Trans i18nKey="app.product-panel.product-details.ref">Ref: {{ productId: productId }}</Trans>
      </ProductInformation>
    </Container>
  );
}
