import type { ProductResponse } from '@selfridges-pkg/product-aggregation-service-api/contracts/ProductResponse';
import type { OutOfStockTemplateProps } from '../out-of-stock-template/OutOfStockTemplate';

type OutOfStockTemplatePropsToTransform = Partial<OutOfStockTemplateProps> &
  Pick<OutOfStockTemplateProps, 'backToLink' | 'tagLinks'>;

export function transformProductDataToOutOfStockTemplateProps(
  { breadcrumbs, brand }: ProductResponse,
  countryLanguage: string,
): OutOfStockTemplatePropsToTransform {
  return {
    tagLinks: [
      ...(brand
        ? [
            {
              text: brand?.name,
              url: `/${countryLanguage}/cat/${brand?.seo}`,
            },
          ]
        : []),
      ...(breadcrumbs || []).map(({ seo, name }) => ({
        text: name,
        url: `/${countryLanguage}/cat/${seo}`,
      })),
    ],
    backToLink: {
      text: breadcrumbs?.[0].name || '',
      url: `/${countryLanguage}/cat/${breadcrumbs?.[0].seo || ''}`,
    },
  };
}
