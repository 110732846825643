import React, { MouseEvent, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Textarea from '@selfridges-co/frontend-sdk-react-textarea';
import Dropdown from '@selfridges-co/frontend-sdk-react-dropdown';
import { breakpoints, color, spacing, typography } from '@selfridges-co/frontend-sdk-react-theme';
import { ButtonLink, ButtonLinkOwnProps } from '@selfridges-co/frontend-sdk-react-button-link';

import { PersonalisationInfo, PersonalisationUserInput } from '../types';
import { savePersonalisationTracking } from '../analytics';

type PersonalisedProps = {
  savedInput: PersonalisationUserInput;
  onSave: (userInput: PersonalisationUserInput) => void;
  onRemove: (event?: MouseEvent<HTMLElement>) => void;
  personalisation: PersonalisationInfo;
  partNumber: string;
};

const REMOVE_MESSAGE_ICON_SVG =
  "data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='12' cy='12' r='7.5' stroke='%23212121'/%3e%3cpath d='M9 9L12 12M15 15L12 12M12 12L15 9M12 12L9 15' stroke='%23212121' stroke-linecap='round'/%3e%3c/svg%3e";

const Container = styled.div({
  ...typography.typeface.text.md.regular,
  padding: spacing(0, 4, 6, 4),

  [breakpoints.md.mediaQuery]: {
    padding: spacing(0, 6, 6, 6),
  },
});

const FontDropdownContainer = styled.div({
  marginBottom: spacing(10),
});

const CharacterCount = styled.p<{ isError: boolean }>(({ isError }) => ({
  color: isError ? color.palette.warningRed : color.palette.midGrey1,
}));

const TextAreaWithFixedHeight = styled(Textarea)({
  height: '96px',
});

const Instructions = styled.section({
  ...typography.typeface.text.md.regular,
  marginBottom: spacing(10),
});

const Rule = styled.p({
  marginTop: spacing(4),
});

const SaveButton = styled(ButtonLink).attrs({ as: 'button' })<ButtonLinkOwnProps>({
  border: 'none',
  cursor: 'pointer',
  marginTop: spacing(10),
});

const RemoveButton = styled(ButtonLink).attrs({ as: 'button' })<ButtonLinkOwnProps>({
  border: 'none',
  cursor: 'pointer',
  marginTop: spacing(3),
});

const RemoveIcon = styled.span({
  width: '24px',
  height: '24px',
  display: 'inline-block',
  verticalAlign: 'middle',
  marginRight: spacing(2),

  ':before': {
    content: "''",
    display: 'block',
    backgroundImage: `url("${REMOVE_MESSAGE_ICON_SVG}")`,
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100%',
  },
});

function Personalised({ savedInput, onSave, onRemove, personalisation, partNumber }: PersonalisedProps) {
  const { t } = useTranslation();

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const fontRef = useRef<HTMLSelectElement>(null);
  const fontColourRef = useRef<HTMLSelectElement>(null);
  const hasSavedMessageRef = useRef(savedInput.message.length > 0);

  const [characterCount, setCharacterCount] = useState(savedInput.message.length);
  const [fontErrorMessage, setFontErrorMessage] = useState('');
  const [fontColourErrorMessage, setFontColourErrorMessage] = useState('');
  const [textAreaErrorMessages, setTextAreaErrorMessages] = useState<Array<string>>([]);

  const { minChars, maxChars, invalidCharacterErrorMessage, allowedCharactersRegex, fonts, fontColours, price } =
    personalisation;

  const selectFontErrorMessage = t('app.personalised.error.select-font', {
    defaultValue: 'Please select a font',
  });

  const selectFontColourErrorMessage = t('app.personalised.error.select-font-colour', {
    defaultValue: 'Please select a font colour',
  });

  const minCharacterLimitErrorMessage = t('app.personalised.error.min-character', {
    defaultValue: 'There is a minimum character limit of {{characterLimit}} required for this product',
    characterLimit: minChars,
  });

  const reachedCharacterLimitErrorMessage = t('app.personalised.error.reached-character-limit', {
    defaultValue: "You've reached the limit, use {{characterLimit}} characters or less",
    characterLimit: maxChars,
  }).toString();

  const emptyMessageErrorMessage = t('app.personalised.error.empty-message', {
    defaultValue: 'Please enter a message above if you wish to save a personalised message',
  }).toString();

  function validateMessage(message: string) {
    const errorMessages: Array<string> = [];

    if (invalidCharacterErrorMessage && hasInvalidCharacters(message)) {
      errorMessages.push(invalidCharacterErrorMessage);
    }

    if (maxChars && message.length > maxChars) {
      errorMessages.push(reachedCharacterLimitErrorMessage);
    }

    return errorMessages;
  }

  function handleChangeTextArea(event: React.ChangeEvent<HTMLTextAreaElement>) {
    if (!textAreaRef.current) return;

    const message = event.target.value;
    textAreaRef.current.value = message;

    setCharacterCount(message.length);

    const errors = validateMessage(message);
    setTextAreaErrorMessages(errors);
  }

  function handleSaveMessage(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!textAreaRef.current) return;

    let hasOnSaveError = false;

    if (fonts && !fonts.includes(fontRef.current?.value || '')) {
      setFontErrorMessage(selectFontErrorMessage);
      hasOnSaveError = true;
    }

    if (fontColours && !fontColours.includes(fontColourRef.current?.value || '')) {
      setFontColourErrorMessage(selectFontColourErrorMessage);
      hasOnSaveError = true;
    }

    const message = textAreaRef.current.value.trim();

    if (!message) {
      setTextAreaErrorMessages([emptyMessageErrorMessage]);
      hasOnSaveError = true;
    }

    if (minChars && message && message.length < minChars) {
      setTextAreaErrorMessages(prev => [...prev, minCharacterLimitErrorMessage]);
      hasOnSaveError = true;
    }

    const messageErrors = validateMessage(message);
    if (messageErrors.length > 0) {
      setTextAreaErrorMessages(prev => [...prev, ...messageErrors]);
      hasOnSaveError = true;
    }

    if (!hasOnSaveError) {
      onSave({
        message,
        ...(fontRef.current?.value && { font: fontRef.current.value }),
        ...(fontColourRef.current?.value && { colour: fontColourRef.current.value }),
      });
    }

    savePersonalisationTracking({
      saveStatus: hasOnSaveError ? 'fail' : 'success',
      partNumber,
    });
  }

  function hasInvalidCharacters(message: string) {
    if (allowedCharactersRegex === undefined) return;

    return !allowedCharactersRegex.test(message.replace(/\n/g, ''));
  }

  function handleResetInput() {
    onRemove();

    if (!textAreaRef.current) return;
    textAreaRef.current.value = '';
  }

  const hasError = Boolean(textAreaErrorMessages.length > 0 || fontErrorMessage || fontColourErrorMessage);

  const textAreaLabel = price
    ? t('app.personalised.textarea.label-with-price', {
        defaultValue: 'Personalise it for {{price}}',
        price: price.formatted,
      })
    : t('app.personalised.textarea.label', {
        defaultValue: 'Personalise it',
      });

  return (
    <Container>
      <Instructions>
        <p>
          <Trans i18nKey="app.personalised.instruction">
            Check your spelling & use only English characters as this product is non-refundable.
          </Trans>
        </p>
        <Rule>{invalidCharacterErrorMessage}</Rule>
        {minChars && minChars > 1 && (
          <Rule>
            <Trans i18nKey="app.personalised.min-character-instruction" minChars={minChars}>
              This product has a minimum character count of {{ minChars }}
            </Trans>
          </Rule>
        )}
      </Instructions>
      <form onSubmit={handleSaveMessage} onReset={handleResetInput}>
        {fonts && (
          <FontDropdownContainer>
            <Dropdown
              name="personalised-font"
              ref={fontRef}
              label={t('app.personalised.font-dropdown.label', {
                defaultValue: 'Font',
              }).toString()}
              placeholderOption={t('app.personalised.font-dropdown.placeholder-option', {
                defaultValue: 'Select font',
              }).toString()}
              options={fonts}
              onChange={() => fontErrorMessage && setFontErrorMessage('')}
              selectedOption={savedInput.font}
              errorMessage={fontErrorMessage}
            />
          </FontDropdownContainer>
        )}
        {fontColours && (
          <FontDropdownContainer>
            <Dropdown
              name="personalised-font-colour"
              ref={fontColourRef}
              label={t('app.personalised.font-colour-dropdown.label', {
                defaultValue: 'Font colour',
              }).toString()}
              placeholderOption={t('app.personalised.font-colour-dropdown.placeholder-option', {
                defaultValue: 'Select font colour',
              }).toString()}
              options={fontColours}
              onChange={() => fontColourErrorMessage && setFontColourErrorMessage('')}
              selectedOption={savedInput.colour}
              errorMessage={fontColourErrorMessage}
            />
          </FontDropdownContainer>
        )}
        <TextAreaWithFixedHeight
          name="personalised-message"
          ref={textAreaRef}
          defaultValue={savedInput.message}
          label={textAreaLabel.toString()}
          placeholder={t('app.personalised.textarea.placeholder', {
            defaultValue: 'Type your personalisation here',
          }).toString()}
          onChange={handleChangeTextArea}
          errorMessages={textAreaErrorMessages}
          helperText={
            maxChars ? (
              <CharacterCount isError={characterCount > maxChars}>
                <Trans i18nKey="app.personalised.textarea.character-count">
                  {{ characterCount }} / {{ characterLimit: maxChars }} Characters
                </Trans>
              </CharacterCount>
            ) : undefined
          }
        />
        <SaveButton type="submit" buttonType="primary" variant="black" disabled={hasError} fullWidth>
          <Trans i18nKey="app.personalised.save-button-text">Save</Trans>
        </SaveButton>
        {hasSavedMessageRef.current && (
          <RemoveButton buttonType="secondary" fullWidth type="reset">
            <RemoveIcon />
            <Trans i18nKey="app.personalised.remove-button-text">Remove personalised message</Trans>
          </RemoveButton>
        )}
      </form>
    </Container>
  );
}

export default Personalised;
