import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { fetchBundles } from '@selfridges-pkg/product-aggregation-service-api/fetchBundles';
import { env } from '@selfridges-pkg/utils';

const PRODUCT_API_HOST = () => env('REACT_APP_PRODUCT_API_HOST') || '';
const PRODUCT_API_KEY = () => env('REACT_APP_PRODUCT_API_KEY') || '';

export function useFetchBundles({
  countryLanguage,
  partNumber,
  enabled,
}: {
  countryLanguage: string;
  partNumber: string;
  enabled: boolean;
}) {
  const [dataKey, setDataKey] = useState<string | null>(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search);
    setDataKey(searchParams.get('bundlesDataKey') || '');
  }, []);

  const { data } = useQuery({
    enabled: dataKey !== null && enabled,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    queryKey: [countryLanguage, 'product', partNumber, 'bundles'],
    queryFn: () =>
      fetchBundles({
        hostRootUrl: PRODUCT_API_HOST(),
        countryLanguage,
        partNumber,
        additionalHeaders: {
          ...(PRODUCT_API_KEY() && { 'x-api-key': PRODUCT_API_KEY() }),
          ...(dataKey && { 'X-Bundles-Data-Key': dataKey }),
        },
      }),
  });

  return data;
}
