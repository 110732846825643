import React, { MouseEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { typography } from '@selfridges-co/frontend-sdk-react-theme';

import AddOnMessageNotification from '../add-on-message-notification/AddOnMessageNotification';

import AddPersonalisedMessageButton from './AddPersonalisedMessageButton';

type AddPersonalisedMessageProps = {
  personalisedMessage: string;
  isMandatory: boolean;
  onEdit: (event?: MouseEvent<HTMLElement>) => void;
  price?: string;
  onRemove: (event?: MouseEvent<HTMLElement>) => void;
};

const MandatoryText = styled.p({
  ...typography.typeface.text.md.regular,
  textAlign: 'center',
});

function AddPersonalisedMessage({
  personalisedMessage,
  price,
  onEdit,
  isMandatory,
  onRemove,
}: AddPersonalisedMessageProps) {
  const { t } = useTranslation();

  return personalisedMessage ? (
    <AddOnMessageNotification
      message={personalisedMessage}
      title={t('app.personalised.notification.title', {
        defaultValue: 'Personalised message:',
      }).toString()}
      editButtonAriaLabel={t('app.personalised.notification.edit-button-aria-label', {
        defaultValue: 'Edit personalised message',
      }).toString()}
      removeButtonAriaLabel={t('app.personalised.notification.remove-button-aria-label', {
        defaultValue: 'Remove personalised message',
      }).toString()}
      price={price}
      onEdit={onEdit}
      onRemove={onRemove}
    />
  ) : (
    <>
      <AddPersonalisedMessageButton onClick={onEdit} price={price} type={isMandatory ? 'primary' : 'secondary'} />
      {isMandatory && (
        <MandatoryText>
          <Trans i18nKey="app.personalised.add-personalised-message.mandatory-text">
            Personalisation is mandatory on this item
          </Trans>
        </MandatoryText>
      )}
    </>
  );
}

export default AddPersonalisedMessage;
