import { withLDProvider, useFlags as useLdFlags } from 'launchdarkly-react-client-sdk';

import type { AsyncProviderConfig, LDFlagSet } from 'launchdarkly-react-client-sdk';

const getLdConfig = (clientSideId: string, bootstrappedFlags: LDFlagSet): AsyncProviderConfig => ({
  clientSideID: clientSideId,
  reactOptions: { useCamelCaseFlagKeys: false },
  options: {
    bootstrap: bootstrappedFlags,
  },
});

export const LaunchDarklyProvider = (clientSideId: string, bootstrappedFlags: LDFlagSet) =>
  withLDProvider(getLdConfig(clientSideId, bootstrappedFlags));

export const useFlags = (): LDFlagSet | undefined => {
  const ldFlags = useLdFlags();

  if (!ldFlags || !Object.keys(ldFlags).length) return undefined;

  return ldFlags;
};
