import styled from 'styled-components';
import { Trans } from 'react-i18next';

import { breakpoints, spacing, typography, color } from '@selfridges-co/frontend-sdk-react-theme';
import { ButtonLink } from '@selfridges-co/frontend-sdk-react-button-link';

type InStoreOnlyProps = {
  htmlMessage: string | undefined;
};

const Container = styled.div({
  marginTop: spacing(8),

  [breakpoints.md.mediaQuery]: {
    marginTop: spacing(10),
  },
});

const Title = styled.h4({
  ...typography.typeface.display.xs,
});

const Content = styled.div({
  ...typography.typeface.text.md.regular,
  marginTop: spacing(2),

  'b, strong': {
    fontWeight: 400,
  },

  a: {
    ...typography.typeface.display.xs,
    color: color.palette.mainBlack,
  },

  ul: {
    listStyle: 'disc',
    marginLeft: spacing(6),

    '&:not(:first-child)': {
      marginTop: spacing(6),
    },

    '&:not(:last-child)': {
      marginBottom: spacing(6),
    },
  },
});

const ButtonsContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginTop: spacing(8),
  gap: spacing(4),
});

export default function InStoreOnlyTemplate({ htmlMessage }: InStoreOnlyProps) {
  return (
    <Container>
      <Title>
        <Trans i18nKey="app.in-store-only.title">This product is only available in-store</Trans>
      </Title>
      <Content>
        {htmlMessage ? (
          <div
            dangerouslySetInnerHTML={{
              __html: htmlMessage,
            }}
          />
        ) : (
          <p>
            <Trans i18nKey="app.in-store-only.instruction">Find your nearest store below</Trans>
          </p>
        )}
      </Content>
      <ButtonsContainer>
        <ButtonLink variant="black" fullWidth href="/features/info/stores/london/" target="_blank">
          <Trans i18nKey="app.in-store-only.store-information-cta.text">Store information</Trans>
        </ButtonLink>
        <ButtonLink buttonType="secondary" fullWidth href="/features/info/contact-us/" target="_blank">
          <Trans i18nKey="app.in-store-only.contact-us-cta.text">Contact us</Trans>
        </ButtonLink>
      </ButtonsContainer>
    </Container>
  );
}
