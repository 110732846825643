import { formatPrice } from './formatPrice';

import type { CountryInfo, AvailableCountryCode } from './types';

export const countryInfo: CountryInfo = {
  AU: { en: 'Australia', zh: '澳大利亚', continent: 'Australia', sfCurrency: 'AUD' },
  CA: { en: 'Canada', zh: '加拿大', continent: 'North America', sfCurrency: 'CAD' },
  CN: { en: 'China', zh: '中国大陆', continent: 'Asia', sfCurrency: 'CNY' },
  FR: { en: 'France', zh: '法国', continent: 'Europe', sfCurrency: 'EUR' },
  DE: { en: 'Germany', zh: '德国', continent: 'Europe', sfCurrency: 'EUR' },
  HK: { en: 'Hong Kong SAR China', zh: '中国香港', continent: 'Asia', sfCurrency: 'HKD' },
  IE: { en: 'Ireland', zh: '爱尔兰', continent: 'Europe', sfCurrency: 'EUR' },
  IT: { en: 'Italy', zh: '意大利', continent: 'Europe', sfCurrency: 'EUR' },
  JP: { en: 'Japan', zh: '日本', continent: 'Asia', sfCurrency: 'JPY' },
  KW: { en: 'Kuwait', zh: '科威特', continent: 'Asia', sfCurrency: 'USX' },
  MO: { en: 'Macao SAR China', zh: '中国澳门', continent: 'Asia', sfCurrency: 'HKD' },
  NL: { en: 'Netherlands', zh: '荷兰', continent: 'Europe', sfCurrency: 'EUR' },
  QA: { en: 'Qatar', zh: '卡塔尔', continent: 'Asia', sfCurrency: 'USX' },
  SA: { en: 'Saudi Arabia', zh: '沙特阿拉伯', continent: 'Asia', sfCurrency: 'USX' },
  SG: { en: 'Singapore', zh: '新加坡', continent: 'Asia', sfCurrency: 'SGD' },
  KR: { en: 'South Korea', zh: '韩国', continent: 'Asia', sfCurrency: 'KRW' },
  ES: { en: 'Spain', zh: '西班牙', continent: 'Europe', sfCurrency: 'EUR' },
  TW: { en: 'Taiwan', zh: '台湾', continent: 'Asia', sfCurrency: 'TWD' },
  AE: { en: 'United Arab Emirates', zh: '阿拉伯联合酋长国', continent: 'Asia', sfCurrency: 'USX' },
  GB: { en: 'United Kingdom', zh: '英国', alt: 'UK', continent: 'Europe', sfCurrency: 'GBP' },
  US: { en: 'United States', zh: '美国', continent: 'North America', sfCurrency: 'USD' },
};

export const countryCodes: Array<AvailableCountryCode> = [
  'AU',
  'CA',
  'CN',
  'FR',
  'DE',
  'HK',
  'IE',
  'IT',
  'JP',
  'KW',
  'MO',
  'NL',
  'QA',
  'SA',
  'SG',
  'KR',
  'ES',
  'TW',
  'AE',
  'GB',
  'US',
];

export { formatPrice };
