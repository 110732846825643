import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';

import { useCountdownTimer } from '../countdown-timer/useCountdownTimer';

import { GbFlagIcon, ClickAndCollectIcon } from './SalesPropIcons';
import { PropContainer } from './SalesProp';
import { PromotionalMessage } from './PromotionalMessage';
import DeliveryCountdown from './DeliveryCountdown';

import type { PromotionalMessages } from '../types';
import type { DeliveryCutoffs, TimerLabel } from '../transformers/useTransformDataToCountdown';

type UseSalesPropProps = {
  promotionalMessages?: PromotionalMessages;
  ukDeliveryOnly: boolean;
  countryCode: string;
  clickAndCollectOnly: boolean;
  deliveryCutoffs: DeliveryCutoffs;
  outOfStock: boolean;
};

export function useSalesProp({
  promotionalMessages,
  ukDeliveryOnly,
  countryCode,
  clickAndCollectOnly,
  deliveryCutoffs,
  outOfStock,
}: UseSalesPropProps): Array<React.JSX.Element> {
  const { duration, status, label, context } = useCountdownTimer<TimerLabel, string>(deliveryCutoffs);

  const DeliveryCutOffMessage: Array<React.JSX.Element> = useMemo(() => {
    if (status !== 'ACTIVE' || outOfStock) return [];
    return [<DeliveryCountdown duration={duration} label={label} context={context} />];
  }, [context, duration, label, status, outOfStock]);

  const UkDeliveryOnlyMessage: Array<React.JSX.Element> = useMemo(() => {
    if (!ukDeliveryOnly || countryCode === 'GB') return [];
    return [
      <PropContainer key="uk-delivery-only" icon={<GbFlagIcon />}>
        <p>
          <Trans i18nKey="app.sales-prop.uk-delivery-only">
            Only available for <b>UK Delivery</b>
          </Trans>
        </p>
      </PropContainer>,
    ];
  }, [countryCode, ukDeliveryOnly]);

  const ClickAndCollectMessage: Array<React.JSX.Element> = useMemo(() => {
    if (!clickAndCollectOnly) return [];
    return [
      <PropContainer key="uk-click-and-collect" icon={<ClickAndCollectIcon />}>
        <p>
          <Trans i18nKey="app.sales-prop.uk-click-and-collect">
            Only available for <b>Click & Collect</b>
          </Trans>
        </p>
      </PropContainer>,
    ];
  }, [clickAndCollectOnly]);

  const PromotionalMessages: Array<React.JSX.Element> = useMemo(() => {
    if (!promotionalMessages?.length) return [];
    return promotionalMessages.map((content, index) => <PromotionalMessage key={index} content={content} />);
  }, [promotionalMessages]);

  return [...DeliveryCutOffMessage, ...ClickAndCollectMessage, ...UkDeliveryOnlyMessage, ...PromotionalMessages]
    .filter(Boolean)
    .slice(0, 3);
}
