import styled from 'styled-components';
import { t } from 'i18next';

import { spacing } from '@selfridges-co/frontend-sdk-react-theme';
import Rating from '@selfridges-co/frontend-sdk-react-rating';

type AverageRatingProps = {
  score: number;
  totalReviews: number;
  scrollToTarget: Nullable<HTMLDivElement>;
};

const Wrapper = styled.div({
  margin: spacing(2, 0),
});

export default function AverageRating({ score, totalReviews, scrollToTarget }: AverageRatingProps) {
  function handleClick() {
    if (!scrollToTarget) return null;
    scrollToTarget.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  return (
    <Wrapper>
      <Rating
        type="STARS_WITH_RATING"
        rating={score}
        aria-label={t('app.product-panel.rating.aria-label', {
          defaultValue: 'Rated {{rating}} out of 5 stars, {{totalReviews}} reviews',
          rating: score,
          totalReviews,
        }).toString()}
        totalReviews={totalReviews}
        onClick={handleClick}
        data-analytics-component="rating-anchor"
      />
    </Wrapper>
  );
}
