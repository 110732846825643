import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { fetchProductMedia } from '@selfridges-pkg/product-aggregation-service-api/fetchProductMedia';
import { env } from '@selfridges-pkg/utils';

const PRODUCT_API_HOST = () => env('REACT_APP_PRODUCT_API_HOST') || '';
const PRODUCT_API_KEY = () => env('REACT_APP_PRODUCT_API_KEY') || '';

export function useProductMedia({
  colour,
  partNumber,
  countryLanguage,
  enabled,
}: {
  colour: string;
  partNumber: string;
  countryLanguage: string;
  enabled: boolean;
}) {
  const [dataKey, setDataKey] = useState<string | null>(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search);
    setDataKey(searchParams.get('mediaDataKey'));
  }, []);

  const { data, isError } = useQuery(
    [countryLanguage, 'product', partNumber, 'media', colour],
    () =>
      fetchProductMedia({
        colour,
        hostRootUrl: PRODUCT_API_HOST(),
        countryLanguage,
        partNumber,
        additionalHeaders: {
          ...(PRODUCT_API_KEY() && { 'x-api-key': PRODUCT_API_KEY() }),
          ...(dataKey && { 'X-Media-Data-Key': dataKey }),
        },
      }),
    {
      enabled,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: Infinity,
      retry: 0,
    },
  );

  return isError ? { defaultImage: '', images: [], videos: null } : data;
}
