import styled from 'styled-components';

import { breakpoints, spacing, typography } from '@selfridges-co/frontend-sdk-react-theme';

import { cleanHtmlString } from '../utils';

type SpecificationsProps = {
  content: string;
};

const Content = styled.div({
  ...typography.typeface.text.md.regular,
  padding: spacing(0, 4, 6, 4),

  [breakpoints.md.mediaQuery]: {
    padding: spacing(0, 6, 6, 6),
  },

  a: {
    overflowWrap: 'anywhere',
  },

  ul: {
    listStyle: 'disc',
    marginLeft: spacing(6),
  },
});

export default function Specifications({ content }: SpecificationsProps) {
  return (
    <Content
      dangerouslySetInnerHTML={{
        __html: cleanHtmlString(content),
      }}
    />
  );
}
