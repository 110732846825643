import styled from 'styled-components';
import { Trans } from 'react-i18next';

import { Link } from '@selfridges-co/frontend-sdk-react-link';
import { breakpoints, color, spacing, typography } from '@selfridges-co/frontend-sdk-react-theme';

import { InfoToast } from '../hooks/useToastMessage';
import { VIEW_BAG_URL } from '../constants';

type SuccessMessageProps = {
  title: string;
  countryLanguage: string;
};

const Container = styled(InfoToast)({
  boxSizing: 'border-box',
  padding: spacing(4),
  background: color.palette.mainBlack,
  pointerEvent: 'auto',

  [breakpoints.md.mediaQuery]: {
    width: '100%',
  },
});

const Text = styled.div({
  ...typography.typeface.display.xs,
  color: color.palette.mainWhite,
  marginBottom: spacing(2),
});

export default function SuccessMessage({ title, countryLanguage }: SuccessMessageProps) {
  return (
    <Container>
      <Text>
        <Trans i18nKey="app.selfridges-plus.success-message.content">Selfridges Plus - {{ title }}</Trans>
      </Text>
      <Link linkType="primary" variant="yellow" href={`/${countryLanguage}${VIEW_BAG_URL}`}>
        <Trans i18nKey="app.selfridges-plus.success-message.view-bag">View bag</Trans>
      </Link>
    </Container>
  );
}
