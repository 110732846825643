import { useEffect, useState, useRef, useCallback } from 'react';
import styled from 'styled-components';

import { spacing, color, breakpoints } from '@selfridges-co/frontend-sdk-react-theme';

import { PRODUCT_PANEL_GUTTER, TOAST_MESSAGE_ANIMATION_DURATION_IN_MS } from '../constants';

const Container = styled.div.withConfig<{ direction: string }>({
  shouldForwardProp: props => !['direction'].includes(String(props)),
})(({ direction }) => ({
  width: '100%',
  height: 'fit-content',
  display: 'flex',
  flexDirection: direction === 'normal' ? 'column' : 'column-reverse',
}));

const MessageEl = styled.div<{ destroying: boolean } & React.HTMLAttributes<HTMLDivElement>>(({ destroying }) => ({
  width: '100%',
  opacity: 1,
  transition: 'all 0.3s',
  ...(destroying && {
    opacity: 0,
    pointerEvents: 'none',
  }),
}));

export const InfoToast = styled.div({
  width: '100%',
  padding: spacing(4),
  boxSizing: 'border-box',
  background: color.palette.mainBlack,
  pointerEvents: 'auto',
});

const AnimationEl = styled.div(({ overflow, direction }: { overflow: boolean; direction?: 'normal' | 'reverse' }) => ({
  transition: `all ${TOAST_MESSAGE_ANIMATION_DURATION_IN_MS / 1000}s ease-in-out`,
  height: 0,
  overflow: overflow ? 'visible' : 'hidden',
  marginTop: PRODUCT_PANEL_GUTTER.MOBILE,

  [breakpoints.md.mediaQuery]: {
    marginTop: PRODUCT_PANEL_GUTTER.DESKTOP,
  },

  [direction === 'normal' ? ':first-child' : ':last-child']: {
    marginTop: 0,
  },

  [direction !== 'normal' ? ':first-child' : ':last-child']: {
    marginBottom: 0,
  },
}));

interface MessageObject {
  id: number;
  Message: React.ReactChild;
  lifetime: number;
  destroyTimeout: ReturnType<typeof setTimeout> | null;
  startTime: number;
}

function Message({
  lifetime,
  pause,
  popMessage,
  pauseMessageAtIndex,
  index,
  messageObject,
  children,
  animationOverflow,
  direction = 'normal',
}: {
  popMessage: () => void;
  lifetime: number;
  pause: boolean;
  pauseMessageAtIndex: (index: number) => void;
  index: number;
  messageObject: MessageObject;
  children: React.ReactChild;
  animationOverflow: boolean;
  direction?: 'normal' | 'reverse';
}) {
  const [destroying, setDestroying] = useState(false);
  const [goalHeight, setGoalHeight] = useState(0);
  const MessageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!window || pause) return;
    const timeout = setTimeout(() => {
      setDestroying(true); // mark to be destroyed
      setGoalHeight(0);

      if (!messageObject.destroyTimeout) {
        messageObject.destroyTimeout = setTimeout(() => {
          popMessage();
        }, TOAST_MESSAGE_ANIMATION_DURATION_IN_MS);
      }
    }, lifetime);

    return () => {
      clearTimeout(timeout);
    };
  }, [lifetime, pause, messageObject, popMessage]);

  useEffect(() => {
    setGoalHeight(MessageRef.current?.getBoundingClientRect().height || 0);
  }, []);

  return (
    <AnimationEl style={{ height: `${goalHeight}px` }} overflow={animationOverflow} direction={direction}>
      <MessageEl
        ref={MessageRef}
        destroying={destroying}
        onMouseOver={() => pauseMessageAtIndex(index)}
        onMouseOut={() => pauseMessageAtIndex(-1)}
      >
        {children}
      </MessageEl>
    </AnimationEl>
  );
}

export function useToastMessage({
  messageLifeTime,
  messageAnimationOverflow = 'overflow',
  direction = 'normal',
}: {
  messageLifeTime?: number;
  messageAnimationOverflow?: 'contain' | 'overflow';
  direction?: 'normal' | 'reverse';
}) {
  const [messages, setMessages] = useState<Array<MessageObject>>([]);
  const [pauseIndex, setPauseIndex] = useState<number>(-1);
  const containerRef = useRef<HTMLDivElement>(null);

  const queueMessage = useCallback(
    (Message: React.ReactChild) => {
      setMessages(previous => [
        {
          id: Date.now(),
          Message,
          lifetime: messageLifeTime || 3000,
          destroyTimeout: null,
          startTime: Date.now(),
        },
        ...previous,
      ]);
    },
    [setMessages, messageLifeTime],
  );

  const popMessage = useCallback(() => {
    setMessages(previous => previous.slice(0, -1));
  }, []);

  const pauseMessageAtIndex = (pauseIndex: number) => {
    const pauseTime = Date.now();

    messages.forEach((messageObject, messageIndex) => {
      if (pauseIndex === -1) {
        messageObject.startTime = pauseTime;
      } else if (messageIndex >= pauseIndex) {
        messageObject.lifetime = messageObject.lifetime - (pauseTime - messageObject.startTime);
      }
    });

    setPauseIndex(pauseIndex);
  };

  const MessageContainer = (
    <Container ref={containerRef} direction={direction}>
      {messages.map((messageObject, index) => (
        <Message
          key={messageObject.id}
          lifetime={messageObject.lifetime}
          pause={index <= pauseIndex}
          pauseMessageAtIndex={pauseMessageAtIndex}
          popMessage={popMessage}
          index={index}
          messageObject={messageObject}
          animationOverflow={messageAnimationOverflow === 'overflow'}
          direction={direction}
        >
          {messageObject.Message}
        </Message>
      ))}
    </Container>
  );

  const scrollToMessage = useCallback(() => {
    setTimeout(() => {
      containerRef.current?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
    }, TOAST_MESSAGE_ANIMATION_DURATION_IN_MS + 100); // add 100ms to allow toast animation to settle before scrolling to the  message
  }, []);

  return {
    queueMessage,
    messages,
    scrollToMessage,
    Message: MessageContainer,
  };
}
