import React, { useContext, createContext } from 'react';

const ldFlagNames = [
  'enable-for-test-do-not-remove',
  'enable-personalisation',
  'enable-chanel-pdp',
  'enable-dior-pdp',
  'enable-new-product-route',
  'enable-pdp-perso-rec',
] as const;

export type LdFlags = Record<(typeof ldFlagNames)[number], boolean> | Record<string, never>;

const LdFlagContext = createContext<LdFlags>({});

interface ProviderProps {
  flags: LdFlags;
  children: React.ReactNode;
}

export function filterLdFlags(flags: object | null | undefined) {
  if (!flags) return null;

  return ldFlagNames.reduce((acc: LdFlags, flagName) => {
    acc[flagName] = flags[flagName] || false;
    return acc;
  }, {});
}

export function LdFlagProvider({ flags, children }: ProviderProps) {
  // NOTE: Provide an empty array if value is undefined to distinguish between
  //       this case, and the case where consumer is not being used within provider.
  //
  //       The latter will throw the helpful error seen in the various `use*()` hooks below.
  return <LdFlagContext.Provider value={flags}>{children}</LdFlagContext.Provider>;
}

interface ConsumerProps {
  children: (context: LdFlags) => React.ReactNode;
}

export function LdFlagConsumer({ children }: ConsumerProps) {
  return <LdFlagContext.Consumer>{context => children(context)}</LdFlagContext.Consumer>;
}

export function useLdFlags() {
  const context = useContext(LdFlagContext);

  if (context === undefined) {
    throw new Error('useLdFlags must be used within a Provider');
  }

  return context;
}
