import { formatPrice } from '@selfridges-pkg/intl/formatPrice';

import type { Price as PriceResponse } from '@selfridges-pkg/product-aggregation-service-api/contracts/Price';
import type { AvailableCurrency } from '@selfridges-pkg/intl/types';
import type { Price as TransformedPriceResponse } from '../types';

export function transformPriceWithCurrency(price: PriceResponse): TransformedPriceResponse {
  const currency = price.currency as AvailableCurrency;

  return {
    currency: currency,
    current: formatPrice(price.current, currency),
    ...(price.was && { was: formatPrice(price.was, currency) }),
    ...(price.wasWas && { wasWas: formatPrice(price.wasWas, currency) }),
    raw: {
      current: price.current,
      ...(price.was && { was: price.was }),
      ...(price.wasWas && { wasWas: price.wasWas }),
    },
  };
}
