import React from 'react';
import styled, { CSSObject } from 'styled-components';

import { typography, spacing, color, breakpoints } from '@selfridges-co/frontend-sdk-react-theme';
import { ButtonLink } from '@selfridges-co/frontend-sdk-react-button-link';

interface OverlayLinksProps {
  children: React.ReactElement<typeof Item> | Array<React.ReactElement<typeof Item>>;
}

function renderArrowSvg(colorCode: string): CSSObject {
  const encodedColor = colorCode.replace('#', '%23');

  return {
    backgroundImage: `url("data:image/svg+xml,%3csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M8.5 23.5H39.5L27.5 35.5' stroke='${encodedColor}' stroke-linecap='round' stroke-linejoin='round'/%3e%3cpath d='M8.5 23.5H39.5L28.5 12.5' stroke='${encodedColor}' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e")`,
  };
}

const Wrapper = styled.div({
  marginBottom: spacing(16),

  [breakpoints.md.mediaQuery]: {
    marginBottom: spacing(20),
  },
});

const Button = styled(ButtonLink).attrs({ as: 'button' })({
  ...typography.typeface.display.sm,
  border: 'none',
  boxShadow: `inset 0px 1px 0px 0px ${color.palette.lightGrey1}`,
  padding: spacing(8, 0),
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  cursor: 'pointer',

  ':last-child': {
    boxShadow: `inset 0px 1px 0px 0px ${color.palette.lightGrey1}, inset 0px -1px 0px 0px ${color.palette.lightGrey1}`,
  },

  ':after': {
    content: "' '",
    ...renderArrowSvg(color.palette.mainBlack),
    width: '48px',
    height: '48px',
    position: 'absolute',
    right: 0,
  },

  ':hover': {
    boxShadow: `inset 0px 1px 0px 0px ${color.palette.lightGrey1}`,
    textDecoration: 'underline',

    ':last-child': {
      boxShadow: `inset 0px 1px 0px 0px ${color.palette.lightGrey1}, inset 0px -1px 0px 0px ${color.palette.lightGrey1}`,
    },

    ':after': {
      ...renderArrowSvg(color.palette.midGrey2),
    },
  },
});

export default function OverlayLinks({ children }: OverlayLinksProps) {
  return (
    <Wrapper>
      {React.Children.map(children, child => {
        if (child.type !== Item) return;
        return child;
      })}
    </Wrapper>
  );
}

interface ItemProps {
  onClick: () => void;
  hide?: boolean;
  children: React.ReactNode;
}

function Item({ onClick, hide = false, children }: ItemProps) {
  if (hide) return null;

  return (
    <Button onClick={onClick} buttonType="secondary" variant="black">
      {children}
    </Button>
  );
}

OverlayLinks.Item = Item;
