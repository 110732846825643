import React, { useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import { breakpoints, color, spacing } from '@selfridges-co/frontend-sdk-react-theme';

import { AddToCartButtonProps } from '../hooks/useAddToCart';
import { useBrandConfig } from '../brand-configs/Context';

type AddToBagProps = {
  id?: string;
  AddToCartButton: React.FC<AddToCartButtonProps>;
  displayPrice?: string;
  disabled: boolean;
  show?: boolean;
  sticky?: boolean;
  ToastMessage?: React.ReactElement<HTMLElement>;
  ExtraContentBelow?: React.ReactElement<HTMLElement>;
};

const FixedWrapper = styled.div({
  position: 'absolute',
  top: 0,
  height: '100%',
  width: '1%', // don't cover the screen as false positive in pa11y but keep relative sizing
  pointerEvents: 'none',
  [breakpoints.md.mediaQuery]: {
    display: 'none',
  },
});

const FixedSticky = styled.div({
  position: 'sticky',
  top: 0,
  height: `100dvh`,
  width: '100%',
  transition: 'height 0.1s linear',
});

const FixedContainer = styled.div({
  display: 'block',
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '10000%', // restore sizing to 100% for pa11y
  zIndex: 100,
  pointerEvents: 'none',
});

const AnimateContainer = styled.div<{ show: boolean }>(({ show }) => ({
  width: '100%',
  height: '100%',
  transform: `translateY(${show ? 0 : 100}%)`,
  opacity: `${show ? 1 : 0}`,
  transition: 'all 0.2s ease-in-out',
  pointerEvents: 'auto',
  padding: spacing(2),
  backgroundColor: color.palette.mainWhite,
  boxSizing: 'border-box',

  ...(!show && {
    pointerEvents: 'none',
  }),
}));

const StandardContainer = styled.div<{ show: boolean }>(({ show }) => ({
  position: 'relative',
  // make standard Add To Bag button transition slightly slower so it leaves time for the sticky Add To Bag button to disappear
  transition: 'opacity 0.3s ease-in-out',
  opacity: `${show ? 1 : 0}`,
  ...(!show && {
    pointerEvents: 'none',
  }),

  [breakpoints.md.mediaQuery]: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginLeft: 'auto',
    opacity: 1,
    pointerEvents: 'auto',
  },
}));

const ToastContainer = styled.div<{ sticky: boolean; minHeight: number }>(({ sticky, minHeight }) => ({
  transform: `translateY(calc(-100% - ${spacing(4)}))`,
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  boxSizing: 'border-box',

  ...(sticky && {
    paddingLeft: spacing(4),
    paddingRight: spacing(4),
    transform: `translateY(calc(-100% - ${spacing(2)}))`,
  }),

  [breakpoints.md.mediaQuery]: {
    zIndex: 101,
    transform: 'none',
    position: 'relative',
    paddingTop: spacing(4),
    paddingLeft: 0,
    paddingRight: 0,
    order: 2,
    minHeight: minHeight ? `calc(${minHeight}px + ${spacing(4)})` : 'auto',
    pointerEvents: 'none',
  },
}));

const ExtraContentContainer = styled.div({
  width: '100%',
  marginTop: spacing(4),
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(4),

  [breakpoints.md.mediaQuery]: {
    position: 'absolute',
    top: spacing(16),
    marginTop: 0,
    zIndex: 100,
  },
});

function StickyContainer({ show, children }: { show: boolean; children: React.ReactNode }) {
  return (
    <FixedWrapper>
      <FixedSticky>
        <FixedContainer tabIndex={-1}>
          <AnimateContainer show={show}>{children}</AnimateContainer>
        </FixedContainer>
      </FixedSticky>
    </FixedWrapper>
  );
}

function AddToBag({
  id,
  AddToCartButton,
  displayPrice,
  disabled,
  show = true,
  sticky = false,
  ToastMessage, // ToastMessage is passed in for layout reasons only
  ExtraContentBelow,
}: AddToBagProps) {
  const Container = sticky ? StickyContainer : StandardContainer;
  const extraContainerRef = useRef<HTMLDivElement>(null);
  const [reserveSpace, setReserveSpace] = useState(0);

  const { addToBag: addToBagBrandConfig } = useBrandConfig();

  useEffect(() => {
    if (!extraContainerRef.current) return;

    setReserveSpace(extraContainerRef.current.offsetHeight);
  }, [ExtraContentBelow, setReserveSpace]);

  const idleStatusText = displayPrice ? (
    <Trans i18nKey="app.add-to-bag.add-to-bag-with-price" price={displayPrice}>
      Add to bag - {{ price: displayPrice }}
    </Trans>
  ) : (
    <Trans i18nKey="app.add-to-bag.add-to-bag">Add to bag</Trans>
  );

  return (
    <Container show={show}>
      <ToastContainer sticky={sticky} minHeight={reserveSpace}>
        {ToastMessage}
      </ToastContainer>
      <AddToCartButton
        id={id}
        disabled={disabled}
        statusText={{
          idle: idleStatusText,
          loading: idleStatusText,
          success: <Trans i18nKey="app.add-to-bag.item-added-to-bag">Item added to bag</Trans>,
        }}
        {...addToBagBrandConfig}
      />
      {ExtraContentBelow && <ExtraContentContainer ref={extraContainerRef}>{ExtraContentBelow}</ExtraContentContainer>}
    </Container>
  );
}

export default AddToBag;
