import styled from 'styled-components';
import { Trans } from 'react-i18next';

import { typography, spacing, color } from '@selfridges-co/frontend-sdk-react-theme';

import { VisuallyHidden } from '../visually-hidden/VisuallyHidden';

import type { Price as PriceType } from '../types';

interface PriceProps {
  hiddenPrice?: PriceType;
  displayPrice?: PriceType;
  isFinal?: boolean;
  taxAndDuties?: string;
  variant?: 'regular' | 'small';
}

const Wrapper = styled.div<{ variant: 'regular' | 'small' }>(({ variant }) => ({
  minHeight: '24px',
  ...typography.typeface.text.md.bold,
  display: 'flex',

  ...(variant === 'small' && { ...typography.typeface.text.sm.bold, minHeight: '20px' }),
}));

const PriceContainer = styled.div<{ hasMarkdown: boolean }>(({ hasMarkdown }) => ({
  color: hasMarkdown ? color.palette.warningRed : color.palette.mainBlack,
  display: 'flex',
  flexWrap: 'wrap',
  gap: `${spacing(2)} ${spacing(4)}`,
}));

const PreMarkdownPrice = styled.span({
  color: color.palette.mainBlack,
  textDecoration: 'line-through',
});

const FromText = styled.span({
  color: color.palette.mainBlack,
  display: 'inline-block',
});

const TaxAndDuties = styled.div<{ hasPrice: boolean }>(({ hasPrice }) => ({
  ...typography.typeface.text.md.regular,
  color: color.palette.midGrey1,
  marginTop: spacing(2),
  visibility: `${hasPrice ? 'visible' : 'hidden'}`,
}));

function PriceMarkup({ price }: { price: PriceType }) {
  return (
    <PriceContainer hasMarkdown={Boolean(price.was || price.wasWas)}>
      <span>{price.current}</span>
      {price.was && <PreMarkdownPrice>{price.was}</PreMarkdownPrice>}
      {price.wasWas && <PreMarkdownPrice>{price.wasWas}</PreMarkdownPrice>}
    </PriceContainer>
  );
}

export default function Price({ isFinal, hiddenPrice, displayPrice, taxAndDuties, variant = 'regular' }: PriceProps) {
  return (
    <>
      <Wrapper variant={variant}>
        {displayPrice &&
          (!isFinal ? (
            <FromText>
              <Trans i18nKey="app.product-panel.price.from-price">from {{ price: displayPrice.current }}</Trans>
            </FromText>
          ) : (
            <PriceMarkup price={displayPrice} />
          ))}

        {!displayPrice && hiddenPrice && (
          <VisuallyHidden>
            <PriceMarkup price={hiddenPrice} />
          </VisuallyHidden>
        )}
      </Wrapper>

      {taxAndDuties && (
        <TaxAndDuties
          hasPrice={Boolean(displayPrice)}
          dangerouslySetInnerHTML={{
            __html: taxAndDuties,
          }}
        />
      )}
    </>
  );
}
