import { countryInfo } from '@selfridges-pkg/intl';

import type { AvailableCountryCode } from '@selfridges-pkg/intl/types';
import type { Price } from '@selfridges-pkg/product-aggregation-service-api/contracts/Price';

export function getPriceByCountryCode(prices: Nullable<Array<Price>>, countryCode: AvailableCountryCode) {
  if (!prices) return;

  return prices.find(price => price.currency === countryInfo[countryCode].sfCurrency);
}

export function extractTextFromProductName(productName: string) {
  const [, , subBrand, description] = productName.trim().match(/(<strong>(.+)<\/strong>)?(.+)/i) || [];

  return {
    subBrand: subBrand?.trim() || '',
    productDescription: description?.trim() || '',
  };
}
