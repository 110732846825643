import type { AvailableCurrency } from './types';

const currencyToIntlCurrencyMap: Record<AvailableCurrency, string> = {
  AUD: 'AUD',
  CAD: 'CAD',
  CNY: 'CNY',
  JPY: 'JPY',
  EUR: 'EUR',
  GBP: 'GBP',
  HKD: 'HKD',
  KRW: 'KRW',
  SGD: 'SGD',
  TWD: 'TWD',
  USD: 'USD',
  USX: 'USD',
  GBX: 'GBP',
};

export function formatPrice(price: number, currency: AvailableCurrency) {
  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: currencyToIntlCurrencyMap[currency],
    currencyDisplay: 'narrowSymbol',
  }).format(price);
}
