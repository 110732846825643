import { useCallback, useEffect, useMemo, useState } from 'react';

import type {
  SelectedColourValue,
  SelectedColour,
  ColourInfo,
  ColourSelectionSource,
  SetSelectedColour,
} from '../types';

export function useSelectedColour(colours?: Array<ColourInfo>): {
  selectedColour: SelectedColour;
  setSelectedColour: SetSelectedColour;
  isColourUrlHashChecked: boolean;
} {
  function setInitialState() {
    if (!colours) {
      return 'NOT_APPLICABLE';
    } else if (colours.length === 1) {
      return colours[0].name;
    }
    return 'NOT_SELECTED';
  }

  const [selectedColour, setSelectedColour] = useState<SelectedColourValue>(setInitialState());
  const [source, setSource] = useState<ColourSelectionSource>('NOT_SELECTED');
  const [isColourUrlHashChecked, setIsColourUrlHashChecked] = useState(false);

  const selectedColourInfo: SelectedColour = useMemo(() => {
    return {
      value: selectedColour,
      display: ['NOT_APPLICABLE', 'NOT_SELECTED'].includes(selectedColour) ? '' : selectedColour,
      source,
    };
  }, [selectedColour, source]);

  const updateSelectedColour = useCallback(
    (colour: string, source: ColourSelectionSource) => {
      if (!colours || colours.length < 2) return;

      const targetColour = colours.find(
        ({ name }) =>
          name.toLowerCase() === colour.toLowerCase() || name.toLowerCase().replace(/ /g, '') === colour.toLowerCase(),
      )?.name;
      setSelectedColour(targetColour || 'NOT_SELECTED');

      window.history.replaceState(null, '', targetColour ? `#colour=${encodeURIComponent(targetColour)}` : '#');

      setSource(source);
    },
    [colours, setSelectedColour],
  );

  useEffect(() => {
    const urlHashColour = window.location.hash.match(/#colour=(.[^&]+)/)?.[1];

    setIsColourUrlHashChecked(true);

    if (colours?.length === 1) {
      window.history.replaceState(null, '', `#colour=${encodeURIComponent(colours[0].name)}`);
    }

    if (!urlHashColour) return;
    updateSelectedColour(decodeURIComponent(urlHashColour.replace(/\+/g, '%20')), 'BROWSER_HASH');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { selectedColour: selectedColourInfo, setSelectedColour: updateSelectedColour, isColourUrlHashChecked };
}
