import { useMemo } from 'react';

import {
  Breadcrumb,
  Colours,
  SelfridgesPlusInfo,
  PromotionalMessages,
  ProjectEarth,
  Brand,
  Personalisation,
} from '@selfridges-pkg/product-aggregation-service-api/contracts/Product';
import { ProductResponse } from '@selfridges-pkg/product-aggregation-service-api/contracts/ProductResponse';
import { formatPrice } from '@selfridges-pkg/intl/formatPrice';
import { countryInfo } from '@selfridges-pkg/intl';
import { env } from '@selfridges-pkg/utils';
import { useLdFlags } from '@selfridges-pkg/feature-flag/LDContext';

import { cleanHtmlString, convertToHoverImage, generateErrorImageUrl } from '../utils';
import { PersonalisationInfo } from '../types';
import { isRatingReviewsProduct } from '../rating-reviews/isRatingReviewsProduct';
import { isFitFinderProduct } from '../fit-finder/isFitFinderProduct';

import { transformPriceWithCurrency } from './transformPriceWithCurrency';
import { extractTextFromProductName } from './helpers';

import type { AvailableCountryCode, AvailableCurrency } from '@selfridges-pkg/intl/types';
import type {
  BrandInfo,
  Breadcrumbs,
  ColourInfo,
  ColourInfos,
  Price,
  Sizes,
  SelfridgesPlusItems,
  ProjectEarthContent,
} from '../types';

const MEDIA_ROOT_URL = () => env('REACT_APP_MEDIA_ROOT_URL') || '';

function sanitiseSwatchName(colourName: string, swatchName: string) {
  const normalisedSwatchName = swatchName.toUpperCase();
  const normalisedColourName = colourName.replace(/ /g, '').toUpperCase();

  const original = swatchName.replace(/\//g, '');
  let sanitised = original;

  if (normalisedSwatchName.indexOf(normalisedColourName) > -1) {
    const sanitisedColourName = normalisedColourName.replace(/[[\](){}<>%$/\\+-.'!&]/g, '');

    sanitised = normalisedSwatchName.replace(normalisedColourName, sanitisedColourName);
  }

  sanitised = sanitised
    .normalize('NFD') // splits diacritic from it's base character (é -> e´)
    .replace(/\p{Diacritic}/gu, '') // removes diacritics
    .replace(/\u0142/g, 'l') // replaces l's with stroke with normal l
    .replace(/'/g, ''); // remove apostrophes

  return { original, sanitised };
}

type PDPData = {
  pageTitle: string;
  pageDescription: string;
  sizes?: Sizes;
  colourInfos?: ColourInfos;
  brandInfo: BrandInfo;
  productName: string;
  productDescription: string;
  selfridgesSays?: string;
  showSizeGuide: boolean;
  showCountdownTimer: boolean;
  details?: string;
  specifications?: string;
  price?: Price;
  taxAndDuties?: string;
  productOutOfStock: boolean;
  breadcrumbs?: Breadcrumbs;
  showQuantity: boolean;
  isBeauty: boolean;
  hasRatingReviews: boolean;
  hasFitFinder: boolean;
  displayFlag?: string;
  isGifting: boolean;
  personalisation?: PersonalisationInfo;
  isBundled: boolean;
  projectEarth?: ProjectEarthContent;
  selfridgesPlusItems?: SelfridgesPlusItems;
  productUrl: string;
  imageUrl: string;
  hoverImageUrl: string;
  promotionalMessages?: PromotionalMessages;
  productLaunchDate?: string;
  inStoreOnlyMessageHtml?: string;
  isInStoreOnly?: boolean;
};

function transformPageTitle(productDescription: string, brand?: string, subBrand?: string) {
  let brandTitle = '';
  let productDescriptionTitle = productDescription;

  if (brand) brandTitle = brand;
  if (subBrand) productDescriptionTitle = `${subBrand} ${productDescription}`;

  return `${brandTitle} - ${productDescriptionTitle} | Selfridges.com`;
}

function transformBreadcrumbs(breadcrumbs: Array<Breadcrumb>, countryLanguage: string): Breadcrumbs {
  return breadcrumbs.map(({ name, seo, key }) => ({
    title: name,
    url: `/${countryLanguage}/cat/${seo}/`,
    seo,
    key,
  }));
}

export function transformColours(colours: Nullable<Colours>): ColourInfos | undefined {
  if (!colours?.length) return undefined;

  return colours.map(({ name, swatch }): ColourInfo => {
    const { original, sanitised } = sanitiseSwatchName(name, swatch);

    return {
      name,
      swatchUrl: `${MEDIA_ROOT_URL()}/image/selfridges/${sanitised}?$PDP_SWATCH$=&defaultimage=${original}`,
    };
  });
}

export function transformBrand({
  brand,
  subBrand,
  countryLanguage,
}: {
  brand: Nullable<Brand>;
  subBrand?: string;
  countryLanguage: string;
}): BrandInfo {
  if (!brand) {
    return {
      name: '',
      seo: '',
      url: '',
    };
  }

  return {
    name: brand.name,
    subName: subBrand,
    seo: brand.seo.toLowerCase(),
    url: `/${countryLanguage}/cat/${brand.seo}/`,
  };
}

function transformSelfridgesPlus(selfridgesPlus: Array<SelfridgesPlusInfo>, countryLanguage: string) {
  const [countryCode] = countryLanguage.split('/') as [AvailableCountryCode];
  const ukEurope = selfridgesPlus.find(({ id }) => id === 'UkEurope');
  const global = selfridgesPlus.find(({ id }) => id === 'Global');

  const items: Array<SelfridgesPlusInfo> = (
    countryInfo[countryCode].continent === 'Europe' ? [ukEurope, global] : [global, ukEurope]
  ).filter((item): item is SelfridgesPlusInfo => Boolean(item));

  return items.map(({ title, copyList, price, skuId, partNumber, termsConditions }) => ({
    title: title,
    copyList: copyList,
    price: formatPrice(price.current, price.currency as AvailableCurrency),
    skuId,
    partNumber,
    termsConditions: {
      text: termsConditions.text,
      seoText: termsConditions.seoText,
      url: `/${countryLanguage}${termsConditions.url}`,
    },
  }));
}

function transformProjectEarth(projectEarth: ProjectEarth): ProjectEarthContent {
  const { genericTitle, genericText, customTitle, customText } = projectEarth.main;

  return {
    title: genericTitle,
    description: genericText,
    sideTitle: customTitle,
    sideDescription: customText,
  };
}

function transformPersonalisation(personalisation: Personalisation): PersonalisationInfo {
  const { mandatory, minChars, maxChars, regex, displayText, fonts, fontColours, price } = personalisation;

  return {
    mandatory,
    ...(minChars && minChars > 1 && { minChars }),
    ...(maxChars && { maxChars }),
    ...(regex && { allowedCharactersRegex: new RegExp(regex) }),
    ...(displayText && { invalidCharacterErrorMessage: displayText }),
    ...(fonts && { fonts }),
    ...(fontColours && { fontColours }),
    ...(price && {
      price: {
        formatted: formatPrice(price.current, price.currency as AvailableCurrency),
        currency: price.currency as AvailableCurrency,
        raw: price.current,
      },
    }),
  };
}

export const useTransformProductData = (
  productKey: string,
  partNumber: string,
  productResponse: ProductResponse,
  countryLanguage: string,
): PDPData => {
  const ldFlags = useLdFlags();
  const enableNewProductRoute = ldFlags['enable-new-product-route'];

  // eslint-disable-next-line sonarjs/cognitive-complexity
  return useMemo((): PDPData => {
    const {
      sizes,
      colours,
      brand,
      productName,
      selfridgesSays,
      showSizeGuide,
      details,
      specifications,
      price,
      taxAndDuties,
      outOfStock,
      breadcrumbs,
      displayFlag,
      projectEarth,
      giftMessageAvailable,
      personalisation,
      selfridgesPlus,
      media,
      promotionalMessages,
      showCountdownTimer,
      isBundled,
      productLaunchDate,
      inStoreOnlyMessageHtml,
    } = productResponse;

    const { subBrand, productDescription } = extractTextFromProductName(productName);

    return {
      pageTitle: transformPageTitle(productDescription, brand?.name, subBrand),
      pageDescription: selfridgesSays ? selfridgesSays.replace(/(<([^>]+)>)/gi, '') : '', // remove HTML tags
      brandInfo: transformBrand({ brand, subBrand, countryLanguage }),
      productName: `${subBrand} ${productDescription}`.trim(),
      productDescription,
      sizes: sizes || undefined,
      colourInfos: transformColours(colours),
      showQuantity: Boolean((!sizes && !colours) || (sizes && !colours) || (colours && !sizes)) && !personalisation,
      showSizeGuide,
      isBeauty: breadcrumbs?.[0]?.seo.toLowerCase() === 'beauty',
      hasRatingReviews: breadcrumbs
        ? isRatingReviewsProduct(transformBreadcrumbs(breadcrumbs, countryLanguage), countryLanguage)
        : false,
      hasFitFinder: breadcrumbs ? isFitFinderProduct(transformBreadcrumbs(breadcrumbs, countryLanguage)) : false,
      details: details || undefined,
      specifications: specifications || undefined,
      selfridgesSays: selfridgesSays ? cleanHtmlString(selfridgesSays) : undefined,
      price: price ? transformPriceWithCurrency(price) : undefined,
      taxAndDuties: taxAndDuties || undefined,
      breadcrumbs: breadcrumbs ? transformBreadcrumbs(breadcrumbs, countryLanguage) : undefined,
      productOutOfStock: outOfStock,
      displayFlag: displayFlag || undefined,
      isGifting: giftMessageAvailable,
      isBundled,
      personalisation: personalisation ? transformPersonalisation(personalisation) : undefined,
      projectEarth: projectEarth ? transformProjectEarth(projectEarth) : undefined,
      selfridgesPlusItems: selfridgesPlus ? transformSelfridgesPlus(selfridgesPlus, countryLanguage) : undefined,
      productUrl: `/${countryLanguage}/${enableNewProductRoute ? 'product' : 'cat'}/${productKey}_${partNumber}`,
      imageUrl: media?.defaultImage ? `${MEDIA_ROOT_URL()}/image/${media.defaultImage}` : generateErrorImageUrl(),
      hoverImageUrl: media?.defaultImage
        ? `${MEDIA_ROOT_URL()}/image/${convertToHoverImage(media.defaultImage, true)}`
        : generateErrorImageUrl(),
      promotionalMessages: promotionalMessages || undefined,
      showCountdownTimer,
      productLaunchDate: productLaunchDate || undefined,
      inStoreOnlyMessageHtml: inStoreOnlyMessageHtml ? cleanHtmlString(inStoreOnlyMessageHtml) : undefined,
      isInStoreOnly: displayFlag?.toLowerCase() === 'in store only' || displayFlag === '门店特供',
    };
  }, [countryLanguage, partNumber, productKey, productResponse, enableNewProductRoute]);
};
