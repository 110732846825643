import { MouseEvent } from 'react';
import styled from 'styled-components';
import { t } from 'i18next';

import { color } from '@selfridges-co/frontend-sdk-react-theme';

import { WISHLIST_ICON_SIZE } from '../constants';
import { useBrandConfig } from '../brand-configs/Context';

type IconButtonProps = {
  onClick: (event?: MouseEvent<HTMLElement>) => void;
  active: boolean;
};

function renderHeartIcon(colour: string) {
  const heartSvg = encodeURI(
    `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="${colour}" stroke="#212121"><path d="M8.259 15.817C10.009 17.362 11.737 18.344 12 18.49C12.264 18.344 13.992 17.362 15.742 15.817C16.843 14.844 17.712 13.859 18.331 12.892C19.128 11.646 19.5 10.625 19.5 9.5C19.5 7.377 17.726 5.5 15.579 5.5C14.873 5.5 14.096 5.761 13.402 6.258C12.998 6.548 12.659 6.894 12.413 7.253L12 7.856L11.587 7.253C11.341 6.894 11.002 6.548 10.598 6.258C9.904 5.761 9.127 5.5 8.421 5.5C6.275 5.5 4.5 7.377 4.5 9.5C4.53 10.57 4.904 11.697 5.669 12.891C6.289 13.859 7.157 14.844 8.259 15.817Z"/></svg>`,
  ).replace(/#/g, '%23');

  return `url("data:image/svg+xml,${heartSvg}")`;
}

const WishlistButtonEl = styled.button<{ selected: boolean; brandIconColour?: string }>(
  ({ selected, brandIconColour }) => ({
    backgroundColor: color.palette.lightGrey1,
    border: 0,
    padding: 0,

    backgroundImage: renderHeartIcon(color.palette.lightGrey1),
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: WISHLIST_ICON_SIZE,
    height: WISHLIST_ICON_SIZE,
    borderRadius: '3.6rem',

    '@media (hover: hover)': {
      ':hover': {
        cursor: 'pointer',
        backgroundColor: color.palette.midGrey2,
        backgroundImage: renderHeartIcon(brandIconColour || color.palette.midGrey2),
      },
    },

    ...(selected && {
      border: `1px solid ${color.palette.midGrey2}`,
      backgroundColor: color.palette.lightGrey1,
      backgroundImage: renderHeartIcon(brandIconColour || color.palette.mainYellow),
    }),
  }),
);

export default function IconButton({ onClick, active }: IconButtonProps) {
  const { wishlist } = useBrandConfig();

  return (
    <WishlistButtonEl
      onClick={onClick}
      selected={active}
      data-analytics-component="wish_list_button"
      data-analytics-details={active ? 'remove' : 'add'}
      brandIconColour={wishlist?.iconColour}
      aria-label={
        active
          ? t('app.wish-list.button.checked', { defaultValue: 'Saved to wish list' }).toString()
          : t('app.wish-list.button.unchecked', { defaultValue: 'Add to wish list' }).toString()
      }
    />
  );
}
