import styled from 'styled-components';

import { getStaticResourceUrl } from '@selfridges-pkg/utils';
import { breakpoints, color, spacing, typography } from '@selfridges-co/frontend-sdk-react-theme';
import { Col, Row } from '@selfridges-co/frontend-sdk-react-grid-system';

import ProjectEarthIcon from './ProjectEarthIcon.svg';

import type { ProjectEarthContent } from '../types';

type ProjectEarthProps = {
  projectEarth: ProjectEarthContent;
};

const ColWithNoTopMargin = styled(Col)({
  marginTop: 0,
});

const Title = styled.h3({
  ...typography.typeface.display.sm,
  color: color.palette.mainBlack,
});

const Description = styled.div({
  ...typography.typeface.text.md.regular,
  maxWidth: '34.3rem',
  color: color.palette.mainBlack,

  // override default behaviour of <strong> and <b> tags
  'strong, b': {
    ...typography.typeface.text.md.bold,
    fontWeight: 400,
  },

  a: {
    ...typography.typeface.text.md.bold,
    color: color.palette.mainBlack,
    ':hover': {
      color: color.palette.midGrey1,
    },
  },

  p: {
    marginTop: spacing(3),
  },

  'p:not(:last-child)': {
    marginBottom: spacing(3),
  },

  [breakpoints.md.mediaQuery]: {
    maxWidth: '100%',
  },
});

const Icon = styled.div({
  height: '54px',
  width: '54px',
  position: 'relative',
  marginBottom: spacing(6),

  '::after': {
    content: '" "',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundImage: `url(${getStaticResourceUrl(ProjectEarthIcon)})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
});

const Wrapper = styled.div({
  marginBottom: spacing(16),

  [breakpoints.md.mediaQuery]: {
    marginBottom: spacing(20),
  },
});

const Container = styled.div({
  display: 'inline-flex',
  gap: spacing(10),
  flexDirection: 'column',

  [breakpoints.md.mediaQuery]: {
    flexDirection: 'row',
  },
});

const Item = styled.div({
  flex: 1,
});

export default function ProjectEarth({ projectEarth }: ProjectEarthProps) {
  return (
    <Row>
      <ColWithNoTopMargin xxs={12}>
        <Wrapper>
          <Icon />
          <Container>
            <Item>
              <Title>{projectEarth.title}</Title>
              <Description
                dangerouslySetInnerHTML={{
                  __html: projectEarth.description,
                }}
              />
            </Item>
            <Item>
              <Title>{projectEarth.sideTitle}</Title>
              <Description
                dangerouslySetInnerHTML={{
                  __html: projectEarth.sideDescription,
                }}
              />
            </Item>
          </Container>
        </Wrapper>
      </ColWithNoTopMargin>
    </Row>
  );
}
