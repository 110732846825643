import { Trans } from 'react-i18next';

import { useModal } from '../modal/useModal';

import ColourDropdown from './ColourDropdown';
import ColourPalette from './ColourPalette';
import { ColourList } from './ColourList';

import type { ColourInfos, GetSku, SelectedColour, SetSelectedColour, SelectedSize } from '../types';

type ColourSelectorProps = {
  colours: ColourInfos;
  selectedSize: SelectedSize;
  selectedColour: SelectedColour;
  isBeauty: boolean;
  getSku: GetSku;
  showSelectPromptMessage: boolean;
  onSelect: SetSelectedColour;
};

export default function ColourSelector({
  colours,
  selectedSize,
  selectedColour,
  getSku,
  isBeauty,
  showSelectPromptMessage,
  onSelect,
}: ColourSelectorProps) {
  const { Modal: ColourListModal, open: openColourListModal, close: closeColourListModal } = useModal();

  return (
    <>
      {isBeauty ? (
        <ColourDropdown
          colours={colours}
          getSku={getSku}
          selectedSize={selectedSize}
          selectedColour={selectedColour}
          onClick={openColourListModal}
          showSelectPromptMessage={showSelectPromptMessage}
        />
      ) : (
        <ColourPalette
          colours={colours}
          getSku={getSku}
          selectedSize={selectedSize}
          selectedColour={selectedColour}
          onSelect={onSelect}
          onShowAll={openColourListModal}
          showSelectPromptMessage={showSelectPromptMessage}
        />
      )}

      <ColourListModal
        Title={
          <Trans i18nKey="app.colour-selector.colour-list-modal.title" count={colours.length}>
            {{ count: colours.length }} Colour:
          </Trans>
        }
        CloseButtonLabel={
          <Trans i18nKey="app.colour-selector.colour-list-modal.close-button-label">Close colour list modal</Trans>
        }
      >
        <ColourList
          colours={colours}
          selectedSize={selectedSize}
          selectedColour={selectedColour}
          onSelect={onSelect}
          onClick={closeColourListModal}
          getSku={getSku}
        />
      </ColourListModal>
    </>
  );
}
