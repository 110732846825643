import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

import { breakpoints, color, spacing } from '@selfridges-co/frontend-sdk-react-theme';
import { Picture, PictureProps } from '@selfridges-co/frontend-sdk-react-scene7';
import { ButtonLink } from '@selfridges-co/frontend-sdk-react-button-link';
import { getStaticResourceUrl, isMobile } from '@selfridges-pkg/utils';

import { MAX_IMAGE_HEIGHT } from '../constants';
import {
  DOT_WIDTH,
  DOTS_AMOUNT_TO_SHOW,
  MEDIUM_SIZE_DOT,
  SMALL_SIZE_DOT,
  useAnimatedDots,
} from '../hooks/useAnimatedDots';
import LoadingSkeleton from '../loading-skeleton/LoadingSkeleton';
import { ImageGalleryColumnSpan } from '../types';
import { generateErrorImageUrl } from '../utils';

import ZoomInCursor from './ZoomInCursor.png';
import ZoomImageModal from './ZoomImageModal';
import { Scene7Video } from './Scene7Video';

import type { MediaInfo } from './ZoomImageModal';
import type { ImageGalleryColumnSpan as ColumnSpan, ImageGalleryPattern, ProductImages, ProductVideos } from '../types';

export interface ImageGalleryProps {
  customisedPattern?: ImageGalleryPattern;
  viewAllImagePattern?: Array<ImageGalleryColumnSpan>;
  backgroundColour?: string;
  productImageUrlList?: ProductImages;
  productVideoList?: ProductVideos;
  maxInitialNumOfImagesValue?: number;
  imageAltText: string;
  loading: boolean;
  onUpdate?: () => void;
}

const GAP = spacing(2);
const PLUS_SVG = encodeURI(
  `<svg width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='#212121' strokeLinecap='round' xmlns='http://www.w3.org/2000/svg'><path d='M6 12H18' /><path d='M12 6V18' /></svg>`,
).replace(/#/g, '%23');

const LEFT_ARROW_SVG = encodeURI(
  `<svg width='16' height='12' viewBox='0 0 16 12' fill='none' stroke='#212121' stroke-linecap='round' stroke-linejoin='round' xmlns='http://www.w3.org/2000/svg'><path d='M15.5 6H0.5L6 11.5'/><path d='M15.5 6H0.5L6 0.5'/></svg>`,
).replace(/#/g, '%23');

const defaultPattern: ImageGalleryPattern = {
  '1': [2],
  '2': [2, 2],
  '3': [1, 1, 2],
  '4': [1, 1, 1, 1],
  '5': [1, 1, 2, 1, 1],
};

const defaultViewAllImagePattern: Array<ImageGalleryColumnSpan> = [2, 1, 1];

const ImageGalleryContainer = styled.div({
  position: 'relative',
  zIndex: 0,
});

const ImageGalleryWrapper = styled.div({
  boxSizing: 'border-box',

  [breakpoints.xs.mediaQuery]: {
    maxHeight: MAX_IMAGE_HEIGHT.TABLET,
  },

  [breakpoints.md.mediaQuery]: {
    height: 'auto',
    maxHeight: 'none',
  },
});

const ImageGallerySlider = styled.div({
  display: 'flex',

  flexWrap: 'nowrap',
  overflowX: 'auto',
  scrollSnapType: 'x mandatory',
  scrollBehavior: 'smooth',
  scrollbarWidth: 'none',

  [breakpoints.md.mediaQuery]: {
    flexWrap: 'wrap',
    marginTop: `-${GAP}`,
    marginLeft: `-${GAP}`,
  },

  ['::-webkit-scrollbar']: {
    display: 'none',
  },
});

const ImageContainerButton = styled.button<{
  columnSpan: number;
  backgroundColour: string;
  displayZoomCursor?: boolean;
}>(({ columnSpan, backgroundColour, displayZoomCursor = true }) => ({
  border: 'none',
  background: backgroundColour,
  position: 'relative',
  marginTop: 0,
  marginLeft: 0,
  padding: 0,
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  flexShrink: 0,
  scrollSnapAlign: 'start',
  scrollSnapStop: 'always',

  img: {
    transform: 'scale(1)',
    transition: 'transform 1.6s cubic-bezier(0,.7,.3,1)',
  },

  [breakpoints.xs.mediaQuery]: {
    maxHeight: MAX_IMAGE_HEIGHT.TABLET,
  },

  [breakpoints.md.mediaQuery]: {
    marginTop: GAP,
    marginLeft: GAP,
    width: `calc(${columnSpan * 50}% - ${GAP});`,
    maxHeight: MAX_IMAGE_HEIGHT.DESKTOP,

    ...(displayZoomCursor && {
      ':hover': {
        // uses 2x to make the cursor twice in pixel density to product better cursor image
        // IMPORTANT: PNG is required here to enable FireFox to work correctly
        cursor: `-webkit-image-set( url(${getStaticResourceUrl(ZoomInCursor)}) 2x ) 20 20, zoom-out`,

        img: {
          transform: 'scale(1.05)',
        },
      },
    }),
  },
}));

const ImageSkeletonContainer = styled(ImageContainerButton)({
  display: 'block',
  aspectRatio: '3 / 4',

  [breakpoints.xs.mediaQuery]: {
    maxHeight: MAX_IMAGE_HEIGHT.TABLET,
  },

  [breakpoints.md.mediaQuery]: {
    maxHeight: MAX_IMAGE_HEIGHT.DESKTOP,
  },
});

const ViewAllImageContainer = styled(ImageContainerButton)<{ showOnDesktop: boolean }>(({ showOnDesktop }) => ({
  [breakpoints.md.mediaQuery]: {
    display: showOnDesktop ? 'flex' : 'none',
  },
}));

const Image = styled(Picture).withConfig<{ columnSpan: number }>({
  shouldForwardProp: props => !['columnSpan'].includes(String(props)),
})(({ columnSpan }) => ({
  height: '100%',
  objectFit: 'contain',

  [breakpoints.md.mediaQuery]: {
    maxWidth: `${columnSpan === 2 ? 50 : 100}%`,
  },
}));

const VideoContainer = styled.div<{ ratio: number }>(({ ratio }) => ({
  width: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',

  [breakpoints.md.mediaQuery]: {
    width: ratio > 1 ? '100%' : `calc(50% - ${spacing(2)})`,
  },
}));

const PreviousButton = styled.button({
  border: 'none',
  borderRadius: '100%',
  backgroundColor: color.palette.mainWhite,
  fontSize: '0rem',
  transition: 'opacity 0.2s',
  cursor: 'pointer',
  display: 'block',
  width: '3.2rem',
  height: '3.2rem',
  justifySelf: 'center',
  position: 'absolute',
  top: '50%',
  left: '1.2rem',
  zIndex: 1,

  ':disabled': {
    opacity: 0,
    cursor: 'auto',
  },

  [breakpoints.md.mediaQuery]: {
    display: 'none',
  },

  '::after': {
    content: '" "',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundImage: `url("data:image/svg+xml,${LEFT_ARROW_SVG}")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
});

const NextButton = styled(PreviousButton)({
  left: 'auto',
  right: '1.2rem',
  transform: 'rotate(180deg)',
});

const ViewAllButtonContainer = styled.div({
  display: 'none',
  width: '100%',
  paddingTop: spacing(9),
  justifyContent: 'center',

  [breakpoints.md.mediaQuery]: {
    display: 'flex',
  },
});

const ViewAllButton = styled(ButtonLink).attrs({ as: 'button' })({
  border: 'none',
  display: 'none',
  cursor: 'pointer',
  userSelect: 'none',

  [breakpoints.md.mediaQuery]: {
    display: 'inline-block',
  },
});

const PlusIcon = styled.div({
  backgroundImage: `url("data:image/svg+xml,${PLUS_SVG}")`,
  display: 'inline-block',
  height: '2.4rem',
  width: '2.4rem',
  verticalAlign: 'middle',
});

const DotGroupContainer = styled.ul<{ imagesLength: number }>(({ imagesLength }) => ({
  listStyle: 'none',
  margin: 0,
  padding: spacing(0, 0, 3, 3),
  position: 'absolute',
  bottom: 0,
  left: 0,
  display: 'flex',
  maxWidth: `${DOTS_AMOUNT_TO_SHOW * DOT_WIDTH}px`,
  overflowX: 'hidden',

  ...(imagesLength > DOTS_AMOUNT_TO_SHOW && {
    [`li:nth-child(${DOTS_AMOUNT_TO_SHOW - 1})`]: {
      transform: `scale(${MEDIUM_SIZE_DOT})`,

      span: {
        borderWidth: `${1 / MEDIUM_SIZE_DOT}px`,
      },
    },

    [`li:nth-child(${DOTS_AMOUNT_TO_SHOW})`]: {
      transform: `scale(${SMALL_SIZE_DOT})`,

      span: {
        borderWidth: `${1 / SMALL_SIZE_DOT}px`,
      },
    },

    [`li:nth-child(${DOTS_AMOUNT_TO_SHOW + 1})`]: {
      transform: `scale(${SMALL_SIZE_DOT})`,

      span: {
        borderWidth: `${1 / SMALL_SIZE_DOT}px`,
      },
    },
  }),

  [breakpoints.md.mediaQuery]: {
    display: 'none',
  },
}));

const Dot = styled.li<{ active: boolean }>(({ active }) => ({
  paddingRight: spacing(3),
  position: 'relative',
  transition: 'all .2s',
  transformOrigin: 'left',

  span: {
    boxSizing: 'border-box',
    width: '8px',
    height: '8px',
    display: 'block',
    borderRadius: '100%',
    border: `1px solid ${color.palette.mainBlack}`,
    overflow: 'hidden',
    ...(active && { background: color.palette.mainBlack }),
  },
}));

function gotoSlide(sliderRef: React.RefObject<HTMLDivElement>, index: number, lastPosition: { current: number }): void {
  if (!sliderRef.current) return;

  const sliderEl = sliderRef.current;

  const slideWidth = sliderEl.clientWidth;

  lastPosition.current = sliderEl.scrollLeft;
  sliderEl.scrollLeft = slideWidth * index;
}

function computeCurrentSlide(
  intersections: Array<IntersectionObserverEntry>,
  sliderRef: React.RefObject<HTMLDivElement>,
  setCurrentSlideIndex: React.Dispatch<React.SetStateAction<number>>,
  setIsScrollingLeft: React.Dispatch<React.SetStateAction<boolean>>,
  lastPosition: React.MutableRefObject<number>,
): void {
  if (!sliderRef.current) return;

  const intersection = intersections.find(currentIntersection => currentIntersection.isIntersecting);

  const sliderChildren = Array.from(sliderRef.current.children) as Array<HTMLDivElement>;

  const intersectionIndex = sliderChildren.findIndex(child => intersection?.target === child);

  if (intersectionIndex === -1) return;

  const isScrollingLeft = sliderRef.current.scrollLeft > lastPosition.current;

  setIsScrollingLeft(previous => {
    if (!sliderRef.current) return previous;

    return isScrollingLeft;
  });

  setCurrentSlideIndex(previous => {
    if (!sliderRef.current) return previous;

    const slideIndexDiff = Math.abs(intersectionIndex - previous) > 0;

    // if scrolling left
    if (isScrollingLeft && previous > intersectionIndex && slideIndexDiff) return previous;
    // if scrolling right
    if (!isScrollingLeft && previous < intersectionIndex && slideIndexDiff) return previous;

    return intersectionIndex;
  });

  lastPosition.current = sliderRef.current.scrollLeft;
}

function getFinalImagesColumnSpan(
  index: number,
  viewAllUrlListLength: number,
  viewAllImagePattern: Array<ImageGalleryColumnSpan>,
): any {
  const secondLastImageIndex = viewAllUrlListLength - 2;
  const lastImageIndex = viewAllUrlListLength - 1;

  if (viewAllUrlListLength === 1) {
    return 2;
  }

  const shouldCollapse =
    viewAllImagePattern[secondLastImageIndex % viewAllImagePattern.length] === 2 &&
    viewAllImagePattern[lastImageIndex % viewAllImagePattern.length] === 1;

  if (shouldCollapse && index === secondLastImageIndex) {
    return 1;
  }

  let isOdd = false;
  if (index === lastImageIndex) {
    for (let p = lastImageIndex; p >= 0; p--) {
      if (viewAllImagePattern[p % viewAllImagePattern.length] === 2) {
        isOdd = Boolean((viewAllUrlListLength - (p + 1)) % 2);
        break;
      } else if (p === 0 && viewAllImagePattern[p % viewAllImagePattern.length] === 1) {
        isOdd = Boolean(viewAllUrlListLength % 2);
        break;
      }
    }
  }

  if (isOdd && !shouldCollapse) {
    return 2;
  }
}

export default function ImageGallery({
  customisedPattern,
  viewAllImagePattern = defaultViewAllImagePattern,
  productImageUrlList,
  productVideoList,
  backgroundColour = color.palette.lightGrey2,
  imageAltText,
  loading,
  onUpdate,
}: ImageGalleryProps): React.ReactElement {
  const { t } = useTranslation();

  const imagePatternPerNumberOfImages: Partial<ImageGalleryPattern> = customisedPattern || defaultPattern;
  const maxInitialImagesLength = Object.keys(imagePatternPerNumberOfImages).length;

  const imageUrlList = useMemo(() => productImageUrlList || [generateErrorImageUrl()], [productImageUrlList]);

  const sliderRef = useRef<HTMLDivElement>(null);
  const dotsRef = useRef<HTMLUListElement>(null);
  const lastPosition = useRef(0);

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [viewAllImages, setViewAllImages] = useState(false);
  const [isScrollingLeft, setIsScrollingLeft] = useState(false);
  const [faultyVideoUrls, setFaultyVideoUrls] = useState<Array<string>>([]);

  const validVideos = productVideoList?.filter(video => !faultyVideoUrls.includes(video.url));

  let maxInitialMediaLength = validVideos?.length ? maxInitialImagesLength - 1 : maxInitialImagesLength;
  maxInitialMediaLength = Math.min(maxInitialMediaLength, imageUrlList.length);

  const imagePatternKey = Math.min(imageUrlList.length, maxInitialMediaLength);
  const displayPattern: Array<ColumnSpan> = imagePatternPerNumberOfImages[imagePatternKey];

  const viewAllUrlListLength = imageUrlList.slice(maxInitialMediaLength).length;

  const [zoomedImageIndex, setZoomedImageIndex] = useState<number>(-1);

  const { dotsLeftPos } = useAnimatedDots({
    dotsRef,
    imagesLength: imageUrlList.length,
    currentSlideIndex,
    isScrollingLeft,
  });

  useEffect(() => {
    if (!sliderRef.current) return;

    const observer = new IntersectionObserver(
      intersections =>
        computeCurrentSlide(intersections, sliderRef, setCurrentSlideIndex, setIsScrollingLeft, lastPosition),
      {
        root: sliderRef.current,
        rootMargin: '0px',
        threshold: [0.5],
      },
    );

    Array.from(sliderRef.current.children).forEach(element => {
      observer.observe(element);
    });

    setViewAllImages(false);

    if (onUpdate) onUpdate();

    return () => {
      observer.disconnect();
    };
  }, [imageUrlList, onUpdate]);

  useEffect(() => {
    if (viewAllImages && sliderRef.current) {
      (sliderRef.current.children[maxInitialImagesLength] as HTMLButtonElement).focus();
    }
    if (onUpdate) onUpdate();
  }, [viewAllImages, onUpdate, maxInitialImagesLength]);

  useEffect(() => {
    const matchMedia = window.matchMedia('(orientation:portrait)');
    matchMedia.addEventListener('change', () => {
      if (sliderRef.current === null) return;

      const sliderEl = sliderRef.current;
      const slideWidth = sliderEl.clientWidth;

      lastPosition.current = sliderEl.scrollLeft;
      sliderEl.scrollLeft = slideWidth * currentSlideIndex;
    });
  }, [currentSlideIndex]);

  function handleNextButtonClick() {
    setIsScrollingLeft(true);
    setCurrentSlideIndex(previous => {
      gotoSlide(sliderRef, previous + 1, lastPosition);
      return previous + 1;
    });
  }

  function handlePreviousButtonClick() {
    setIsScrollingLeft(false);
    setCurrentSlideIndex(previous => {
      gotoSlide(sliderRef, previous - 1, lastPosition);
      return previous - 1;
    });
  }

  function handleVideoError(url: string) {
    setFaultyVideoUrls(prev => [...prev, url]);
  }

  const handleImageClick = useCallback(
    (index: number) => {
      if (!productImageUrlList) return;

      return () => {
        setZoomedImageIndex(index);
      };
    },
    [productImageUrlList],
  );

  const imageSettings: Pick<PictureProps, 'intrinsicMinWidth' | 'aspectRatio' | 'extendedCrop' | 'bgColour'> = {
    intrinsicMinWidth: {
      xxs: 399,
      xs: 767,
      sm: 1023,
      md: 392,
      lg: 476,
    },
    aspectRatio: [3, 4],
    extendedCrop: [-18, 0, -18, 0],
    bgColour: backgroundColour,
  };

  const mediaInfoList = imageUrlList.map<MediaInfo>(value => ({ type: 'image', url: value }));

  if (validVideos?.length) {
    const videos: Array<MediaInfo> = validVideos.map(video => ({
      type: 'video',
      url: video.url || '',
    }));

    mediaInfoList.splice(4, 0, videos[0]);
    mediaInfoList.push(...videos.slice(1));
  }

  return (
    <>
      <ImageGalleryContainer>
        <PreviousButton onClick={handlePreviousButtonClick} disabled={currentSlideIndex === 0}>
          <Trans i18nKey="image-gallery.previous-button">Previous Image</Trans>
        </PreviousButton>
        <ImageGalleryWrapper>
          <ImageGallerySlider ref={sliderRef} data-testid="slider">
            {loading ? (
              <Loading backgroundColour={backgroundColour} numOfLoadingImages={3} />
            ) : (
              <>
                {imageUrlList.slice(0, maxInitialMediaLength).map((imageUrl, index) => {
                  const columnSpan = displayPattern[index];
                  return (
                    <ImageContainerButton
                      key={imageUrl}
                      columnSpan={columnSpan}
                      backgroundColour={backgroundColour}
                      onClick={handleImageClick(index)}
                      displayZoomCursor={Boolean(productImageUrlList)}
                    >
                      <Image
                        columnSpan={columnSpan}
                        src={imageUrl}
                        loading="eager"
                        alt={`${t('image-gallery.image.alt-text', {
                          defaultValue: '{{altText}} - Image {{index}}',
                          altText: imageAltText,
                          index: index + 1,
                        }).toString()}`}
                        {...imageSettings}
                      />
                    </ImageContainerButton>
                  );
                })}
                {Boolean(validVideos?.length) &&
                  validVideos?.slice(0, maxInitialImagesLength - maxInitialMediaLength).map((video, videoIndex) => (
                    <ImageContainerButton
                      key={video.url}
                      columnSpan={2}
                      backgroundColour={backgroundColour}
                      onClick={handleImageClick(maxInitialMediaLength + videoIndex)}
                      displayZoomCursor={Boolean(productImageUrlList)}
                    >
                      <VideoContainer ratio={video.ratio}>
                        <Scene7Video
                          src={video.url}
                          fmt="mp4"
                          centerPlayButton={isMobile()}
                          alt={`${t('image-gallery.video.alt-text', {
                            defaultValue: '{{altText}} - Video',
                            altText: imageAltText,
                          }).toString()}`}
                          muted={true}
                          showControls={false}
                          autoPlay={!isMobile() && (validVideos.length === 1 || videoIndex === 0)}
                          showPlayButton={true}
                          onError={() => handleVideoError(video.url)}
                        ></Scene7Video>
                      </VideoContainer>
                    </ImageContainerButton>
                  ))}
                {imageUrlList.slice(maxInitialMediaLength).map((imageUrl, index) => {
                  const currentIndex = index % viewAllImagePattern.length;

                  let columnSpan = viewAllImagePattern[currentIndex];

                  const isEndOfGallery = index >= viewAllUrlListLength - 2;
                  if (isEndOfGallery) {
                    columnSpan =
                      getFinalImagesColumnSpan(index, viewAllUrlListLength, viewAllImagePattern) || columnSpan;
                  }

                  return (
                    <ViewAllImageContainer
                      key={imageUrl}
                      columnSpan={columnSpan}
                      backgroundColour={backgroundColour}
                      showOnDesktop={viewAllImages}
                      onClick={() =>
                        setZoomedImageIndex(
                          (validVideos?.length ? maxInitialMediaLength + 1 : maxInitialMediaLength) + index,
                        )
                      }
                    >
                      <Image
                        columnSpan={columnSpan}
                        src={imageUrl}
                        loading="lazy"
                        alt={`${t('image-gallery.image.alt-text', {
                          defaultValue: '{{altText}} - Image {{index}}',
                          altText: imageAltText,
                          index: maxInitialMediaLength + index + 1,
                        }).toString()}`}
                        {...imageSettings}
                      />
                    </ViewAllImageContainer>
                  );
                })}
                {Boolean(validVideos?.length) &&
                  validVideos?.slice(maxInitialImagesLength - maxInitialMediaLength).map((video, videoIndex) => (
                    <ViewAllImageContainer
                      key={video.url}
                      columnSpan={2}
                      backgroundColour={backgroundColour}
                      onClick={handleImageClick(imageUrlList.length + 1 + videoIndex)}
                      displayZoomCursor={Boolean(productImageUrlList)}
                      showOnDesktop={viewAllImages}
                    >
                      <VideoContainer ratio={video.ratio}>
                        <Scene7Video
                          src={video.url}
                          fmt="mp4"
                          centerPlayButton={isMobile()}
                          alt={`${t('image-gallery.video.alt-text', {
                            defaultValue: '{{altText}} - Video',
                            altText: imageAltText,
                          }).toString()}`}
                          muted={true}
                          showControls={false}
                          autoPlay={false}
                          showPlayButton={true}
                          onError={() => handleVideoError(video.url)}
                        ></Scene7Video>
                      </VideoContainer>
                    </ViewAllImageContainer>
                  ))}
              </>
            )}
          </ImageGallerySlider>
          <DotGroupContainer ref={dotsRef} imagesLength={mediaInfoList.length} data-testid="image-gallery-dots">
            {mediaInfoList.length > 1 &&
              mediaInfoList.map((imageUrl, index) => {
                return (
                  <Dot
                    style={{
                      left: `${dotsLeftPos}px`,
                    }}
                    key={imageUrl.url}
                    active={currentSlideIndex === index}
                    aria-current={currentSlideIndex === index}
                  >
                    <span />
                  </Dot>
                );
              })}
          </DotGroupContainer>
        </ImageGalleryWrapper>
        <NextButton onClick={handleNextButtonClick} disabled={currentSlideIndex === mediaInfoList.length - 1}>
          <Trans i18nKey="image-gallery.next-button">Next Image</Trans>
        </NextButton>
        {!viewAllImages && mediaInfoList.length > maxInitialImagesLength && (
          <ViewAllButtonContainer>
            <ViewAllButton buttonType="secondary" variant="grey" onClick={() => setViewAllImages(true)}>
              <Trans i18nKey="image-gallery.view-all-images-button">View all images</Trans> <PlusIcon />
            </ViewAllButton>
          </ViewAllButtonContainer>
        )}
      </ImageGalleryContainer>
      <ZoomImageModal
        mediaInfoList={mediaInfoList}
        altText={imageAltText}
        zoomedImageIndex={zoomedImageIndex}
        onClickThumbnail={(index: number) => setZoomedImageIndex(index)}
        onPreviousImage={() => setZoomedImageIndex(zoomedImageIndex - 1)}
        onNextImage={() => setZoomedImageIndex(zoomedImageIndex + 1)}
        onClose={() => setZoomedImageIndex(-1)}
        imageBackgroundColour={backgroundColour}
      />
    </>
  );
}

type LoadingProps = {
  backgroundColour: string;
  numOfLoadingImages: number;
};

function Loading({ backgroundColour, numOfLoadingImages }: LoadingProps) {
  return (
    <>
      {Array(3)
        .fill(null)
        .map((_, index) => (
          <ImageSkeletonContainer
            key={`loading-skeleton-${index}`}
            columnSpan={defaultPattern[numOfLoadingImages][index]}
            backgroundColour={backgroundColour}
            displayZoomCursor={false}
          >
            <LoadingSkeleton height="100%" />
          </ImageSkeletonContainer>
        ))}
    </>
  );
}
