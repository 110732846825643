import styled from 'styled-components';

import { typography, color, spacing } from '@selfridges-co/frontend-sdk-react-theme';

export type ReturnsProps = {
  title?: string;
  description?: string;
  termsAndConditions?: string;
  internationalDescription?: string;
};

const Title = styled.h3({
  ...typography.typeface.text.lg.bold,
  marginBottom: spacing(3),
});

const Text = styled.p({
  ...typography.typeface.text.md.regular,
  marginBottom: spacing(3),
});

const TermsAndConditions = styled.div({
  ...typography.typeface.text.md.regular,

  a: {
    ...typography.typeface.text.md.bold,
    color: color.palette.mainBlack,
    textDecoration: 'none',
  },
});

export default function Returns({ title, description, termsAndConditions, internationalDescription }: ReturnsProps) {
  return (
    <div>
      {title && <Title>{title}</Title>}
      {description && <Text>{description}</Text>}
      {internationalDescription && <Text>{internationalDescription}</Text>}
      {termsAndConditions && (
        <TermsAndConditions
          dangerouslySetInnerHTML={{
            __html: termsAndConditions,
          }}
        />
      )}
    </div>
  );
}
