import styled from 'styled-components';
import { Trans } from 'react-i18next';

import { breakpoints, color, spacing, typography } from '@selfridges-co/frontend-sdk-react-theme';

import { SelectedSize } from '../types';

export type SizeDropdownProps = {
  sizes: Array<string>;
  selectedSize: SelectedSize;
  hideOnMobile: boolean;
  hideOnDesktop: boolean;
  onClick: () => void;
};

const Wrapper = styled.div<{ hideOnMobile: boolean; hideOnDesktop: boolean }>(({ hideOnMobile, hideOnDesktop }) => ({
  display: hideOnMobile ? 'none' : 'block',

  [breakpoints.md.mediaQuery]: {
    display: hideOnDesktop ? 'none' : 'block',
  },
}));

const Dropdown = styled.button({
  ...typography.typeface.text.md.regular,
  background: 'none',
  border: `1px solid ${color.palette.midGrey2}`,
  color: color.palette.mainBlack,
  padding: 0,
  width: '100%',
  alignItems: 'center',
  textAlign: 'left',
  cursor: 'pointer',
  display: 'flex',
});

const ChevronDown = styled.span({
  backgroundImage: `url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M7 10L12 15L17 10' stroke='%23212121' stroke-linecap='round'/%3e%3c/svg%3e")`,
  backgroundRepeat: 'no-repeat',
  width: '24px',
  height: '24px',
  margin: spacing(3),
});

const DropdownText = styled.div({
  flexGrow: 1,
  padding: spacing(3),
});

function SizeDropdown({ sizes, selectedSize, hideOnDesktop, hideOnMobile, onClick }: SizeDropdownProps) {
  return (
    <Wrapper hideOnDesktop={hideOnDesktop} hideOnMobile={hideOnMobile}>
      <Dropdown {...(sizes.length > 1 && { onClick })}>
        <DropdownText>
          {selectedSize.display || <Trans i18nKey="app.product-panel.size-dropdown.text">Choose size</Trans>}
        </DropdownText>
        {sizes.length > 1 && <ChevronDown />}
      </Dropdown>
    </Wrapper>
  );
}

export default SizeDropdown;
