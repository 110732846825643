import { env } from '@selfridges-pkg/utils';

import { convertToHoverImage, generateErrorImageUrl } from '../utils';

import { transformBrand, transformColours } from './transformProductData';
import { transformToStockAndPriceMap } from './transformToStockAndPriceMap';
import { getPriceByCountryCode } from './helpers';

import type { BundleProduct as BundleProductFromResponse } from '@selfridges-pkg/product-aggregation-service-api/contracts/BundleProduct';
import type { BundlesResponse } from '@selfridges-pkg/product-aggregation-service-api/contracts/BundlesResponse';
import type { Sku } from '@selfridges-pkg/product-aggregation-service-api/contracts/Sku';
import type { Colours, Sizes } from '@selfridges-pkg/product-aggregation-service-api/contracts/Product';
import type { AvailableCountryCode } from '@selfridges-pkg/intl/types';
import type { BundleProductBase, BundleProductMedia, CountryLanguage, StockAndPriceMap } from '../types';
import type { SanitisedSku } from './transformToStockAndPriceMap';

type BundleId = string;
type PartNumber = string;
type ColourName = string;
type BundleProductSkus = Record<BundleId, Record<PartNumber, StockAndPriceMap>>;
type BundleProductMap = Record<BundleId, Record<PartNumber, BundleProductBase>>;
type BundleProductMediaMap = Record<BundleId, Record<PartNumber, Record<ColourName, BundleProductMedia>>>;

const MEDIA_ROOT_URL = () => env('REACT_APP_MEDIA_ROOT_URL') || '';

function getUniqueColoursAndSizesFromAllSkus(skus: Array<Sku>) {
  const sizes: Sizes = [];
  const colours: Colours = [];

  skus.forEach(({ size, colour }) => {
    if (size && !sizes.includes(size)) {
      sizes.push(size);
    }

    if (colour && !colours.some(storedColour => storedColour.name === colour.name)) {
      colours.push(colour);
    }
  });

  return { sizes, colours };
}

function isProjectEarth(displayFlag: Nullable<string>): boolean {
  if (!displayFlag) return false;

  const keywords = ['project earth', '地球计划'];

  return keywords.some(keyword => displayFlag.toLowerCase().indexOf(keyword) >= 0);
}

function sanitiser(
  result: Array<SanitisedSku>,
  { skuId, prices, availableQuantity, colour: skuColour, size: skuSize }: Sku,
  countryCode: AvailableCountryCode,
): Array<SanitisedSku> {
  // Remove item if price is missing
  const localePrice = getPriceByCountryCode(prices, countryCode);
  if (!localePrice || !localePrice.current) return result;

  const gbpPrice = getPriceByCountryCode(prices, 'GB');

  if (availableQuantity === null) availableQuantity = 0;

  result.push({
    skuId,
    price: localePrice,
    gbpPrice,
    availableStock: availableQuantity,
    colour: skuColour?.name || null,
    size: skuSize,
  });

  return result;
}

function transformSkus(skus: Array<Sku>, countryCode: AvailableCountryCode): StockAndPriceMap {
  const sanitisedList = skus
    .reduce((acc: Array<SanitisedSku>, item) => sanitiser(acc, item, countryCode), [])
    .sort((a, b) => a.price.current - b.price.current);

  return transformToStockAndPriceMap(sanitisedList);
}

export function transformToBundleProductMediaMap(bundlesResponse: BundlesResponse | undefined): BundleProductMediaMap {
  if (!bundlesResponse) return {};

  return Object.entries(bundlesResponse).reduce((acc: BundleProductMediaMap, [bundleId, bundleProducts]) => {
    if (acc[bundleId]) return acc;

    acc[bundleId] = bundleProducts.reduce(
      (
        productMediaMap: Record<PartNumber, Record<ColourName, BundleProductMedia>>,
        bundleProduct: BundleProductFromResponse,
      ) => {
        const { partNumber, skus } = bundleProduct;

        if (productMediaMap[partNumber]) return productMediaMap;

        productMediaMap[partNumber] = skus.reduce((colourMediaMap: Record<ColourName, BundleProductMedia>, sku) => {
          const colourName = sku.colour?.name || '';

          if (!sku.media) {
            const errorImageUrl = generateErrorImageUrl();

            colourMediaMap[colourName] = {
              imageUrl: errorImageUrl,
              hoverImageUrl: errorImageUrl,
            };

            return colourMediaMap;
          }

          colourMediaMap[colourName] = {
            imageUrl: `${MEDIA_ROOT_URL()}/image/${sku.media.defaultImage}`,
            hoverImageUrl: `${MEDIA_ROOT_URL()}/image/${convertToHoverImage(sku.media.defaultImage, true)}`,
          };

          return colourMediaMap;
        }, {});

        return productMediaMap;
      },
      {},
    );

    return acc;
  }, {});
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function transformToBundleProductMap(
  bundlesResponse: BundlesResponse | undefined,
  countryLanguage: CountryLanguage,
  enableProductRoute: boolean,
): BundleProductMap {
  if (!bundlesResponse) return {};

  return Object.entries(bundlesResponse).reduce((acc: BundleProductMap, [bundleId, bundleProducts]) => {
    if (acc[bundleId]) return acc;

    acc[bundleId] = bundleProducts.reduce(
      (productMap: Record<string, BundleProductBase>, bundleProduct: BundleProductFromResponse) => {
        const {
          partNumber,
          brand,
          productName,
          displayFlag,
          skus,
          department,
          season,
          breadcrumbs,
          division,
          group,
          parentCategory,
          seoKey,
        } = bundleProduct;

        if (productMap[partNumber]) return productMap;

        const { colours, sizes } = getUniqueColoursAndSizesFromAllSkus(skus);

        const transformedBrand = brand
          ? transformBrand({ brand, countryLanguage: countryLanguage.toString() })
          : undefined;

        const output: BundleProductBase = {
          partNumber,
          productUrl: `/${countryLanguage.toString()}/${enableProductRoute ? 'product' : 'cat'}/${seoKey}/`,
          brand: transformedBrand,
          productName: productName || undefined,
          displayFlag: displayFlag || undefined,
          isProjectEarth: isProjectEarth(displayFlag),
          sizes: sizes.length ? sizes : undefined,
          colours: transformColours(colours),
          department,
          season,
          breadcrumbs,
          division,
          group,
          parentCategory: parentCategory || undefined,
        };

        productMap[partNumber] = output;

        return productMap;
      },
      {},
    );

    return acc;
  }, {});
}

export function transformToBundleProductSkusMap(
  bundlesResponse: BundlesResponse | undefined,
  countryCode: AvailableCountryCode,
): BundleProductSkus {
  if (!bundlesResponse) return {};

  return Object.entries(bundlesResponse).reduce((acc: BundleProductSkus, [bundleId, bundleProducts]) => {
    if (acc[bundleId]) return acc;

    acc[bundleId] = bundleProducts.reduce((productSkusMap: Record<PartNumber, StockAndPriceMap>, bundleProduct) => {
      const { partNumber, skus } = bundleProduct;
      if (productSkusMap[partNumber]) return productSkusMap;

      productSkusMap[partNumber] = transformSkus(skus, countryCode);

      return productSkusMap;
    }, {});

    return acc;
  }, {});
}
