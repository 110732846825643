import { StockAndPriceResponse } from './contracts/StockAndPriceResponse';

export async function fetchStockAndPrice({
  hostRootUrl,
  countryLanguage,
  partNumber,
  additionalHeaders = {},
}: {
  hostRootUrl: string;
  countryLanguage: string;
  partNumber: string;
  additionalHeaders?: Record<string, string>;
}): Promise<StockAndPriceResponse> {
  const response = await fetch(`${hostRootUrl}/${countryLanguage}/product/${partNumber}/stock-and-price`, {
    headers: { ...additionalHeaders },
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message);
  }

  return await response.json();
}
