import { useEffect, useState } from 'react';

import type {
  RatingsAndReviewsResponse,
  Review as ReviewResponse,
} from '@selfridges-pkg/yotpo-api/contracts/RatingsAndReviewsResponse';
import type { Review, Rating } from '../types';

function transformReviews(reviews?: Array<ReviewResponse>): Array<Review> {
  if (!reviews) return [];

  return reviews.reduce((acc: Array<Review>, review) => {
    return [
      ...acc,
      {
        id: review.id,
        title: review.title,
        content: review.content,
        rating: review.score as Rating,
        user: {
          name: review.user.display_name,
          verifiedBuyer: review.verified_buyer,
        },
        createdTimestamp: review.created_at,
      },
    ];
  }, []);
}

export function useTransformReviewsData(data?: RatingsAndReviewsResponse): Array<Review> {
  const [reviews, setReviews] = useState<Array<Review>>([]);

  useEffect(() => {
    const transformedReviews = transformReviews(data?.response.reviews);

    if (!transformedReviews.length) return;

    setReviews(prev => {
      if (data?.response.pagination.page === 1) return transformedReviews;

      return [...prev, ...transformedReviews];
    });
  }, [data]);

  return reviews;
}
