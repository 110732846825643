import { RefObject, useEffect } from 'react';

import { isMobile } from '@selfridges-pkg/utils';

import { TOAST_MESSAGE_ANIMATION_DURATION_IN_MS } from '../constants';

function adjustForShortScreen(offsetTop: number, stickyPanelRef: RefObject<HTMLDivElement>) {
  if (!stickyPanelRef.current) return;

  const stickyPanelEl = stickyPanelRef.current;
  const stickyPanelChild = stickyPanelEl.children[0] as HTMLElement;

  stickyPanelEl.style.height = ''; // reset the height for the calculation
  stickyPanelChild.style.height = ''; // reset the height for the calculation

  const heightDiff = stickyPanelEl.offsetHeight + offsetTop - window.innerHeight;

  if (heightDiff > 0) {
    Object.assign(stickyPanelChild.style, {
      marginTop: `${heightDiff}px`,
      transform: `translateY(${-heightDiff}px)`,
      height: `${stickyPanelEl.offsetHeight}px`,
    });

    Object.assign(stickyPanelEl.style, {
      height: `${stickyPanelEl.offsetHeight - heightDiff}px`,
    });
  } else {
    Object.assign(stickyPanelChild.style, {
      marginTop: '0px',
      transform: 'translateY(0px)',
    });

    Object.assign(stickyPanelEl.style, {
      height: '',
    });
  }
}

export function useAdjustProductPanelHeight({
  offsetTop,
  numOfToastMessages,
  stickyPanelRef,
  reviewsLength,
  personalisationMessageLength,
  giftMessageLength,
  numOfBundleProducts,
}: {
  offsetTop: number;
  numOfToastMessages: number;
  stickyPanelRef: RefObject<HTMLDivElement>;
  reviewsLength: number;
  giftMessageLength: number;
  personalisationMessageLength: number;
  numOfBundleProducts: number;
}) {
  useEffect(() => {
    if (isMobile()) return;

    function handleResize() {
      adjustForShortScreen(offsetTop, stickyPanelRef);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [offsetTop, stickyPanelRef, reviewsLength, giftMessageLength, personalisationMessageLength, numOfBundleProducts]);

  useEffect(() => {
    if (isMobile()) return;

    // use timeout to allow animation to complete before adjusting height for short screen
    setTimeout(
      () => adjustForShortScreen(offsetTop, stickyPanelRef),
      // To make animation smoother, we use 0ms when number of messages is 0
      // Otherwise, use extra 100ms delay on top of default animation duration to allow animation to settle
      // before adjusting for short screen
      numOfToastMessages ? TOAST_MESSAGE_ANIMATION_DURATION_IN_MS + 100 : 0,
    );
  }, [
    numOfToastMessages,
    offsetTop,
    stickyPanelRef,
    reviewsLength,
    giftMessageLength,
    personalisationMessageLength,
    numOfBundleProducts,
  ]);
}
