import { env } from '@selfridges-pkg/utils';

import type { MediaResponse } from '@selfridges-pkg/product-aggregation-service-api/contracts/MediaResponse';
import type { ProductResponse } from '@selfridges-pkg/product-aggregation-service-api/contracts/ProductResponse';

const MEDIA_ROOT_URL = () => env('REACT_APP_MEDIA_ROOT_URL') || '';

export function transformToMainImageUrl(productResponse: ProductResponse, mediaResponse?: MediaResponse) {
  let imageUrl = productResponse.media?.defaultImage;

  if (mediaResponse) {
    imageUrl = mediaResponse.defaultImage;
  }

  return `${MEDIA_ROOT_URL()}/image/${imageUrl}`;
}
