/* istanbul ignore file */
import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import i18n, { use } from 'i18next';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { LaunchDarklyProvider, useFlags } from '@selfridges-pkg/launch-darkly/client';
import { filterLdFlags, LdFlagProvider, LdFlags } from '@selfridges-pkg/feature-flag/LDContext';

import App from './App';
import { BrandConfigProvider } from './brand-configs/Context';

import type { BrandConfig } from './types';

const {
  pdp,
  initialI18nStore,
  initialI18nLanguage,
  countryLanguage,
  tealiumConfig,
  brandConfig,
  filteredLdFlags,
  pageUrl,
  isMfeTemplate,
} = window['__INITIAL_STATE__'];

const { REACT_APP_LAUNCH_DARKLY_CLIENT_ID } = window['__ENV__'];

use(initReactI18next).init({
  fallbackLng: 'en',
  ns: ['translations'],
  interpolation: {
    escapeValue: false, // react already safe from xss
  },
  resources: initialI18nStore,
  lng: initialI18nLanguage,
});

const queryClient = new QueryClient();

const RootComponent = () => {
  const ldFlags: LdFlags = filterLdFlags(useFlags()) || {};

  return (
    <I18nextProvider i18n={i18n}>
      <QueryClientProvider client={queryClient}>
        <LdFlagProvider flags={ldFlags}>
          <BrandConfigProvider config={brandConfig as BrandConfig}>
            <App
              data={pdp}
              pageUrl={pageUrl}
              countryLanguage={countryLanguage}
              tealiumConfig={tealiumConfig}
              isMfeTemplate={isMfeTemplate}
            />
          </BrandConfigProvider>
        </LdFlagProvider>
      </QueryClientProvider>
    </I18nextProvider>
  );
};

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: LaunchDarklyProvider(REACT_APP_LAUNCH_DARKLY_CLIENT_ID, filteredLdFlags)(RootComponent) as React.FC,
  renderType: 'hydrate',
  errorBoundary: () => {
    // Customize the root error boundary for your microfrontend here.
    return <div>Error rendering PDP component</div>;
  },
  domElementGetter: () => {
    const domElId = 'mf-app';
    let targetEl = document.getElementById(domElId);

    if (targetEl) return targetEl;

    targetEl = document.createElement('div');
    targetEl.id = domElId;

    document.body.appendChild(targetEl);

    return targetEl;
  },
});

export const { bootstrap, mount, unmount, update } = lifecycles;
