import { useCallback, useState, useMemo } from 'react';
import styled from 'styled-components';
import { t } from 'i18next';

import { Col, Grid, GridConfigs, Row } from '@selfridges-co/frontend-sdk-react-grid-system';
import { spacing, color, breakpoints } from '@selfridges-co/frontend-sdk-react-theme';
import Rating from '@selfridges-co/frontend-sdk-react-rating';
import Reviews from '@selfridges-co/frontend-sdk-react-reviews';
import StarFilter from '@selfridges-co/frontend-sdk-react-star-filter';

import { REVIEWS_PER_PAGE, INITIAL_NUM_OF_REVIEWS } from '../constants';

import type { RatingSummary, Review, Rating as RatingType } from '../types';

type StarDistribution = Record<RatingType, { numberOfRatings: number; ariaLabel: string }>;

type RatingReviewsProps = {
  gridConfigs: GridConfigs;
  reviews: Array<Review>;
  ratingSummary: RatingSummary;
  nextPage: () => void;
  updateStarFilter: (filter: Nullable<number>) => void;
  starFilter: Nullable<number>;
  isLoadingReviews: boolean;
};

const Container = styled.div({
  padding: spacing(16, 0),

  [breakpoints.md.mediaQuery]: {
    padding: spacing(20, 0),
  },
});

const RatingWrapper = styled.div({
  paddingBottom: spacing(6),
  boxShadow: `0px 1px 0px 0px ${color.palette.lightGrey1}`,
  marginBottom: spacing(6),
});

const StarFilterWrapper = styled.div({
  marginBottom: spacing(4),

  [breakpoints.md.mediaQuery]: {
    marginBottom: 0,
  },
});

function RatingReviews({
  gridConfigs,
  reviews,
  ratingSummary,
  nextPage,
  updateStarFilter,
  starFilter,
  isLoadingReviews,
}: RatingReviewsProps) {
  const [prevReviews, setPrevReviews] = useState(reviews);
  const [visibleReviews, setVisibleReviews] = useState(reviews.slice(0, INITIAL_NUM_OF_REVIEWS));

  if (reviews !== prevReviews) {
    setPrevReviews(reviews);

    if (reviews.length <= REVIEWS_PER_PAGE) {
      setVisibleReviews(reviews.slice(0, INITIAL_NUM_OF_REVIEWS));
    }
  }

  function onLoadMoreReviews() {
    setVisibleReviews(reviews);
    nextPage();
  }

  const onStarFilterClick = useCallback(
    (rating: Nullable<number>) => {
      updateStarFilter(rating);
    },
    [updateStarFilter],
  );

  const starDistribution: StarDistribution = useMemo(
    () =>
      Object.entries(ratingSummary.starDistribution).reduce(
        (starFilterRatings, [rating, numberOfRatings]) => ({
          ...starFilterRatings,
          [rating]: {
            numberOfRatings,
            ariaLabel: t('app.rating-reviews.star-filter.aria-label', {
              defaultValue: 'Filter {{rating}} stars',
              rating,
            }).toString(),
          },
        }),
        {},
      ) as StarDistribution,
    [ratingSummary.starDistribution],
  );

  const endOfReviewsLength: number = starFilter
    ? ratingSummary.starDistribution[starFilter]
    : ratingSummary.totalReviews;
  const reachedEndOfReviews = visibleReviews.length >= endOfReviewsLength;

  return (
    <Container data-analytics-component="ratings-and-reviews">
      <Grid {...gridConfigs}>
        <Row>
          <Col xxs={4} sm={6} md={4}>
            <RatingWrapper>
              <Rating
                type="STARS_WITH_RATING"
                rating={ratingSummary.score}
                aria-label={t('app.rating-reviews.rating.aria-label', {
                  defaultValue: 'Rated {{rating}} out of 5 stars, {{totalReviews}} reviews',
                  rating: ratingSummary.score,
                  totalReviews: ratingSummary.totalReviews,
                }).toString()}
                ratingTextSize="large"
                totalReviews={ratingSummary.totalReviews}
              />
            </RatingWrapper>
            <StarFilterWrapper>
              <StarFilter
                title={t('app.rating-reviews.star-filter.title', { defaultValue: 'Filter by rating' }).toString()}
                ratings={starDistribution}
                onStarButtonClick={onStarFilterClick}
              />
            </StarFilterWrapper>
          </Col>
          <Col xxs={0} sm={0} md={1} />
          <Col xxs={4} sm={6} md={7}>
            <Reviews
              reviews={visibleReviews.map(review => ({
                ...review,
                rating: {
                  score: review.rating,
                  ariaLabel: t('app.rating-reviews.reviews.aria-label', {
                    defaultValue: 'Rated {{rating}} out of 5 stars',
                    rating: review.rating,
                  }).toString(),
                },
              }))}
              onLoadMore={onLoadMoreReviews}
              loadMoreDisabled={isLoadingReviews || reachedEndOfReviews}
              staticText={{
                loadMoreReviews: t('app.rating-reviews.reviews.load-more-reviews', {
                  defaultValue: 'Read more reviews',
                }).toString(),
                showMore: t('app.rating-reviews.reviews.show-more-review-content', {
                  defaultValue: 'Show more',
                }).toString(),
                showLess: t('app.rating-reviews.reviews.show-less-review-content', {
                  defaultValue: 'Show less',
                }).toString(),
                verifiedBuyer: t('app.rating-reviews.reviews.verified-buyer', {
                  defaultValue: 'Verified buyer',
                }).toString(),
              }}
            />
          </Col>
        </Row>
      </Grid>
    </Container>
  );
}

export default RatingReviews;
