import { useMemo } from 'react';

import { countryInfo } from '@selfridges-pkg/intl';
import { getCurrentUKTime, parseUKTime } from '@selfridges-pkg/utils';

import type { DeliveryAndReturnsProps } from '../delivery-and-returns/DeliveryAndReturns';
import type { EndTime } from '../countdown-timer/useCountdownTimer';

type DeliveryAndReturnsPropsToTransform = Partial<DeliveryAndReturnsProps> &
  Pick<DeliveryAndReturnsProps, 'localDelivery' | 'clickAndCollect'>;

export type TimerLabel = 'COLLECT' | 'DELIVERY';

export type DeliveryCutoffs = {
  startTime: number;
  endTimes: Array<EndTime<TimerLabel, string>>;
};

// use __DELIVERY_COUNTDOWN_START_TIME__ for testing purposes
const START_TIME = (typeof window !== 'undefined' && window['__DELIVERY_COUNTDOWN_START_TIME__']) || '13:00:00';

const defaultDeliveryCutoffs = { startTime: 0, endTimes: [] };

export function useTransformDataToCountdown({
  localDelivery,
  clickAndCollect,
  showCountdownTimer,
}: DeliveryAndReturnsPropsToTransform & { showCountdownTimer: boolean }): DeliveryCutoffs {
  return useMemo(() => {
    if (
      !showCountdownTimer ||
      !clickAndCollect.isAvailable ||
      !localDelivery.options
        .find(option => option.region === countryInfo.GB.alt || option.region === countryInfo.GB.zh)
        ?.methods.find(method => method.cutoffTimeRaw !== null)
    ) {
      return defaultDeliveryCutoffs;
    }

    try {
      const todaysDate = new Date().toLocaleDateString('en-GB');
      const localTimeEpoch = Date.now();
      const localTimeUkTimeDiff = parseUKTime(getCurrentUKTime()) - localTimeEpoch;
      const deliveryOptions = localDelivery.options[0].methods.filter(method => Boolean(method.cutoffTimeRaw));

      const localDeliveryCutoffs = deliveryOptions
        .map(option => ({
          label: option.label,
          cutoffTime: parseUKTime(`${todaysDate}, ${option.cutoffTimeRaw}:00`) - localTimeUkTimeDiff,
        }))
        .map<EndTime<TimerLabel, string>>(option => ({
          time: option.cutoffTime,
          label: 'DELIVERY',
          context: option.label,
        }));

      const clickAndCollectCutoff: EndTime<TimerLabel, string> = {
        time: parseUKTime(`${todaysDate}, ${clickAndCollect.cutoffTimeRaw}:00`) - localTimeUkTimeDiff,
        label: 'COLLECT',
      };

      const endTimesOrdered = [clickAndCollectCutoff, ...localDeliveryCutoffs];
      endTimesOrdered.sort((a, b) => {
        if (a.time === b.time) {
          if (a.label === 'DELIVERY') {
            return -1;
          } else if (b.label === 'DELIVERY') {
            return 1;
          }
        }
        return a.time - b.time;
      });

      const startTime = parseUKTime(`${todaysDate}, ${START_TIME}`) - localTimeUkTimeDiff;

      return {
        startTime,
        endTimes: endTimesOrdered,
      };
    } catch {
      return defaultDeliveryCutoffs;
    }
  }, [localDelivery, clickAndCollect, showCountdownTimer]);
}
