import React from 'react';

import { env } from '@selfridges-pkg/utils';

const klarnaEnvironment = () => env('REACT_APP_KLARNA_ENVIRONMENT') || '';
const klarnaClientId = () => env('REACT_APP_KLARNA_CLIENT_ID') || '';

function KlarnaScript() {
  return (
    <script
      async
      src={'https://js.klarna.com/web-sdk/v1/klarna.js'}
      data-environment={klarnaEnvironment()}
      data-client-id={klarnaClientId()}
    ></script>
  );
}

export default KlarnaScript;
