import styled from 'styled-components';
import { Trans } from 'react-i18next';

import { typography } from '@selfridges-co/frontend-sdk-react-theme';

import CountdownTimer from '../countdown-timer/CountdownTimer';
import { TimerLabel } from '../transformers/useTransformDataToCountdown';

import { PropContainer } from './SalesProp';
import { CountdownTimerIcon } from './SalesPropIcons';

interface DeliveryCountdownProps {
  duration: { hours: number; minutes: number; seconds: number };
  label: TimerLabel | 'ACTIVE';
  context?: string;
}

const BoldFont = styled.span({
  ...typography.typeface.text.sm.bold,
});

export default function DeliveryCountdown({ label, duration, context }: DeliveryCountdownProps) {
  return (
    <PropContainer key="countdown-timer" icon={<CountdownTimerIcon />}>
      <div>
        <p>
          {label === 'COLLECT' ? (
            <Trans i18nKey="app.sales-prop.delivery-countdown.prompt-message.line-1.click-and-collect">
              Collect it tomorrow? Order within
            </Trans>
          ) : (
            <Trans i18nKey="app.sales-prop.delivery-countdown.prompt-message.line-1.delivery">
              Want it tomorrow? Order within
            </Trans>
          )}
          <CountdownTimer hours={duration.hours} minutes={duration.minutes} seconds={duration.seconds} />
        </p>
        <p>
          {label === 'COLLECT' ? (
            <Trans i18nKey="app.sales-prop.delivery-countdown.prompt-message.line-2.click-and-collect">
              Choose <BoldFont>Click & Collect</BoldFont> at checkout
            </Trans>
          ) : (
            <Trans i18nKey="app.sales-prop.delivery-countdown.prompt-message.line-2.delivery">
              Choose <BoldFont>{{ context }}</BoldFont> at checkout
            </Trans>
          )}
        </p>
      </div>
    </PropContainer>
  );
}
