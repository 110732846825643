import styled from 'styled-components';

export const VisuallyHidden = styled.div({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '0.0625rem !important',
  height: '0.0625rem !important',
  padding: 0,
  margin: ' -0.0625rem',
  overflow: 'hidden',
  border: 0,
  outline: 'none !important',
});
