import { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components';

import { breakpoints } from '@selfridges-co/frontend-sdk-react-theme';
import { isMobile } from '@selfridges-pkg/utils';

import { MEGAMENU_HEIGHT } from '../constants';

const LowerBoundaryContainer = styled.div({
  width: '100%',
  height: 0,
  overflow: 'hidden',
  position: 'relative',
});

const UpperBoundaryContainer = styled.div({
  width: '100%',
  height: 0,
  position: 'relative',
});

const Intersector = styled.div<{ useOnMobile: boolean; useOnDesktop: boolean }>(({ useOnMobile, useOnDesktop }) => ({
  width: '100%',
  height: 0,
  position: 'absolute',
  top: 0,
  left: 0,
  pointerEvents: 'none',
  display: useOnMobile ? 'block' : 'none',
  [breakpoints.md.mediaQuery]: {
    display: useOnDesktop ? 'block' : 'none',
  },
}));

function resizeIntersection(
  setIsNegative: React.Dispatch<React.SetStateAction<boolean>>,
  upperBoundary?: HTMLDivElement | null,
  lowerBoundary?: HTMLDivElement | null,
  intersector?: HTMLDivElement | null,
) {
  if (!upperBoundary || !lowerBoundary || !intersector || !window) return;

  const startPos = upperBoundary.getBoundingClientRect().y + window.scrollY;
  const endPos = lowerBoundary.getBoundingClientRect().y + window.scrollY;
  const menuOffset = isMobile() ? MEGAMENU_HEIGHT.MOBILE : MEGAMENU_HEIGHT.DESKTOP;

  // if the bottom is above the top check the intersector is visible
  if (endPos - window.innerHeight < startPos + window.innerHeight) {
    setIsNegative(true);

    const intersectorTop = endPos - window.innerHeight - startPos;
    intersector.style.top = `calc(${intersectorTop}px)`;

    intersector.style.height = `calc(${window.innerHeight - intersectorTop}px - (${menuOffset}))`;
  } else {
    // if the bottom is below the top check only part is intersecting
    setIsNegative(false);

    const intersectionHeight = endPos - startPos - window.innerHeight - window.innerHeight;
    intersector.style.top = `calc(${window.innerHeight}px - ${menuOffset})`;
    intersector.style.height = `calc(${intersectionHeight}px + (${menuOffset}))`;
  }
}

export function useIntersectionArea({
  useOnMobile = false,
  useOnDesktop = false,
  intersectTop = true,
}: {
  useOnMobile?: boolean;
  useOnDesktop?: boolean;
  intersectTop?: boolean;
}) {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [isNegative, setIsNegative] = useState(false);
  const upperBoundaryEl = useRef<HTMLDivElement>(null);
  const lowerBoundaryEl = useRef<HTMLDivElement>(null);

  const updateIntersection = useCallback(() => {
    resizeIntersection(
      setIsNegative,
      upperBoundaryEl.current,
      lowerBoundaryEl.current,
      upperBoundaryEl.current?.children[0] as HTMLDivElement,
    );
  }, [setIsNegative]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      intersections => {
        intersections.forEach(intersection => {
          const topInView = intersection.boundingClientRect.top >= (intersection.rootBounds?.top || 0);
          const bottomInView = intersection.boundingClientRect.bottom <= (intersection.rootBounds?.bottom || 0);

          if (intersectTop && !isNegative) {
            // if not negative use normal intersection checking
            setIsIntersecting(Boolean(intersection.isIntersecting));
          } else if (intersectTop) {
            // check if the whole div is intersecting
            setIsIntersecting(bottomInView && topInView);
          } else if (!intersectTop && !isNegative) {
            // if any part is intersecting and the top is below the upper bounds
            setIsIntersecting(Boolean(intersection.isIntersecting) || topInView);
          } else if (!intersectTop) {
            // once the top is off screen it should hide (ie. bottom of screen is in view)
            setIsIntersecting(topInView);
          }
        });
      },
      { threshold: [0, 1] },
    );

    if (upperBoundaryEl.current) {
      observer.observe(upperBoundaryEl.current.children[0]);
    }

    updateIntersection();

    window.addEventListener('resize', updateIntersection);

    return () => {
      observer.disconnect();
      window.removeEventListener('resize', updateIntersection);
    };
  }, [isNegative, intersectTop, updateIntersection]);

  const UpperBoundary = useMemo(
    () => (
      <UpperBoundaryContainer ref={upperBoundaryEl}>
        <Intersector useOnMobile={useOnMobile} useOnDesktop={useOnDesktop} />
      </UpperBoundaryContainer>
    ),
    [useOnMobile, useOnDesktop],
  );

  const LowerBoundary = useMemo(() => <LowerBoundaryContainer ref={lowerBoundaryEl} />, []);

  return {
    isIntersecting,
    UpperBoundary,
    LowerBoundary,
    updateIntersection,
  };
}
