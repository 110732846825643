import styled from 'styled-components';
import { Trans } from 'react-i18next';

import { color, spacing, typography } from '@selfridges-co/frontend-sdk-react-theme';
import { Link } from '@selfridges-co/frontend-sdk-react-link';

import { InfoToast } from '../hooks/useToastMessage';

const Text = styled.p({
  ...typography.typeface.display.xs,
  color: color.palette.mainWhite,
  width: '100%',
});

const AddToWishList = styled.div({
  flexShrink: 0,
  marginLeft: spacing(4),
});

const Container = styled(InfoToast)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  pointerEvents: 'auto',
});

const CenteredText = styled.div({
  textAlign: 'center',
});

export function ToastMessage({
  addedToWishList,
  countryLanguage,
}: {
  addedToWishList: boolean;
  countryLanguage: string;
}) {
  return (
    <Container>
      <Text>
        {addedToWishList ? (
          <Trans i18nKey="app.wish-list.added-to-wishlist">Item added to Wish list!</Trans>
        ) : (
          <CenteredText>
            <Trans i18nKey="app.wish-list.removed-from-wishlist">Item removed from Wish list!</Trans>
          </CenteredText>
        )}
      </Text>
      {addedToWishList && (
        <AddToWishList>
          <Link linkType="primary" variant="yellow" href={`/${countryLanguage}/app/wishlist`}>
            <Trans i18nKey="app.wish-list.view-wishlist">View Wish list</Trans>
          </Link>
        </AddToWishList>
      )}
    </Container>
  );
}
