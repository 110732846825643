import styled from 'styled-components';
import { Trans } from 'react-i18next';

import { spacing, typography } from '@selfridges-co/frontend-sdk-react-theme';

export type ClickAndCollectOption = {
  isAvailable: boolean;
  cutoffTimeRaw?: string | null;
};

const Title = styled.h3({
  ...typography.typeface.text.lg.bold,
  marginBottom: spacing(3),
});

const Container = styled.div({
  marginBottom: spacing(10),
});

const Message = styled.p({
  ...typography.typeface.text.md.regular,
  margin: 0,
});

function ClickAndCollect({ isAvailable }: ClickAndCollectOption) {
  return (
    <Container>
      <Title>
        <Trans i18nKey="app.delivery-and-returns.click-and-collect.title">Complimentary UK Click & Collect</Trans>
      </Title>
      <Message>
        {isAvailable ? (
          <Trans i18nKey="app.delivery-and-returns.click-and-collect.available">
            Enjoy delivery to your chosen store, on a day that suits you. Order until 10pm for next day collection.
          </Trans>
        ) : (
          <Trans i18nKey="app.delivery-and-returns.click-and-collect.unavailable">
            Sorry, this product is unavailable for UK Click & Collect
          </Trans>
        )}
      </Message>
    </Container>
  );
}

export default ClickAndCollect;
