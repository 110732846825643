import { cleanHtmlString } from '../utils';

import { PropContainer } from './SalesProp';
import { RosetteIcon, KeyIcon } from './SalesPropIcons';

export function PromotionalMessage({ content }: { content: string }) {
  let icon = <RosetteIcon />;

  if (content.toLowerCase().includes('free delivery')) {
    icon = <KeyIcon />;
  }

  return (
    <PropContainer icon={icon}>
      <div
        dangerouslySetInnerHTML={{
          __html: cleanHtmlString(content),
        }}
      />
    </PropContainer>
  );
}
