import { useMemo } from 'react';

import env from '@selfridges-pkg/utils/env';
import { useLdFlags } from '@selfridges-pkg/feature-flag/LDContext';
import {
  Price,
  ProductRecommendationsResponse,
} from '@selfridges-pkg/product-aggregation-service-api/contracts/ProductRecommendationsResponse';
import { AvailableCountryCode } from '@selfridges-pkg/intl/types';

import { AnalyticsProductArray } from '../analytics';

import { transformPriceWithCurrency } from './transformPriceWithCurrency';
import { getPriceByCountryCode } from './helpers';

import type { ProductCarouselProps } from '@selfridges-co/frontend-sdk-react-native-carousel';

type CarouselPropsToTransform = (Partial<ProductCarouselProps> & Pick<ProductCarouselProps, 'slides'>) | null;

const MEDIA_ROOT_URL = () => env('REACT_APP_MEDIA_ROOT_URL') || '';

const buildImageUrl = (url?: string | null, altUrl?: string | null) =>
  (url && `${url.startsWith('//') ? 'https:' : ''}${url}`) ||
  (altUrl && `${altUrl.startsWith('//') ? 'https:' : ''}${altUrl}`) ||
  `${MEDIA_ROOT_URL()}/image/selfridges/image-not-found-selfridges`; // prevent Picture to throw an exception

function transformPrices(prices: Array<Price>) {
  return prices.map(price => {
    return {
      currency: price.currency,
      current: price.price,
      was: price.markdownPrice || null,
      wasWas: price.prevMarkdownPrice || null,
    };
  });
}

export function useTransformProductDataToRecommendedForYouProps(
  countryLanguage: string,
  userRecommendations?: ProductRecommendationsResponse,
  hideRecommendedForYou?: boolean,
): CarouselPropsToTransform {
  const ldFlags = useLdFlags();
  const enableNewProductRoute = ldFlags['enable-new-product-route'];
  const enableRecommendedForYou = ldFlags['enable-pdp-perso-rec'];

  // eslint-disable-next-line sonarjs/cognitive-complexity
  return useMemo(() => {
    const { recommendations } = userRecommendations ?? {};
    const validRecommendations = recommendations?.filter(({ defaultImageUrl }) => defaultImageUrl);
    if (!validRecommendations || !validRecommendations.length || hideRecommendedForYou || !enableRecommendedForYou)
      return null;

    return {
      slides: validRecommendations.map(
        ({
          seoKey,
          brand,
          defaultImageUrl,
          altImageUrl,
          description,
          name,
          lowestPrice: lowestPrices,
          productType,
          productId,
          displayFlag,
          division,
          department,
        }) => {
          const [countryCode] = countryLanguage.split('/') as [AvailableCountryCode];
          const prices = lowestPrices ? transformPrices(lowestPrices) : null;

          const localePrice = getPriceByCountryCode(prices, countryCode) || prices?.[0];
          const gbpPrice = getPriceByCountryCode(prices, 'GB') || prices?.[0];

          const { current, was, wasWas } = localePrice
            ? transformPriceWithCurrency({
                currency: localePrice.currency,
                current: localePrice.current,
                was: localePrice.was,
                wasWas: localePrice.wasWas,
              })
            : { current: null, was: null, wasWas: null };

          return {
            brandName: brand || '',
            description: `${description || name || ''}`.trim(),
            targetUrl: `/${countryLanguage}/${enableNewProductRoute ? 'product' : 'cat'}/${seoKey}/`,
            ...(current && { lowestPrice: current }),
            ...(was && { lowestWasPrice: was }),
            ...(wasWas && { lowestWasWasPrice: wasWas }),
            analyticsDetails: (<AnalyticsProductArray>[
              name,
              productType,
              productId,
              gbpPrice?.current || '',
              brand,
              productId,
              gbpPrice?.was || '',
              gbpPrice?.wasWas || '',
              displayFlag,
              division?.name || '',
              department?.name || '',
            ]).join('|'),
            imageUrl: buildImageUrl(defaultImageUrl),
            hoverImageUrl: buildImageUrl(altImageUrl, defaultImageUrl),
          };
        },
      ),
    };
  }, [countryLanguage, userRecommendations, hideRecommendedForYou, enableRecommendedForYou, enableNewProductRoute]);
}
