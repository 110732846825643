import React from 'react';
import { t } from 'i18next';

import NotificationBox from '@selfridges-co/frontend-sdk-react-notification-box';

import { OutOfStockErrorMessage } from './OutOfStockErrorMessage';

import type { ErrorKey } from '@selfridges-pkg/wcs-api/contracts/AddToCartResponse';

type ErrorMessageProps = {
  errorKey: ErrorKey | 'SELECT_SIZE';
  errorMessage?: string;
  addToWishList?: () => void;
};

type Error = {
  title?: string;
  paragraph?: string;
  Component?: JSX.Element;
};

export function ErrorMessage({ errorKey, errorMessage, addToWishList }: ErrorMessageProps) {
  const error: Error = {};

  switch (errorKey) {
    case 'CART.CHECK_QUANTITY_RULES.EXCEEDED_MAX_QUANTITY_PER_CUSTOMER': {
      const findMaxAmount = /\d+/.exec(errorMessage || '');
      const maxAmount = findMaxAmount && Number.parseInt(findMaxAmount[0], 10);

      if (maxAmount) {
        error.title = t('app.add-to-cart.error.exceeded-max-quantity', {
          defaultValue: 'Sorry, you can only buy a maximum of {{maxAmount}} of this product',
          maxAmount,
        }).toString();

        break;
      } else {
        error.title = t('app.add-to-cart.error.exceeded-max-quantity-fallback', {
          defaultValue: 'Sorry, you have exceeded the max purchase quantity for this product',
        }).toString();

        break;
      }
    }
    case 'CART.PROCESSITEMGIFTPACKAGING.GIFTBOX_UNABLE_TO_PROCESS':
      error.title = t('app.add-to-cart.error.gift-message-unavailable.title', {
        defaultValue: 'Sorry, gift messaging is unavailable',
      }).toString();
      error.paragraph = t('app.add-to-cart.error.gift-message-unavailable.text', {
        defaultValue:
          'We’re not able to add gift messaging at this time, you can still purchase without a gift message',
      }).toString();

      break;
    case 'CART.CHECK_PERSONALISATION_RULES.PZN_MESSAGE_REQUIRED':
    case 'CART.CHECK_PERSONALISATION_RULES.PZN_CHARGE_PARTNUMBER_DOES_NOT_EXIST':
    case 'CART.CHECK_PERSONALISATION_RULES.PZN_MESSAGE_REJECTED_BY_PZNREGEX':
      error.title = t('app.add-to-cart.error.personalisation-unavailable.title', {
        defaultValue: 'Sorry, personalisation is unavailable',
      }).toString();
      error.paragraph = t('app.add-to-cart.error.personalisation-unavailable.text', {
        defaultValue:
          'We’re not able to add personalisation at this time, you can still purchase without personalisation',
      }).toString();

      break;
    case 'SELECT_SIZE':
      error.title = t('app.add-to-cart.error.select-size.title', {
        defaultValue: 'Please select a size',
      }).toString();

      break;
    case 'CART.ITEMS.NOT_IN_STOCK':
    case 'ERR_INTERNAL_SERVER_ERROR':
    default:
      if (!addToWishList) break;
      error.Component = <OutOfStockErrorMessage onClick={addToWishList} />;
      break;
  }

  if (error.Component) {
    return <NotificationBox variant="error">{error.Component}</NotificationBox>;
  }

  return <NotificationBox variant="error" title={error.title} paragraph={error.paragraph} />;
}
