import { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { breakpoints, spacing } from '@selfridges-co/frontend-sdk-react-theme';

import { useIsServerSide } from '../hooks/useIsServerSide';

type BottomToastProps = {
  offsetTop: number;
};

const ToastContainer = styled.div({
  position: 'fixed',
  height: ' 100%',
  width: '1%', // don't cover the screen as false positive in pa11y but keep relative sizing
  zIndex: 100,
  pointerEvents: 'none',
  top: 0,
  [breakpoints.md.mediaQuery]: {
    position: 'absolute',
  },
});

const ToastSticky = styled.div<{ offsetTop: number }>(({ offsetTop }) => ({
  height: `calc(100dvh - ${offsetTop}px)`,
  width: '100%',
  position: 'sticky',
  top: `${offsetTop}px`,
  transition: 'height 0.1s linear',
}));

const Toast = styled.div({
  position: 'absolute',
  boxSizing: 'border-box',
  pointerEvents: 'none',
  bottom: 0,
  left: 0,
  width: '10000%', // restore sizing to 100% for pa11y
  padding: spacing(4),
  [breakpoints.md.mediaQuery]: {
    padding: spacing(6),
  },
});

export default function BottomToast({ offsetTop, children }: PropsWithChildren<BottomToastProps>) {
  const isServerSide = useIsServerSide();

  if (isServerSide) return null;

  return (
    <ToastContainer>
      <ToastSticky offsetTop={offsetTop}>
        <Toast>{children}</Toast>
      </ToastSticky>
    </ToastContainer>
  );
}
