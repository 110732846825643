import { AvailableCountryCode, AvailableLanguage } from '@selfridges-pkg/intl/types';

import { Breadcrumbs } from '../types';

import { whitelistCategories } from './whitelistCategories';

export function isRatingReviewsProduct(breadcrumbs: Breadcrumbs, countryLanguage: string): boolean {
  const [countryCode] = countryLanguage.split('/') as [AvailableCountryCode, AvailableLanguage];

  const fullBreadcrumbSeo = [...breadcrumbs].pop()?.seo || '';

  const matchedWhitelistCategories = whitelistCategories.filter(item => fullBreadcrumbSeo.includes(item.key));

  if (!matchedWhitelistCategories.length) return false;

  return !matchedWhitelistCategories.some(
    ({ locales: whitelistLocales }) =>
      typeof whitelistLocales !== 'undefined' && !whitelistLocales.includes(countryCode),
  );
}
