export function getCookie(name: string) {
  const cookieName = `${name}=`;
  const decodedCookie = decodeURIComponent(document.cookie);

  const cookieEntry = decodedCookie.split(';').find(cookiesStr => {
    return cookiesStr.trim().startsWith(cookieName);
  });

  if (cookieEntry) return cookieEntry.split(cookieName)[1];

  return '';
}

export function setCookie(name: string, value: string) {
  document.cookie = `${name}=${value};path=/;`;
}
