import { Trans } from 'react-i18next';
import styled from 'styled-components';

import { countryInfo } from '@selfridges-pkg/intl';
import { countryFlag } from '@selfridges-pkg/intl/countryFlag';
import { getStaticResourceUrl } from '@selfridges-pkg/utils';
import { color, spacing, typography, breakpoints } from '@selfridges-co/frontend-sdk-react-theme';

import { DELIVERY_RESTRICTION_BANNER_HEIGHT, MEGAMENU_HEIGHT } from '../constants';

import type { AvailableCountryCode, AvailableLanguage } from '@selfridges-pkg/intl/types';

interface DeliveryRestrictionBannerProps {
  countryCode: AvailableCountryCode;
  language: AvailableLanguage;
}

const Banner = styled.div({
  ...typography.typeface.text.sm.regular,
  color: color.palette.mainWhite,
  background: color.palette.informativeBlue,
  position: 'sticky',
  height: DELIVERY_RESTRICTION_BANNER_HEIGHT,
  top: MEGAMENU_HEIGHT.MOBILE,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 3,

  img: {
    width: '32px',
    height: '24px',
    marginRight: spacing(3),
  },

  [breakpoints.md.mediaQuery]: {
    top: MEGAMENU_HEIGHT.DESKTOP,
  },
});

function DeliveryRestrictionBanner({ countryCode, language }: DeliveryRestrictionBannerProps) {
  const countryName = countryInfo[countryCode][language];
  return (
    <Banner>
      <img src={getStaticResourceUrl(countryFlag[countryCode])} alt={countryName} />
      <span>
        <Trans i18nKey="app.intl-delivery-restriction-text">
          Not available for delivery to {{ country: countryName }}
        </Trans>
      </span>
    </Banner>
  );
}

export default DeliveryRestrictionBanner;
