import React, { useEffect, useState } from 'react';
import styled, { CSSObject } from 'styled-components';

import { color, spacing, breakpoints, typography } from '@selfridges-co/frontend-sdk-react-theme';
import { Row, Col } from '@selfridges-co/frontend-sdk-react-grid-system';
import { isMobile } from '@selfridges-pkg/utils';

import {
  PRODUCT_PANEL_WIDTH,
  SALES_PROP_DELAY_IN_MS,
  REMOVED_BODY_SCROLL_BAR_SIZE_ON_MODAL_OPEN,
  MAX_SCREEN_WIDTH,
} from '../constants';
import { useSalesProp } from '../sales-prop/useSalesProp';

import type { PromotionalMessages } from '../types';
import type { DeliveryCutoffs } from '../transformers/useTransformDataToCountdown';

type SalesPropContainerProps = React.PropsWithChildren<{ icon?: JSX.Element }>;

type SalesPropProps = {
  promotionalMessages?: PromotionalMessages;
  ukDeliveryOnly: boolean;
  countryCode: string;
  clickAndCollectOnly: boolean;
  deliveryCutoffs: DeliveryCutoffs;
  salesPropisIntersecting?: boolean;
  outOfStock: boolean;
};

const SIDE_GAP = spacing(6);

const SalesPropDesktopContainer = styled.div({
  display: 'none',

  [breakpoints.md.mediaQuery]: {
    display: 'block',
    position: 'fixed',
    bottom: spacing(6),
    left: '50%',
    maxWidth: MAX_SCREEN_WIDTH.DESKTOP,
    transform: 'translateX(-50%)',
    width: '100%',
    pointerEvents: 'none',
  },
});

const cssDividerVertical: CSSObject = {
  content: '" "',
  position: 'absolute',
  top: '0',
  left: '50%',
  width: '100%',
  height: `1px`,
  transform: 'translateX(-50%)',
  background: color.palette.midGrey2,
};

const cssDividerHorizontal: CSSObject = {
  ...cssDividerVertical,
  top: '50%',
  left: '-0.5px',
  width: '1px',
  height: `calc(100% - ${spacing(8)})`,
  transform: 'translateY(-50%)',
};

const MobileRow = styled(Row)({
  display: 'block',
  paddingBottom: spacing(10),

  [breakpoints.md.mediaQuery]: {
    display: 'none',
  },
});

const FlexContainer = styled.div<{ salesPropisIntersecting?: boolean; multiMessage: boolean }>(
  ({ salesPropisIntersecting = true, multiMessage }) => ({
    flexDirection: 'column',
    backgroundColor: color.palette.mainWhite,
    width: `100%`,

    [breakpoints.sm.mediaQuery]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      marginLeft: spacing(-6),
    },

    [breakpoints.md.mediaQuery]: {
      gridTemplateColumns: 'repeat(auto-fit, minmax(0px, 1fr))',
      marginLeft: spacing(6),
      width: multiMessage
        ? `calc(100% - ${PRODUCT_PANEL_WIDTH.DESKTOP} - (${SIDE_GAP} * 2) - ${REMOVED_BODY_SCROLL_BAR_SIZE_ON_MODAL_OPEN})`
        : `calc(((100% - ${PRODUCT_PANEL_WIDTH.DESKTOP}) / 2) - (${SIDE_GAP} * 2) - ${REMOVED_BODY_SCROLL_BAR_SIZE_ON_MODAL_OPEN})`,
      boxShadow: '0px 2px 16px rgba(0 0 0, 0.04)',
      transition: 'transform 0.5s, opacity 0.5s',
      transform: `translateY(${salesPropisIntersecting ? 0 : 100}%)`,
      opacity: salesPropisIntersecting ? 1 : 0,
      pointerEvents: salesPropisIntersecting ? 'all' : 'none',
    },
  }),
);

const Slot = styled.div({
  position: 'relative',
  paddingBottom: spacing(4),

  ':nth-child(n+2)': {
    paddingTop: spacing(4),

    ':after': {
      ...cssDividerVertical,
    },
  },

  [breakpoints.sm.mediaQuery]: {
    paddingLeft: spacing(6),

    ':nth-child(n+2)': {
      marginTop: spacing(-4),
      ':after': {
        display: 'none',
      },
    },

    ':nth-child(even)': {
      ':after': {
        ...cssDividerHorizontal,
        display: 'block',
      },
    },

    ':nth-child(n+3)': {
      paddingTop: spacing(4),
    },
  },

  [breakpoints.md.mediaQuery]: {
    ':nth-child(n+2)': {
      marginTop: 0,
      padding: spacing(4, 6),
      ':after': {
        ...cssDividerHorizontal,
        display: 'block',
      },
    },
    padding: spacing(4, 6),
  },
});

const Wrapper = styled.div({
  ...typography.typeface.text.sm.regular,
  display: 'flex',
  whiteSpace: 'pre-line',
});

const Icon = styled.div({
  width: spacing(12),
  height: spacing(12),
  paddingRight: spacing(4),
  flexShrink: 0,

  [breakpoints.md.mediaQuery]: {
    display: 'none',
  },

  [breakpoints.lg.mediaQuery]: {
    display: 'block',
  },
});

const Content = styled.div({
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,

  'b, strong': {
    ...typography.typeface.text.sm.bold,
    fontWeight: 400,
  },

  a: {
    ...typography.typeface.text.sm.bold,
    color: color.palette.mainBlack,
  },
});

export function PropContainer({ icon, children }: SalesPropContainerProps) {
  return (
    <Wrapper>
      {icon && <Icon>{icon}</Icon>}
      <Content>{children}</Content>
    </Wrapper>
  );
}

export function SalesPropDesktop({
  salesPropisIntersecting = false,
  promotionalMessages,
  ukDeliveryOnly,
  clickAndCollectOnly,
  countryCode,
  deliveryCutoffs,
  outOfStock,
}: SalesPropProps) {
  const [display, setDisplay] = useState(isMobile());

  const messages = useSalesProp({
    promotionalMessages,
    ukDeliveryOnly,
    clickAndCollectOnly,
    countryCode,
    deliveryCutoffs,
    outOfStock,
  });

  useEffect(() => {
    const timeout = setTimeout(() => setDisplay(true), SALES_PROP_DELAY_IN_MS);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return messages.length === 0 ? null : (
    <SalesPropDesktopContainer>
      <FlexContainer salesPropisIntersecting={salesPropisIntersecting && display} multiMessage={messages.length > 1}>
        {messages.map((message, index) => {
          return <Slot key={index}>{message}</Slot>;
        })}
      </FlexContainer>
    </SalesPropDesktopContainer>
  );
}

export function SalesPropMobile({
  promotionalMessages,
  ukDeliveryOnly,
  clickAndCollectOnly,
  countryCode,
  deliveryCutoffs,
  outOfStock,
}: SalesPropProps) {
  const messages = useSalesProp({
    promotionalMessages,
    ukDeliveryOnly,
    clickAndCollectOnly,
    countryCode,
    deliveryCutoffs,
    outOfStock,
  });

  return messages.length === 0 ? null : (
    <MobileRow>
      <Col xxs={4} sm={6} md={12}>
        <FlexContainer multiMessage={messages.length > 1}>
          {messages.map((message, index) => {
            return <Slot key={index}>{message}</Slot>;
          })}
        </FlexContainer>
      </Col>
    </MobileRow>
  );
}
