import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { env } from '@selfridges-pkg/utils';
import { fetchSizeGuide } from '@selfridges-pkg/product-aggregation-service-api/fetchSizeGuide';

import type { SizeGuideResponse } from '@selfridges-pkg/product-aggregation-service-api/contracts/SizeGuideResponse';

type FetchSizeGuideResponse = {
  data?: SizeGuideResponse;
  isLoading: boolean;
};

const PRODUCT_API_HOST = () => env('REACT_APP_PRODUCT_API_HOST') || '';
const PRODUCT_API_KEY = () => env('REACT_APP_PRODUCT_API_KEY') || '';

export function useFetchSizeGuide({
  countryLanguage,
  categoryName,
  brandName,
}: {
  countryLanguage: string;
  categoryName: string;
  brandName: string;
}): FetchSizeGuideResponse {
  const [dataKey, setDataKey] = useState<string | null>(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search);
    setDataKey(searchParams.get('sizeGuideDataKey') || '');
  }, []);

  const { data, isLoading } = useQuery({
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    queryKey: ['fetch-size-guide'],
    queryFn: () =>
      fetchSizeGuide({
        hostRootUrl: PRODUCT_API_HOST(),
        countryLanguage,
        category: categoryName,
        brand: brandName,
        additionalHeaders: {
          ...(PRODUCT_API_KEY() && { 'x-api-key': PRODUCT_API_KEY() }),
          ...(dataKey && { 'X-Size-Guide-Data-Key': dataKey }),
        },
      }),
  });

  return { data, isLoading };
}
