import { MouseEvent } from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

import { spacing } from '@selfridges-co/frontend-sdk-react-theme';
import { ButtonLink, ButtonLinkOwnProps, ButtonType } from '@selfridges-co/frontend-sdk-react-button-link';

type AddPersonalisedMessageButtonProps = {
  onClick: (event?: MouseEvent<HTMLElement>) => void;
  price?: string;
  type: ButtonType;
};

const PERSONALISED_ICON_SVG =
  "data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.92535 15.224L4.5 19.5L8.77605 18.0747C8.92332 18.0256 9.05715 17.9429 9.16692 17.8331L19.5 7.5L16.5 4.5L6.16693 14.8331C6.05715 14.9428 5.97444 15.0767 5.92535 15.224Z' stroke='%23212121' stroke-linejoin='round'/%3e%3cpath d='M14.5 6.5L17.5 9.5' stroke='%23212121'/%3e%3c/svg%3e";

const Button = styled(ButtonLink).attrs({ as: 'button' })<ButtonLinkOwnProps>({
  border: 'none',
  cursor: 'pointer',
});

const PersonalisedIcon = styled.span({
  marginRight: spacing(2),
  width: '24px',
  height: '24px',
  display: 'inline-block',
  verticalAlign: 'middle',

  ':before': {
    content: "''",
    display: 'block',
    backgroundImage: `url("${PERSONALISED_ICON_SVG}")`,
    width: '100%',
    height: '100%',
  },
});

function AddPersonalisedMessageButton({ onClick, type, price }: AddPersonalisedMessageButtonProps) {
  return (
    <Button buttonType={type} onClick={onClick} fullWidth>
      <PersonalisedIcon />
      {price ? (
        <Trans i18nKey="app.personalised.add-personalised-message-button.text-with-price" price={price}>
          Personalise this item - {{ price }}
        </Trans>
      ) : (
        <Trans i18nKey="app.personalised.add-personalised-message-button.text">Personalise this item</Trans>
      )}
    </Button>
  );
}

export default AddPersonalisedMessageButton;
